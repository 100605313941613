export default {
  /**
   * valida se cvv é superior ao mes atual
   */
  validateCvvCard: (dataValidade: string): boolean => {
    // Obtém a data atual
    const dataAtual = new Date();
    const anoAtual = dataAtual.getFullYear();
    const mesAtual = dataAtual.getMonth() + 1;

    // Obtém o mês e o ano da data de validade
    const mes = parseInt(dataValidade.substr(0, 2));
    const ano = parseInt(dataValidade.substr(3, 2)) + 2000;

    // Verifica se a data de validade é maior ou igual à data atual
    if (ano < anoAtual || (ano === anoAtual && mes < mesAtual)) {
      return false;
    }

    return true;
  },

  /**
   * Valida CPF para nao haver  números muito repetidos
   */
  validateCpf: (cpf: string) => {
    // Remove caracteres especiais (pontos e hífens)
    cpf = cpf.replace(/[^\d]+/g, "");

    // Verifica se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
      return false;
    }

    // Verifica se todos os dígitos são iguais
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }

    // Verifica se o CPF é válido usando os dígitos verificadores
    let soma = 0;
    let resto;
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(9, 10))) {
      return false;
    }
    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(10, 11))) {
      return false;
    }

    // Verifica se o CPF não contém sequências de 0 ou números muito repetidos
    const sequenciaZeros = "00000000000";
    if (cpf === sequenciaZeros) {
      return false;
    }
    for (let i = 0; i <= 9; i++) {
      const sequenciaNumeros = "" + i + i + i + i + i + i + i + i + i + i;
      if (cpf === sequenciaNumeros) {
        return false;
      }
    }

    return true;
  },

  validateCNPJ: (cnpj: string) => {
    if (!cnpj) return false;
    // Remover caracteres especiais
    cnpj = cnpj.replace(/[^\d]+/g, "");

    // Verificar se o CNPJ possui 14 dígitos
    if (cnpj.length !== 14) {
      return false;
    }

    // Verificar se todos os dígitos são iguais
    if (/^(\d)\1+$/.test(cnpj)) {
      return false;
    }

    // Validar CNPJ
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado.toString() !== digitos.charAt(0)) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado.toString() !== digitos.charAt(1)) {
      return false;
    }

    return true;
  },
  /**
   * Valida se data é maior que hoje
   */
  validateDateBeforeToday: (date: Date) => {
    const today = new Date();
    const dataInput = new Date(date);

    return dataInput < today;
  },
};
