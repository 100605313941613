import { HeaderStyled, DescriptionStyled, SubTitle } from './styles';
import { Invite } from '../../../../shared/interfaces/invite.interface';

type ContentHeaderProps = {
  invite: Invite;
  step: number;
};

const ContentHeader = ({ invite, step }: ContentHeaderProps) => {
  function getTitle() {
    switch (step) {
      case 1:
        return <HeaderStyled>Olá, {invite?.name}!</HeaderStyled>;

        break;
      case 2:
        return <SubTitle>Preencha com seu endereço</SubTitle>;

        break;
      case 3:
        return <SubTitle>Cadastre uma senha</SubTitle>;

        break;

      default:
        break;
    }
    return <></>;
  }

  return (
    <>
      <>{getTitle()}</>
      {step == 1 && <DescriptionStyled>Agora você faz parte do melhor benefício visual do mundo. Bem-vindo(a) à nossa tribo 💙</DescriptionStyled>}
    </>
  );
};

export default ContentHeader;
