import styled from "styled-components"
import * as colors from "../../../../shared/styles/colors";
import BgBlue from "../../assets/images/bg-blue.svg";



export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  gap: 2rem;
  padding: 44px 0 62px 0;

  overflow: auto; // obs

  /* background: linear-gradient(
      360deg,
      rgba(51, 255, 182, 0.44) 1.02%,
      rgba(51, 255, 182, 0) 103.53%
    ),
    radial-gradient(
      131.31% 131.31% at 72.81% 43.51%,
      #407bff 0%,
      rgba(40, 69, 220, 0) 100%
    ); */

  /* background-color: rgb(40, 69, 220); */
  background-image: url(BgBlue);
  background-color: ${colors.primaryColor};
  background-size: cover;
  background-position: inherit;
  background-repeat: no-repeat;
  .i-btn-goback {
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    background: transparent;
    padding: 0;
    margin-bottom: 24px;

    span {
      font-weight: 600;
      font-size: 16px;
      color: #2845dc;
    }
  }
`

export const TitleStyled = styled.div`
  color: white;
  font-weight: 700;
  font-size: max(30px, 2vw);
`

export const StepTitleStyled = styled.div``

export const StepSubTitleStyled = styled.div``

export const ContentStyled = styled.div`
  max-width: 736px;
  /* padding: 2rem 1rem; */
  padding: 32px 24px;
  border-radius: 16px;
  background: white;
`

export const LoadingInviteTextStyled = styled.h3`
  text-align: center;
`
