import styled from "styled-components";
import * as colors from "../../../../shared/styles/colors";

export const ContainerStyled = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "flex" : "none")};

  @media (max-width: 600px) {
    .i-btn-finish {
      margin-top: 32px;
    }
  }
`;

export const PoliciesWrapperStyled = styled.div`
  width: 100%;
  margin: 20px 0;
  gap: 5px;
  display: flex;
  flex-direction: row;
  justify-content: start;

  @media (max-width: 600px) {
    display: inline;

    > div {
      padding: 0;
    }
  }
`;

export const AreaPasswordRules = styled.ul`
  background: #f8f9fa;

  border: 1px solid #9ec5fe;
  border-radius: 4px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const LinkPoliciesStyled = styled.a`
  font-weight: 500;
  color: ${colors.primaryColor};
`;
