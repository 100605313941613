import styled from "styled-components";
import * as colors from "../../../../shared/styles/colors";

export const ContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  justify-content: space-between;
`;

export const StepStyled = styled.div<{ selected: boolean }>`
  width: 100%;
  height: 8px;
  border-radius: 30px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? colors.primaryColor : colors.strokeLight};
`;
