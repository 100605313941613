export const background: string = "#EBF4FB"
export const primaryColor: string = "#2845DC"
export const darkBlue: string = "#0C1D59"
export const primaryLight: string = "#BBE2FF"
export const titleColor: string = "#152536"
export const strokeLight: string = "#DEE2E6"
export const statusColorSuccess: string = "#08C581"
export const statusColorWarning: string = "#FD7E14"
export const statusColorDanger: string = "#F04438"

export const green100: string = "#F3FFFA"
export const orange100: string = "#FEFFF3"
export const blue100: string = "#CFE2FF"
export const blue200: string = "#9EC5FE"
export const blue300: string = "#3E8CF6"
export const blue400: string = "#3D8BFD"
export const orange500: string = "#FD7E14"
export const primaryDark = "#182B87"
export const yellow500 = "#FFC107"
export const yellow100 = "#FFF3CD"
export const gray400 = "#CED4DA"
export const gray600 = "#475467"
export const gray900 = "#101828"
export const neutralDarkest = "#050609"
export const neutralDark = "#303033"
export const negativePure = "#EAEBEB"
export const negativeLight = "#F8F8F8"

export const primaryLightset = "#DBE0F9"
export const neutralLight = "#ACACAD"
export const oceanBluePure = "#14B5CE"
export const greenDark = "#128729"
export const primaryPurely = "#94A2EE"
export const secundaryDarkest = "#0092FF"

export const lightBlue = "#D5EDFF"
export const white = "#FFFFFF"
export const black = "#000000"
