import React, { useState } from 'react';

import { REGEX_CEP } from '../../constants';
import { Controller, useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import InputField from '../InputField';
import StepTitle from '../StepTitle';
import { ContainerStyled } from './styles';

import { Form } from '../../interfaces/form.interface';
import BVUtilsOld from '@bv/utils-old';
import ReactSelect from '../ReactSelect';

import { Form as IForm } from '../../interfaces/form.interface';
import { states } from '../../../../shared/data/estados-brasileiros';
import BVComponentsOld from '@bv/components-old';
import BVUtils from '@bv/utils';

type AddressStepProps = {
  visible: boolean;
  setStep: (step: number) => void;
  setForm: React.Dispatch<React.SetStateAction<Form>>;
  isSSO: boolean;
  form: IForm;
};

const AddressStep: React.FC<AddressStepProps> = ({ visible, setStep, setForm, isSSO, form }: AddressStepProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });
  const [loading, setLoading] = useState(false);

  async function getAddress(cep: string) {
    try {
      const data = await BVUtilsOld.api.commonApi.getAddressByZipCode(cep);

      setValue('street', data.street, {
        shouldValidate: true,
      });
      setValue('neighbourhood', data.neighbourhood, {
        shouldValidate: true,
      });
      setValue('city', data.city, {
        shouldValidate: true,
      });
      setValue('state', data.state, {
        shouldValidate: true,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const onSubmit = async (data) => {
    setLoading(true);
    setForm((old) => ({
      ...old,
      address: {
        city: data.city,
        complement: data.complement,
        neighbourhood: data.neighbourhood,
        number: data.number,
        state: data.state,
        street: data.street,
        zipCode: data.zipcode,
      },
    }));
    if (isSSO) {
      const __form: IForm = {
        ...form,
        address: {
          city: data.city,
          complement: data.complement,
          neighbourhood: data.neighbourhood,
          number: data.number,
          state: data.state,
          street: data.street,
          zipCode: data.zipcode,
        },
        acceptedTerms: true,
        password: '',
        repeatPassword: '',
      };
      try {
        await BVUtilsOld.api.userApi.create(__form);
        BVUtils.toastHandler({ type: 'success', message: 'Cadastro realizado com sucesso' });

        setTimeout(() => {
          location.href = '/login';
        }, 2000);
        setLoading(false);
      } catch (err) {
        BVUtils.toastHandler({ type: 'error', message: err.message });
      }
    } else {
      setStep(3);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContainerStyled className="row" visible={visible}>
        <div className="col-xs-12">
          <StepTitle>Endereço</StepTitle>
        </div>
        <div className="col-xs-12">
          <InputField label="CEP" showError={!!errors?.zipcode?.type} id="zipcode">
            <Controller
              rules={{
                required: true,
                pattern: {
                  value: REGEX_CEP,
                  message: 'CEP Inválido',
                },
              }}
              name={`zipcode`}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <PatternFormat
                  id="zipcode"
                  format="#####-###"
                  mask="_"
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\_/g, '').replace(/\-/g, '').trim();

                    if (value.length >= 8) {
                      getAddress(value);
                    }
                    onChange(e.target.value);
                  }}
                  placeholder="00000-000"
                  type={'tel'}
                />
              )}
              control={control}
            />
          </InputField>
        </div>
        <div className="col-md-9 col-xs-12">
          <InputField label="Rua" showError={!!errors?.street?.type} id="street">
            <input type="text" id="street" placeholder="Nome da rua" {...register('street', { required: true, minLength: 3 })} />
          </InputField>
        </div>
        <div className="col-md-3 col-xs-12">
          <InputField label="Número" showError={!!errors?.number?.type} id="number">
            <input type="text" id="number" placeholder="Número" {...register('number', { required: true, minLength: 1 })} />
          </InputField>
        </div>
        <div className="col-md-6 col-xs-12">
          <InputField label="Complemento" showError={!!errors?.complement?.type} id="complement">
            <input type="text" id="complement" placeholder="Complemento" {...register('complement', { required: false })} />
          </InputField>
        </div>
        <div className="col-md-6 col-xs-12">
          <InputField label="Bairro" showError={!!errors?.neighbourhood?.type} id="neighbourhood">
            <input type="text" id="neighbourhood" placeholder="Bairro" {...register('neighbourhood', { required: true })} />
          </InputField>
        </div>
        <div className="col-md-6 col-xs-12">
          <InputField label="Cidade" showError={!!errors?.city?.type} id="city">
            <input type="text" id="city" placeholder="Cidade" {...register('city', { required: true, minLength: 3 })} />
          </InputField>
        </div>
        <div className="col-md-6 col-xs-12">
          <InputField showError={!!errors?.state?.type} label="Estado" id="state">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="state"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <ReactSelect
                  value={states.find((c) => c.value === value)}
                  options={states}
                  placeholder="Selecionar..."
                  isSearchable={true}
                  onChange={(val) => onChange(val?.value)}
                  showError={!!errors?.state?.type}
                  id="state"
                  name={name}
                  className="w-100"
                />
              )}
            />
          </InputField>
        </div>

        <div className="col-xs-12">
          <BVComponentsOld.ButtonLoading type="submit" loading={loading} className="btn btn-primary w-100 flex justify-center">
            {isSSO ? 'Concluir cadastro' : 'Continuar'}
          </BVComponentsOld.ButtonLoading>
        </div>
      </ContainerStyled>
    </form>
  );
};

export default AddressStep;
