import styled from "styled-components";
import * as colors from "../../../../shared/styles/colors";

export const HeaderStyled = styled.h4`
  font-weight: 700;
  color: ${colors.darkBlue};

  font-size: 24px;
`;
export const SubTitle = styled.h4`
  font-weight: 700;
  color: ${colors.darkBlue};

  font-size: 20px;
  line-height: 24px;
`;

export const DescriptionStyled = styled.div`
  font-size: 1rem;
  color: ${colors.darkBlue};
`;
