import React from 'react'
import envelop from '../../assets/images/envelope.png'
import cloud from '../../assets/images/cloud.png'
import { 
    ContainerStyled, 
    TitleStyled,
    ImageWrapperStyled,
    CloudStyled,
    EnvelopStyled,
    SubTitleStyled,
} from './styles'


const LoadingInvite = () => (
    <div className='w-screen h-screen bg-white'>
        <ContainerStyled>
            <TitleStyled>Benefício Visão</TitleStyled>
            <ImageWrapperStyled>
                <CloudStyled src={cloud} />
                <EnvelopStyled src={envelop} />
            </ImageWrapperStyled>
            <SubTitleStyled>Estamos buscando seu convite...</SubTitleStyled>
        </ContainerStyled>
    </div>
)

export default LoadingInvite