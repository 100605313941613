import { TOptions } from "../../bv-components-old/src/components/Select"

export const JOBS: string[] = [
  "Abacaxicultor",
  "Abade",
  "Abadessa",
  "Abanador na agricultura",
  "Abastecedor de caldeira",
  "Abastecedor de combustível de aeronave",
  "Abastecedor de linha de produção",
  "Abastecedor de máquinas de linha de produção",
  "Abastecedor de silos de carvão",
  "Abatedor",
  "Abatedor  em matadouro",
  "Abatedor - na extração de madeira",
  "Abatedor de animais",
  "Abatedor de aves",
  "Abatedor de gado",
  "Abatedor de galinha",
  "Abatedor de porco",
  "Abelheiro",
  "Abridor - nas salinas",
  "Abridor de lã",
  "CBO2002 - Classificação Brasileira de Ocupações",
  "Acabador de calçados",
  "Acabador de chapéus de palha",
  "Acabador de embalagens (flexíveis e cartotécnicas)",
  "Acabador de macho - na fundição",
  "Acabador de mármore e granito",
  "Acabador de moldes (fundição)",
  "Acabador de pedras",
  "Acabador de superfícies de concreto",
  "Acafelador",
  "Açaizeiro",
  "Acepilhador de metais",
  "Acertador de maçaroqueira",
  "Acompanhante de idosos",
  "Açougueiro",
  "Açougueiro classificador (exclusive comércio)",
  "Açougueiro cortador (exclusive comércio)",
  "Açougueiro desossador",
  "Açougueiro retalhista",
  "Acrobata",
  "Acupuntor",
  "Acupunturista",
  "Adaptador de obras para teatro, cinema e televisão",
  "Adegueiro",
  "Adeleiro  (comércio atacadista)",
  "Adeleiro (comércio varejista)",
  "Adesivador",
  "Adestrador (eqüinos)",
  "Adestrador de animais",
  "Adestrador de animais de trabalho ( asininos e muares)",
  "Administrador",
  "Administrador (serviço de hospedagem)",
  "Administrador apostólico",
  "Administrador de ambulatório",
  "Administrador de arquivos",
  "Administrador de banco de dados",
  "Administrador de camping",
  "Administrador de carteiras de câmbio e comércio exterior",
  "Administrador de carteiras de crédito e cobrança",
  "Administrador de carteiras de crédito imobiliário",
  "Administrador de compras",
  "Administrador de contadorias e registros fiscais",
  "Administrador de edifícios",
  "Administrador de empresas",
  "Administrador de empresas de hospedagem",
  "Administrador de empresas de pesca",
  "Administrador de fundos e carteiras de investimento",
  "Administrador de instituições culturais",
  "Administrador de marketing",
  "Administrador de materiais",
  "Administrador de orçamento",
  "Administrador de patrimônio",
  "Administrador de pequena e média empresa",
  "Administrador de pessoal",
  "Administrador de prédios",
  "Administrador de recursos humanos",
  "Administrador de recursos tecnológicos",
  "Administrador de rede e de sistemas computacionais",
  "Administrador de redes",
  "Administrador de refeitório",
  "Administrador de restaurante",
  "Administrador de sistema operacional de rede",
  "Administrador de sistemas computacionais",
  "Administrador de sistemas operacionais",
  "Administrador de sistemas operacionais de rede",
  "Administrador em segurança da informação",
  "Administrador financeiro",
  "Administrador florestal",
  "Administrador hospitalar",
  "Administrador no comércio de mercadorias",
  "Administrador paroquial",
  "Administrador público",
  "ADMINISTRADORES",
  "ADMINISTRADORES DE TECNOLOGIA DA INFORMAÇÃO",
  "Adubador",
  "Advogado",
  "Advogado (abuso do poder econômico)",
  "Advogado (aeroespacial)",
  "Advogado (agente de propriedade industrial)",
  "Advogado (ambientalista)",
  "Advogado (arbitragem)",
  "Advogado (áreas especiais)",
  "Advogado (biodireito)",
  "Advogado (concorrência desleal)",
  "Advogado (desportivo)",
  "Advogado (direito administrativo)",
  "Advogado (direito civil)",
  "Advogado (direito de família e sucessões)",
  "Advogado (direito do trabalho)",
  "Advogado (direito internacional)",
  "Advogado (direito penal)",
  "Advogado (direito público)",
  "Advogado (direitos da criança e do adolescente)",
  "Advogado (direitos do consumidor)",
  "Advogado (energia elétrica)",
  "Advogado (propriedade intelectual)",
  "Advogado (recursos hídricos e minerais)",
  "Advogado (telecomunicações)",
  "Advogado civilista",
  "Advogado comercial",
  "Advogado constitucionalista",
  "Advogado contratualista",
  "Advogado criminalista",
  "Advogado da união",
  "Advogado de empresa",
  "Advogado empresarial",
  "Advogado fiscal (direito fiscal)",
  "Advogado generalista",
  "Advogado previdenciário",
  "Advogado trabalhista",
  "Advogado tributarista",
  "ADVOGADOS",
  "Advogados (direito eletrônico)",
  "Aeromoça",
  "Afiador - na fabricação",
  "Afiador ambulante",
  "Afiador de brocas e coroas",
  "Afiador de cardas",
  "Afiador de cutelaria",
  "Afiador de facas",
  "Afiador de facas ( inclusive ambulante)",
  "Afiador de ferramentas",
  "Afiador de lâminas",
  "Afiador de serras",
  "Afiador de talhadeira",
  "Afiador de tesouras ( inclusive ambulante)",
  "Afiador e laminador de serras",
  "Afiador manual",
  "Afiador, a máquina",
  "AFIADORES E POLIDORES DE METAIS",
  "Afinador de instrumentos musicais",
  "Afinador de motores a diesel",
  "Afinador de motores de automóveis",
  "Afinador de motores diesel  (exceto de veículos automotores)",
  "Afinador de piano",
  "Afinador mecânico",
  "Afretador",
  "Agaipi",
  "Agbá",
  "Agbagigan",
  "Agenciador de cargas",
  "Agenciador de propaganda",
  "Agenciador de publicidade",
  "Agente administrativo",
  "Agente administrativo supervisor",
  "Agente comunitário de saúde",
  "Agente da guarda portuária",
  "Agente de ação social",
  "Agente de apoio socioeducativo",
  "Agente de atuidade marítima e fluvial",
  "Agente de carga",
  "Agente de coleta ( censo e pesquisas amostrais)",
  "Agente de coleta de lixo",
  "Agente de coleta supervisor",
  "Agente de combate às endemias",
  "Agente de comércio exterior",
  "Agente de controle de vetores",
  "Agente de contrrole de dengue",
  "Agente de defesa ambiental",
  "Agente de defesa florestal",
  "Agente de direitos autorais",
  "Agente de estação (ferrovia e metrô)",
  "Agente de estação (manobras)",
  "Agente de estação portuária",
  "Agente de execução penal",
  "Agente de fiscalização ambiental",
  "Agente de higiene e segurança",
  "Agente de inspeção (qualidade)",
  "Agente de inspeção de pesca",
  "Agente de inteligência",
  "Agente de investigação de incêndio",
  "Agente de investigação privada",
  "Agente de manobra e docagem",
  "Agente de manutenção de veículos ferroviários",
  "Agente de microcrédito",
  "Agente de microfinanças",
  "Agente de navio - no transporte marítimo",
  "Agente de organização escolar",
  "Agente de passagens",
  "Agente de passagens aéreas",
  "Agente de pastoral",
  "Agente de pátio",
  "Agente de pátio e terminais (ferrovia e metrô)",
  "Agente de pesquisa",
  "Agente de polícia federal",
  "Agente de polícia legislativa",
  "Agente de portaria",
  "Agente de proteção de aviação civil",
  "Agente de proteção e defesa civil",
  "Agente de proteção e defesa civil operacional",
  "Agente de proteção social",
  "Agente de proteção social de rua",
  "Agente de reciclagem de materiais",
  "Agente de recrutamento e seleção",
  "Agente de reserva de passagens aéreas",
  "Agente de reserva de passagens rodoviárias",
  "Agente de reservas",
  "Agente de ressocialização prisional",
  "Agente de saneamento",
  "Agente de saúde",
  "Agente de saúde pública",
  "Agente de segurança",
  "Agente de segurança ferroviária",
  "Agente de segurança penitenciária",
  "Agente de segurança socioeducativa",
  "Agente de tráfego",
  "Agente de trânsito",
  "Agente de transporte",
  "Agente de transporte e trânsito",
  "Agente de venda de passagens",
  "Agente de vendas de serviços",
  "Agente de viagem",
  "Agente de vistoria de alarmes",
  "Agente educacional",
  "Agente educador",
  "Agente fiscal de qualidade",
  "Agente fiscal metrológico",
  "Agente fiscal têxtil",
  "Agente fiscal(atividades urbanas)",
  "Agente funerário",
  "Agente funerário - tanatopraxista",
  "Agente indígena de saneamento",
  "Agente indígena de saúde",
  "Agente operacional de estação",
  "Agente penitenciário",
  "Agente policial",
  "Agente rodoviário",
  "Agente sanitarista",
  "Agente social",
  "Agente técnico de inteligência",
  "Agente técnico de operação na captação, tratamento e distribuição de água",
  "Agente vistor",
  "AGENTES  FISCAIS METROLÓGICOS E DE QUALIDADE",
  "AGENTES DA SAÚDE E DO MEIO AMBIENTE",
  "AGENTES DE INVESTIGAÇÃO E IDENTIFICAÇÃO",
  "AGENTES, ASSISTENTES E AUXILIARES ADMINISTRATIVOS",
  "Agonjaí",
  "Agregado - na agropecuária",
  "Agricultor familiar polivalente",
  "Agricultor na produção da cana-de-açúcar",
  "Agricultor na produção de arroz",
  "Agricultor na produção de cereais de inverno",
  "Agricultor na produção de gramíneas forrageiras",
  "Agricultor na produção de milho e sorgo",
  "Agricultor polivalente",
  "Agricultor polivalente - conta própria",
  "Agricultor polivalente - empregador",
  "Agrimensor",
  "Agrônomo",
  "Agropecuarista",
  "Agrostólogo",
  "Agrotécnico",
  "Aguardenteiro (fabricação)",
  "Aguilhoeiro",
  "Ajudante de acabamento de fundição",
  "Ajudante de açougueiro (comércio)",
  "Ajudante de afiação de serras",
  "Ajudante de afiador",
  "Ajudante de banhos (vidros)",
  "Ajudante de boiadeiro",
  "Ajudante de britador",
  "Ajudante de cabeleireiro",
  "Ajudante de cabista",
  "Ajudante de calceteiro",
  "Ajudante de carga e descarga de mercadoria",
  "Ajudante de carvoaria",
  "Ajudante de churrasqueiro",
  "Ajudante de classificador de folhas (madeira)",
  "Ajudante de classificador de madeira",
  "Ajudante de confecção",
  "Ajudante de confeiteiro",
  "Ajudante de cozinha",
  "Ajudante de derrubada",
  "Ajudante de despachante aduaneiro",
  "Ajudante de despachante alfandegário",
  "Ajudante de destilação de amônia",
  "Ajudante de eletricista",
  "Ajudante de embalador",
  "Ajudante de encaixotador",
  "Ajudante de enfardamento",
  "Ajudante de engarrafamento",
  "Ajudante de ensacador, a máquina",
  "Ajudante de entregador de publicações",
  "Ajudante de esterilização (indústria alimentícia)",
  "Ajudante de estruturas metálicas",
  "Ajudante de estufador (madeira)",
  "Ajudante de evaporador na destilação",
  "Ajudante de extrusão de fibras de vidro",
  "Ajudante de fábrica de tijolos",
  "Ajudante de fabricação - na indústria de bebidas",
  "Ajudante de fabricação de celulose",
  "Ajudante de farmácia",
  "Ajudante de ferreiro",
  "Ajudante de filtrador (filtro-prensa)",
  "Ajudante de forjador",
  "Ajudante de forneiro",
  "Ajudante de fundidor",
  "Ajudante de gasista",
  "Ajudante de guincheiro",
  "Ajudante de impressor de formulários contínuos",
  "Ajudante de lapidador (vidros)",
  "Ajudante de lavador de automóvel",
  "Ajudante de lavador de tapetes",
  "Ajudante de lavanderia de artefatos da tapeçaria",
  "Ajudante de lingotamento",
  "Ajudante de lubrificação (indústria)",
  "Ajudante de lustrador",
  "Ajudante de macheiro",
  "Ajudante de manutenção de aeronave",
  "Ajudante de maquinista de trem",
  "Ajudante de marteleteiro",
  "Ajudante de mineiro",
  "Ajudante de montagem e fotolito",
  "Ajudante de motorista",
  "Ajudante de obras",
  "Ajudante de oleiro",
  "Ajudante de operador de centrífugas",
  "Ajudante de operador de gás",
  "Ajudante de operador de guincho",
  "Ajudante de operador de serra",
  "Ajudante de padeiro",
  "Ajudante de pasteleiro",
  "Ajudante de pátio de sucata",
  "Ajudante de pintor",
  "Ajudante de pintor à pistola",
  "Ajudante de pintor de móveis",
  "Ajudante de pintor de produção",
  "Ajudante de pizzaiolo",
  "Ajudante de polidor de veículos",
  "Ajudante de polimento",
  "Ajudante de produção - na indústria de bebidas",
  "Ajudante de produção de óleos vegetais - na ind. Alimentar",
  "Ajudante de queijeiro",
  "Ajudante de rebarbação (metais)",
  "Ajudante de reflorestamento",
  "Ajudante de reparador (telecomunicações)",
  "Ajudante de saneamento",
  "Ajudante de serrador",
  "Ajudante de serralheiro",
  "Ajudante de serviço de (aplicação de produtos agroquímicos)",
  "Ajudante de sushiman",
  "Ajudante de tratamento de preparo de xarope",
  "Ajudante de tratamento térmico",
  "Ajudante de vaqueiro",
  "Ajudante xaropeiro",
  "Ajudante, auxiliar de bar",
  "Ajudante, auxiliar de lanchonete",
  "Ajudante, auxiliar de mecânico de avião",
  "AJUDANTES DE OBRAS CIVIS",
  "Ajuntador de andiroba",
  "Ajuntador de buriti",
  "Ajuntador de ouricuri",
  "Ajustador de agulhas",
  "Ajustador de fôrmas (concreto)",
  "Ajustador de instrumentos de precisão",
  "Ajustador de máquinas de embalagem",
  "Ajustador de oficina de cilindros",
  "Ajustador de rebarbador",
  "Ajustador de válvulas",
  "Ajustador ferramenteiro",
  "Ajustador limador de fresas",
  "Ajustador limador de tornos",
  "Ajustador mecânico",
  "Ajustador mecânico (usinagem em bancada e em máquinas-ferramentas)",
  "Ajustador mecânico de ferramentas",
  "Ajustador mecânico de manutenção",
  "Ajustador mecânico em bancada",
  "Ajustador naval (reparo e construção)",
  "AJUSTADORES MECÂNICOS POLIVALENTES E AFINS",
  "Alabê",
  "Alambiqueiro",
  "Alambrador - na fabricação de arame",
  "Alapini",
  "Alayan",
  "Alcatroeiro",
  "Alergista",
  "Alergista e imunologista",
  "Alergologista",
  "Alfaceiro",
  "Alfaiate",
  "Alfaite modelista",
  "Alfarrabista",
  "Alfarrabista (comércio varejista)",
  "Alfeloeiro",
  "Alheiro (comércio atacadista)",
  "Alheiro (comércio varejista)",
  "Álim",
  "Alimentador de esteiras (preparação de alimentos e bebidas)",
  "Alimentador de linha de produção",
  "Alimentador de máquina automática",
  "Alimentador de peixe",
  "Alimentador de rotativas",
  "Alimentador de serra de fita",
  "ALIMENTADORES DE LINHAS DE PRODUÇÃO",
  "Alinhador de direção",
  "Alinhador de pneus",
  "Alinhador de rodas",
  "Alisador de metal",
  "Almirante",
  "Almirante-de-esquadra",
  "Almoxarife",
  "ALMOXARIFES E ARMAZENISTAS",
  "Alugador (comerciante varejista)",
  "Alvanel",
  "Alvaner",
  "Alvejador (tecidos)",
  "Alvejador de couros",
  "Alvejador de sola",
  "Amaciador de couros e peles",
  "Amansador",
  "Amapateiro",
  "Amarrador de embalagens",
  "Amarrador de embarcações",
  "Amarrador de madeira",
  "Amarrador de piaçava",
  "Amarrador e desamarrado de embarcações",
  "Amarrador e desatracados de embarcações",
  "Amassador - em olaria",
  "Ambientador",
  "Ambulante",
  "Amestrador",
  "Amolador - na fabricação",
  "Amolador ambulante",
  "Amolador de cardas",
  "Amolador de cilindros",
  "Amolador de cutelaria",
  "Amolador de facas",
  "Amolador de ferramentas",
  "Amolador de guarnição - na fabricação têxtil",
  "Amolador de serras",
  "Amolador de talhadeira",
  "Amolador de tesouras ( inclusive ambulante)",
  "Amostrador de minérios",
  "Amostrista",
  "Amostrista de minérios",
  "Ampoleiro",
  "Ampoleiro (jarras e garrafas térmicas)",
  "Analisador de tráfego telefônico",
  "Analista (psicanálise)",
  "Analista administrativo",
  "Analista agroindustrial (economista)",
  "Analista ambiental",
  "Analista contábil",
  "Analista de agronegócios (economista)",
  "Analista de aplicativo básico (software)",
  "Analista de areias em fundição",
  "Analista de balanço",
  "Analista de cadeias produtivas (economista)",
  "Analista de câmbio",
  "Analista de cargos e salários",
  "Analista de cobrança",
  "Analista de cobrança (instituições financeiras)",
  "Analista de compliance",
  "Analista de comunicação (teleprocessamento)",
  "Analista de contabilidade",
  "Analista de contas",
  "Analista de contas a pagar",
  "Analista de controle de qualidade",
  "Analista de controle e gestão (economista)",
  "Analista de controle orçamentário (economista)",
  "Analista de controle técnico de manutenção",
  "Analista de crédito (economista)",
  "Analista de crédito (instituições financeiras)",
  "Analista de crédito rural",
  "Analista de custos",
  "Analista de desembaraço aduaneiro",
  "Analista de desenvolvimento de sistemas",
  "Analista de desenvolvimento de software",
  "Analista de desenvolvimento regional (economista)",
  "Analista de documentação",
  "Analista de e-commerce",
  "Analista de ecodesenvolvimento (economista)",
  "Analista de economia internacional",
  "Analista de estação de tratamento de efluentes",
  "Analista de estoque",
  "Analista de estudos de mercado",
  "Analista de estudos econômicos",
  "Analista de exportação e importação",
  "Analista de finanças públicas (economista)",
  "Analista de folha de pagamento",
  "Analista de fundos de investimento",
  "Analista de gerenciamento de riscos",
  "Analista de gestão de estoque",
  "Analista de impactos ambientais (economista)",
  "Analista de informação em saúde",
  "Analista de informações (pesquisador de informações de rede)",
  "Analista de informações de mercado",
  "Analista de infraestrutura",
  "Analista de integridade (compliance)",
  "Analista de inteligência de mercado",
  "Analista de inventário",
  "Analista de laboratório de controle de qualidade",
  "Analista de laboratório químico",
  "Analista de laboratório químico  (petróleo)",
  "Analista de leasing",
  "Analista de logistica",
  "Analista de logística de transporte",
  "Analista de manutenção (equipamentos aéreos)",
  "Analista de manutenção (equipamentos industriais)",
  "Analista de marketing",
  "Analista de meio ambiente (economista)",
  "Analista de mercado",
  "Analista de mercado agrícola (economista)",
  "Analista de mercado de trabalho (economista)",
  "Analista de mercado e produtos (economista)",
  "Analista de mercado industrial (economista)",
  "Analista de mercado internacional",
  "Analista de mercadologia (economista)",
  "Analista de micróbios",
  "Analista de mídias digitais",
  "Analista de mídias sociais",
  "Analista de negócios",
  "Analista de ocupações",
  "Analista de pcp (programação e controle da produção)",
  "Analista de pesquisa de mercado",
  "Analista de pesquisa operacional",
  "Analista de planejamento de manutenção",
  "Analista de planejamento de materias",
  "Analista de planejamento e orçamento - apo",
  "Analista de planejamento financeiro",
  "Analista de planejamento industrial (economista)",
  "Analista de políticas públicas (economista)",
  "Analista de produto agropecuário (economista)",
  "Analista de produto industrial (economista)",
  "Analista de produtos bancários",
  "Analista de produtos químicos",
  "Analista de projetos industriais (economista)",
  "Analista de projetos logisticos",
  "Analista de projetos viários",
  "Analista de qualidade de ti",
  "Analista de recursos humanos",
  "Analista de recursos naturais (economista)",
  "Analista de rede",
  "Analista de redes e de comunicação de dados",
  "Analista de redes sociais",
  "Analista de relações internacionais",
  "Analista de riscos",
  "Analista de riscos corporativos",
  "Analista de riscos e controles internos",
  "Analista de seguros (técnico)",
  "Analista de service desk",
  "Analista de sinistros",
  "Analista de sistemas",
  "Analista de sistemas de automação",
  "Analista de sistemas web",
  "Analista de subscrição de títulos",
  "Analista de suporte",
  "Analista de suporte à inteligência ( grupo apoio)",
  "Analista de suporte computacional",
  "Analista de suporte de banco de dados",
  "Analista de suporte de sistema",
  "Analista de suporte técnico",
  "Analista de telecomunicação",
  "Analista de testes automatizados de ti",
  "Analista de testes de tecnologia da informação",
  "Analista de tráfego",
  "Analista de transporte em comércio exterior",
  "Analista de transporte multimodal",
  "Analista de transportes e trânsito",
  "Analista de tratamento de água",
  "Analista de turismo (turismólogo)",
  "Analista econômico",
  "Analista econômico-financeiro",
  "Analista em segurança da informação",
  "Analista financeiro (economista)",
  "Analista financeiro (instituições financeiras)",
  "Analista fiscal (economista)",
  "Analista kirlian",
  "Analista musical",
  "Analista químico",
  "Analista químico (petróleo)",
  "Analista técnico de seguros",
  "Analista técnico de sinistros",
  "Analista técnico em inteligência (grupo informações)",
  "Analista tributário (economista)",
  "ANALISTAS DE COMÉRCIO EXTERIOR",
  "Analistas de projetos ambientais",
  "ANALISTAS DE TECNOLOGIA DA INFORMAÇÃO",
  "Ancião",
  "Âncora de mídias audiovisuais",
  "Âncora de rádio e tv",
  "Andrologista",
  "Anestesiologista",
  "Anestesista",
  "Angiologista",
  "Animador de circo",
  "Animador de eventos",
  "Animador de festas populares",
  "Animador de rádio",
  "Animador de televisão",
  "Anodizador e anodizador de metais",
  "Anotador (futebol de salão)",
  "Anotador (judô)",
  "Anotador (karatê)",
  "Anotador de consumo de energia elétrica, água e gás",
  "Anotador de mão-de-obra",
  "Anotador de pessoal",
  "Anotador de pontuação (karatê)",
  "Anotador de processo de produção",
  "Anotador de produção",
  "Antenista",
  "Antiquário (comércio atacadista)",
  "Antiquário comércio varejista",
  "Antropólogo",
  "Apanhador - na cultura",
  "Apanhador de algodão",
  "Apanhador de babaçu",
  "Apanhador de bacaba",
  "Apanhador de buriti",
  "Apanhador de café",
  "Apanhador de laranja",
  "Aparador - na extração da carnaúba",
  "Aparador de óleo de copaíba",
  "Aparelhador de pedra (mármore)",
  "Aparelhador de pedra (mina)",
  "Apartador de gado",
  "Apicultor",
  "Apicultor - empregador",
  "Apicultor - exclusive conta própria e empregador",
  "Aplainador de mármore",
  "Aplicador agrícola",
  "Aplicador de adesivos",
  "Aplicador de adesivos em superfícies",
  "Aplicador de asfalto impermeabilizante (coberturas)",
  "Aplicador de autoadesivo",
  "Aplicador de decalque em cerâmica",
  "Aplicador de impressos em cerâmica",
  "Aplicador de inseticida",
  "Aplicador de isolante (coberturas)",
  "Aplicador de isolante térmico",
  "Aplicador de material isolante em caldeiras e tubulações",
  "Aplicador de provas (concurso, avaliação,exame)",
  "Aplicador de resinas em pisos",
  "Aplicador de serigrafia",
  "Aplicador de silkscreen",
  "Aplicador de silkscreen, à máquina",
  "Aplicador de sinteco e bona",
  "Aplicador de vinil autoadesivo",
  "Aplicador serigráfico em vidros",
  "APLICADORES DE MATERIAIS ISOLANTES",
  "APLICADORES DE PROVAS E AFINS",
  "APLICADORES DE REVESTIMENTOS CERÂMICOS, PASTILHAS, PEDRAS E MADEIRAS",
  "Apoiador político partidário",
  "Apontador (basquete)",
  "Apontador (vôlei)",
  "Apontador de bicos (calçados)",
  "Apontador de campo",
  "Apontador de cartões de ponto",
  "Apontador de mão-de-obra",
  null,
  "Apontador de obras",
  "Apontador de pessoal",
  "Apontador de produção",
  "Apontador de tempo (produção)",
  "Apontador industrial",
  "APONTADORES E CONFERENTES",
  "Apóstolo",
  "Aprendiz de macarroneiro",
  "Aprendiz de mecânica de manutenção",
  "Aprendiz de mecânico ajustador",
  "Apresentador animador de circo",
  "Apresentador animador de eventos",
  "Apresentador animador de festas populares",
  "Apresentador animador de programas de rádio",
  "Apresentador animador de programas de televisão",
  "Apresentador de circo",
  "Apresentador de convenções",
  "Apresentador de espetáculos",
  "Apresentador de eventos",
  "Apresentador de festas populares",
  "Apresentador de programas de rádio",
  "Apresentador de programas de televisão",
  "Apresentador de rádio e televisão",
  "Apresentador de telejornal",
  "APRESENTADORES DE EVENTOS, PROGRAMAS E ESPETÁCULOS",
  "Apropriador de mão-de-obra",
  "Aquarelista",
  "Arador",
  "Aramador - na fabricação de arame",
  "Arameiro (colocador de arames)",
  "Aramista (equilibrista)",
  "Árbitro assistente (futebol)",
  "Árbitro central (futebol)",
  "Árbitro central (judô)",
  "Árbitro central (karatê)",
  "Árbitro chefe de área (karatê)",
  "Árbitro de atletismo",
  "Árbitro de basquete",
  "Árbitro de basquetebol",
  "Árbitro de futebol",
  "Árbitro de futebol de salão",
  "Árbitro de futsal",
  "Árbitro de judô",
  "Árbitro de karatê",
  "Árbitro de poló aquático",
  "Árbitro de vôlei",
  "Árbitro desportivo",
  "Árbitro econômico",
  "Árbitro extrajudicial",
  "Árbitro geral de campo",
  "Árbitro geral de pista",
  "Árbitro geral de provas fora do estádio",
  "Árbitro lateral (judô)",
  "Árbitro reserva (basquete)",
  "Árbitro reserva (futebol)",
  "Árbitro reserva (karatê)",
  "ÁRBITROS DESPORTIVOS",
  "Arborário",
  "Arcebispo",
  "Arcipreste",
  "Argumentista-roteirista de história em quadrinhos",
  "Armador de calçados",
  "Armador de estrutura de concreto",
  "Armador de estrutura de concreto armado",
  "Armador de ferragens na construção civil",
  "Armador de ferros",
  "Armador de manilhas",
  "Armador de móveis de vime",
  "Armador de telhados",
  "Armarinheiro (comércio atacadista)",
  "Armarinheiro (comércio varejista)",
  "Armazenário (comércio atacadista)",
  "Armazenário (comércio varejista)",
  "Armazenista",
  "Armeiro (comércio atacadista)",
  "Armeiro (comércio varejista)",
  "Aromista",
  "Arqueador",
  "Arqueador de molas",
  "Arqueador e temperador de molas",
  "Arqueólogo",
  "Arquiteto da paisagem",
  "Arquiteto de edificações",
  "Arquiteto de interiores",
  "Arquiteto de patrimônio",
  "Arquiteto de restauro",
  "Arquiteto de sistemas de ti",
  "Arquiteto de soluções de tecnologia da informação",
  "Arquiteto paisagista",
  "Arquiteto restaurador",
  "Arquiteto urbanista",
  "ARQUITETOS E URBANISTAS",
  "Arquivador",
  "Arquivista",
  "Arquivista de documentos",
  "Arquivista pesquisador (jornalismo)",
  "ARQUIVISTAS E MUSEÓLOGOS",
  "Arraçoador (pecuária polivalente)",
  "Arraçoador de camarões",
  "Arraçoador de peixe",
  "Arrancador - na cultura",
  "Arrancador em matadouro",
  "Arrastador de árvores",
  "Arreador",
  "Arreador em matadouro",
  "Arrebanhador",
  "Arreeiro de couro - na fabricação",
  "Arreeiro na fabricação",
  "Arrematadeira",
  "Arrematador de fogos",
  "Arrematador, no comércio",
  "Arrendatário (pecuária)",
  "Arrendatário - na agropecuária",
  "Arrendatário na agropecuária",
  "Arrendatário polivalente",
  "Arriador de filatório",
  "Arrieiro",
  "Arrolhador",
  "Arrombador (comércio de gado)",
  "Arrombador - no comércio de gado - empregador",
  "Arrozeiro",
  "Arrozeiro (comércio)",
  "Arrozeiro - na cultura - conta própria",
  "Arruador de café",
  "Arrumadeira de hotel",
  "Arrumador de hotel",
  "Arrumador de prateleiras - em supermercados",
  "Arrumador de prateleiras - no comércio",
  "Arrumador no serviço doméstico",
  "Arte educador",
  "Arte-finalista",
  "Arte-finalista (desenhista técnico de artes gráficas)",
  "Artesão (artista visual)",
  "Artesão bordador",
  "Artesão ceramista",
  "Artesão com material reciclável",
  "Artesão confeccionador de biojóias e ecojóias",
  "Artesão crocheteiro",
  "Artesão do couro",
  "Artesão escultor",
  "Artesão modelador (vidros)",
  "Artesão moveleiro (exceto reciclado)",
  "Artesão rendeiro",
  "Artesão tecelão",
  "Artesão trançador",
  "Artesão tricoteiro",
  "ARTESÃOS",
  "ARTESÃOS DE METAIS PRECIOSOS E SEMI-PRECIOSOS",
  "Arteterapeuta",
  "Artífice de manutenção",
  "Artífice de serra",
  "Artífice de vime",
  "Artífice do couro",
  "Artista (artes visuais)",
  "Artista aéreo",
  "Artista de cinema",
  "Artista de circo (outros)",
  "Artista de corda",
  "Artista de força capilar",
  "Artista de lira",
  "Artista de rádio",
  "Artista de teatro",
  "Artista de televisão",
  "Artista dramático",
  "Artista plástico",
  "ARTISTAS DA DANÇA (EXCETO DANÇA TRADICIONAL E POPULAR)",
  "ARTISTAS DE CIRCO (CIRCENSES)",
  "ARTISTAS VISUAIS,DESENHISTAS INDUSTRIAIS E CONSERVADORES-RESTAURADORES DE BENS",
  "Ascensorista",
  "Asfaltador",
  "Asfaltador na conservação de vias permanentes (exceto trilhos)",
  "Asperador de couro no acabamento de calçados",
  "Asperador de solas e palmilhas",
  "Assentador cerâmico",
  "Assentador de canalização (edificações)",
  "Assentador de manilhas",
  "Assentador de revestimentos cerâmicos",
  "Assentador de vidros",
  "Assessor bilíngüe",
  "Assessor de diretoria",
  "Assessor de imprensa",
  "Assessor de microcrédito",
  null,
  "Assessor de microfinanças",
  "Assessor de presidência",
  "Assessor imobiliário",
  "Assessor jurídico",
  "Assessor político partidário",
  "Assessor trilíngüe",
  "Assessr",
  "Assinalador - na pecuária",
  "Assistente Jurídico",
  "Assistente administrativo",
  "Assistente administrativo sindical",
  "Assistente bilíngüe",
  "Assistente comercial de seguros",
  "Assistente de analista de produtos",
  "Assistente de apontador (basquete)",
  "Assistente de biblioteca",
  "Assistente de cabo de fogo",
  "Assistente de câmera externa",
  "Assistente de cobrança",
  "Assistente de compliance",
  "Assistente de compras",
  "Assistente de contabilidade industrial",
  "Assistente de contador de custos",
  "Assistente de contadoria fiscal",
  "Assistente de contas de seguros",
  "Assistente de controladoria",
  "Assistente de controle técnico de manutenção",
  "Assistente de coordenação (concurso, avaliação, exame)",
  "Assistente de coreografia",
  "Assistente de cozinhador",
  "Assistente de desembaraço aduaneiro",
  "Assistente de direção",
  "Assistente de direção (tv)",
  "Assistente de direção de cinema",
  "Assistente de direção de restaurante",
  "Assistente de direção de teatro",
  "Assistente de diretoria",
  "Assistente de editorial",
  "Assistente de engenharia (construção civil)",
  "Assistente de escritório",
  "Assistente de estação (metrô e ferrovia)",
  "Assistente de estúdio (cinema e vídeo)",
  "Assistente de estúdio (gravação de áudio)",
  "Assistente de governanta",
  "Assistente de laboratório industrial",
  "Assistente de logística de transporte",
  "Assistente de mídias sociais",
  "Assistente de negócios de seguros",
  "Assistente de operações audiovisuais",
  "Assistente de parto",
  "Assistente de planejamento de manutenção",
  "Assistente de presidência",
  "Assistente de prevenção de perdas",
  "Assistente de produção (moda)",
  "Assistente de produtos (técnico de seguros)",
  "Assistente de salinas",
  "Assistente de segurança",
  "Assistente de service desk",
  "Assistente de serviço de contabilidade",
  "Assistente de tráfego rodoviário",
  "Assistente de vendas",
  "Assistente sindical de entidades de trabalhadores",
  "Assistente sindical de entidades patronais",
  "Assistente social",
  "Assistente técnico - no serviço público",
  "Assistente técnico de engenharia (obras de infra-estrutura de estrada)",
  "Assistente técnico de engenharia de obras de saneamento",
  "Assistente técnico de fundição em siderurgia",
  "Assistente técnico de seguros",
  "Assistente técnico em madeira",
  "Assistente técnico florestal",
  "Assistente técnico na fabricação de produtos de cerâmica, porcelanatos e afins",
  "Assistente trilíngüe",
  "ASSISTENTES DE DIREÇÃO (TV) E CONTINUÍSTAS",
  "ASSISTENTES SOCIAIS E ECONOMISTAS DOMÉSTICOS",
  "Assoalhador",
  "Astrólogo",
  "ASTRÓLOGOS E NUMERÓLOGOS",
  "Astrônomo",
  "Atacadista",
  "Atacante - no futebol",
  "Atendente balconista",
  "Atendente barista",
  "Atendente central telemarketing",
  "Atendente comercial (agência postal)",
  "Atendente comercial em agência postal",
  "Atendente de aeroporto",
  "Atendente de agência",
  "Atendente de ambulatório",
  "Atendente de balcão",
  "Atendente de balcão de café",
  "Atendente de bar",
  "Atendente de berçário",
  "Atendente de buffet",
  "Atendente de cafeteria",
  "Atendente de centro cirúrgico",
  "Atendente de clínica  médica",
  "Atendente de clínica  veterinária",
  "Atendente de clínica dentária",
  "Atendente de consultório médico",
  "Atendente de consultório veterinário",
  "Atendente de creche",
  "Atendente de emergência",
  "Atendente de enfermagem",
  "Atendente de enfermagem no serviço doméstico",
  "Atendente de estabelecimento com cervejas artesanais",
  "Atendente de farmácia - balconista",
  "Atendente de gabinete dentário",
  "Atendente de hospital",
  "Atendente de informações (telemarketing)",
  "Atendente de judiciário",
  "Atendente de lanchonete",
  "Atendente de lavanderia",
  "Atendente de lojas",
  "Atendente de lojas e mercados",
  "Atendente de mesa",
  "Atendente de padaria",
  "Atendente de pedágio",
  "Atendente de peixaria",
  "Atendente de portaria de hotel",
  "Atendente de posto de gasolina",
  "Atendente de reintegração social",
  "Atendente de seguro saúde",
  "Atendente de serviço de saúde",
  "Atendente de serviço médico",
  "Atendente de serviço odontológico",
  "Atendente de telemarketing",
  "Atendente do setor de frios e laticínios",
  "Atendente do setor de hortifrutigranjeiros",
  "Atendente do setor de rotisserie (pratos prontos)",
  "Atendente do setor de vinhos",
  "Atendente em agência de correios",
  "Atendente funerário",
  "Atendente hospitalar",
  "Atendente odontológico",
  "Atendente-enfermeiro",
  "Atirador de facas",
  "Atleta da ginástica",
  "Atleta da natação",
  "Atleta de bodybare",
  "Atleta de canoagem",
  "Atleta de ciclismo",
  "Atleta de escalada",
  "Atleta de esgrima",
  "Atleta de futebol sete",
  "Atleta de futsal",
  "Atleta de futvôlei",
  "Atleta de handebol",
  "Atleta de judô",
  "Atleta de karatê",
  "Atleta de nado sincronizado",
  "Atleta de paraquedismo",
  "Atleta de patinação",
  "Atleta de peteca",
  "Atleta de pólo aquático",
  "Atleta de remo",
  "Atleta de skate",
  "Atleta de squash",
  "Atleta de surf e windsurf",
  "Atleta de taichichuan",
  "Atleta de tênis profissional",
  "Atleta de triatlo",
  "Atleta de vela",
  "Atleta de voleibol",
  "Atleta do basquete",
  "Atleta do beisebol",
  "Atleta do hipismo",
  "Atleta do hóquei",
  "Atleta profissional (outras modalidades)",
  "Atleta profissional de futebol",
  "Atleta profissional de golfe",
  "Atleta profissional de luta",
  "Atleta profissional de tênis",
  "ATLETAS PROFISSIONAIS",
  "Ator",
  "Ator bonequeiro",
  "Ator de cinema",
  "Ator de rádio",
  "Ator de teatro",
  "Ator de televisão",
  "Ator dramático",
  "Ator dublador",
  "ATORES",
  "Atuário",
  "Audiodescritor",
  "Audiologista (fonoaudiólogo)",
  "Auditor (contadores e afins)",
  "Auditor contábil",
  "Auditor de atividades urbanas",
  "Auditor de contabilidade e orçamento",
  "Auditor externo (contadores e afins)",
  "Auditor financeiro",
  "Auditor fiscal (em contabilidade)",
  "Auditor fiscal de atividades urbanas",
  "Auditor independente (contadores e afins)",
  "Auditor interno (contadores e afins)",
  "Auditor-fiscal da previdência social",
  "Auditor-fiscal da receita federal",
  "Auditor-fiscal do trabalho",
  "AUDITORES FISCAIS DA PREVIDÊNCIA SOCIAL",
  "AUDITORES FISCAIS DO TRABALHO",
  "AUDITORES FISCAIS E TÉCNICOS DA RECEITA FEDERAL",
  "Aurifice",
  "Autor de ficção",
  "Autor de música",
  "Autor-roteirista",
  "Autor-roteirista de cinema",
  "Autor-roteirista de rádio",
  "Autor-roteirista de teatro",
  "Autor-roteirista de televisão",
  "Autor-roteirista multimídia",
  "Autoridade policial (delegado)",
  "Auxiliar administrativo",
  "Auxiliar administrativo bilíngüe",
  "Auxiliar administrativo de diretoria",
  "Auxiliar administrativo de presidência",
  "Auxiliar administrativo trilíngüe",
  "Auxiliar contábil",
  "Auxiliar de afiador de ferramentas",
  "Auxiliar de agricultura",
  "Auxiliar de almoxarifado",
  "Auxiliar de ambulatório",
  "Auxiliar de apostas",
  "Auxiliar de armazenamento",
  "Auxiliar de arquitetura",
  "Auxiliar de banco de sangue",
  "Auxiliar de barman",
  "Auxiliar de biblioteca",
  "Auxiliar de bibliotecário",
  "Auxiliar de bobinagem, à mão",
  "Auxiliar de cabeleireiro",
  "Auxiliar de cabo de fogo",
  "Auxiliar de cartório",
  "Auxiliar de churrasqueiro",
  "Auxiliar de cinegrafia",
  "Auxiliar de classificador de carne",
  "Auxiliar de cobrança",
  "Auxiliar de colocador de vidros",
  "Auxiliar de compras",
  "Auxiliar de confecção",
  "Auxiliar de confeitaria",
  "Auxiliar de confeiteiro",
  "Auxiliar de conservação de barragens",
  "Auxiliar de conservação de obras civis",
  "Auxiliar de contabilidade",
  "Auxiliar de contas a pagar",
  "Auxiliar de contas a receber",
  "Auxiliar de coordenação de ensino fundamental de primeira a quarta séries",
  "Auxiliar de coordenador escolar",
  "Auxiliar de cortador de vidros",
  "Auxiliar de corte (preparação da confecção de roupas)",
  "Auxiliar de costura",
  "Auxiliar de costureira (no acabamento)",
  "Auxiliar de cozinha",
  "Auxiliar de creche",
  "Auxiliar de crédito",
  "Auxiliar de curtimento",
  "Auxiliar de custos",
  "Auxiliar de dendrometria",
  "Auxiliar de dendrometrista",
  "Auxiliar de dentista",
  "Auxiliar de desenvolvimento infantil",
  "Auxiliar de desgaseificação",
  "Auxiliar de desossador",
  "Auxiliar de dessulfuração e estação de mistura",
  "Auxiliar de dietista",
  "Auxiliar de dobrador (metais)",
  "Auxiliar de doceiro",
  "Auxiliar de eletrônica",
  "Auxiliar de eletrotécnico",
  "Auxiliar de encanador",
  "Auxiliar de enfermagem",
  "Auxiliar de enfermagem da estratégia de saúde da família",
  "Auxiliar de enfermagem de central de material esterelizado (cme)",
  "Auxiliar de enfermagem de centro cirúrgico",
  "Auxiliar de enfermagem de clínica médica",
  "Auxiliar de enfermagem de hospital",
  "Auxiliar de enfermagem de saúde pública",
  "Auxiliar de enfermagem do trabalho",
  "Auxiliar de enfermagem em hemodiálise",
  "Auxiliar de enfermagem em home care",
  "Auxiliar de enfermagem em nefrologia",
  "Auxiliar de enfermagem em saúde mental",
  "Auxiliar de enfermagem em saúde ocupacional",
  "Auxiliar de enfermagem ocupacional",
  "Auxiliar de enfermagem socorrista",
  "Auxiliar de engenheiro da construção civil",
  "Auxiliar de escritório",
  "Auxiliar de escrituração fiscal",
  "Auxiliar de estatística",
  "Auxiliar de estoque",
  "Auxiliar de estúdio",
  "Auxiliar de expedição",
  "Auxiliar de exportação e importação",
  "Auxiliar de fabricação nas indústrias de artefatos de cimento",
  "Auxiliar de farmácia de manipulação",
  "Auxiliar de faroleiro",
  "Auxiliar de faturamento",
  "Auxiliar de feirante",
  "Auxiliar de fermentação",
  "Auxiliar de funerária",
  "Auxiliar de galvanização",
  "Auxiliar de garçom",
  "Auxiliar de ginecologia",
  "Auxiliar de hipodermia",
  "Auxiliar de iluminação",
  "Auxiliar de incubação",
  "Auxiliar de instalação (equipamentos de rádio)",
  "Auxiliar de inventário",
  "Auxiliar de jardinagem na conservação de vias permanentes",
  "Auxiliar de judiciário",
  "Auxiliar de laboratório de análises clínicas",
  "Auxiliar de laboratório de análises físico-químicas",
  "Auxiliar de laboratório de imunobiológicos",
  "Auxiliar de laboratório fotográfico",
  "Auxiliar de laboratorista (indústria)",
  "Auxiliar de laboratorista de solo",
  "Auxiliar de laboratorista de solos e de concreto",
  "Auxiliar de lavador de roupas, a máquina industrial",
  "Auxiliar de lavador de tapetes",
  "Auxiliar de lavanderia",
  "Auxiliar de limpeza",
  "Auxiliar de linha de produção",
  "Auxiliar de logistica",
  "Auxiliar de lubrificador de veículos",
  "Auxiliar de maestro",
  "Auxiliar de magarefe",
  "Auxiliar de maître",
  "Auxiliar de manutenção de edificações",
  "Auxiliar de manutenção de edifícios",
  "Auxiliar de manutenção de linha férrea",
  "Auxiliar de manutenção e recarga de extintor de incêndio",
  "Auxiliar de manutenção elétrica e hidráulica",
  "Auxiliar de manutenção predial",
  "Auxiliar de maquinista de trem",
  "Auxiliar de marceneiro",
  "Auxiliar de mecânico de autos",
  "Auxiliar de mecânico diesel (exceto de veículos automotores)",
  "Auxiliar de mesa em restaurantes, hotéis e outros",
  "Auxiliar de montador de fotolito",
  "Auxiliar de montagem de transformadores",
  "Auxiliar de nutrição e dietética",
  "Auxiliar de obstetrícia",
  "Auxiliar de oftalmologia",
  "Auxiliar de operação",
  "Auxiliar de operador de máquina de bordar",
  "Auxiliar de operador de motosserra",
  "Auxiliar de operador de serra circular múltipla",
  "Auxiliar de operador de usina de asfalto",
  "Auxiliar de orientação educacional",
  "Auxiliar de orientação pedagógica",
  "Auxiliar de orientação pedagógica em educação fundamental de primeira a quarta séries",
  "Auxiliar de padeiro",
  "Auxiliar de parteira",
  "Auxiliar de pedreiro",
  "Auxiliar de pessoal",
  "Auxiliar de pintor de automóveis",
  "Auxiliar de pizzaiolo",
  "Auxiliar de preparação de areia",
  "Auxiliar de processamento de fumo",
  "Auxiliar de produção - na confecção de roupas",
  "Auxiliar de produção de fumo",
  "Auxiliar de produção de gorduras vegetais comestíveis",
  "Auxiliar de produção farmacêutica",
  "Auxiliar de prótese dentária",
  "Auxiliar de radiologia (revelação fotográfica)",
  "Auxiliar de recepção",
  "Auxiliar de salsicheiro",
  "Auxiliar de sanitarista",
  "Auxiliar de saúde (navegação marítima)",
  "Auxiliar de saúde marítimo",
  "Auxiliar de segurança",
  "Auxiliar de seguros",
  "Auxiliar de serigrafia",
  "Auxiliar de serviço de copa",
  "Auxiliar de serviço de segurança",
  "Auxiliar de serviços bibliotecários",
  "Auxiliar de serviços de importação e exportação",
  "Auxiliar de serviços gerais - na confecção de roupas",
  "Auxiliar de serviços jurídicos",
  "Auxiliar de serviços no aeroporto",
  "Auxiliar de supervisão de ensino",
  "Auxiliar de supervisor escolar",
  "Auxiliar de sushiman",
  "Auxiliar de técnico de controle de qualidade",
  "Auxiliar de técnico de eletrônica",
  "Auxiliar de topógrafo",
  "Auxiliar de torneiro mecânico",
  "Auxiliar de tráfego",
  "Auxiliar de tráfego de exportação e importação",
  "Auxiliar de unidade portátil externa",
  "Auxiliar de vaqueiro",
  "Auxiliar de veterinário",
  "Auxiliar de vidraceiro",
  "Auxiliar de vitralista",
  "Auxiliar em hemotransfusão",
  "Auxiliar em saúde bucal",
  "Auxiliar em saúde bucal da estratégia de saúde da família",
  "Auxiliar financeiro",
  "Auxiliar geral de conservação de vias permanentes (exceto trilhos)",
  "Auxiliar iluminador",
  "Auxiliar jurídico",
  "Auxiliar mecânico de ar condicionado",
  "Auxiliar mecânico de refrigeração",
  "Auxiliar nos serviços de alimentação",
  "Auxiliar operacional de logística",
  "Auxiliar técnico de centro de operação do sistema de energia",
  "Auxiliar técnico de conservação de estradas, caminhos e pontes",
  "Auxiliar técnico de distribuição de energia",
  "Auxiliar técnico de educação",
  "Auxiliar técnico de eletricidade de linhas de transmissão",
  "Auxiliar técnico de engenharia (construção civil)",
  "Auxiliar técnico de fundição em siderurgia",
  "Auxiliar técnico de geração de energia",
  "Auxiliar técnico de hidrografia",
  "Auxiliar técnico de instalações eletromecânicas",
  "Auxiliar técnico de manipulação em laboratório de farmácia",
  "Auxiliar técnico de mecânica",
  "Auxiliar técnico de montagem",
  "Auxiliar técnico de obras saneamento",
  "Auxiliar técnico de refrigeração",
  "Auxiliar técnico de seguros",
  "Auxiliar técnico de sinalização nautica",
  "Auxiliar técnico eletrônico",
  "Auxiliar técnico em laboratório de farmácia",
  "Auxiliar técnico em obras de infra-estrutura de estradas",
  "Auxiliar técnico em patologia cínica",
  "Auxiliar técnico florestal",
  "Auxiliar técnico na mecânica de máquinas",
  "Auxiliar técnico na reparação de equipamentos fotográficos",
  "Auxiliar técnico- no futebol",
  "Auxiliar técnico- nos esportes",
  "Auxiliar-ajudante de operador de máquinas de lavar fibras, fios, tecidos e peças confeccionadas",
  "AUXILIARES DE CONTABILIDADE",
  "AUXILIARES DE LABORATÓRIO DA SAÚDE",
  "Auxiliares de produção de refinação de sal",
  "AUXILIARES DE SERVIÇOS DE DOCUMENTAÇÃO, INFORMAÇÃO E PESQUISA",
  "Avaliador de artes e antiguidades",
  "Avaliador de automóveis",
  "Avaliador de bens",
  "Avaliador de bens móveis",
  "Avaliador de imóveis",
  "Avaliador de jóias",
  "Avaliador de prédios",
  "Avaliador de produtos dos meios de comunicação",
  "Avaliador de propriedade",
  "Avaliador físico",
  "Avaliador judicial",
  "Avaliador judicial (escrivão)",
  "Aviador (comércio varejista)",
  "Aviador civil",
  "Avícola - empregador",
  "Avicultor",
  "Avicultor  de postura - exclusive conta própria e empregador",
  "Avicultor - empregador",
  "Avicultor - exclusive conta própria na avicultura de postura",
  "Avicultor avozeiro",
  "Avicultor comercial",
  "Avicultor de corte - exclusive conta própria e empregador",
  "Avicultor matrizeiro",
  "Axogum",
  "Azulejador",
  "Azulejista",
  "Babá",
  "Babá de umbanda",
  "Babakekerê",
  "Babalawô",
  "Babalorixá",
  "Babalossain",
  "Babaojé",
  "Baby-sitter",
  "Bacalhoeiro",
  "Bacteriologista",
  "Bagaceiro de rami",
  "Bagaceiro de sisal",
  "Baganeiro - na extração da carnaúba",
  "Bagrinho (estiva)",
  "Baiana de acarajé",
  "Bailarino (exceto danças populares)",
  "Bailarino coreógrafo",
  "Bailarino criador",
  "Bailarino de danças folclóricas",
  "Bailarino intérprete",
  "Bailarinos de danças parafolclóricas",
  "Bailarinos étnicos",
  "Bailarinos populares",
  "Bainheiro - em selaria",
  "Baiuqueiro",
  "Balaieiro",
  "Balanceador",
  "Balanceador de motores",
  "Balanceiro",
  "Balanceiro de concreto",
  "Balateiro",
  "Balateiro na extração de látex",
  "Balconista",
  "Balconista de açougue",
  "Balconista de bar",
  "Balconista de crediário",
  "Balconista de lanchonete",
  "Baleiro (ambulante)",
  "Baleiro (exclusive no comércio ambulante)",
  "Baleiro (fabricação)",
  "Balizador em inventário florestal",
  "Bamburista",
  "Bananicultor",
  "Banguezeiro - empregador",
  "Banguezista - empregador",
  "Banhista de animais domésticos",
  "Barbanteiro",
  "Barbeiro",
  "Barcaceiro - na cultura de cacau",
  "Barista",
  "Barman",
  "Barqueiro (turismo náutico)",
  "Barraconista",
  "Barranqueiro - no garimpo",
  "Barraqueiro (barracão comercial)",
  "Barreiro",
  "Barrerista",
  "Bataticultor",
  "Bate-folha a  máquina",
  "Bateador",
  "Batedor - em olaria",
  "Batedor de palha de carnaúba",
  "Batedor de pasto",
  "Batedor de piaçava",
  "Batedor de rami",
  "Batedor de sisal - na cultura",
  "Batedor de tijolos - na fabricação",
  "Bateeiro",
  "Bauleiro",
  "Bazareiro",
  "Bedel",
  "Beiradeiro",
  "Belchior",
  "Beneficiador de cristal",
  "Beneficiador de piaçava",
  "Beneficiador de sal",
  "Bibliógrafo",
  "Bibliopola",
  "Bibliotecário",
  "Biblioteconomista",
  "Bikeboy",
  "Bikkhu",
  "Bikku - bikkhuni",
  "Bikkuni",
  "Bilheteiro (estações de metrô, ferroviárias e assemelhadas)",
  "Bilheteiro de agência",
  "Bilheteiro de cinema",
  "Bilheteiro de circo",
  "Bilheteiro de parques de diversão",
  "Bilheteiro de teatro",
  "Bilheteiro de transportes coletivos",
  "Bilheteiro no serviço de diversões",
  "Bioengenheiro",
  "Bioestatístico",
  "Biógrafo",
  "Biologista",
  "Biólogo",
  "BIÓLOGOS E AFINS",
  "Biomédico",
  "BIOMÉDICOS",
  "Biotecnologista",
  "Biselador de cristais",
  "Bispo",
  "Bispo auxiliar",
  "Bispo coadjutor",
  "Bispo emérito",
  "Blaster",
  "Blender (cigarros)",
  "Blender de fumo",
  "Bloqueiro (trabalhador portuário)",
  "Bloquista (trabalhador portuário)",
  "Bobinador - eletricista",
  "Bobinador eletricista, à mão",
  "Bobinador eletricista, à máquina",
  "Bodegueiro",
  "Bodegueiro (gelador industrial)",
  "Bodegueiro (gelador profissional)",
  "Body piercer",
  "Bóia-fria",
  "Bóia-fria - na agropecuária",
  "Boiadeiro",
  "Boiadeiro (comércio de gado)",
  "Boiadeiro - no comércio de gado - empregador",
  "Boieiro",
  "Bokonô",
  "Bolacheiro",
  "Boleiro (fabricação de bolas de couro)",
  "Bolseiro (bolsas de couro)",
  "Bombeador (refinação de petróleo)",
  "Bombeiro civil",
  "Bombeiro de aeródromo",
  "Bombeiro de aeroporto",
  "Bombeiro de empresas particulares",
  "Bombeiro de estabelecimentos comerciais",
  "Bombeiro de estabelecimentos industriais",
  "Bombeiro de posto de gasolina",
  "Bombeiro de segurança do trabalho",
  "Bombeiro eletricista",
  "Bombeiro hidráulico",
  "Bombeiro resgatista",
  "Bombeiro socorrista",
  "BOMBEIROS, SALVA-VIDAS E AFINS",
  "Bombista",
  "Bomboneiro",
  "Boneleiro",
  "Bonequeiro",
  "Bordadeira",
  "Bordador, a  mão",
  "Bordador, à máquina",
  "Borracheiro",
  "Borracheiro - na extração de látex",
  "Borracheiro auxiliar",
  "Botânico",
  "Botequineiro",
  "Bovinocultor - empregador",
  "Boxeador",
  "Boxeur",
  "Brabo",
  "Branqueador de pasta  de celulose para fabricação de papel",
  "Brasador",
  "Brazidor de curtume",
  "Bricabraquista",
  "Bricabraquista - empregador",
  "Brigadeiro",
  "Brigadista florestal",
  "Britador - na extração de pedras",
  "Bronzeador de metais",
  "Broqueador de cilindros",
  "Broxador-caiador",
  "Bruaqueiro",
  "Brunidor de cilindros",
  "Bubalinocultor - corte",
  "Bubalinocultor - leite",
  "Buerista na conservação de vias permanentes",
  "Buteiro",
  "Cabanheiro - corte",
  "Cabanheiro - leite",
  "Cabazeiro",
  "Cabeça-de-campo",
  "Cabeceiro  em matadouro",
  "Cabeleireiro",
  "Cabeleireiro afro",
  "Cabeleireiro escovista",
  "Cabeleireiro feminino",
  "Cabeleireiro masculino",
  "Cabeleireiro penteador",
  "Cabeleireiro tinturista",
  "Cabeleireiro unissex",
  "Cabineiro",
  "Cabineiro de elevador",
  "Cabineiro de veículos",
  "Cabista",
  "Cableador",
  "Cableador (cabos de aço)",
  "Cabo bombeiro militar",
  "Cabo da aeronáutica",
  "Cabo da marinha",
  "Cabo da polícia militar",
  "Cabo de fogo",
  "Cabo do exército",
  "CABOS E SOLDADOS DA POLÍCIA MILITAR",
  "CABOS E SOLDADOS DO CORPO DE BOMBEIROS MILITAR",
  "Caboucador (minas)",
  "Cabouqueiro - na extração de pedras",
  "Cabresteador",
  "Cabuqueiro",
  "Caçambeiro",
  "Cacauicultor - empregador",
  "Cacauicultor - exclusive conta própria e empregador",
  "Cacaulista",
  "Cachacier",
  "Cachacista",
  "Cachorreiro",
  "Cacimbeiro (estivador)",
  "Cacimbeiro (poço)",
  "Cacique",
  "Cádi",
  "Cadista (desenhista técnico de arquitetura)",
  "Cafeicultor",
  "Cafeicultor - empregador",
  "Cafeicultor - exclusive conta própria e empregador",
  "Cafeteiro",
  "Caga-sebista",
  "Caiador",
  "Caieiro  (pintor de paredes)",
  "Caieiro - na fabricação de cal",
  "Caixa (supermercado)",
  "Caixa de banco",
  "Caixa de bar, lanchonetes e restaurantes",
  "Caixa de loja",
  "Caixa de turfe",
  "Caixa lotérico",
  "Caixa no comércio",
  "Caixa no serviço de alimentação",
  "CAIXAS E BILHETEIROS (EXCETO CAIXA DE BANCO)",
  "Cake designer",
  "Calafate",
  "Calafetador",
  "Calandreiro de plástico",
  "Calandrista de borracha",
  "Calandrista de papel",
  "Calceirista",
  "Calceteiro",
  "Calceteiro (pastilheiro)",
  "Calcinador",
  "Calculista de folha de pagamento",
  "Calculista de produção",
  "Calculista de tempos",
  "Caldeireiro (chapas de cobre)",
  "Caldeireiro (chapas de ferro e aço)",
  "Caldeireiro de manutenção",
  "Caldeireiro em arsenal",
  "Caldeireiro em estaleiro",
  "Caldeireiro instalador",
  "Caldeireiro montador",
  "Caldeireiro serralheiro",
  "Caldeirista",
  "Caldereiro - na exploração de babaçu",
  "Calibrador de tubos de vidro",
  "Calibrador na fabricação de ampolas",
  "Camarada - na agropecuária",
  "Camareira de hotel",
  "Camareiro  de hotel",
  "Camareiro de embarcações",
  "Camareiro de teatro",
  "Camareiro de televisão",
  "Camareiro no transporte marítimo e fluvial",
  "Camareiro(a) de teatro",
  "Camareiro(a) de televisão",
  "CAMAREIROS, ROUPEIROS E AFINS",
  "Camaroneiro",
  "Cambiteiro",
  "Cambiteiro - na extração da carnaúba",
  "Cambono",
  "Camelô (comércio ambulante)",
  "Camelô (permissionário em ponto fixo)",
  "Cameraman (cinema)",
  "Caminhoneiro",
  "Caminhoneiro autônomo (rotas regionais e internacionais)",
  "Caminhoneiro caçambeiro",
  "Caminhoneiro carreteiro",
  "Caminhoneiro carreteiro (transporte animal)",
  "Caminhoneiro de basculante",
  "Caminhoneiro de caminhão basculante",
  "Caminhoneiro de caminhão leve",
  "Caminhoneiro de caminhão pipa",
  "Caminhoneiro de caminhão-betoneira",
  "Caminhoneiro de caminhão-tanque",
  "Caminhoneiro gaioleiro (gado)",
  "Caminhoneiro operador de caminhão-betoneira",
  "Campeiro (asininos e muares)",
  "Campeiro (bovinos de corte)",
  "Campeiro (bubalinos)",
  "Campeiro (eqüinos)",
  "Campeiro - na agropecuária",
  "Campeiro - na pecuária",
  "Camponês na agropecuária",
  "Canalista",
  "Canavieiro",
  "Cancerologista clínico",
  "Cangalheiro",
  "Cangalheiro - na fabricação",
  "Cangoteiro em matadouro",
  "Cangueiro - carro de boi",
  "Canicultor",
  "Canoeiro (turismo náutico)",
  "Canteiro",
  "Cantineiro (comércio varejista)",
  "Cantineiro (escola)",
  "Cantineiro (fabricação de vidros)",
  "Capataz",
  "Capataz (criação de gado bovino)",
  "Capataz da exploração agrícola",
  "Capataz da exploração agropecuária",
  "Capataz de currais bovinos",
  "Capataz de horticultura",
  "Capataz na exploração de pecuária",
  "Capataz na fruticultura e na floricultura",
  "Capataz na lavoura (exceto na floricultura, fruticultura e horticultura)",
  "Capataz na pecuária",
  "Capataz rural",
  "Capeador de charutos",
  "Capelão",
  "Capinador - na cultura",
  "Capinador - na lavoura",
  "Capineiro - na cultura",
  "Capinheiro - na cultura",
  "Capinzeiro - na cultura",
  "CAPITÃES DA  POLÍCIA MILITAR",
  "Capitão bombeiro militar",
  "Capitão da aeronáutica",
  "Capitão da polícia militar",
  "Capitão de cabotagem",
  "Capitão de cabotagem (comandante)",
  "Capitão de cabotagem (imediato)",
  "Capitão de longo curso",
  "Capitão de longo curso (comandante)",
  "Capitão de longo curso (imediato)",
  "Capitão de manobra da marinha mercante",
  "Capitão de pequena cabotagem",
  "Capitão do corpo de bombeiros militar",
  "Capitão do exército",
  "Capitão porteiro",
  "Capitão-de-corveta",
  "Capitão-de-fragata",
  "Capitão-de-mar-e-guerra",
  "Capitão-tenente da marinha",
  "Capoteiro",
  "Caprinocultor",
  "Caprinocultor - empregador",
  "Caprinocultor - exclusive conta própria",
  "Caprinocultor cabanheiro",
  "Caprinocultor de corte",
  "Caprinocultor de leite",
  "Caprinocultor matrizeiro",
  "Captador de recursos",
  "CAPTADORES DE IMAGENS EM MOVIMENTO",
  "Carameleiro",
  "Caranguejeiro",
  "Carapina",
  "Carbonizador",
  "Carcinicultor",
  "Carcinologista",
  "Cardeal",
  "Cardiologista",
  "Cardista",
  "Cargueiro",
  "Caricaturista",
  "Carimbador, a mão",
  "Carimbador, a máquina",
  "Carneador em matadouro e açougue",
  "Carniceiro",
  "Caroneiro - em cartonagem",
  "Carpidor - na cultura",
  "Carpinteiro",
  "Carpinteiro (cenários)",
  "Carpinteiro (esquadrias)",
  "Carpinteiro (mineração)",
  "Carpinteiro (obras)",
  "Carpinteiro (telhados)",
  "Carpinteiro (tripulante de embarcações)",
  "Carpinteiro auxiliar",
  "Carpinteiro de carretas",
  "Carpinteiro de carrocerias",
  "Carpinteiro de estruturas",
  "Carpinteiro de estúdio",
  "Carpinteiro de fôrmas (concreto)",
  "Carpinteiro de fôrmas para concreto",
  "Carpinteiro de manutenção",
  "Carpinteiro de obras",
  "Carpinteiro de obras civis de arte (pontes, túneis, barragens)",
  "Carpinteiro de pontes",
  "Carpinteiro naval (construção de pequenas embarcações)",
  "Carpinteiro naval (embarcações)",
  "Carpinteiro naval (estaleiros)",
  "CARPINTEIROS DE CARROCERIAS E CARRETAS",
  "CARPINTEIROS NAVAIS",
  "Carregador (aeronaves)",
  "Carregador (armazém)",
  "Carregador (veículos de transportes terrestres)",
  "Carregador de alto-forno",
  "Carregador de andiroba",
  "Carregador de bacaba",
  "Carregador de buriti",
  "Carregador de caminhão",
  "Carregador de forno de segunda fusão e reaquecimento",
  "Carregador de rami",
  "Carregador de utensílios de cozinha",
  "Carregador de vagões",
  "Carregador e descarregador de caminhões",
  "Carreiro",
  "Carreteiro (caminhoneiro de caminhão-carreta)",
  "Carreteiro (motorista de caminhão-carreta)",
  "Carreteiro (transporte de animal)",
  "Carroceiro",
  "Cartazeiro",
  "Carteiro",
  "Carteiro motorizado",
  "Cartógrafo",
  "Cartomante",
  "Cartonageiro - em cartonagem",
  "Cartonageiro - nas indústrias gráficas",
  "Cartonageiro, a mão (caixas de papelão)",
  "Cartonageiro, a máquina",
  "Cartunista",
  "Carvoeiro",
  "Carvoeiro (comércio varejista)",
  "Carvoeiro - no comércio - empregador",
  "Carvoeiro - no transporte ferroviário",
  "CARVOEJADORES",
  "Caseiro",
  "Caseiro (agricultura)",
  "Casqueador de animais",
  "Casqueador de bovinos",
  "Casqueador de toras",
  "Castanheiro",
  "Castrador",
  "Castrador - na pecuária",
  "Catadeira - na cultura",
  "Catador - na cultura",
  "Catador de algodão",
  "Catador de café",
  "Catador de caranguejos",
  "Catador de caranguejos e siris",
  "Catador de crustáceos",
  "Catador de ferro-velho",
  "Catador de mariscos",
  "Catador de material reciclável",
  "Catador de ouro",
  "Catador de papel e papelão",
  "Catador de piaçava",
  "Catador de pinhão",
  "Catador de siris",
  "Catador de sucata",
  "Catador de vasilhame",
  "Catequista",
  "Caucheiro",
  "Cavador - na cultura",
  "Cavador de poço",
  "Cavalariço",
  "Cavaleiro",
  "Cavouqueiro - na extração de pedras",
  "Cebolecultor",
  "Cegonheiro ( motorista de caminhão)",
  "Ceifador",
  "Ceifador - na cultura",
  "Ceifeiro",
  "Celofanista na fabricação de charutos",
  "Cementador de metais",
  "Cenógrafo carnavalesco e festas populares",
  "Cenógrafo de cinema",
  "Cenógrafo de eventos",
  "Cenógrafo de teatro",
  "Cenógrafo de tv",
  "CENÓGRAFOS",
  "Cenotécnico (cinema, vídeo, televisão, teatro e espetáculos)",
  "Cenotécnico de filmagem",
  "Centralizador de canos",
  "Centralizador de fusos",
  "Centralizador de rodas",
  "Centrifuguista",
  "Cerâmico",
  "Ceramista",
  "Ceramista (artes visuais)",
  "Ceramista (confecção de moldes)",
  "Ceramista (torno de pedal e motor)",
  "Ceramista (torno semi-automático)",
  "Ceramista artesanal",
  "Ceramista artístico",
  "Ceramista batedor de prensa extrusora",
  "Ceramista escultor (acabamento)",
  "Ceramista fileteiro",
  "Ceramista fundidor de molde",
  "Ceramista modelador",
  "Ceramista moldador",
  "Ceramista multifuncional",
  "Ceramista operador de prensa hidráulica",
  "Ceramista prensador",
  "Ceramista prensador (prensa a  fricção)",
  "Ceramista prensador (prensa extrusora)",
  "Ceramista prensador (prensa hidráulica)",
  "Ceramista prensista (prensa extrusora)",
  "Ceramista prensista (prensa hidráulica)",
  "CERAMISTAS (PREPARAÇÃO E FABRICAÇÃO)",
  "Cerimonialista",
  "Cerqueiro",
  "Cervejeiro (fabricação)",
  "Cervejeiro - empregador",
  "Cerzideira de malha",
  "Cerzideira de meias",
  "Cerzideira de roupas",
  "Cerzideira de tecidos",
  "Cerzidor",
  "Cesteiro",
  "Ceteísta",
  "Cevador (pecuária)",
  "Chacareiro - exclusive conta própria e empregador",
  "Chamador de bois",
  "Chanfaneiro",
  "Chanfrador de calçados",
  "Chapa (movimentador de mercadoria)",
  "Chapa arrumador de caminhões",
  "Chapa de caminhão",
  "Chapeador",
  "Chapeador de aeronaves",
  "Chapeador de automóveis",
  "Chapeador de aviões",
  "Chapeador de carrocerias metálicas (fabricação)",
  "Chapeador de fórmica",
  "Chapeador de móveis de madeira",
  "Chapeador de móveis metálicos",
  "Chapeador de tijolos",
  "Chapeador de veículos",
  "Chapeador metalúrgico",
  "Chapeador montador",
  "Chapeador naval",
  "Chapeiro",
  "Chapeiro (chapas de ferrro e aço)",
  "Chapeleiro (chapéus de palha)",
  "Chapeleiro (comércio varejista)",
  "Chapeleiro - exclusive de palha",
  "Chapeleiro de chapéus de luxo",
  "Chapeleiro de palha",
  "Chapeleiro de senhoras",
  "Chapista de aeronaves",
  "Chapista de automóveis",
  "Chapista de aviões",
  "Chapista de carrocerias metálicas",
  "Chapista de lanchonete",
  "Chapista de veículos",
  "Chapista de veículos (fabricação)",
  "Chapista gráfico",
  "Chapista paginador",
  "Chapista tipográfico",
  "Charcuteiro (comércio varejista)",
  "Charcuteiro - no comércio - conta própria",
  "Chargista",
  "Charqueador",
  "Charqueador (comércio varejista)",
  "Charreteiro",
  "Charuteiro (comércio varejista)",
  "Charuteiro - no comércio - empregador",
  "Charuteiro a mão",
  "CHARUTEIROS",
  "Chasquil",
  "Chaveiro",
  "Chef",
  "Chef executivo nos serviços de alimentação",
  "Chefe administrativo",
  "Chefe da copa",
  "Chefe de acabamento de papel",
  "Chefe de administração patrimonial",
  "Chefe de armazém (técnicos em transportes rodoviários)",
  "Chefe de assessoria técnica do serviço público municipal",
  "Chefe de bar",
  "Chefe de barewomen",
  "Chefe de bartander",
  "Chefe de bilheteria",
  "Chefe de brigada",
  "Chefe de carga e descarga no transporte rodoviário",
  "Chefe de centrifugação",
  "Chefe de centro de formação profissional privado",
  "Chefe de chaparia",
  "Chefe de cobrança",
  "Chefe de confeitaria",
  "Chefe de contabilidade (técnico)",
  "Chefe de cozinha",
  "Chefe de cristalização",
  "Chefe de departamento de motoristas",
  "Chefe de departamento de pessoal",
  "Chefe de departamento em escolas privadas",
  "Chefe de depósito",
  "Chefe de desenvolvimento de novos produtos",
  "Chefe de digitação",
  "Chefe de divisão gráfica",
  "Chefe de escritório",
  "Chefe de esquadrão",
  "Chefe de estação (ferrovia)",
  "Chefe de estação portuária",
  "Chefe de expediente - no serviço público",
  "Chefe de fabricação de chocolate",
  "Chefe de fila nos serviços de alimentação",
  "Chefe de gabinete do serviço público municipal",
  "Chefe de garagem (transporte rodoviário)",
  "Chefe de lavagem de equipamentos de proteção individual  (epi)",
  "Chefe de lavanderia",
  "Chefe de manobra - no transporte ferroviário",
  "Chefe de manutenção eletroeletrônica veicular",
  "Chefe de manutenção eletromecânica",
  "Chefe de manutenção mecânica de sistemas operacionais",
  "Chefe de máquina - no transporte marítimo",
  "Chefe de máquinas da marinha mercante",
  "Chefe de oficina de manutenção e recarga de extintor de incêndio",
  "Chefe de operações de sistemas de televisão e produtoras de vídeo",
  "Chefe de operações portuárias",
  "Chefe de orçamento",
  "Chefe de partida",
  "Chefe de portaria (edifício)",
  "Chefe de portaria de hotel",
  "Chefe de produção (indústria de máquinas e outros equipamentos mecânicos)",
  "Chefe de produção (indústria de material elétrico e eletrônico)",
  "Chefe de produção na fabricação de produtos de cerâmica, porcelanatos e afins",
  "Chefe de produção na fabricação de produtos de vidro",
  "Chefe de recepção",
  "Chefe de recurso de glosa de contas de convênios (administração de hospital)",
  "Chefe de recurso de glosas de contas nos hostpitais (administração de convênios e fonte pagadora)",
  "Chefe de sala (concurso, avaliação, exame)",
  "Chefe de seção - no serviço público",
  "Chefe de seção de artes gráficas",
  "Chefe de seção de expedição",
  "Chefe de seção de manutenção eletromecânica (utilidades)",
  "Chefe de seção de mina",
  "Chefe de seção de serviços administrativos",
  "Chefe de seção de serviços gerais - exclusive no serviço público",
  "Chefe de serviço de limpeza",
  "Chefe de serviço de pesquisa e estatística",
  "Chefe de serviço de transporte rodoviário",
  "Chefe de serviço de transporte rodoviário (passageiros e cargas)",
  "Chefe de serviço financeiro",
  "Chefe de serviços bancários",
  "Chefe de serviços de coordenação de contratos",
  "Chefe de serviços de saúde",
  "Chefe de setor - exclusive no serviço público",
  "Chefe de setor - no serviço público",
  "Chefe de setor de cromação",
  "Chefe de setor de fundição de metais",
  "Chefe de setor de plásticos",
  "Chefe de transporte - no serviço público",
  "Chefe de transportes - exclusive no serviço público",
  "Chefe de turma volante - na cultura",
  "Chefe do serviço público estadual e distrital",
  "Chefe executivo de cozinha",
  "CHEFES DE COZINHA E AFINS",
  "Chofer",
  "Chofer de praça",
  "Chopeiro",
  "Chumbeiro (comércio varejista)",
  "Churrasqueiro",
  "CHURRASQUEIROS, PIZZAIOLOS E SUSHIMEN",
  "Ciclista de carga",
  "Ciclista mensageiro",
  "Cientista de dados",
  "Cientista de informação",
  "Cientista político",
  "Cientista social",
  "Cigarreiro (comércio varejista)",
  "CIGARREIROS",
  "CIGARREIROS E BENEFICIADORES DE FUMO",
  "Cilindreiro de preparação de massa alimentícia",
  "Cilindreiro na preparação de pasta para fabricação de papel",
  "Cilindrista (petroquímica e afins)",
  "Cilindrista de borracha",
  "Cilindrista de imprensa",
  "Cilindrista de plástico",
  "Cimentador (poços de petróleo)",
  "Cineasta",
  "Cinesiólogo ludomotricista",
  "Cinteiro (correeiro)",
  "Cinzelador de metais preciosos",
  "Circuleiro",
  "Cirurgião",
  "Cirurgião cardiovascular",
  "Cirurgião da mão",
  "Cirurgião de cabeça e pescoço",
  "Cirurgião de mama",
  "Cirurgião dentista - auditor",
  "Cirurgião dentista - clínico geral",
  "Cirurgião dentista - dentística",
  "Cirurgião dentista - disfunção temporomandibular e dor orofacial",
  "Cirurgião dentista - endodontista",
  "Cirurgião dentista - epidemiologista",
  "Cirurgião dentista - estomatologista",
  "Cirurgião dentista - implantodontista",
  "Cirurgião dentista - odontogeriatra",
  "Cirurgião dentista - odontologia do trabalho",
  "Cirurgião dentista - odontologia para pacientes com necessidades especiais",
  "Cirurgião dentista - odontologista legal",
  "Cirurgião dentista - odontopediatra",
  "Cirurgião dentista - ortopedista e ortodontista",
  "Cirurgião dentista - patologista bucal",
  "Cirurgião dentista - periodontista",
  "Cirurgião dentista - protesiólogo bucomaxilofacial",
  "Cirurgião dentista - protesista",
  "Cirurgião dentista - radiologista",
  "Cirurgião dentista - reabilitador oral",
  "Cirurgião dentista - traumatologista bucomaxilofacial",
  "Cirurgião dentista de saúde coletiva",
  "Cirurgião do aparelho digestivo",
  "Cirurgião gastroenterológico",
  "Cirurgião geral",
  "Cirurgião ginecológico",
  "Cirurgião mastologista",
  "Cirurgião oftalmológico",
  "Cirurgião oral e maxilofacial",
  "Cirurgião ortopedista",
  "Cirurgião otorrinolaringologista",
  "Cirurgião pediátrico",
  "Cirurgião plástico",
  "Cirurgião proctologista",
  "Cirurgião torácico",
  "Cirurgião traumatologista",
  "Cirurgião urológico",
  "Cirurgião urologista",
  "Cirurgião vascular",
  "Cirurgião-dentista da estratégia de saúde da família",
  "CIRURGIÕES-DENTISTAS",
  "Cisterneiro",
  "Citologista",
  "Citopatologista",
  "Citotécnico",
  "Citotecnologista",
  "Citricultor",
  "Clarificador de vinhos",
  "Classificador de algodão - no beneficiamento de fibras têxteis",
  "Classificador de algodão em caroço",
  "Classificador de algodão em pluma",
  "Classificador de amostras - na mineração",
  "Classificador de bebida",
  "Classificador de café",
  "Classificador de carnes",
  "Classificador de carnes em matadouro",
  "Classificador de cera de carnaúba - na extração",
  "Classificador de charutos",
  "Classificador de correspondências (correios e telégrafos)",
  "Classificador de couros",
  "Classificador de embalagens (manual)",
  "Classificador de fibras na indústria têxtil",
  "Classificador de fibras têxteis",
  "Classificador de fumo",
  "Classificador de grãos",
  "Classificador de lã na indústria têxtil",
  "Classificador de lâminas (madeira)",
  "Classificador de licores",
  "Classificador de madeira",
  "Classificador de madeira na indústria",
  "Classificador de minérios",
  "Classificador de peles",
  "Classificador de sisal - no beneficiamento",
  "Classificador de tecidos",
  "Classificador de toras",
  "Classificador de vinhos",
  "Classificador e empilhador de tijolos e telhas",
  "Classificador e empilhador de tijolos refratários",
  "Clérigo",
  "Clínico geral",
  "Clown",
  "Coadjuvante (artístico)",
  "Cobrador de aluguéis",
  "Cobrador de transportes coletivos (exceto trem)",
  "Cobrador externo",
  "Cobrador interno",
  "Cobrador pracista",
  "Cobrador viajante",
  "COBRADORES E AFINS",
  "Cobreiro",
  "Cocheiro",
  "Codificador de dados",
  "Codificador de dados clínicos",
  "Codificador hospitalar",
  "Coelheiro",
  "Coladeira (confecção em série)",
  "Colador de caixas",
  "Colador de calçados",
  "Colador de cartazes",
  "Colador de couro - exclusive calçados",
  "Colador de couro, a  mão",
  "Colador de palmilhas",
  "Colador de rótulos em caixas",
  "Colchoeiro (confecção de colchões)",
  "Colecionador de selos e moedas",
  "Coletador de preços",
  "COLETADORES DE APOSTAS E DE JOGOS",
  "Coletor de babaçu",
  "Coletor de látex",
  "Coletor de lixo",
  "Coletor de lixo domiciliar",
  "Coletor de lixo hospitalar",
  "Coletor de materiais recicláveis",
  "Coletor de resíduos de saúde",
  "Coletor de resíduos hospitalares",
  "Coletor de resíduos sólidos de serviços de saúde",
  "Coletor de resinas",
  "Coletor de sementes (floresta)",
  "Coletor na cultura",
  "Colhedor - em fábrica de vidro",
  "Colhedor - na cultura",
  "Colhedor de algodão",
  "Colhedor de amostras - em minas",
  "Colhedor de arroz",
  "Colhedor de banana",
  "Colhedor de cacau",
  "Colhedor de café",
  "Colhedor de caju",
  "Colhedor de cana-de-açúcar",
  "Colhedor de coco",
  "Colhedor de erva-mate",
  "Colhedor de fumo",
  "Colhedor de guaraná",
  "Colhedor de laranja",
  "Colhedor de lavoura (exceto na floricultura, fruticultura e horticultura)",
  "Colhedor de manga",
  "Colhedor de pêssego",
  "Colhedor de sementes",
  "Colhedor de uva",
  "Colocador de assoalho",
  "Colocador de cartazes",
  "Colocador de cerâmica",
  "Colocador de cerâmica (pastilhas)",
  "Colocador de estruturas metálicas",
  "Colocador de painéis",
  "Colocador de papel de parede",
  "Colocador de piso vinílico",
  "Colocador de pisos emborrachados",
  "Colocador de tacos",
  "Colocador de tapetes, carpetes e pisos plásticos",
  "Colono - na agropecuária",
  "Colono na produção de arroz",
  "Colono na produção de cereais de inverno",
  "Colono na produção de gramíneas forrageiras",
  "Colono na produção de milho e sorgo",
  "Coloproctologista",
  "Colorista",
  "Colorista - na indústria do couro",
  "Colorista de couro",
  "Colorista de papel",
  "Colorista têxtil",
  "COLORISTAS",
  "Colunista",
  "Colunista de jornal",
  "Comandante da marinha mercante",
  "Comboieiro",
  "Comediante",
  "Comedor de espada",
  "Comedor de fogo (pirofagista)",
  "Comentarista cultural",
  "Comentarista de esportes",
  "Comentarista de jornal",
  "Comentarista de mídias audiovisuais",
  "Comentarista de moda",
  "Comentarista de rádio e tv",
  "Comentarista de rodeio",
  "Comentarista econômico",
  "Comentarista esportivo",
  "Comentarista político",
  "Comerciante atacadista",
  "Comerciante varejista",
  "Cômico de circo",
  "Comissário de avarias",
  "Comissário de bordo",
  "Comissário de bordo no transporte marítimo",
  "Comissário de café",
  "Comissário de mercadorias",
  "Comissário de polícia",
  "Comissário de transporte (comércio varejista)",
  "Comissário de trem",
  "Comissário de vôo",
  "Compensador de banco",
  "Compositor",
  "Compositor de imagens",
  "Compositor de música",
  "Compositor de texto",
  "Compositor, a  máquina",
  "Compoteiro",
  "Comprador",
  "Comprador de fumo",
  "COMPRADORES",
  "Compressorista de ar",
  "Computometrista",
  "Comunicador de mídias audiovisuais",
  "Comunicador de rádio e televisão",
  "Concierge",
  "Concreteiro",
  "Condicionador de animais",
  "Condutor  em fábrica de papel",
  "Condutor de ambulância",
  "Condutor de asfaltadora",
  "Condutor de bate-estaca",
  "Condutor de betoneira",
  "Condutor de bicicleta no transporte de mercadorias",
  "Condutor de boiada",
  "Condutor de bois",
  "Condutor de bois - na criação",
  "Condutor de bovinos",
  "Condutor de carroça",
  "Condutor de charrete",
  "Condutor de ecotrilha",
  "Condutor de escavadeira",
  "Condutor de máquina contínua de extração",
  "Condutor de máquina de abrir valas",
  "Condutor de máquina de fabricar papel e papelão",
  "Condutor de máquina de papel (fase úmida)",
  "Condutor de máquina de secagem de celulose",
  "Condutor de máquina de secagem na fabricação de pasta/celulose",
  "Condutor de máquinas",
  "Condutor de máquinas (bombeador)",
  "Condutor de máquinas (mecânico)",
  "Condutor de motoniveladora",
  "Condutor de niveladora",
  "Condutor de pá mecânica",
  "Condutor de pavimentadora",
  "Condutor de perfuradora de precisão",
  "Condutor de processos robotizados de pintura",
  "Condutor de processos robotizados de soldagem",
  "Condutor de táxi",
  "Condutor de transporte de pacientes",
  "Condutor de turismo de aventura",
  "Condutor de turismo de pesca",
  "Condutor de veículo de carga",
  "Condutor de veículos a pedais",
  "Condutor de veículos ambulatoriais",
  "Condutor de veículos de tração animal (ruas e estradas)",
  "Condutor maquinista motorista fluvial",
  "Condutor turismo náutico",
  "CONDUTORES DE ANIMAIS E DE VEÍCULOS DE TRAÇÃO ANIMAL E PEDAIS",
  "CONDUTORES DE PROCESSOS ROBOTIZADOS",
  "CONDUTORES DE TURISMO",
  "Cônega",
  "Cônego",
  "Confeccionador de acordeão",
  "Confeccionador de artefatos de couro (exceto sapatos)",
  "Confeccionador de artigos de papel e papelão",
  "Confeccionador de bolsas de papel",
  "Confeccionador de bolsas, sacos e sacolas e papel, a máquina",
  "Confeccionador de brinquedos de pano",
  "Confeccionador de cabrestos",
  "Confeccionador de caixas de papelão",
  "Confeccionador de caixas de papelão, a mão",
  "Confeccionador de caixas de papelão, a máquina",
  "Confeccionador de calçados de couro",
  "Confeccionador de câmaras de ar",
  "Confeccionador de carimbos",
  "Confeccionador de carimbos de borracha",
  "Confeccionador de chapéus de palha",
  "Confeccionador de clichês de flexografia",
  "Confeccionador de escovas",
  "Confeccionador de escovas, pincéis e produtos similares (a mão)",
  "Confeccionador de escovas, pincéis e produtos similares (a máquina)",
  "Confeccionador de estalinho",
  "Confeccionador de esteiras",
  "Confeccionador de esteiras de palha",
  "Confeccionador de foguetes",
  "Confeccionador de instrumentos de corda",
  "Confeccionador de instrumentos de percussão (pele, couro ou plástico)",
  "Confeccionador de instrumentos de sopro (madeira)",
  "Confeccionador de instrumentos de sopro (metal)",
  "Confeccionador de lixas",
  "Confeccionador de matrizes tipográficas",
  "Confeccionador de móveis de vime, junco e bambu",
  "Confeccionador de órgão",
  "Confeccionador de piano",
  "Confeccionador de pincéis",
  "Confeccionador de pincéis, escovas e vassouras",
  "Confeccionador de pneumáticos",
  "Confeccionador de pneus",
  "Confeccionador de produtos de papel e papelão",
  "Confeccionador de sacolas de papel",
  "Confeccionador de sacos de celofane, a máquina",
  "Confeccionador de sacos de papel",
  "Confeccionador de tambores (música)",
  "Confeccionador de tapetes e passadeiras, a  máquina",
  "Confeccionador de vassouras de piaçaba, palha, cerdas plásticas e náilon",
  "Confeccionador de velas náuticas, barracas e toldos",
  "Confeccionador de velas por imersão",
  "Confeccionador de velas por moldagem",
  "CONFECCIONADORES DE ARTEFATOS DE MADEIRA, MÓVEIS DE VIME E AFINS",
  "CONFECCIONADORES DE INSTRUMENTOS MUSICAIS",
  "Confeiteiro",
  "Conferente de carga e descarga",
  "Conferente de controle de produção",
  "Conferente de documentação de importação e exportação",
  "Conferente de exportação",
  "Conferente de faturas e notas fiscais",
  "Conferente de logística",
  "Conferente de serviços bancários",
  "Conferente mercadoria (exceto carga e descarga)",
  "Conferente portuário",
  "Conferente-expedidor de roupas (lavanderias)",
  "Confessor",
  "Conformador de chapéus",
  "Consagrado",
  "Conselheiro (diplomacia)",
  "Conselheiro classista",
  "Conselheiro correicional eclesiástico",
  "Conselheiro de dependente químico",
  "Conselheiro do tribunal eclesiástico",
  "Conselheiro em acantonamento",
  "Conselheiro julgador",
  "Conselheiro julgador relator",
  "Conselheiro relator",
  "Conselheiro tutelar",
  "CONSELHEIROS JULGADORES",
  "Consertador de equipamentos eletrônicos",
  "Consertador de pneus",
  "Consertador de rádio",
  "Consertador de roupas",
  "Conservador de edificações",
  "Conservador de estradas de rodagem",
  "Conservador de fachadas",
  "Conservador de linhas elétricas e telefônicas",
  "Conservador de pescado em barco de pesca (gelador industrial)",
  "Conservador de pescado em barco de pesca (gelador profissional)",
  "Conservador de via permanente (trilhos)",
  "Conservador-restaurador de bens  culturais",
  "CONSERVADORES DE VIAS PERMANENTES (TRILHOS)",
  "Construtor civil",
  "Construtor de cenários",
  "Consultor",
  "Consultor administrativo",
  "Consultor contábil (técnico)",
  "Consultor de cobrança",
  "Consultor de estilo",
  "Consultor de informação",
  "Consultor de organização",
  "Consultor de produtos farmacêuticos",
  "Consultor de sistemas de ti",
  "Consultor de tecnologia da informação",
  "Consultor de vendas",
  "Consultor de viagem",
  "Consultor em dependência química",
  "Consultor em organização",
  "Consultor em turismo (turismólogo)",
  "Consultor imobiliário",
  "Consultor jurídico",
  "Consultor técnico - na extração mineral",
  "Consultor técnico em aqüicultura",
  "Consultor técnico em exploração vegetal",
  "Consultor técnico em pesca",
  "Contabilista",
  "Contador",
  "Contador de história",
  "Contador judicial",
  "CONTADORES E AFINS",
  "Contatólogo",
  "Continuista",
  "Contínuo",
  "CONTÍNUOS",
  "Contista",
  "Contorcionista",
  "Contra-almirante",
  "Contramestre (distribuição de energia elétrica, gás e água)",
  "Contramestre (indústria de celulose, papel e papelão)",
  "Contramestre (indústria de máquinas e outros equipamentos mecânicos)",
  "Contramestre (produção de energia elétrica, gás e captação de água)",
  "Contramestre da indústria de calçados",
  "Contramestre de abastecimento, captação e distribuição de água",
  "Contramestre de acabamento (indústria têxtil)",
  "Contramestre de cabotagem",
  "Contramestre de conservação de vias permanentes",
  "Contramestre de fiação (indústria têxtil)",
  "Contramestre de indústrias de máquinas",
  "Contramestre de indústrias têxtil",
  "Contramestre de malharia (indústria têxtil)",
  "Contramestre de manutenção (serviço de esgotos sanitários)",
  "Contramestre de manutenção de serviço de esgoto",
  "Contramestre de produção e distribuição de energia elétrica",
  "Contramestre de tecelagem (indústria têxtil)",
  "Contramestre ferreiro",
  "Contramestre fluvial",
  "Controlador da velocidade de equipamentos na refinação de açúcar",
  "Controlador de acesso",
  "Controlador de almoxarifado",
  "Controlador de caldeira",
  "Controlador de carro torpedo",
  "Controlador de centro de controle operacional",
  "Controlador de centro de controle operacional (metrô e ferrovia)",
  "Controlador de dosadores de rações",
  "Controlador de entrada e saída",
  "Controlador de estatística",
  "Controlador de laminação",
  "Controlador de líbero (vôlei)",
  "Controlador de mão-de-obra",
  "Controlador de motores - no transporte marítimo",
  "Controlador de orçamento",
  "Controlador de pátio de sucata",
  "Controlador de pragas",
  "Controlador de produção",
  "Controlador de programação",
  "Controlador de qualidade",
  "Controlador de serviço de transporte rodoviário",
  "Controlador de serviços de máquinas e veículos",
  "Controlador de serviços de produção",
  "Controlador de tráfego",
  "Controlador de tráfego -na mineração",
  "Controlador de tráfego aéreo",
  "Controlador de vôo",
  "Controle mestre",
  "Controller (contador)",
  "Coordenador  do serviço público federal",
  "Coordenador administrativo",
  "Coordenador auxiliar de curso",
  "Coordenador de administração de pessoal",
  "Coordenador de área (judô)",
  "Coordenador de artes gráficas",
  "Coordenador de associação quilombola",
  "Coordenador de bordado",
  "Coordenador de caldeiraria",
  "Coordenador de câmara de chegada",
  "Coordenador de cobrança",
  "Coordenador de compras",
  "Coordenador de contabilidade",
  "Coordenador de corte na confecção do vestuário",
  "Coordenador de costura do vestuário",
  "Coordenador de desenvolvimento de ti",
  "Coordenador de digitação",
  "Coordenador de disciplina e área de estudo",
  "Coordenador de ensino",
  "Coordenador de entidade religiosa",
  "Coordenador de eventos",
  "Coordenador de futebol",
  "Coordenador de infraestrutura de tecnologia da informação",
  "Coordenador de manutenção eletrônica",
  "Coordenador de manutenção mecânica de sistemas operacionais",
  "Coordenador de microcrédito",
  "Coordenador de mídia",
  "Coordenador de obras (construção civil)",
  "Coordenador de operação de tecnologia da informação",
  "Coordenador de operações de combate à poluição no meio aquaviário",
  "Coordenador de operações de sistemas de televisão e produtoras de vídeo",
  "Coordenador de operações portuárias",
  "Coordenador de orientação pedagógica",
  "Coordenador de produção de madeira",
  "Coordenador de programação",
  "Coordenador de projetos de manutenção",
  "Coordenador de projetos de tecnologia da informação",
  "Coordenador de projetos sociais",
  "Coordenador de provas (concurso, avaliação, exame)",
  "Coordenador de provas de local de aplicação (concurso, avaliação, exame)",
  "Coordenador de provas estadual (concurso, avaliação, exame)",
  "Coordenador de provas municipal (concurso, avaliação, exame)",
  "Coordenador de recepção",
  "Coordenador de restaurante",
  "Coordenador de rh",
  "Coordenador de segurança da informação",
  "Coordenador de serviço de orientação pedagógica",
  "Coordenador de serviços de manutenção",
  "Coordenador de suporte técnico de tecnologia da informação",
  "Coordenador de tráfego rodoviário",
  "Coordenador de turismo",
  "Coordenador de turno - área de fibras",
  "Coordenador de turno - área de papel",
  "Coordenador de vendas (restaurante)",
  "Coordenador do serviço público estadual e distrital",
  "Coordenador escolar",
  "Coordenador executivo",
  "Coordenador geral de operações de correios",
  "Coordenador geral do serviço público federal",
  "Coordenador pedagógico",
  "Coordenador técnico de eletromecânica",
  "Copeiro",
  "Copeiro  de bar",
  "Copeiro  de eventos",
  "Copeiro  de hotel",
  "Copeiro  de lanchonete",
  "Copeiro  de restaurante",
  "Copeiro de hospital",
  "Copiador de chapa",
  "Copiador de chapas de off-set",
  "Copiador de clicheria",
  "Copiador de clichês tipográficos",
  "Copiador de desenhos sobre pedras litográficas",
  "Copiador de documentos",
  "Copiador de fotolito",
  "Copiador de texto na tipografia",
  "Copiador gravador",
  "Copista de croqui",
  "Cordeiro - limpeza de vidros",
  "Cordista",
  "Coreógrafo",
  "Coreógrafo bailarino",
  "Coronel bombeiro militar",
  "Coronel da aeronáutica",
  "Coronel da polícia militar",
  "Coronel do corpo de bombeiros militar",
  "Coronel do exército",
  "Corredor fundista",
  "Correeiro (peças para animais)",
  "Correeiro - empregador",
  "Correiro - empregador",
  "Correspondente de jornal",
  "Correspondente de línguas estrangeiras",
  "Corretor de anúncios (publicidade)",
  "Corretor de cereais",
  "Corretor de frete",
  "Corretor de grãos",
  "Corretor de imóveis",
  "Corretor de mercadorias",
  "Corretor de mercadorias e futuros",
  "Corretor de seguros",
  "Corretor de valores, ativos financeiros, mercadorias e derivativos",
  "CORRETORES DE IMÓVEIS E DE GRÃOS",
  "CORRETORES DE SEGUROS",
  "CORRETORES DE VALORES, ATIVOS FINANCEIROS, MERCADORIAS E DERIVATIVOS",
  "Corrieiro - empregador",
  "Cortador  de papel à guilhotina",
  "Cortador a serra circular",
  "Cortador de arroz",
  "Cortador de artefatos de couro (exceto roupas e calçados)",
  "Cortador de árvores",
  "Cortador de barro",
  "Cortador de bobina de papel",
  "Cortador de calçados",
  "Cortador de calçados, a  mão (exceto solas)",
  "Cortador de calçados, a  máquina (exceto solas e palmilhas)",
  "Cortador de cana-de-açúcar",
  "Cortador de capas de saltos para calçados",
  "Cortador de carne em açougue",
  "Cortador de carne em matadouro",
  "Cortador de cepos",
  "Cortador de chapas de madeiras",
  "Cortador de chapéus",
  "Cortador de charutos",
  "Cortador de couro na fabricação de calçados",
  "Cortador de cristais de óptica",
  "Cortador de dendê",
  "Cortador de ferro",
  "Cortador de laminados de madeira",
  "Cortador de lenha",
  "Cortador de madeira - na extração",
  "Cortador de mármore",
  "Cortador de metais",
  "Cortador de ouricuri",
  "Cortador de palha de carnaúba",
  "Cortador de palha de ouricuri",
  "Cortador de palha de piaçava",
  "Cortador de palmilhas",
  "Cortador de papel",
  "Cortador de papel em máquina eletrônica",
  "Cortador de pedras",
  "Cortador de pedras - lavra de pedras",
  "Cortador de pedras - na extração de pedras",
  "Cortador de piaçava",
  "Cortador de rami",
  "Cortador de roupas",
  "Cortador de roupas (couro e pele)",
  "Cortador de salsichas",
  "Cortador de solas e palmilhas, a  máquina",
  "Cortador de solas, a  máquina",
  "Cortador de sucata à oxigênio",
  "Cortador de tapeçaria",
  "Cortador de tapetes",
  "Cortador de telhas",
  "Cortador de tijolos",
  "Cortador de vidro",
  "Cortador de vidro - no comércio - exclusive conta própria e empregador",
  "Cortador de vidros",
  "Cortador descascador de toras",
  "Cortador oxiacetilênico",
  "Cortador, a  balancim",
  "Cortador, a  mão",
  "Cosmoanalista",
  "Cosmológo",
  "Costurador de artefatos de couro, a  mão (exceto roupas e calçados)",
  "Costurador de artefatos de couro, a  máquina (exceto roupas e calçados)",
  "Costurador de calçados, a  máquina",
  "Costurador de calçados, à mão",
  "Costurador de fardos",
  "Costurador de lonas e encerados",
  "Costureira de máquina overloque",
  null,
  "Costureira de máquina reta",
  "Costureira de máquinas industriais",
  "Costureira de peças sob encomenda",
  "Costureira de reparação de roupas",
  "Costureira em geral",
  "Costureiro de amostra",
  "Costureiro de artefatos de tecido (brinquedos)",
  "Costureiro de calçados a  máquina",
  "Costureiro de colchões",
  "Costureiro de roupa de couro e pele",
  "Costureiro de roupas (confecção em série)",
  "Costureiro de roupas de couro e pele, a  máquina na  confecção em série",
  "Costureiro na confecção em série",
  "Costureiro velamista",
  "Costureiro, a  máquina  na confecção em série",
  "Cotonicultor",
  "Cotonicultor - empregador",
  "Coveiro",
  "Coxãozeiro em matadouro",
  "Cozedor de salsichas",
  "Cozinhador (conservação de alimentos)",
  "Cozinhador de alimentos",
  "Cozinhador de andiroba",
  "Cozinhador de carnes",
  "Cozinhador de cera de carnaúba - na extração",
  "Cozinhador de conservas",
  "Cozinhador de extrato de cerveja",
  "Cozinhador de frutas e legumes",
  "Cozinhador de malte",
  "Cozinhador de molhos e condimentos",
  "Cozinhador de pescado",
  "Cozinhador de vinho de bacaba",
  "Cozinheiro de bordo",
  "Cozinheiro de cerveja",
  "Cozinheiro de embarcações",
  "Cozinheiro de hospital",
  "Cozinheiro de restaurante",
  "Cozinheiro de restaurante de indústria",
  "Cozinheiro do serviço doméstico",
  "Cozinheiro geral",
  "Cozinheiro hospitalar",
  "Cozinheiro industrial",
  "Cozinheiro salgador",
  "COZINHEIROS",
  "Cravador de jóias",
  "Cravador de metais",
  "Craveiro (forjaria)",
  "Cravejador de jóias",
  "Cravejador de metais preciosos",
  "Cravejador de ourivesaria",
  "Crecheira",
  "Crematório",
  "Criador de abelhas - exclusive conta própia e empregador",
  "Criador de animais domésticos",
  "Criador de animais produtores de veneno",
  "Criador de asininos e muares",
  "Criador de asininos e muares - empregador",
  "Criador de aves",
  "Criador de aves e bovinos",
  "Criador de bicho-da-seda - conta própria",
  "Criador de bicho-da-seda - empregador",
  "Criador de bode",
  "Criador de bovinos (corte)",
  "Criador de bovinos (leite)",
  "Criador de bubalinos (corte)",
  "Criador de bubalinos (leite)",
  "Criador de búfalos - corte",
  "Criador de búfalos - leite",
  "Criador de cabras",
  "Criador de cachorros",
  "Criador de cães",
  "Criador de camarões",
  "Criador de caprinos",
  "Criador de caprinos - empregador",
  "Criador de carneiro",
  "Criador de cavalo de raça",
  "Criador de cavalos - conta própria",
  "Criador de cavalos - empregador",
  "Criador de coelhos",
  "Criador de conteúdo digital",
  "Criador de efeitos especiais",
  "Criador de efeitos visuais",
  "Criador de eqüideos eqüinos - empregador",
  "Criador de eqüínos",
  "Criador de gado - bovino, caprino, suíno",
  "Criador de gado - eqüino, ovino, muar - conta própria",
  "Criador de gado bovino (exceto gado leiteiro) - empregador",
  "Criador de gatos",
  "Criador de jacarés",
  "Criador de matrizes de coelhos",
  "Criador de mexilhões",
  "Criador de minhocas",
  "Criador de ostras",
  "Criador de ovelhas - empregador",
  "Criador de ovinos",
  "Criador de ovinos - empregador",
  "Criador de peixes",
  "Criador de peixes ornamentais",
  "Criador de pequenos animais",
  "Criador de porcos - empregador",
  "Criador de quelônios",
  "Criador de rãs",
  "Criador de suínos",
  "Criador de suínos - empregador",
  "Criador de tartarugas",
  "Criador de zebu",
  "Criador de zebu - conta própria",
  "Criador de zebu - empregador",
  "Criador em pecuária polivalente",
  "CRIADORES DE ANIMAIS AQUÁTICOS",
  "Criadores de coelhos integrados",
  "Criadores de perfumes",
  "Criminalista",
  "Cristaleiro (corte de vidros)",
  "Cristalomante",
  "Crítico",
  "Crítico de artes plásticas",
  "Crítico de cinema",
  "Crítico de dança",
  "Crítico de jornal (ombudsman)",
  "Crítico de música",
  "Crítico de rádio",
  "Crítico de teatro",
  "Crítico de televisão",
  "Crítico literário",
  "Crítico textual",
  "Criveira",
  "Crocheteiro",
  "Crocheteiro, a  mão",
  "Cromador de metais",
  "Cromista",
  "Cronista",
  "Cronista de ficção",
  "Cronista de não ficção",
  "Cronoanalista",
  "Cronometrista",
  "Cronometrista (basquete)",
  "Cronometrista (futebol de salão)",
  "Cronometrista (judô)",
  "Cronometrista (karatê)",
  "Cronometrista de pólo aquático",
  "Cronometrista de trinta e cinco segundos (poló aquático)",
  "Cubador de madeira",
  "Cuidador de  pessoas idosas e dependentes",
  "Cuidador de animais",
  "Cuidador de idosos",
  "Cuidador de idosos domiciliar",
  "Cuidador de idosos institucional",
  "Cuidador em saúde",
  "CUIDADORES DE CRIANÇAS, JOVENS, ADULTOS E IDOSOS",
  "Cultivador de agave - conta própria",
  "Cultivador de agave - exclusive conta própria e empregador",
  "Cultivador de algodão - conta própria",
  "Cultivador de algodão - exclusive conta própria e empregador",
  "Cultivador de cultura permanente",
  "Cultivador de cultura temporária",
  "Cultivador de fumo - exclusive conta própria e empregador",
  "Cultivador de guaraná - exclusive conta própria e empregador",
  "Cultivador de malva - exclusive conta própria e empregador",
  "Cultivador de rami - conta própria",
  "Cultivador de rami - exclusive conta própria e empregador",
  "Cultivador de sisal - conta própria",
  "Cultivador de sisal - exclusive conta própria e empregador",
  "Cumim",
  "Cunhador - na extração de pedras",
  "Cunicultor",
  "Cunicultor - exclusive conta própria e empregador",
  "Cura",
  "Curador de animais - na agropecuária",
  "Curimbeiro",
  "Curraleiro de pesca artesanal de peixes e camarões",
  "Curtidor (couros e peles)",
  "Curtidor - empregador",
  "Curtidor de couro",
  "Curvador de tubos de vidro",
  "Cuteleiro  (comércio varejista)",
  "Cuteleiro (na fabricação)",
  "Dabôce",
  "Dada voduno",
  "Dáia",
  "Daiosho",
  "Dançarino",
  "Dançarino brincante",
  "Dançarino de danças de raiz",
  "Dançarino de danças folclóricas",
  "Dançarino de danças rituais",
  "Dançarino de rua",
  "Dançarino de salão",
  "Dançarino popular",
  "Dançarino tradicional",
  "Dançarinos de danças parafolclóricas",
  "Dançarinos étnicos",
  "Dançarinos populares",
  "DANÇARINOS TRADICIONAIS E POPULARES",
  "Datilógrafo",
  "Datiloscopista",
  "Dba",
  "Debruador de calçados",
  "Debulhador - na cultura",
  "Decalcador em cerâmica",
  "Decapador",
  "Decatizador",
  "Declamador",
  "Decorador ceramista",
  "Decorador com balões",
  "Decorador de arte com balões",
  "Decorador de arte floral",
  "Decorador de cerâmica",
  "Decorador de chapéus de palha",
  "Decorador de eventos",
  "Decorador de festas",
  "Decorador de festas infantis",
  "Decorador de interiores",
  "Decorador de interiores de nível superior",
  "Decorador de lycra tensionada",
  "Decorador de vidro",
  "Decorador de vidro a  decalque",
  "Decorador de vidro a  pistola",
  "Decorador de vidro à pincel",
  "Dedetizador",
  "Dee jay",
  "Defensor de interesses ( relações governamentais)",
  "Defensor público",
  "Defensor público estadual",
  "Defensor público federal",
  "DEFENSORES PÚBLICOS E PROCURADORES DA ASSISTÊNCIA JUDICIÁRIA",
  "Defumador de carnes e pescados",
  "Defumador de látex",
  "Defumador de pescados",
  "Degolador em matadouro",
  "Degustador de bebidas",
  "Degustador de café",
  "Degustador de chá",
  "Degustador de charutos",
  "Degustador de derivados de cacau",
  "Degustador de vinho",
  "Degustador de vinhos ou licores",
  "Delegado de polícia",
  "Delegado distrital de polícia",
  "Delegado regional de polícia",
  "DELEGADOS DE POLÍCIA",
  "Demógrafo",
  "Demolidor de casas",
  "Demolidor de edificações",
  "Demolidor de prédios",
  "Demonstrador de mercadorias",
  "Dendeicultor",
  "Dendrometrista",
  "Dentista",
  "Dentista da dor",
  "Dentista de aparelho",
  "Dentista de criança",
  "Dentista de cuidados especiais",
  "Dentista de excepcionais",
  "Dentista de gengivas",
  "Dentista de idosos",
  "Dentista de pacientes com necessidades especiais",
  "Dentista de pacientes especiais",
  "Dentista de saúde coletiva",
  "Dentista de terceira idade",
  "Dentistas de deficientes",
  "Dentística restauradora",
  "Depilador de couros e peles",
  "Depositário público",
  "Deputado estadual e distrital",
  "Deputado federal",
  "Deré",
  "Dermatologista",
  "Derrubador - na extração de madeira",
  "Derrubador de árvores",
  "Desbarbeador de tijolos",
  "Descarnador de couros e peles, à maquina",
  "Descarnador em matadouro",
  "Descascador - na cultura",
  "Descascador de árvores",
  "Descupinizador",
  "Desdobrador de madeira",
  "Desembargadores",
  "Desempenador em olaria",
  "Desenformador - na fundição",
  "Desenfornador de tijolo e telha",
  "Desenhista  técnico de arte-final",
  "Desenhista calculista",
  "Desenhista calculista na construção civil",
  "Desenhista copista",
  "Desenhista copista  (cad)",
  "Desenhista copista (arquitetura)",
  "Desenhista de arquitetura",
  "Desenhista de carrocerias",
  "Desenhista de cartografia",
  "Desenhista de desenho animado",
  "Desenhista de editorial",
  "Desenhista de embalagens",
  "Desenhista de estrutura metálica (construção civil)",
  "Desenhista de estruturas metálicas (mecânica)",
  "Desenhista de ferramentas (mecânica)",
  "Desenhista de filatelia  (selos)",
  "Desenhista de fotoestamparia",
  "Desenhista de identidade visual",
  "Desenhista de instalações de combate a incêndio",
  "Desenhista de instalações hidráulicas",
  "Desenhista de instalações mecânicas",
  "Desenhista de leiaute de circuito impresso",
  "Desenhista de leiautes",
  "Desenhista de móveis",
  "Desenhista de páginas da internet (web designer)",
  "Desenhista de produto (artigos esportivos)",
  "Desenhista de produto (brinquedos)",
  "Desenhista de produto (construção civil)",
  "Desenhista de produto (cuidados pessoais)",
  "Desenhista de produto (eletroeletrônicos e eletrodomésticos)",
  "Desenhista de produto (embalagem)",
  "Desenhista de produto (iluminação)",
  "Desenhista de produto (jóias)",
  "Desenhista de produto (máquinas e equipamentos)",
  "Desenhista de produto (material promocional)",
  "Desenhista de produto (mobiliário)",
  "Desenhista de produto (transporte)",
  "Desenhista de produto (utensílios domésticos e escritório)",
  "Desenhista de som",
  "Desenhista de tubulação (mecânica)",
  "Desenhista detalhista",
  "Desenhista detalhista (cad)",
  "Desenhista detalhista na arquitetura",
  "Desenhista detalhista na construção civil",
  "Desenhista gráfico de embalagem",
  "Desenhista gráfico de sinalização",
  "Desenhista gráfico de superfície",
  "Desenhista gráfico promocional",
  "Desenhista industrial de acessórios",
  "Desenhista industrial de calçados",
  "Desenhista industrial de produto (designer de produto)",
  "Desenhista industrial de produto de moda (designer de moda)",
  "Desenhista industrial gráfico (designer gráfico)",
  "Desenhista industrial têxtil",
  "Desenhista instrucional",
  "Desenhista letrista",
  "Desenhista maquetista",
  "Desenhista mecânico",
  "Desenhista projetista de arquitetura",
  "Desenhista projetista de circuitos impressos",
  "Desenhista projetista de construção civil",
  "Desenhista projetista de eletricidade",
  "Desenhista projetista de ferramentas",
  "Desenhista projetista de máquinas",
  "Desenhista projetista de moldes",
  "Desenhista projetista de produtos (mecânica)",
  "Desenhista projetista eletroeletrônico",
  "Desenhista projetista eletrônico",
  "Desenhista projetista mecânico",
  "Desenhista técnico",
  "Desenhista técnico (arquitetura)",
  "Desenhista técnico (artes gráficas)",
  "Desenhista técnico (calefação, ventilação e refrigeração)",
  "Desenhista técnico (cartografia)",
  "Desenhista técnico (construção civil)",
  "Desenhista técnico (eletricidade e eletrônica)",
  "Desenhista técnico (ilustrações artísticas)",
  "Desenhista técnico (ilustrações técnicas)",
  "Desenhista técnico (indústria têxtil)",
  "Desenhista técnico (instalações hidrossanitárias)",
  "Desenhista técnico (mobiliário)",
  "Desenhista técnico aeronáutico",
  "Desenhista técnico auxiliar",
  "Desenhista técnico de aeronaves",
  "Desenhista técnico de embalagens, maquetes e leiautes",
  "Desenhista técnico de engenharia civil",
  "Desenhista técnico de ferramentas",
  "Desenhista técnico de hidráulica",
  "Desenhista técnico de máquinas",
  "Desenhista técnico de topografia",
  "Desenhista técnico de tubulação",
  "Desenhista técnico detalhista",
  "Desenhista técnico eletromecânica",
  "Desenhista técnico em calefação",
  "Desenhista técnico em eletricidade",
  "Desenhista técnico em eletroeletrônica",
  "Desenhista técnico em eletrônica",
  "Desenhista técnico em refrigeração",
  "Desenhista técnico em ventilação",
  "Desenhista técnico mecânico",
  "Desenhista técnico naval",
  "Desenhista têxtil",
  "DESENHISTAS PROJETISTAS DA ELETRÔNICA",
  "DESENHISTAS PROJETISTAS DA MECÂNICA",
  "DESENHISTAS PROJETISTAS DE CONSTRUÇÃO CIVIL E ARQUITETURA",
  "DESENHISTAS PROJETISTAS E MODELISTAS DE PRODUTOS E SERVIÇOS DIVERSOS",
  "DESENHISTAS TÉCNICOS DA CONSTRUÇÃO CIVIL E ARQUITETURA",
  "DESENHISTAS TÉCNICOS DA MECÂNICA",
  "DESENHISTAS TÉCNICOS DE PRODUTOS E SERVIÇOS DIVERSOS",
  null,
  "DESENHISTAS TÉCNICOS EM ELETRICIDADE, ELETRÔNICA, ELETROMECÂNICA, CALEFAÇÃO, VENTILAÇÃO EFamília",
  "DESENHISTAS TÉCNICOS, EM GERAL",
  "Desenvolvedor de games",
  "Desenvolvedor de internet (técnico)",
  "Desenvolvedor de jogos",
  "Desenvolvedor de jogos para computador",
  "Desenvolvedor de multimídia",
  "Desenvolvedor de plataforma web (técnico)",
  "Desenvolvedor de sistemas de tecnologia da informação (técnico)",
  "Desenvolvedor de sustentação de ti (técnico)",
  "Desenvolvedor de ti",
  "Desenvolvedor web (técnico)",
  "Desfibrador de agave",
  "Desidratador de alimentos",
  "Desifbrador de sisal - na cultura",
  "Designer de cílios",
  "Designer de eventos",
  "Designer de interiores",
  "DESIGNER DE INTERIORES DE NÍVEL SUPERIOR",
  "Designer de sobrancelhas",
  "Designer de unhas",
  "Designer de vitrines",
  "Designer educacional",
  "Designer instrucional",
  "Designers de ambientes",
  "Designers de interiores",
  "DESIGNERS DE INTERIORES, DE VITRINES E VISUAL MERCHANDISER E AFINS (NÍVEL MÉDIO)",
  "Desincrustador (poços de petróleo)",
  "Desincrustador de poços de petróleo e gás",
  "Desinfestador",
  "Deslocador",
  "Desmanchador  em matadouro",
  "Desmanchador exclusive em matadouro",
  "Desmembrador de mocotó dianteiro",
  "Desmoldador",
  "Desnucador em matadouro",
  "Desorelhador em matadouro",
  "Desossador",
  "Despachante aduaneiro",
  "Despachante alfandegário",
  "Despachante de bagagens em aeroportos",
  "Despachante de documentos",
  "Despachante de embarque",
  "Despachante de plataforma (petróleo)",
  "Despachante de tráfego",
  "Despachante de trânsito",
  "Despachante de transportes coletivos (exceto trem)",
  "Despachante de veículos",
  "Despachante documentalista",
  "Despachante emplacador",
  "Despachante nos transportes",
  "Despachante operacional de vôo",
  "Despachante policial",
  "DESPACHANTES ADUANEIROS",
  "DESPACHANTES DOCUMENTALISTAS E AFINS",
  "Despansador em matadouro",
  "Despolidor",
  "Desqueixador em matadouro",
  "Desratizador",
  "Dessebador em matadouro e açougues",
  "Dessecador de malte",
  "Destalador - na cultura",
  "Destilador",
  "Destilador de álcool",
  "Destilador de bebidas",
  "Destilador de glicerina",
  "Destilador de licores",
  "Destilador de madeira",
  "Destilador de produtos de perfumaria",
  "Destilador de produtos químicos (exceto petróleo)",
  "Destilador de produtos químicos e afins",
  "Destilador de resinas",
  "Destocador - na agropecuária",
  "Destopador (madeira)",
  "Destroçador de pedra",
  "Detalhista em desenho técnico",
  "Detetive de polícia",
  "Detetive particular",
  "Detetive profissional",
  "Detonador",
  "Diabetólogo",
  "Diácono",
  "Diácono permanente",
  "Diagramador ( jornalismo)",
  "Diagramador e desenhista",
  "Diagramador em terminal de vídeo",
  "Diamantário (comércio varejista)",
  "Diarista - na agropecuária",
  "Diarista na agricultura",
  "Diarista-em jornal",
  "Dietista",
  "Digitador",
  "Digitador de fotocomposição",
  "Digitador de terminal",
  "Dinamitador",
  "Diretor adjunto",
  "Diretor adjunto comercial em operações de intermediação financeira",
  "Diretor adjunto de escolas privadas",
  "Diretor administrativo",
  "Diretor administrativo e financeiro",
  "Diretor artistíco",
  "Diretor cinematográfico",
  "Diretor circense",
  "Diretor clínico",
  "Diretor comercial",
  "Diretor comercial em operações de intermediação financeira",
  "Diretor de  produção e operações de alimentação",
  "Diretor de  produção e operações de hotel",
  "Diretor de  produção e operações de turismo",
  "Diretor de arte",
  "Diretor de arte (publicidade)",
  "Diretor de biblioteca",
  "Diretor de caic público",
  "Diretor de câmbio e comércio exterior",
  "Diretor de capital humano",
  "Diretor de centro de educação infantil (creche e pré-escola) privado",
  "Diretor de centro de educação infantil público",
  "Diretor de centro de ensino especial público",
  "Diretor de centro de ensino fundamental público",
  "Diretor de centro de ensino médio profissionalizante público",
  "Diretor de centro de ensino médio público",
  "Diretor de centro de ensino supletivo público",
  "Diretor de centro de formação profissional privado",
  "Diretor de cinema",
  "Diretor de colégio privado",
  "Diretor de colégio público",
  "Diretor de compliance",
  "Diretor de compras",
  "Diretor de contas (publicidade)",
  "Diretor de contratos de obras pública e civil",
  "Diretor de correios",
  "Diretor de corte",
  "Diretor de crédito (exceto crédito imobiliário)",
  "Diretor de crédito imobiliário",
  "Diretor de crédito rural",
  "Diretor de criação",
  "Diretor de departamento de esportes",
  "Diretor de departamento de saúde",
  "Diretor de departamento do  serviço público municipal",
  "Diretor de departamento médico",
  "Diretor de divisão do serviço público municipal",
  "Diretor de divisão médica",
  "Diretor de entidade religiosa",
  "Diretor de escola de classe pública",
  "Diretor de escola privada",
  "Diretor de escola pública",
  "Diretor de escola religiosa privada",
  "Diretor de estabelecimento de ensino fundamental privado",
  "Diretor de estabelecimento de ensino médio privado",
  "Diretor de estabelecimento de ensino superior privado",
  "Diretor de estúdio cinematográfico",
  "Diretor de finanças",
  "Diretor de fotografia",
  "Diretor de futebol",
  "Diretor de gestão portuária",
  "Diretor de imagens (tv)",
  "Diretor de informática",
  "Diretor de infraestrutura de tecnologia da informação",
  "Diretor de instituição educacional da área privada",
  "Diretor de instituição educacional pública",
  "Diretor de internato privado",
  "Diretor de leasing",
  "Diretor de logística e de suprimentos",
  "Diretor de logística em operações de transportes",
  "Diretor de manutenção",
  "Diretor de marketing",
  "Diretor de mercado de capitais",
  "Diretor de mídia (publicidade)",
  "Diretor de museu",
  "Diretor de obras pública e civil",
  "Diretor de ong (organização não-governamental)",
  "Diretor de operações comerciais (comércio atacadista e varejista)",
  "Diretor de operações de correios",
  "Diretor de operações de obras pública e civil",
  "Diretor de operações de radiodifusão",
  "Diretor de operações de serviços de armazenamento",
  "Diretor de operações de serviços de telecomunicações",
  "Diretor de operações de serviços de transporte",
  "Diretor de operações e manutenção",
  "Diretor de organização social e interesse público",
  "Diretor de osip (organização social de interesse público)",
  "Diretor de pesquisa",
  "Diretor de pesquisa e desenvolvimento (p&d)",
  "Diretor de planejamento estratégico",
  "Diretor de planejamento financeiro",
  "Diretor de plataforma em operações de intermediação financeira",
  "Diretor de processos de tecnologia da informação",
  "Diretor de produção",
  "Diretor de produção de obras pública e civil",
  "Diretor de produção e operações da indústria de transformação, extração mineral e utilidades",
  "Diretor de produção e operações em empresa agropecuária",
  "Diretor de produção e operações em empresa aqüícola",
  "Diretor de produção e operações em empresa florestal",
  "Diretor de produção e operações em empresa pesqueira",
  "Diretor de produção em empresa florestal",
  "Diretor de produção em empresa pesqueira",
  "Diretor de produção em utilidades",
  "Diretor de produção na agropecuária",
  "Diretor de produção na indústria de transformação",
  "Diretor de produção na mineração",
  "Diretor de produtos bancários",
  "Diretor de programação",
  "Diretor de programas de rádio",
  "Diretor de programas de televisão",
  "Diretor de recuperação de créditos em operações de intermediação financeira",
  "Diretor de recursos humanos",
  "Diretor de redação",
  "Diretor de redes (telecomunicações)",
  "Diretor de relações de trabalho",
  "Diretor de relações interinstitucionais",
  "Diretor de riscos de mercado",
  "Diretor de serviços culturais",
  "Diretor de serviços de informática",
  "Diretor de serviços de saúde",
  "Diretor de serviços de tecnologia da informação",
  "Diretor de serviços médicos",
  "Diretor de serviços sociais",
  "Diretor de sindicato de trabalhadores",
  "Diretor de sindicato patronal",
  "Diretor de som",
  "Diretor de suprimentos",
  "Diretor de suprimentos no serviço público",
  "Diretor de tecnologia",
  "Diretor de tecnologia (pesquisa e desenvolvimento)",
  "Diretor de tecnologia da informação",
  "Diretor de televisão",
  "Diretor de tv",
  "Diretor de unidade assistencial",
  "Diretor de unidade de saúde",
  "Diretor de unidade hospitalar",
  "Diretor de unidade integrada de pré-escola privada",
  "Diretor de vendas",
  "Diretor do serviço público federal",
  "Diretor executivo de instituições culturais",
  "Diretor financeiro",
  "Diretor geral de empresa e organizações (exceto de interesse público)",
  "Diretor geral de vendas",
  "Diretor médico-hospitalar",
  "Diretor noticiarista",
  "Diretor regente de bateria",
  "Diretor regente musical",
  "Diretor regional (telecomunicações)",
  "Diretor regional em operações de intermediação financeira",
  "Diretor teatral",
  "Diretor técnico (pesquisa e desenvolvimento)",
  "Diretor técnico de unidade hospitalar",
  "DIRETORES ADMINISTRATIVOS E FINANCEIROS",
  "DIRETORES DE COMERCIALIZAÇÃO E MARKETING",
  "DIRETORES DE ESPETÁCULOS E AFINS",
  "DIRETORES DE MANUTENÇÃO",
  "DIRETORES DE OPERAÇÕES DE OBRAS EM EMPRESA DE CONSTRUÇÃO",
  "DIRETORES DE OPERAÇÕES DE SERVIÇOS EM EMPRESA DE ARMAZENAMENTO, DE TRANSPORTE E DE",
  "DIRETORES DE OPERAÇÕES DE SERVIÇOS EM INSTITUIÇÃO DE INTERMEDIAÇÃO FINANCEIRA",
  "DIRETORES DE OPERAÇÕES EM EMPRESA DO COMÉRCIO",
  "DIRETORES DE PESQUISA E DESENVOLVIMENTO",
  "DIRETORES DE PRODUÇÃO E OPERAÇÕES EM EMPRESA AGROPECUÁRIA, PESQUEIRA , AQÜÍCOLA E",
  "DIRETORES DE PRODUÇÃO E OPERAÇÕES EM EMPRESA DA INDÚSTRIA EXTRATIVA, TRANSFORMAÇÃO E",
  "DIRETORES DE RECURSOS HUMANOS E RELAÇÕES DE TRABALHO",
  "DIRETORES DE SERVIÇOS DE TURISMO, DE ALOJAMENTO E DE ALIMENTAÇÃO",
  "DIRETORES DE SUPRIMENTOS E AFINS",
  null,
  "DIRETORES DE TECNOLOGIA DA INFORMAÇÃO",
  "DIRETORES E GERENTES DE INSTITUIÇÃO DE SERVIÇOS EDUCACIONAIS",
  "DIRETORES GERAIS",
  "Dirigente de clube e associação esportiva",
  "Dirigente de entidade de trabalhadores",
  "Dirigente de entidade patronal",
  "Dirigente de organização de defesa de direitos",
  "Dirigente de organização de desenvolvimento cultural",
  "Dirigente de organização de proteção ao meio ambiente",
  "Dirigente de organização filantrópica",
  "Dirigente de organização humanitária",
  "Dirigente de organização não-governamental",
  "Dirigente de partido político",
  "Dirigente do serviço público estadual e distrital",
  "Dirigente do serviço público federal",
  "Dirigente do serviço público municipal",
  "Dirigente e administrador de organização da sociedade civil sem fins lucrativos",
  "Dirigente e administrador de organização religiosa",
  "Dirigente espiritual de umbanda",
  "Dirigente partidário",
  "Dirigente político",
  "DIRIGENTES DE PARTIDOS POLÍTICOS",
  "DIRIGENTES DE POVOS INDÍGENAS, DE QUILOMBOLAS E CAIÇARAS",
  "DIRIGENTES DO SERVIÇO PÚBLICO",
  "DIRIGENTES E ADMINISTRADORES DE ENTIDADES RELIGIOSAS",
  "DIRIGENTES E ADMINISTRADORES DE ORGANIZAÇÕES DA SOCIEDADE CIVIL SEM FINS LUCRATIVOS",
  "DIRIGENTES GERAIS DA ADMINISTRAÇÃO PÚBLICA",
  "Disc jóquei",
  "Discotecário",
  "Disfunção de atm",
  "Disfunção e dor",
  "Distribuidor de fumo",
  "Distribuidor de maçarocas",
  "Distribuidor de produtos químicos",
  "Distribuidor de revistas",
  "Distribuidor público",
  "Divisor de cortes em matadouro",
  "Dj (disc jockey)",
  "Dobrador gráfico",
  "Dobrador-revisor - em fábrica de tecidos",
  "Doceiro (comércio ambulante)",
  "Doceiro (exclusive no comércio ambulante)",
  "Docente de ensino superior na área de didática",
  "Docente de ensino superior na área de língua brasileira de sinais (libras)",
  "Docente de ensino superior na área de orientação educacional",
  "Docente de ensino superior na área de pesquisa educacional",
  "Docente de magistério superior de lingua brasileira de sinais (libras)",
  "Docente de nível médio no ensino profissionalizante",
  "Docente do ensino superior na área de prática de ensino",
  "Documentalista",
  "Dom",
  "Domador (asininos e muares)",
  "Domador (eqüinos)",
  "Domador - na pecuária",
  "Domador de animais (circense)",
  "Domador de animais domésticos",
  "Doné",
  "Dono de hotel - conta própria",
  "Dono de lavanderia automática - conta própria",
  "Dono de motel - conta própria",
  "Dono de pensão - conta própria",
  "Dono de pensão - empregador",
  "Doqueiro",
  "Dosador de xaropes",
  "Dosimetrista",
  "Dosimetrista clínico",
  "Doté",
  "Doula",
  "Dourador",
  "Dourador (metais)",
  "Dr. Raiz",
  "Drageador",
  "Drageador (medicamentos)",
  "Drageador manipulador",
  "Dramaturgista",
  "Dramaturgo",
  "Dramaturgo de dança",
  "Droguista (comércio varejista)",
  "Dtm/dof",
  "Dtv",
  "Duteiro",
  "Ecologista",
  "Ecólogo",
  "Econometrista",
  "Economista",
  "Economista agrícola",
  "Economista agroindustrial",
  "Economista ambiental",
  "Economista do setor público",
  "Economista doméstico",
  "Economista financeiro",
  "Economista industrial",
  "Economista regional e urbano",
  "Economista urbano",
  "ECONOMISTAS",
  "Edificador - mestre de obras",
  "Edificador de casco (navios)",
  "Editor",
  "Editor assistente",
  "Editor de área",
  "Editor de arte",
  "Editor de fotografia",
  "Editor de imagem",
  "Editor de imagens",
  "Editor de jornal",
  "Editor de livro",
  "Editor de mídia audiovisual",
  "Editor de mídia eletrônica",
  "Editor de pós-produção",
  "Editor de rádio",
  "Editor de revista",
  "Editor de revista científica",
  "Editor de texto e imagem",
  "Editor de tv e vídeo",
  "Editor de vt",
  "Editor de web",
  "Editor executivo",
  "Editor finalizador",
  "EDITORES",
  "Editorialista",
  "Educador de animais",
  "Educador de rua",
  "Educador infantil de nível médio",
  "Educador sanitário",
  "Educador social",
  "Educador social de rua",
  "Egbonmi",
  "Ekêdi",
  "Eletricista",
  "Eletricista auxiliar",
  "Eletricista bobinador",
  "Eletricista de alta-tensão",
  "Eletricista de baixa-tensão",
  "Eletricista de bordo",
  "Eletricista de embarcações",
  "Eletricista de iluminação pública",
  "Eletricista de instalações",
  "Eletricista de instalações (aeronaves)",
  "Eletricista de instalações (cenários)",
  "Eletricista de instalações (edifícios)",
  "Eletricista de instalações (embarcações)",
  "Eletricista de instalações (iluminação a gás neon)",
  "Eletricista de instalações (semáforos)",
  "Eletricista de instalações comerciais e residenciais",
  "Eletricista de instalações de prédios",
  "Eletricista de instalações de veículos automotores",
  "Eletricista de instalações elétricas de minas",
  "Eletricista de instalações industriais",
  "Eletricista de linha de alta-tensão",
  "Eletricista de linha de baixa-tensão",
  "Eletricista de linha de transmissão",
  "Eletricista de manutenção de linhas elétricas",
  "Eletricista de manutenção de linhas elétricas, telefônicas e de comunicação de dados",
  "Eletricista de manutenção de linhas telefônicas",
  "Eletricista de manutenção de parques de diversões",
  "Eletricista de manutenção eletroeletrônica",
  "Eletricista de manutenção em geral",
  "Eletricista de manutenção industrial",
  "Eletricista de planejamento de instalações elétricas",
  "Eletricista de rede",
  "Eletricista de rede aérea de tração de veículos",
  "Eletricista de teatro e televisão",
  "Eletricista de veículos de máquinas operatrizes",
  "Eletricista instalador de alta e baixa tensão",
  "ELETRICISTAS DE MANUTENÇÃO ELETROELETRÔNICA",
  "ELETRICISTAS ELETRÔNICOS DE MANUTENÇÃO VEICULAR (AÉREA, TERRESTRE E NAVAL)",
  "Eletromecânico de manutenção de elevadores",
  "Eletromecânico de manutenção de escadas rolantes",
  "Eletromecânico de manutenção de portas automáticas",
  "Eletrônico de manutenção",
  "Eletrônico de manutenção de parques de diversões",
  "Eletrônico de rádio e televisão",
  "Eletrotécnico",
  "Eletrotécnico (produção de energia)",
  "Eletrotécnico na fabricação, montagem e instalação de máquinas e equipamentos",
  "Eletrotécnico reparador de aparelhos de som",
  "Embalador de mudas",
  "Embalador, a mão",
  "Embalador, a máquina",
  "Embalsamador",
  "Embandeirador de cacau",
  "Embocador",
  "Embretador",
  "Embrulhador",
  "Emendador de cabos elétricos e telefônicos (aéreos e subterrâneos)",
  "Emendador de câmaras de ar",
  "Emendador de cartazes",
  "Emendador de fios (elétricos e telefônicos)",
  "Emissor de passagens",
  "Emissor de passagens aéreas",
  "Emissor de passagens no transporte rodoviário",
  "Empacotador, a mão",
  "Empacotador, a máquina",
  "Empalhador de cadeiras",
  "Empregado  doméstico  nos serviços gerais",
  "Empregado doméstico  arrumador",
  "Empregado doméstico  faxineiro",
  "Empregado doméstico diarista",
  "Empreiteiro - na cultura",
  "Empresário de espetáculo, tecnólogo em produção cultural",
  "Empresário rural na agropecuária",
  "ENCADERNADORES E RECUPERADORES DE LIVROS (PEQUENOS LOTES OU A UNIDADE)",
  "Encaierador de tijolos",
  "Encaixotador, a mão",
  "Encanador",
  null,
  "Encanador de manutenção",
  "Encanador industrial",
  "ENCANADORES E INSTALADORES DE TUBULAÇÕES",
  "Encarregado administrativo",
  "Encarregado da aqüicultura",
  "Encarregado da área florestal",
  "Encarregado da recepção",
  "Encarregado de abridores (fumo)",
  "Encarregado de acabamento (embalagem e etiquetagem)",
  "Encarregado de acabamento (indústria têxtil)",
  "Encarregado de acabamento de chapas e metais  (têmpera)",
  "Encarregado de acabamento de estampa",
  "Encarregado de açougue",
  "Encarregado de almoxarifado",
  "Encarregado de alvenaria",
  "Encarregado de andar",
  "Encarregado de armazenagem de sal",
  "Encarregado de ativo fixo",
  "Encarregado de bar e restaurante",
  "Encarregado de bombeiros",
  "Encarregado de câmbio",
  "Encarregado de cantina",
  "Encarregado de carga e descarga no transporte rodoviário",
  "Encarregado de carpintaria",
  "Encarregado de cobrança",
  "Encarregado de conservação de vias permanentes",
  "Encarregado de construção civil",
  "Encarregado de construção civil e carpintaria",
  "Encarregado de construção civil e manutenção",
  "Encarregado de contas a pagar",
  "Encarregado de controle financeiro",
  "Encarregado de corte na confecção do vestuário",
  "Encarregado de costura na confecção do vestuário",
  "Encarregado de cozinha",
  "Encarregado de crédito e cobrança",
  "Encarregado de digitação e operação",
  "Encarregado de edifício",
  "Encarregado de elevador",
  "Encarregado de equipe de conservação de vias permanentes (exceto trilhos)",
  "Encarregado de escritório - exclusive no serviço público",
  "Encarregado de estoque",
  "Encarregado de expedição",
  "Encarregado de extração (mineração)",
  "Encarregado de extração de magnésio",
  "Encarregado de extração de pedreira",
  "Encarregado de extração de talco",
  "Encarregado de frios",
  "Encarregado de fumo desfiado",
  "Encarregado de garagem",
  "Encarregado de gráfica",
  "Encarregado de guarda-roupa de cinema",
  "Encarregado de guarda-roupa de televisão",
  "Encarregado de horticultura",
  "Encarregado de hortifrutigrangeiros",
  "Encarregado de lavanderia",
  "Encarregado de linha de transporte rodoviário",
  "Encarregado de lubrificação de vias permanentes",
  "Encarregado de malote",
  "Encarregado de manutenção",
  "Encarregado de manutenção de instrumentos de controle, medição e similares",
  "Encarregado de manutenção e recarga de extintor de incêndio",
  "Encarregado de manutenção elétrica de veículos",
  "Encarregado de manutenção mecânica de sistemas operacionais",
  "Encarregado de matadouro",
  "Encarregado de mecerização",
  "Encarregado de misturas de divisão de corantes e produtos químicos",
  "Encarregado de moinho",
  "Encarregado de montagem",
  "Encarregado de montagem de caixa diferencial",
  "Encarregado de montagem de móveis",
  "Encarregado de montagem de tubos",
  "Encarregado de obras",
  "Encarregado de obras de manutenção",
  "Encarregado de obras e instalações",
  "Encarregado de obras, manutenção e segurança",
  "Encarregado de operação de porto",
  "Encarregado de operações portuárias",
  "Encarregado de orçamento",
  "Encarregado de oxicorte",
  "Encarregado de padaria",
  "Encarregado de patrimônio",
  "Encarregado de pedreira (mineração)",
  "Encarregado de pesagem",
  "Encarregado de pintura (tratamento de superfícies)",
  "Encarregado de portaria de hotel",
  "Encarregado de portaria e segurança",
  "Encarregado de preparação de talo (indústria de fumo)",
  "Encarregado de produção (indústria de madeira e mobiliário)",
  "Encarregado de produção na fabricação de produtos de cerâmica, porcelanatos e afins",
  "Encarregado de produção na fabricação de produtos de vidros",
  "Encarregado de produção química",
  "Encarregado de proteção de dados pessoais",
  "Encarregado de qualidade de fumo",
  "Encarregado de recepção",
  "Encarregado de seção de controle de produção",
  "Encarregado de seção de empacotamento",
  "Encarregado de seção de fundição",
  "Encarregado de segurança",
  "Encarregado de serraria",
  "Encarregado de servente",
  "Encarregado de serviço - exclusive no serviço público",
  "Encarregado de serviço de arquivo médico e estatística",
  "Encarregado de serviço de capatazia",
  "Encarregado de serviço de digitação",
  "Encarregado de serviços de cais (capatazia)",
  "Encarregado de serviços de leitura e verificação de consumo de energia elétrica, água e gás",
  "Encarregado de serviços de serras e tornos",
  "Encarregado de setor de borracha",
  "Encarregado de setor de concreto",
  "Encarregado de silos",
  "Encarregado de soldagem",
  "Encarregado de supermercado",
  "Encarregado de telefonistas",
  "Encarregado de telemarketing",
  "Encarregado de tesouraria",
  "Encarregado de tráfego",
  "Encarregado de tráfego rodoviário",
  "Encarregado de tráfego rodoviário e ferroviário",
  "Encarregado de transporte coletivo (exceto trem)",
  "Encarregado de turma de acondicionamento",
  "Encarregado de turma de manutenção mecânica de sistemas operacionais",
  "Encarregado de turmas de eletromecânicos",
  "Encarregado de turno de manutenção eletromecânica",
  "Encarregado de usinagem de metais",
  "Encarregado de vazamento de fundição de ferro",
  "Encarregado de vigilância - organizações particulares de segurança",
  "Encarregado do setor de embalagem",
  "Encarregado eletricista de instalações",
  "Encarregado eletromecânico de instalações",
  "Encarregado fabricação de cigarros",
  "Encarregado florestal",
  "Encarregado geral de fiação",
  "Encarregado geral de fundição",
  "Encarregado geral de malharia",
  "Encarregado geral de tecelagem",
  "Encarregado na agropecuária",
  "Encarregado na exploração de pecuária",
  "Encarregado operacional de lavanderia",
  "Encenador teatral",
  "Enchedor de bandejas",
  "Enchedor de lingüiças",
  "Enchedor de salame",
  "Enciclopedista",
  "Encilhador",
  "Encoivarador - na cultura",
  "Encordoador de instrumentos musicais",
  "Encunhador - na extração de pedras",
  "Encunhador de pedreira",
  "Endocrinologista",
  "Endodontólogo",
  "Endoscopista",
  "Enfardador",
  "Enfardador de material de sucata (cooperativa)",
  "Enfardador de sucata (cooperativa)",
  "Enfeixador de palha de carnaúba",
  "Enfermeira parteira",
  "Enfermeiro",
  "Enfermeiro auditor",
  "Enfermeiro da estratégia de saúde da família",
  "Enfermeiro de berçário",
  "Enfermeiro de bordo",
  "Enfermeiro de centro cirúrgico",
  "Enfermeiro de saúde publica",
  "Enfermeiro de terapia intensiva",
  "Enfermeiro do trabalho",
  "Enfermeiro estomaterapeuta",
  "Enfermeiro forense",
  "Enfermeiro intensivista",
  "Enfermeiro nefrologista",
  "Enfermeiro neonatologista",
  "Enfermeiro obstétrico",
  "Enfermeiro psiquiátrico",
  "Enfermeiro puericultor e pediátrico",
  "Enfermeiro sanitarista",
  "ENFERMEIROS E AFINS",
  "Enfestador de roupas",
  "Enformador de malharia",
  "Enformador de meias",
  "Enfornador de pão",
  "Enfornador de tijolos",
  "Engarrafador",
  "Engastador (jóias)",
  "Engenheiro aeronáutico",
  "Engenheiro agrícola",
  "Engenheiro agrimensor",
  "Engenheiro agrônomo",
  "Engenheiro agrônomo (agricultura)",
  "Engenheiro agrônomo (solos)",
  "Engenheiro ambiental",
  "Engenheiro aqüicultor",
  "Engenheiro arquiteto",
  "Engenheiro biomédico",
  "Engenheiro cartógrafo",
  "Engenheiro civil",
  "Engenheiro civil (aeroportos)",
  "Engenheiro civil (edificações)",
  "Engenheiro civil (estruturas metálicas)",
  "Engenheiro civil (ferrovias e metrovias)",
  "Engenheiro civil (fundações)",
  "Engenheiro civil (geotécnia)",
  "Engenheiro civil (hidráulica)",
  "Engenheiro civil (hidrologia)",
  "Engenheiro civil (mecânica de solos)",
  "Engenheiro civil (obras sanitárias)",
  "Engenheiro civil (pontes e viadutos)",
  "Engenheiro civil (portos e vias navegáveis)",
  "Engenheiro civil (rodovias)",
  "Engenheiro civil (saneamento)",
  "Engenheiro civil (terraplanagem)",
  "Engenheiro civil (transportes e trânsito)",
  "Engenheiro civil (túneis)",
  "Engenheiro clínico",
  "Engenheiro de alimentos",
  "Engenheiro de análise de trabalho",
  "Engenheiro de aplicativos em computação",
  "Engenheiro de automação",
  "Engenheiro de beneficiamento de minério",
  "Engenheiro de celulose e papel",
  "Engenheiro de comutação",
  "Engenheiro de concentração",
  "Engenheiro de construção naval",
  "Engenheiro de controle",
  "Engenheiro de controle de qualidade",
  "Engenheiro de controle e automação",
  "Engenheiro de desenvolvimento químico",
  "Engenheiro de energia",
  "Engenheiro de equipamentos em computação",
  "Engenheiro de estradas",
  "Engenheiro de fauna e flora",
  "Engenheiro de geodésia e topografia",
  "Engenheiro de geometria",
  "Engenheiro de hardware computacional",
  "Engenheiro de indústria química",
  "Engenheiro de instrumentação",
  "Engenheiro de irrigação e drenagem",
  "Engenheiro de logistica",
  "Engenheiro de logística",
  "Engenheiro de manutenção de telecomunicações",
  "Engenheiro de manutenção de veículos",
  "Engenheiro de manutenção elétrica",
  "Engenheiro de manutenção eletrônica",
  "Engenheiro de master",
  "Engenheiro de materiais",
  "Engenheiro de materiais (cerâmica)",
  "Engenheiro de materiais (metais)",
  "Engenheiro de materiais (polímeros)",
  "Engenheiro de meio ambiente",
  "Engenheiro de minas",
  "Engenheiro de minas (beneficiamento)",
  "Engenheiro de minas (carvão)",
  "Engenheiro de minas (lavra a céu aberto)",
  "Engenheiro de minas (lavra subterrânea)",
  "Engenheiro de minas (pesquisa mineral)",
  "Engenheiro de minas (planejamento)",
  "Engenheiro de minas (processo)",
  "Engenheiro de minas (projeto)",
  "Engenheiro de mineração",
  "Engenheiro de operação (transporte rodoviário)",
  "Engenheiro de organização e métodos",
  "Engenheiro de organização industrial",
  "Engenheiro de pavimentação",
  "Engenheiro de pesca",
  "Engenheiro de planejamento",
  "Engenheiro de planejamento industrial",
  "Engenheiro de processamento",
  "Engenheiro de processamento químico",
  "Engenheiro de processos",
  "Engenheiro de produção",
  "Engenheiro de projetos de telecomunicações",
  "Engenheiro de projetos de telefonia",
  "Engenheiro de projetos elétricos",
  "Engenheiro de projetos eletrônicos",
  "Engenheiro de projetos viários",
  "Engenheiro de qualidade",
  "Engenheiro de redes de comunicação",
  "Engenheiro de riscos",
  "Engenheiro de segurança do trabalho",
  "Engenheiro de segurança industrial",
  "Engenheiro de segurança viária",
  "Engenheiro de sinalização viária",
  "Engenheiro de sistemas computacionais - aplicativos",
  "Engenheiro de sistemas computacionais - equipamentos",
  "Engenheiro de sistemas de comunicação",
  "Engenheiro de software computacional básico",
  "Engenheiro de softwares computacionais",
  "Engenheiro de suporte de sistemas operacionais em computação",
  "Engenheiro de telecomunicações",
  "Engenheiro de tempos e movimentos",
  "Engenheiro de tráfego",
  "Engenheiro de tráfego telefônico",
  "Engenheiro de trânsito",
  "Engenheiro de transmissão",
  "Engenheiro de transportes",
  "Engenheiro de tratamento de minério",
  "Engenheiro eletricista",
  "Engenheiro eletricista de manutenção",
  "Engenheiro eletricista de projetos",
  "Engenheiro elétrico",
  "Engenheiro eletrônico",
  "Engenheiro eletrônico de manutenção",
  "Engenheiro eletrônico de projetos",
  "Engenheiro eletrotécnico",
  "Engenheiro eletrotécnico de projetos",
  "Engenheiro especialista em construções rurais",
  "Engenheiro especialista em construções rurais e ambiência",
  "Engenheiro especialista em mecanização agrícola",
  "Engenheiro especialista em preservação florestal",
  "Engenheiro especialista em secagem e armazenagem de grãos",
  "Engenheiro especialista em tecnologia de madeira",
  "Engenheiro especialista em tecnologia de pesca",
  "Engenheiro especialista em tecnologia de pescado",
  "Engenheiro florestal",
  "Engenheiro horticultor",
  "Engenheiro mecânico",
  "Engenheiro mecânico (energia nuclear)",
  "Engenheiro mecânico (veículos automotores)",
  "Engenheiro mecânico automobilístico",
  "Engenheiro mecânico automotivo",
  "Engenheiro mecânico industrial",
  "Engenheiro mecânico nuclear",
  "Engenheiro mecatrônico",
  "Engenheiro metalúrgico",
  "Engenheiro metalúrgico (produção de metais)",
  "Engenheiro metalúrgico (siderurgia)",
  "Engenheiro metalúrgico (tratamento de metais)",
  "Engenheiro metalurgista",
  "Engenheiro naval",
  "Engenheiro operacional em eletrotécnica",
  "Engenheiro orçamentista",
  "Engenheiro pesquisador (engenharia civil)",
  "Engenheiro pesquisador (engenharia elétrica e eletrônica)",
  "Engenheiro pesquisador (engenharia mecânica)",
  "Engenheiro pesquisador (engenharia química)",
  "Engenheiro pesquisador (materiais)",
  "Engenheiro pesquisador (metalurgia)",
  "Engenheiro pesquisador (minas)",
  "Engenheiro pesquisador (outras áreas da engenharia)",
  "Engenheiro pesquisador (telecomunicações)",
  "Engenheiro projetista",
  "Engenheiro projetista de áudio",
  "Engenheiro projetista de telecomunicações",
  "Engenheiro químico",
  "Engenheiro químico (ácidos e bases)",
  "Engenheiro químico (açúcar e álcool)",
  "Engenheiro químico (alimentos e bebidas)",
  "Engenheiro químico (borracha)",
  "Engenheiro químico (celulose)",
  "Engenheiro químico (cerâmica)",
  "Engenheiro químico (cimento)",
  "Engenheiro químico (controle de qualidade)",
  "Engenheiro químico (cosméticos, higiene e limpeza)",
  "Engenheiro químico (derivados de petróleo)",
  "Engenheiro químico (fertilizantes)",
  "Engenheiro químico (indústria química)",
  "Engenheiro químico (meio ambiente)",
  "Engenheiro químico (metalurgia)",
  "Engenheiro químico (mineração)",
  "Engenheiro químico (mineração, metalurgia, siderurgia, cimenteira e cerâmica)",
  "Engenheiro químico (papel e celulose)",
  "Engenheiro químico (papel)",
  "Engenheiro químico (petróleo e borracha)",
  "Engenheiro químico (petróleo e petroquímica)",
  "Engenheiro químico (plástico)",
  "Engenheiro químico (produtos farmacêuticos)",
  "Engenheiro químico (siderurgia)",
  "Engenheiro químico (solventes e tintas)",
  "Engenheiro químico (têxtil)",
  "Engenheiro químico (utilidades e meio ambiente)",
  "Engenheiro químico (utilidades)",
  "Engenheiro químico de processos",
  "Engenheiro químico de produção",
  "Engenheiro químico de projetos",
  "Engenheiro químico, em geral",
  "Engenheiro rodoviário",
  "Engenheiro sanitarista",
  "Engenheiro silvicultor",
  "Engenheiro têxtil",
  "Engenheiro tuneleiro",
  "ENGENHEIROS AGRIMENSORES E ENGENHEIROS CARTÓGRAFOS",
  "ENGENHEIROS AGROSSILVIPECUÁRIOS",
  "ENGENHEIROS AMBIENTAIS E AFINS",
  "ENGENHEIROS CIVIS E AFINS",
  "ENGENHEIROS DE ALIMENTOS E AFINS",
  "ENGENHEIROS DE CONTROLE E AUTOMAÇÃO, ENGENHEIROS MECATRÔNICOS E AFINS",
  "ENGENHEIROS DE MINAS E AFINS",
  "ENGENHEIROS DE PRODUÇÃO, QUALIDADE, SEGURANÇA E AFINS",
  "Engenheiros de sistemas operacionais em computação",
  "ENGENHEIROS ELETRICISTAS, ELETRÔNICOS E AFINS",
  "ENGENHEIROS EM COMPUTAÇÃO",
  null,
  "ENGENHEIROS MECÂNICOS E AFINS",
  "ENGENHEIROS METALURGISTAS, DE MATERIAIS E AFINS",
  "ENGENHEIROS QUÍMICOS E AFINS",
  "Engordador de gado bovino - empregador",
  "Engordador de gado bovino, suíno, caprino - conta própria",
  "Engordador de zebu - empregador",
  "Engradador",
  "Engraxador de máquinas",
  "Engraxate",
  "Enólogo",
  "ENÓLOGOS, PERFUMISTAS E AROMISTAS",
  "Enotécnico",
  "Enquadrador de pedra - na mineração",
  "Enramador gráfico de corte e vinco",
  "Enrolador de charutos",
  "Ensacador",
  "Ensaiador de dança",
  "Ensaiador de teatro",
  "Ensaísta de ficção",
  "Ensaísta de não ficção",
  "Entaipador",
  "Entalhador  de madeira",
  "Entijolador",
  "Entomologista",
  "Entomólogo",
  "Entregador de assinaturas (jornais e revistas)",
  "Entregador de bebidas (ajudante de caminhão)",
  "Entregador de correspondências (correios e telégrafos)",
  "Entregador de gás (ajudante de caminhão)",
  "Entregador de jornais e revistas",
  "Entregador de publicações",
  "Entregador de venda avulsa (jornais e revistas)",
  "Entrevistador censitário e de pesquisas amostrais",
  "Entrevistador de campo",
  "Entrevistador de cati (computer assisted telephone interviewing)",
  "Entrevistador de mídia",
  "Entrevistador de pesquisa de opinião e mídia",
  "Entrevistador de pesquisa em profundidade",
  "Entrevistador de pesquisas de mercado",
  "Entrevistador de preços",
  "Entrevistador social",
  "ENTREVISTADORES E RECENSEADORES",
  "Envelopador",
  "Enxadeiro",
  "Enxadeiro - na cultura",
  "Enxertador de cacau",
  "Enxugador de couros",
  "Enxugador de couros e peles",
  "Enxugador de quadra",
  "Enxugador de veículos",
  "Enxugador e acabador na lavagem de veículos",
  "Epidemiologista",
  "Episcopiza",
  "Eqüideocultor de asininos e muares",
  "Equilibrista",
  "Eqüinocultor",
  "Equoterapeuta",
  "Ervanário (comércio varejista)",
  "Erveiro",
  "Escalante",
  "Escanção",
  "Escarfador",
  "Escarnador em matadouro e açougue",
  "Escavadeirista",
  "Escolhedor - na cultura",
  "Escolhedor de papel",
  "Escorador de minas",
  "Escovador de fundição",
  "Escovador de solas no acabamento de calçados",
  "Escrevente",
  "Escrevente extra - judicial",
  "Escrevente judicial",
  "Escrevente técnico estenotipista",
  "Escritor de cordel",
  "Escritor de ficção",
  "Escritor de folhetim",
  "Escritor de histórias em quadrinhos",
  "Escritor de não ficção",
  "Escritor de novela de rádio",
  "Escritor de novela de televisão",
  "Escritor de obra didática",
  "Escritor de obras científicas",
  "Escritor de obras educativas de ficção",
  "Escritor de obras educativas de não ficção",
  "Escritor de obras técnicas",
  "Escriturário",
  "Escriturário  em  estatística",
  "Escriturário de banco",
  "ESCRITURÁRIOS DE SERVIÇOS BANCÁRIOS",
  "Escrivão civil",
  "Escrivão criminal",
  "Escrivão de cartório",
  "Escrivão de polícia",
  "Escrivão extra - judicial",
  "Escrivão judicial",
  "Escultor",
  "Escultor de madeira",
  "Escultor de vidro",
  "Escultor de vidro (acabamento)",
  "Esfolador em matadouro",
  "Esmaltador",
  "Esmaltador cerâmico",
  "Esmaltador de banho fundente",
  "Esmaltador de cerâmica por imersão",
  "Esmaltador de metais não-preciosos",
  "Esmerilador de metais",
  "Esotérico",
  "ESOTÉRICOS E PARANORMAIS",
  "Esparramador de adubos",
  "Especialista contábil",
  "Especialista de comunicação em relações públicas",
  "Especialista de documentação",
  "Especialista de informação",
  "Especialista de manutenção (equipamentos industriais)",
  "Especialista de políticas públicas e gestão governamental - eppgg",
  "Especialista de sistemas de ti",
  "Especialista em arte final",
  "Especialista em calibrações metrológicas",
  "Especialista em cerâmica",
  "Especialista em cerveja",
  "Especialista em compliance",
  "Especialista em conservação de acervos",
  "Especialista em controle de qualidade e planejamento",
  "Especialista em desenvolvimento de cigarros",
  "Especialista em documentação arquivística",
  "Especialista em documentação museológica",
  "Especialista em educação em museus",
  "Especialista em engenharia civil",
  "Especialista em engenharia e tecnologia (outras áreas)",
  "Especialista em engenharia elétrica e eletrônica",
  "Especialista em engenharia mecânica",
  "Especialista em engenharia metalúrgica, de minas e de materiais",
  "Especialista em engenharia química",
  "Especialista em ensaios metrológicos",
  "Especialista em ferramentas (técnico mecânico na fabricação de ferramentas)",
  "Especialista em ferramentas (técnico mecânico na manutenção de ferramentas)",
  "Especialista em história da tradição, doutrina e textos sagrados",
  "Especialista em instrumentação metrológica",
  "Especialista em linha de transmissão",
  "Especialista em materiais de referência metrológica",
  "Especialista em museografia de exposição",
  "Especialista em organização de arquivos",
  "Especialista em pesquisa operacional",
  "Especialista em segurança da informação",
  "Especialista em sistema elétrico de aviões",
  "Especialista em telecomunicações (tecnólogo )",
  "Especialista em vinho",
  "ESPECIALISTAS EM INSPEÇÕES INDUSTRIAIS",
  "ESPECIALISTAS EM LOGÍSTICA DE TRANSPORTES",
  "ESPECIALISTAS EM PROMOÇÃO DE PRODUTOS E VENDAS",
  "Espelhador",
  "Espelhador (esmaltador)",
  "Espelhador - na fabricação de espelhos",
  "Espinçador (produção têxtil)",
  "Esportista no futebol",
  "Esportista profissional de tênis",
  "Espulador (cabos de aço)",
  "Esquadriador (madeira)",
  "Estafeta",
  "Estalageiro",
  "Estampador de placa de identificação veicular (epiv)",
  "Estampador de tecido",
  "Estancieiro (comércio varejista)",
  "Estancieiro - na criação de gado bovino - empregador",
  "Estancieiro de leite",
  "Estancieiro na criação de gado bovino (gado de corte)",
  "Estanhador",
  "Estatístico",
  "Estatístico (estatística aplicada)",
  "Estatístico - analista",
  "Estatístico teórico",
  "Estátua viva",
  "Esteireiro",
  "Estenógrafo",
  "Estenotipista",
  "Estenotipista em ´close caption´",
  "Estenotipista ´free lancer´",
  "Estenotipista técnico judiciário",
  "Estenotipista técnico parlamentar",
  "Estercador",
  "Esterilizador de alimentos",
  "Esteticista",
  "Esteticista corporal",
  "Esteticista de animais domésticos",
  "Esteticista facial",
  "Estilista de moda",
  "Estirador de couros e peles (acabamento)",
  "Estirador de couros e peles (preparação)",
  "Estirador de tubo de metal",
  "Estirador de tubos de metal sem costura",
  "Estivador",
  "Estofador de aviões",
  "Estofador de móveis",
  "Estofador de veículos",
  "Estoquista",
  "Estripador (matadouro)",
  "Estucador",
  "Etiquetador",
  "Etiquetador de embalagem",
  "Etiquetador, a mão",
  "Evangelista",
  "Eviscerador em matadouro",
  "Examinador de cabos, linhas elétricas e telefônicas",
  "Examinador de meias (produção têxtil)",
  "Excêntrico",
  "Exegeta",
  "Expedidor de calçados",
  "Expedidor de mercadorias",
  "Explodidor - em minas e pedreiras",
  "Explorador de madeira, em geral",
  "Exportador",
  "Exportador - empregador",
  "Extensionista de cílios",
  "Exterminador de insetos - na agropecuária",
  "EXTRATIVISTAS E REFLORESTADORES DE ESPÉCIES PRODUTORAS DE MADEIRA",
  "EXTRATIVISTAS FLORESTAIS DE ESPÉCIES PRODUTORAS DE ALIMENTOS SILVESTRES",
  "EXTRATIVISTAS FLORESTAIS DE ESPÉCIES PRODUTORAS DE FIBRAS, CERAS E ÓLEOS",
  "EXTRATIVISTAS FLORESTAIS DE ESPÉCIES PRODUTORAS DE GOMAS E RESINAS",
  "EXTRATIVISTAS FLORESTAIS DE ESPÉCIES PRODUTORAS DE SUBSTÂNCIAS AROMÁTICAS, MEDICINAIS E",
  "Extrator - na extração de babaçu",
  "Extrator de britagem",
  "Extrator de látex",
  "Extrator de látex (gomas não elásticas)",
  "Extrator de miolos em matadouro",
  "Extrator de piaçava",
  "Extrator de resinas",
  "Extrusor (artes gráficas)",
  "Extrusor de borracha",
  "Extrusor de fios ou fibras de vidro",
  "Fabricador de baladas",
  "Fabricador de fogos de artifício",
  "Fabulista",
  "Faiscador - no garimpo",
  "Falquejador",
  null,
  "Faquir",
  "Farmacêutica criminalista",
  "Farmacêutico",
  "Farmacêutico acupunturista",
  "Farmacêutico analista clínico",
  "Farmacêutico analista clínico (bioquímico)",
  "Farmacêutico antroposófico",
  "Farmacêutico auditor",
  "Farmacêutico bromatologista",
  "Farmacêutico clínico",
  "Farmacêutico clínico domiciliar",
  "Farmacêutico clínico em cardiologia",
  "Farmacêutico clínico em cuidados paliativos",
  "Farmacêutico clínico em famarcocinética clinica",
  "Farmacêutico clínico em farmacovigilância",
  "Farmacêutico clínico em geriatria",
  "Farmacêutico clínico em hematologia",
  "Farmacêutico clínico em oncologia",
  "Farmacêutico clínico em pediatria",
  "Farmacêutico clínico em reumatologia",
  "Farmacêutico clínico em terapia antineoplásica",
  "Farmacêutico de alimentos",
  "Farmacêutico de manipulação",
  "Farmacêutico em alfândega",
  "Farmacêutico em alimentos funcionais e nutracêuticos",
  "Farmacêutico em análise de alimentos",
  "Farmacêutico em análise de solo",
  "Farmacêutico em análises clínicas",
  "Farmacêutico em armazenamento",
  "Farmacêutico em assistência domiciliar",
  "Farmacêutico em atenção farmacêutica",
  "Farmacêutico em bacteriologia clínica",
  "Farmacêutico em banco de leite",
  "Farmacêutico em banco de materiais biológicos",
  "Farmacêutico em banco de órgãos, tecidos e células",
  "Farmacêutico em banco de sangue",
  "Farmacêutico em banco de sêmem",
  "Farmacêutico em biofarmácia",
  "Farmacêutico em biologia molecular",
  "Farmacêutico em bioquímica clínica",
  "Farmacêutico em biossegurança",
  "Farmacêutico em biotecnologia industrial",
  "Farmacêutico em citogenética",
  "Farmacêutico em citologia clínica e diagnóstica",
  "Farmacêutico em citopatologia",
  "Farmacêutico em citoquímica",
  "Farmacêutico em controle de qualidade de alimentos",
  "Farmacêutico em controle de qualidade de insumos, medicamentos, cosméticos e produtos veterinários",
  "Farmacêutico em controle de qualidade e tratamento de água",
  "Farmacêutico em controle de vetores e pragas urbanas",
  "Farmacêutico em cosmetologia",
  "Farmacêutico em cuidados paliativos",
  "Farmacêutico em distribuidora",
  "Farmacêutico em domissanitários",
  "Farmacêutico em estratégia da saúde da família",
  "Farmacêutico em farmácia comunitária",
  "Farmacêutico em farmácia pública",
  "Farmacêutico em farmácia veterinária",
  "Farmacêutico em farmacocinética clínica",
  "Farmacêutico em farmacoepidemiologia",
  "Farmacêutico em farmacotécnica",
  "Farmacêutico em farmacovigilância",
  "Farmacêutico em gases e misturas de usos terapêuticos",
  "Farmacêutico em genética",
  "Farmacêutico em gerenciamento dos resíduos em serviços de saúde",
  "Farmacêutico em gestão ambiental",
  "Farmacêutico em gestão de assistência farmacêutica",
  "Farmacêutico em hematologia clínica",
  "Farmacêutico em hemoterapia",
  "Farmacêutico em histocompatibilidade",
  "Farmacêutico em histoquímica",
  "Farmacêutico em homecare",
  "Farmacêutico em homoderivados",
  "Farmacêutico em imunocitoquímica",
  "Farmacêutico em imunogenética",
  "Farmacêutico em imunologia clínica",
  "Farmacêutico em imunopatologia",
  "Farmacêutico em indústria de alimentos",
  "Farmacêutico em indústria de cosméticos",
  "Farmacêutico em indústria farmacêutica",
  "Farmacêutico em indústria químico-farmacêutico",
  "Farmacêutico em indústria veterinária",
  "Farmacêutico em micologia clínica",
  "Farmacêutico em microbiologia clínica",
  "Farmacêutico em microbiologia de alimentos",
  "Farmacêutico em nutrição animal",
  "Farmacêutico em nutrição parenteral",
  "Farmacêutico em parasitologia clínica",
  "Farmacêutico em pesquisa e desenvolvimento de alimentos",
  "Farmacêutico em pesquisa e desenvolvimento de fármacos",
  "Farmacêutico em pesquisas clínicas",
  "Farmacêutico em plantas medicinais e fitoterapia",
  "Farmacêutico em produção de alimentos",
  "Farmacêutico em radioisótopos",
  "Farmacêutico em saúde ambiental",
  "Farmacêutico em saúde ocupacional",
  "Farmacêutico em saúde pública",
  "Farmacêutico em segurança do trabalho",
  "Farmacêutico em termalismo social/crenoterapia",
  "Farmacêutico em toxicologia ambiental",
  "Farmacêutico em toxicologia analítica",
  "Farmacêutico em toxicologia clínica",
  "Farmacêutico em toxicologia de alimentos",
  "Farmacêutico em toxicologia de cosméticos",
  "Farmacêutico em toxicologia de emergência",
  "Farmacêutico em toxicologia de medicamentos",
  "Farmacêutico em toxicologia desportiva",
  "Farmacêutico em toxicologia experimental",
  "Farmacêutico em toxicologia forense",
  "Farmacêutico em toxicologia ocupacional",
  "Farmacêutico em toxicologia veterinária",
  "Farmacêutico em transportadora",
  "Farmacêutico em vigilância epidemiológica",
  "Farmacêutico em virologia clínica",
  "Farmacêutico farmacologista",
  "Farmacêutico fitoterapeuta",
  "Farmacêutico homeopata",
  "Farmacêutico hospitalar e clínico",
  "Farmacêutico industrial",
  "Farmacêutico logístico",
  "Farmacêutico magistral",
  "Farmacêutico nuclear",
  "Farmacêutico práticas integrativas e complementares",
  "Farmacêutico pré-hospitalar em serviços de urgência e emergência",
  "Farmacêutico toxicologista",
  "FARMACÊUTICOS",
  "Faroleiro (meio militar)",
  "Fateiro em matadouro",
  "Faturista",
  "Faxineiro",
  "Faxineiro no serviço doméstico",
  "Fazedor de cerca - inclusive na agropecuária",
  "Fazendeiro - na criação de asininos  e muares",
  "Fazendeiro - na criação de bovinos - empregador",
  "Fazendeiro - na criação de caprinos - empregador",
  "Fazendeiro - na criação de eqüinos - empregador",
  "Fazendeiro - na criação de ovinos",
  "Fazendeiro de búfalos - corte",
  "Fazendeiro de búfalos - leite",
  "Fazendeiro na agropecuária - conta própria",
  "Fazendeiro na criação de suínos - empregador",
  "Fechador de ampolas (garrafas térmicas)",
  "Fechador de moldes - na fundição",
  "Feirante",
  "Feitor de britagem",
  "Feitor de salinas",
  "Feitor de turma (ferrovias)",
  "Feitor de turma de ferrovia",
  "Feitor de vias férreas",
  "Fenologista",
  "Fermentador",
  "Fermentador de cerveja",
  "Fermentador de fumo",
  "Fermentador de vinho",
  "Ferrador de animais",
  "Ferrador de equinos",
  "Ferrageador de equinos",
  "Ferrageiro (comércio varejista)",
  "Ferragista (comércio varejista)",
  "Ferramenteiro",
  "Ferramenteiro de bancada",
  "Ferramenteiro de coquilhas",
  "Ferramenteiro de injeção termofixo",
  "Ferramenteiro de injeção termoplástico",
  "Ferramenteiro de injeção zamak e alumínio",
  "Ferramenteiro de mandris, calibradores e outros dispositivos",
  "Ferramenteiro de manutenção",
  "Ferramenteiro de matrizes e estampos",
  "Ferramenteiro de molde para borracha",
  "Ferramenteiro de moldes plásticos (sopro)",
  "FERRAMENTEIROS E AFINS",
  "Ferreiro",
  "Ferreiro armador na construção civil",
  "Ferreiro de molas",
  "Fiambreiro na conservação de alimentos",
  "Fiandeiro",
  "Fibrador (fabricação de fibra de vidro)",
  "Fibreiro de rami",
  "Fibreiro de sisal",
  "Ficharista",
  "Ficologista",
  "Fiel de depósito",
  "Figurante",
  "Filigraneiro",
  "Filigranista",
  "Filólogo",
  "Filólogo dicionarista",
  "FILÓLOGOS,TRADUTORES ,INTÉRPRETES E AFINS",
  "Filósofo",
  "FILÓSOFOS",
  "Filtrador de cerveja",
  "Filtrador de goma",
  "Filtrador na indústria de bebidas",
  "Finalizador de filmes",
  "Finalizador de vídeo",
  "FISCAIS DE TRIBUTOS ESTADUAIS E MUNICIPAIS",
  "FISCAIS E COBRADORES DOS TRANSPORTES COLETIVOS",
  "Fiscal de atividades econômicas",
  "Fiscal de atividades urbanas",
  "Fiscal de aviação civil (fac)",
  "Fiscal de balanças",
  "Fiscal de banheiro (concurso, avaliação, exame)",
  "Fiscal de basquetebol",
  "Fiscal de caixa",
  "Fiscal de capela",
  "Fiscal de construção",
  "Fiscal de feiras livres",
  "Fiscal de higiene",
  "Fiscal de lavoura",
  "Fiscal de linha",
  "Fiscal de loja",
  "Fiscal de medição (obras civis)",
  "Fiscal de obras",
  "Fiscal de ônibus",
  "Fiscal de operação",
  "Fiscal de pátio (aeroporto)",
  "Fiscal de pátio de usina de concreto",
  "Fiscal de pesos e medidas",
  "Fiscal de piso",
  "Fiscal de pista de aeroporto",
  "Fiscal de posturas",
  "Fiscal de prevenção de perdas",
  "Fiscal de propriedade agropecuária",
  "Fiscal de sala (concurso, avaliação, exame)",
  "Fiscal de segurança",
  "Fiscal de tráfego",
  "Fiscal de transporte rodoviário",
  "Fiscal de transportes coletivos (exceto trem)",
  "Fiscal de tributos estadual",
  "Fiscal de tributos municipal",
  "Fiscal de viagens",
  "Fiscal de vigilância - organizações particulares de segurança",
  "Fiscal de vigilância bancária",
  "Fiscal do meio ambiente",
  "Fiscal florestal",
  "Fiscal integrado",
  "Fiscal municipal",
  "Fiscal nos transportes",
  "Fiscal rodoviário",
  "Fiscal técnico na geração, transmissão e distribuição de energia elétrica",
  "Fiscal têxtil",
  "Fiscal urbano",
  "Fiscal volante (concurso, avaliação, exame)",
  "Fisiatra",
  "Físico",
  "Físico (acústica)",
  "Físico (atômica e molecular)",
  "Físico (cosmologia)",
  "Físico (estado sólido)",
  "Físico (estatística e matemática)",
  "Físico (fluidos)",
  "Físico (instrumentação)",
  "Físico (matéria condensada)",
  "Físico (materiais)",
  "Físico (medicina)",
  "Físico (nuclear e reatores)",
  "Físico (óptica)",
  "Físico (partículas e campos)",
  "Físico (plasma)",
  "Físico (térmica)",
  "Físico hospitalar",
  "Físico médico",
  "Físico nuclear",
  "FÍSICOS",
  "Fisiologista (exceto médico)",
  "Fisioterapeuta  do trabalho",
  "Fisioterapeuta acupunturista",
  "Fisioterapeuta esportivo",
  "Fisioterapeuta geral",
  "Fisioterapeuta neurofuncional",
  "Fisioterapeuta osteopata",
  "Fisioterapeuta quiropraxista",
  "Fisioterapeuta respiratória",
  "Fisioterapeuta traumato-ortopédica funcional",
  "FISIOTERAPEUTAS",
  "Fitotecário",
  "Fixador de moldes - na fundição",
  "Flandeiro",
  "Flanelinha",
  "Flavorista",
  "Flavourist (cigarros)",
  "Flavourista (cigarros)",
  "Flebotomista",
  "Floricultor  de flores de corte",
  "Floricultor de flores em vaso",
  "Floricultor de forrações",
  "Floricultor de plantas ornamentais",
  "Floricultor no cultivo de flores e folhagens de corte",
  "Floricultor no cultivo de flores em vaso",
  "Floricultor no cultivo de forragens",
  "Floricultor no cultivo de mudas",
  "Floricultor no cultivo de plantas ornamentais",
  "Florista (comércio varejista)",
  "Foguista (locomotivas a vapor)",
  "Foguista de caldeira de trem",
  "Foguista de locomotiva",
  "Foguista de trem",
  "Foiceiro",
  "Foiceiro - na cultura",
  "Foiceiro - na extração da carnaúba",
  "Folclorista de ficção",
  "Folclorista de não ficção",
  "Folgazão",
  "Folheador de móveis",
  "Folheador de móveis de madeira",
  "Foniatra",
  "Fonoaudiólogo educacional",
  "Fonoaudiólogo em audiologia",
  "Fonoaudiólogo em disfagia",
  "Fonoaudiólogo em linguagem",
  "Fonoaudiólogo em motricidade orofacial",
  "Fonoaudiólogo em saúde coletiva",
  "Fonoaudiólogo em voz",
  "Fonoaudiólogo geral",
  "FONOAUDIÓLOGOS",
  "Forjador",
  "Forjador a mão (molas helicoidais)",
  "Forjador a martelete",
  "Forjador a martelo",
  "Forjador a martelo de queda livre",
  "Forjador a martelo-pilão",
  "Forjador de molas",
  "Forjador de talhadeira",
  "Forjador manual",
  "Forjador prensista",
  "Formador - na cultura",
  "Formigueiro (combate às formigas)",
  "Formista (cerâmica)",
  "Formista - na fundição",
  "Formista manual",
  "Fornecedor",
  "Forneiro (fundição)",
  "Forneiro (materiais de construção)",
  "Forneiro - em olaria",
  "Forneiro auxiliar (alto-forno)",
  "Forneiro auxiliar de cubilô",
  "Forneiro conversor a oxigênio",
  "Forneiro de alto-forno",
  "Forneiro de cubilô",
  "Forneiro de forno revérbero",
  "Forneiro de forno-poço",
  "Forneiro de fundição",
  "Forneiro de fundição (forno de redução)",
  "Forneiro de material de construção (telhas e tijolos)",
  "Forneiro de metais ferrosos e não-ferrosos (preparação de ligas)",
  "Forneiro de padaria",
  "Forneiro de reaquecimento e tratamento térmico na metalurgia",
  "Forneiro de recuperação de resíduos",
  "Forneiro de retêmpera",
  "Forneiro de revérbero",
  "Forneiro de têmpera",
  "Forneiro de tratamento térmico de metais",
  "Forneiro de vidro",
  "Forneiro e operador (alto-forno)",
  "Forneiro e operador (conversor a oxigênio)",
  "Forneiro e operador (forno elétrico)",
  "Forneiro e operador (refino de metais não-ferrosos)",
  "Forneiro e operador de forno de redução direta",
  "Forneiro fundidor de metais",
  "Forneiro metalúrgico",
  "Forneiro na fundição de vidro",
  "Forneiro na refrigeração de sal",
  "Forneiro no recozimento de vidro",
  "Forneiro temperador",
  "Forneiro(pizzaria)",
  "Forneiros de não-ferrosos",
  "FORNEIROS METALÚRGICOS (SEGUNDA FUSÃO E REAQUECIMENTO)",
  "Forrador de móveis",
  "Forrador de saltos no acabamento  de calçados",
  "Foscador de cilindros (laminação)",
  "Foscador de espelhos",
  "Foscador de vidro a ácido",
  "Foscador de vidro a jato de areia",
  "Fosfatizador",
  "Fotocompositor",
  "Fotógrafo",
  "Fotógrafo (pré-impressão gráfica)",
  "Fotógrafo científico",
  "Fotógrafo de aerofotografia",
  "Fotógrafo de arquitetura",
  "Fotógrafo de foto submarina",
  "Fotógrafo de sensoriamento remoto",
  "Fotógrafo documentarista",
  "Fotógrafo industrial",
  "Fotógrafo laboratorista filmes fotográficos, em cores",
  "Fotógrafo laboratorista filmes fotográficos, em preto e branco",
  "Fotógrafo publicitário",
  "Fotógrafo retratista",
  "Fotógrafo social",
  "FOTÓGRAFOS PROFISSIONAIS",
  "Fotojornalista",
  "Fotolitógrafo",
  "Fototipista",
  "Frade",
  "Fraldeiro",
  "Frangueiro em matadouro",
  "Frei",
  "Freira",
  "Frenólogo",
  "Frentista",
  "Fresador (fresadora universal)",
  "Fresador cnc",
  "Fruteiro (comércio varejista)",
  "Fulista",
  "Fuloneiro",
  "Fuloneiro no acabamento de couros e peles",
  "Fumeiro",
  "Fumicultor - empregador",
  "Fumicultor - exclusive conta própria e empregador",
  "Funâmbulo",
  "Funcionário de setor de informação",
  "Funcionário público estadual e distrital superior",
  "Funcionário público federal superior",
  "Funcionário público municipal superior",
  "Fundidor (depuração e refinação de metais não-ferrosos)",
  "Fundidor (joalheria e ourivesaria)",
  "Fundidor a pressão",
  "Fundidor coquilheiro",
  "Fundidor de barbotina",
  "Fundidor de coquilha",
  "Fundidor de ligas metálicas",
  "Fundidor de máquina de pressão",
  "Fundidor de metais",
  "Fundidor de pedras artificiais",
  "Fundidor de tipos",
  "Fundidor de vinheta",
  "Fundidor moldador de metais",
  "Fundidor vidreiro",
  "Funileiro de automóveis (reparação)",
  "Funileiro de bancada",
  "Funileiro de manutenção",
  "Funileiro de veículos (reparação)",
  "Funileiro industrial",
  "Funileiro montador",
  "Funileiro soldador",
  "Furão - no garimpo",
  "Gaiaku",
  "Gaioleiro (gado)",
  "Galinheiro (exceto no comércio ambulante)",
  "Galvanista (rotogravura)",
  "Galvanizador",
  "Galvanizador (sherardização)",
  "Gambista (artesão de vidros)",
  "Game designer",
  "Gandula",
  "Garagista",
  "Garção",
  "Garçom",
  "Garçom (serviços de vinhos)",
  "Garçom de bar",
  "Garfeiro - no garimpo",
  "Gari",
  "Garimpeiro",
  "GARIMPEIROS E OPERADORES DE SALINAS",
  "Garota de programa",
  "Garoto de programa",
  "Garrafeiro (comércio ambulante)",
  "Garrafeiro (fabricação)",
  "Garreador de mocotó",
  "Gasista",
  "Gastroenterologista",
  "Gastrólogo",
  "Gateiro",
  "Gãtó",
  "Gelador industrial",
  "Gelador profissional",
  "Geleiro",
  "General-de-brigada",
  "General-de-divisão",
  "General-de-exército",
  "Geneticista",
  "Geofísico",
  "Geofísico espacial",
  "Geógrafo",
  "Geólogo",
  "Geólogo de engenharia",
  "GEÓLOGOS, OCEANÓGRAFOS, GEOFÍSICOS E AFINS",
  "Geoquímico",
  "Gerador de conteúdo digital",
  "Gerente administrativo",
  "Gerente administrativo de lanchonete",
  "Gerente administrativo e financeiro",
  "Gerente avsec (segurança da aviação civil)",
  "Gerente comercial",
  "Gerente da área de saúde mental",
  "Gerente de  albergue",
  "Gerente de administração de carteira de crédito imobiliário",
  "Gerente de administração de carteiras de câmbio e comércio exterior",
  "Gerente de administração de carteiras de crédito e cobrança",
  "Gerente de administração de pessoal",
  "Gerente de administração em aeroportos",
  "Gerente de administração financeira",
  "Gerente de aeródromo",
  "Gerente de aeroportos",
  "Gerente de agência",
  "Gerente de agropecuária",
  "Gerente de almoxarifado",
  "Gerente de ambulatório",
  "Gerente de apart hotel",
  "Gerente de apoio e diagnóstico de saúde",
  "Gerente de apoio educacional em escolas privadas",
  "Gerente de área de vendas",
  "Gerente de armazém",
  "Gerente de arte teatral",
  "Gerente de assistência técnica nas operações de correios e telecomunicações",
  "Gerente de assuntos corporativos",
  "Gerente de atendimento a passageiros",
  "Gerente de atendimento de tecnologia da informação",
  "Gerente de aviação executiva",
  "Gerente de balconista",
  "Gerente de bar",
  "Gerente de bar e lanchonete",
  "Gerente de bar, cantina e restaurante",
  "Gerente de box de mercado público",
  "Gerente de câmbio e comércio exterior",
  "Gerente de cantina",
  "Gerente de captação (fundos e investimentos institucionais)",
  "Gerente de cargas",
  "Gerente de carrocerias (vendas a varejo)",
  "Gerente de casa de peixe",
  null,
  "Gerente de centro de educação profissional em escolas privadas",
  "Gerente de centro de formação profissional em escolas privadas",
  "Gerente de clientes especiais (private)",
  "Gerente de compras",
  "Gerente de comunicação",
  "Gerente de comunicação corporativa",
  "Gerente de comunicação social",
  "Gerente de contabilidade",
  "Gerente de contas - pessoa física e jurídica",
  "Gerente de contratos (construção civil)",
  "Gerente de coordenação de marketing",
  "Gerente de correio expresso - internacional",
  "Gerente de crédito e cobrança",
  "Gerente de crédito imobiliário",
  "Gerente de crédito rural",
  "Gerente de cyber security",
  "Gerente de departamento de fabricação",
  "Gerente de departamento de marketing",
  "Gerente de departamento de planejamento e análise",
  "Gerente de departamento de planejamento e pesquisa",
  "Gerente de departamento de produção",
  "Gerente de departamento de vendas",
  "Gerente de departamento florestal",
  "Gerente de departamento industrial",
  "Gerente de departamento pessoal",
  "Gerente de depósito",
  "Gerente de desenvolvimento (tecnologia)",
  "Gerente de desenvolvimento de aplicações",
  "Gerente de desenvolvimento de sistemas",
  "Gerente de desenvolvimento de software",
  "Gerente de desenvolvimento de ti",
  "Gerente de distribuição de mercadorias",
  "Gerente de divisão comercial",
  "Gerente de divisão de desenvolvimento de novos produtos",
  "Gerente de divisão de marketing",
  "Gerente de divisão de pesquisas e análise",
  "Gerente de divisão de pessoal",
  "Gerente de documentação",
  "Gerente de ecoresort",
  "Gerente de editora",
  "Gerente de empresa de pesca",
  "Gerente de empresas comerciais",
  "Gerente de empresas de construção civil",
  "Gerente de encomendas nas operações de correios e telecomunicações",
  "Gerente de enfermagem",
  "Gerente de estância",
  "Gerente de estudos e projetos",
  "Gerente de exportação",
  "Gerente de exportação e importação",
  "Gerente de fábrica",
  "Gerente de facility management",
  "Gerente de farmácia",
  "Gerente de finanças",
  "Gerente de fogo",
  "Gerente de frota",
  "Gerente de frota (transporte rodoviário)",
  "Gerente de frota pesqueira",
  "Gerente de grandes contas (corporate)",
  "Gerente de granja",
  "Gerente de hotel",
  "Gerente de hotel fazenda",
  "Gerente de hotel residencial",
  "Gerente de informação",
  "Gerente de infraestrutura (facility management)",
  "Gerente de infraestrutura de tecnologia da informação",
  "Gerente de instituição educacional da área privada",
  "Gerente de investigação",
  "Gerente de logística (armazenagem e distribuição)",
  "Gerente de loja e supermercado",
  "Gerente de magazine",
  "Gerente de manufatura",
  "Gerente de marketing",
  "Gerente de marketing e vendas",
  "Gerente de materiais",
  "Gerente de mercado",
  "Gerente de mercearia",
  "Gerente de montagem",
  "Gerente de movimentação de materiais",
  "Gerente de nutrição em unidades de saúde",
  "Gerente de obras (construção civil)",
  "Gerente de ong (organização não-governamental)",
  "Gerente de operação de suporte de tecnologia da informação",
  "Gerente de operação de tecnologia da informação",
  "Gerente de operações de cargas",
  "Gerente de operações de correios e telecomunicações",
  "Gerente de operações de serviços de assistência técnica",
  "Gerente de operações de transportes",
  "Gerente de operações de turismo",
  "Gerente de operações e manutenção nos sistemas de correios e telecomunicações",
  "Gerente de operações e transportes",
  "Gerente de operações em aeroportos",
  "Gerente de operações financeiras",
  "Gerente de organização social de interesse público",
  "Gerente de papelaria",
  "Gerente de pensão",
  "Gerente de pensionato",
  "Gerente de pesquisa (tecnologia)",
  "Gerente de pesquisa e desenvolvimento (p&d)",
  "Gerente de pesquisas no ibge (instituto brasileiro de geografia e estatística)",
  "Gerente de pesquisas técnicas",
  "Gerente de pesquisas tecnológicas",
  "Gerente de planejamento de compras",
  "Gerente de planejamento de salários e benefícios",
  "Gerente de planejamento e estatística",
  "Gerente de planejamento e manutenção",
  "Gerente de planejamento e novos projetos",
  "Gerente de posto de venda",
  "Gerente de pousada",
  "Gerente de prevenção",
  "Gerente de processos",
  "Gerente de produção",
  "Gerente de produção de tecnologia da informação",
  "Gerente de produção e operações",
  "Gerente de produção e operações  aqüícolas",
  "Gerente de produção e operações  florestais",
  "Gerente de produção e operações agropecuárias",
  "Gerente de produção e operações da construção civil e obras públicas",
  "Gerente de produção e operações pesqueiras",
  "Gerente de produção florestal",
  "Gerente de produção na agropecuária",
  "Gerente de produção na fabricação de produtos de cerâmica, porcelanatos e afins",
  "Gerente de produção na fabricação de produtos de vidro",
  "Gerente de produtos",
  "Gerente de produtos bancários",
  "Gerente de produtos de turismo",
  "Gerente de programação",
  "Gerente de programas de saúde",
  "Gerente de programas de ti",
  "Gerente de projeto de pesquisa",
  "Gerente de projetos de tecnologia da informação",
  "Gerente de projetos e serviços de manutenção",
  "Gerente de pronto-socorro",
  "Gerente de propriedade (facility management)",
  "Gerente de rampa",
  "Gerente de recebimento e expedição de materiais",
  "Gerente de recuperação de crédito",
  "Gerente de recursos humanos",
  "Gerente de rede",
  "Gerente de rede nas operações de correios e telecomunicações",
  "Gerente de relações corporativas",
  "Gerente de relações de recursos humanos",
  "Gerente de relações humanas",
  "Gerente de relações industriais",
  "Gerente de relações institucionais",
  "Gerente de relações públicas",
  "Gerente de resort",
  "Gerente de restaurante",
  "Gerente de riscos",
  "Gerente de riscos e seguros",
  "Gerente de safety",
  "Gerente de salão de café",
  "Gerente de security",
  "Gerente de segurança da aviação civil",
  "Gerente de segurança da informação",
  "Gerente de segurança e resposta à emergência",
  "Gerente de segurança empresarial",
  "Gerente de segurança operacional (aviação civil)",
  "Gerente de seguros",
  "Gerente de serviço de manutenção",
  "Gerente de serviços culturais",
  "Gerente de serviços de oficina (assistência técnica)",
  "Gerente de serviços de saúde",
  "Gerente de serviços e peças",
  "Gerente de serviços educacionais da área pública",
  "Gerente de serviços operacionais de correios e telecomunicações",
  "Gerente de serviços sociais",
  "Gerente de setor de pessoal",
  "Gerente de sistema de gerenciamento de segurança operacional (sgso)",
  "Gerente de sistemas administrativos de pessoal",
  "Gerente de sistemas administrativos e finanças",
  "Gerente de sistemas de ti",
  "Gerente de sistemas e métodos administrativos",
  "Gerente de supermercado",
  "Gerente de suporte operacional de correios e telecomunicações",
  "Gerente de suporte técnico de tecnologia da informação",
  "Gerente de suprimentos",
  "Gerente de taxi aéreo",
  "Gerente de termas",
  "Gerente de terminal em operações de armazenamento",
  "Gerente de tráfego",
  "Gerente de tráfego de cargas",
  "Gerente de tráfego rodoviário",
  "Gerente de transportes",
  "Gerente de turismo",
  "Gerente de unidade de fabricação",
  "Gerente de utilidades (operações comerciais)",
  "Gerente de varejo",
  "Gerente de vendas",
  "Gerente de workplace (facility management)",
  "Gerente distrital de vendas",
  "Gerente do serviço público estadual e distrital",
  "Gerente do serviço público federal",
  "Gerente executivo de infraestrutura de ti",
  "Gerente financeiro",
  "Gerente geral de compras",
  "Gerente geral de vendas",
  "Gerente industrial",
  "Gerente nacional de compras",
  "Gerente nacional de vendas",
  "Gerente operacional",
  "Gerente operacional de cargas",
  "Gerente operacional de turismo",
  "Gerente operacional florestal",
  "Gerente operacional na agropecuária",
  "Gerente regional de aeroportos",
  "Gerente regional de cargas",
  "Gerente regional de vendas",
  "Gerente técnico comercial",
  "Gerente técnico de produção (mineração)",
  "Gerente técnico nas operações de correios e telecomunicações",
  "Gerente técnico operacional de transporte",
  "GERENTES ADMINISTRATIVOS, FINANCEIROS, DE RISCOS E AFINS",
  "GERENTES DE COMERCIALIZAÇÃO, MARKETING E COMUNICAÇÃO",
  "GERENTES DE MANUTENÇÃO E AFINS",
  "GERENTES DE OBRAS EM EMPRESA DE CONSTRUÇÃO",
  "GERENTES DE OPERAÇÕES COMERCIAIS E DE ASSISTÊNCIA TÉCNICA",
  "GERENTES DE OPERAÇÕES DE SERVIÇOS EM EMPRESA DE TRANSPORTE, DE COMUNICAÇÃO E DE",
  "GERENTES DE OPERAÇÕES DE SERVIÇOS EM EMPRESA DE TURISMO, DE ALOJAMENTO E ALIMENTAÇÃO",
  "GERENTES DE OPERAÇÕES DE SERVIÇOS EM INSTITUIÇÃO DE INTERMEDIAÇÃO FINANCEIRA",
  "GERENTES DE PESQUISA E DESENVOLVIMENTO E AFINS",
  "GERENTES DE PRODUÇÃO E OPERAÇÕES EM EMPRESA AGROPECUÁRIA, PESQUEIRA, AQÜÍCOLA E",
  "GERENTES DE PRODUÇÃO E OPERAÇÕES EM EMPRESA DA INDÚSTRIA EXTRATIVA, DE TRANSFORMAÇÃO",
  "GERENTES DE RECURSOS HUMANOS E DE RELAÇÕES DO TRABALHO",
  "GERENTES DE SUPRIMENTOS E AFINS",
  "GERENTES DE TECNOLOGIA DA INFORMAÇÃO",
  "GERENTES OPERACIONAIS DA AVIAÇÃO CIVIL",
  "Geriatra",
  "Gero-sitter",
  "Gerontologista",
  "Gerontólogo",
  "Gesseiro",
  "Gesseiro de decoração",
  "Gesseiro de revestimento",
  "Gesseiro forrador",
  "Gesseiro fundidor",
  "Gesseiro modelador",
  "Gesseiro moldureiro",
  "Gesseiro montador",
  "Gesseiro plaqueiro",
  "GESSEIROS",
  "Gestor de dados clínicos",
  "Gestor de documentos",
  "Gestor de entidade religiosa",
  "Gestor de evento",
  "Gestor de informação",
  "Gestor de manutenção",
  "Gestor de negócios internacionais",
  "Gestor de projetos internacionais",
  "Gestor de riscos",
  "Gestor em segurança",
  "Gestor em turismo (turismólogo)",
  "Gestor público (administrador)",
  "GESTORES PÚBLICOS",
  "Gheshe",
  "Ginecologista",
  "Globista",
  "Glossarista",
  "Go - gentil organizador",
  "Goleiro",
  "Golfista profissional",
  "Governador de estado",
  "Governador do distrito federal",
  "Governanta de hotelaria",
  "Governanta de residência",
  "Governanta executiva",
  "Gradeiro",
  "Grafiteiro (artes visuais)",
  "Graneador",
  "Graniteiro",
  "Graniteiro - na extração",
  "Granjeiro na produção de arroz",
  "Granjeiro na produção de gramíneas forrageiras",
  "Granjeiro na produção de milho e sorgo",
  "Gravador (artes visuais)",
  "Gravador (joalheria e ourivesaria)",
  "Gravador a ácido",
  "Gravador com pantógrafo",
  "Gravador de chapa",
  "Gravador de chapas e cilindros com ácido",
  "Gravador de chapas, cliches e cilindros de impressão",
  "Gravador de cilindros, a  máquina",
  "Gravador de clichês (madeira, borracha ou linóleo)",
  "Gravador de clichês, a  mão (matrizes de borracha ou linóleo)",
  "Gravador de fotogravura ou de rotogravura",
  "Gravador de fotolito",
  "Gravador de inscrições em mármore",
  "Gravador de inscrições em mármore e em pedras",
  "Gravador de inscrições em pedra",
  "Gravador de jóias",
  "Gravador de lombadas de livros",
  "Gravador de matriz calcográfica",
  "Gravador de matriz para flexografia (clicherista)",
  "Gravador de matriz para rotogravura (eletromecânico e químico)",
  "Gravador de matriz serigráfica",
  "Gravador de matrizes de impressão",
  "Gravador de relevos em pedra",
  "Gravador de rotogravura",
  "Gravador de vidro a  água-forte",
  "Gravador de vidro a  esmeril",
  "Gravador de vidro a  jato de areia",
  "Gravador em ouriversaria",
  "Gravador, à mão (encadernação)",
  "Greidista",
  "Grossista",
  "Gruneiro",
  "Guaranaicultor",
  "Guaranazeiro",
  "Guaranazeiro - empregador",
  "Guarda ambiental",
  "Guarda de banco - organizações particulares de segurança",
  "Guarda de endemias",
  "Guarda de presídio",
  "Guarda de segurança",
  "Guarda de segurança - empresa particular de segurança",
  "Guarda de vigilância",
  "Guarda ferroviário",
  "Guarda florestal",
  "Guarda portuário",
  "Guarda valores",
  "Guarda vigia",
  "Guarda-civil",
  "Guarda-civil metropolitano",
  "Guarda-civil municipal",
  "Guarda-costas",
  "Guarda-parques",
  "Guarda-roupeiro de cinema",
  "Guarda-roupeiro de teatro",
  "Guarda-roupeiro de televisão",
  "Guarda-rural",
  "Guarda-territorial",
  "Guarda-vidas",
  "Guardador autônomo de veículos",
  "Guardador de carro",
  "Guardador de veículos",
  "Guariteiro",
  "Guest relations",
  "Guia de turismo",
  "Guia de turismo especializado em atrativo turístico",
  "Guia de turismo especializado em excursão internacional",
  "Guia de turismo especializado em excursão nacional",
  "Guia de turismo especializado em turismo regional",
  "Guia florestal",
  "Guia-intérprete",
  "GUIAS DE TURISMO",
  "Guieiro",
  "Guilhotineiro - na indústria gráfica",
  "Guincheiro",
  "Guincheiro (construção civil)",
  "Guindasteiro",
  "Hansenólogo",
  "Hebeatra",
  "Hematologista",
  "Hemoterapeuta",
  "Herborista (comércio varejista)",
  "Hialotécnico",
  "Hidrobiologista",
  "Hidrogenador de óleos e gorduras",
  "Hidrogeólogo",
  "Hidrometrista",
  "Hidrotécnico",
  "Higienista industrial",
  "Higienista ocupacional",
  "Histologista",
  "Historiador",
  "Historiador em música",
  "Homem-bala",
  "Homeopata (não médico)",
  "Horticultor de frutos e sementes",
  "Horticultor de legumes",
  "Horticultor de raízes, bulbos e tubérculos",
  "Horticultor de talos, folhas e flores",
  "Humbono",
  "Humorista",
  "Hunjaí",
  "Huntó",
  "Identificador florestal",
  "Iluminador (televisão)",
  "Ilusionista",
  "Ilustrador (artes visuais)",
  "Imã",
  "Image designer",
  "Imaginologista",
  "Imediato da marinha mercante",
  "Importador",
  "Impregnador de madeira",
  "Impregnador de madeira (produtos antiinflamáveis)",
  "Impressor (serigrafia)",
  "Impressor automático",
  "Impressor calcográfico",
  "Impressor de cartazes",
  "Impressor de corte e vinco",
  "Impressor de etiqueta",
  "Impressor de formulários contínuos",
  "Impressor de máquina ofsete",
  "Impressor de máquina plana",
  "Impressor de ofsete (plano e rotativo)",
  "Impressor de plásticos",
  "Impressor de rotativa",
  "Impressor de rotogravura",
  "Impressor de rótulos",
  "Impressor de segurança",
  "Impressor de selo",
  "Impressor de silkscreen",
  "Impressor de talho doce",
  "Impressor de tipografia",
  "Impressor digital",
  "Impressor flexográfico",
  "Impressor gráfico manual",
  "Impressor letterset",
  "Impressor multilight",
  "Impressor serigráfico",
  "Impressor serigráfico em vidros",
  "Impressor silk screen em vidros",
  "Impressor tampográfico",
  "Impressor tipográfico",
  "Imunizador",
  "Incubador de ovos",
  "Indicador",
  "Infectologista",
  "Influencer",
  "Influenciador digital",
  "Injetor de plástico",
  "Inseminador",
  "Inseminador de animais",
  "Insetologista",
  "Inspetor agrícola",
  "Inspetor de agência bancária",
  "Inspetor de alimentador de linhas de rotulagem",
  "Inspetor de alunos",
  "Inspetor de alunos de escola privada",
  "Inspetor de alunos de escola pública",
  "Inspetor de auditoria",
  "Inspetor de avarias",
  "Inspetor de aviação civil",
  "Inspetor de bilheteria de transportes",
  "Inspetor de bobinas (produção têxtil)",
  "Inspetor de bordo",
  "Inspetor de carga e descarga",
  "Inspetor de carregadoria de embarque rodoviário",
  "Inspetor de centrais privadas de comutação telefônica",
  "Inspetor de chopp",
  "Inspetor de comercialização de produtos",
  "Inspetor de controle dimensional",
  "Inspetor de controle dimensional (caldeiraria e tubulação)",
  "Inspetor de controle dimensional (mecânica)",
  "Inspetor de controle dimensional (montagem de máquinas)",
  "Inspetor de controle dimensional (topografia)",
  "Inspetor de disciplina",
  "Inspetor de dutos",
  "Inspetor de dutos terrestres",
  "Inspetor de ensaios metrológicos",
  "Inspetor de ensaios não destrutivos",
  "Inspetor de ensaios não destrutivos (correntes parasitas)",
  "Inspetor de ensaios não destrutivos (emissão acústica)",
  "Inspetor de ensaios não destrutivos (estanqueidade)",
  "Inspetor de ensaios não destrutivos (líquido penetrante)",
  "Inspetor de ensaios não destrutivos (partícula magnética)",
  "Inspetor de ensaios não destrutivos (radiográfico)",
  "Inspetor de ensaios não destrutivos (termografia)",
  "Inspetor de ensaios não destrutivos (teste por pontos)",
  "Inspetor de ensaios não destrutivos (ultrassom)",
  "Inspetor de ensaios não destrutivos (visual)",
  "Inspetor de equipamentos",
  "Inspetor de equipamentos (estruturas metálicas)",
  "Inspetor de estamparia (produção têxtil)",
  "Inspetor de fabricação",
  "Inspetor de fabricação (acessórios de tubulação)",
  "Inspetor de fabricação (caldeiraria e tubulação)",
  "Inspetor de fabricação (elétrica/instrumentação)",
  "Inspetor de fabricação (mecânica)",
  "Inspetor de fabricação (perfuração e produção de petróleo)",
  "Inspetor de fabricação (tubos flexíveis e umbilicais)",
  "Inspetor de ferramentas",
  "Inspetor de fundição",
  "Inspetor de guarda portuária",
  "Inspetor de guarda-territorial",
  "Inspetor de linha de montagem",
  "Inspetor de linha de produção",
  "Inspetor de linha de transmissão",
  "Inspetor de linhas de cabos aéreos",
  "Inspetor de manutenção",
  "Inspetor de manutenção (elétrica e instrumentação)",
  "Inspetor de manutenção (mecânica)",
  "Inspetor de manutenção de máquinas aquaviárias",
  "Inspetor de manutenção de motores diesel (exceto de veículos automotores)",
  "Inspetor de máquinas e motores",
  "Inspetor de material de embalagem",
  "Inspetor de medição",
  "Inspetor de montagem",
  "Inspetor de obras",
  null,
  "Inspetor de pano",
  "Inspetor de pintura",
  "Inspetor de polícia",
  "Inspetor de polícia rodoviária federal",
  "Inspetor de produção (mineração)",
  "Inspetor de produção de seguros",
  "Inspetor de qualidade",
  "Inspetor de qualidade - nas indústrias",
  "Inspetor de qualidade material",
  "Inspetor de risco",
  "Inspetor de saneamento",
  "Inspetor de segurança",
  "Inspetor de serviços de transportes rodoviários (passageiros e cargas)",
  "Inspetor de sinistros",
  "Inspetor de soldagem",
  "Inspetor de sondagem - na mineração",
  "Inspetor de tecidos crus",
  "Inspetor de terminal",
  "Inspetor de terraplenagem",
  "Inspetor de tráfego rodoviário",
  "Inspetor de transporte de produtos perigosos",
  "Inspetor de transporte rodoviário",
  "Inspetor de via permanente (trilhos)",
  "Inspetor de vigilância",
  "Inspetor dimensional (estruturas metálicas)",
  "Inspetor fiscal",
  "Inspetor geral (comércio de mercadorias a varejo)",
  "Inspetor geral de produção",
  "Inspetor naval",
  "Inspetor penitenciário",
  "Inspetor regional (comércio de mercadorias a varejo)",
  "Inspetor técnico de qualidade",
  "INSPETORES DE ALUNOS E AFINS",
  "INSPETORES E REVISORES DE PRODUÇÃO TÊXTIL",
  "Instalador de águas, esgotos e gás",
  "Instalador de alarme",
  "Instalador de alarmes residenciais",
  "Instalador de antenas de televisão",
  "Instalador de aparelhos telefônicos",
  "Instalador de canos",
  "Instalador de centrais telefônicas",
  "Instalador de cortinas e persianas, portas sanfonadas e boxe",
  "Instalador de equipamentos de áudio",
  "Instalador de equipamentos de comunicação",
  "Instalador de equipamentos de radar",
  "Instalador de estação de rádio",
  "Instalador de estação de tv",
  "Instalador de estações telefônicas",
  "Instalador de isolantes acústicos",
  "Instalador de isolantes térmicos (refrigeração e climatização)",
  "Instalador de isolantes térmicos de caldeira e tubulações",
  "Instalador de linhas elétricas de alta e baixa - tensão (rede aérea e subterrânea)",
  "Instalador de linhas subterrâneas (transmissão de energia elétrica)",
  "Instalador de máquinas",
  "Instalador de máquinas-ferramentas (usinagem de metais)",
  "Instalador de material isolante, a mão (edificações)",
  "Instalador de material isolante, à máquina (edificações)",
  "Instalador de painéis",
  "Instalador de película solar (insulfilm)",
  "Instalador de sistemas eletroeletrônicos de segurança",
  "Instalador de sistemas fotovoltaicos",
  "Instalador de som",
  "Instalador de som e acessórios de veículos",
  "Instalador de telefones",
  "Instalador de tubulações",
  "Instalador de tubulações (aeronaves)",
  "Instalador de tubulações (embarcações)",
  "Instalador de tubulações de gás combustível (produção e distribuição)",
  "Instalador de tubulações de vapor",
  "Instalador de tubulações de vapor (produção e distribuição)",
  "Instalador de vinil adesivo",
  "Instalador e mantenedor de placas fotovoltaicas",
  "Instalador eletricista (tração de veículos)",
  "Instalador fotovoltaico",
  "Instalador hidráulico",
  "Instalador mantenedor de telefonia",
  "Instalador reparador de central",
  null,
  "Instalador reparador de equipamento de força",
  "Instalador-reparador de equipamentos de comutação em telefonia",
  "Instalador-reparador de equipamentos de energia em telefonia",
  "Instalador-reparador de equipamentos de transmissão em telefonia",
  "Instalador-reparador de linhas de comunicação de dados",
  "Instalador-reparador de linhas e aparelhos de telecomunicações",
  "Instalador-reparador de linhas telefônicas aéreas e subterrâneas",
  "Instalador-reparador de linhas telefônicas e telegráficas",
  "Instalador-reparador de rede elétrica",
  "Instalador-reparador de redes e cabos telefônicos",
  "Instalador-reparador de redes telefônicas e de comunicação de dados",
  "INSTALADORES DE EQUIPAMENTOS DE REFRIGERAÇÃO E VENTILAÇÃO",
  "INSTALADORES DE PRODUTOS E ACESSÓRIOS",
  "INSTALADORES E MANTENEDORES DE SISTEMAS ELETROELETRÔNICOS DE SEGURANÇA",
  "INSTALADORES E MANTENEDORES ELETROMECÂNICOS DE ELEVADORES, ESCADAS E PORTAS",
  "Instaladores e reparadores de equipamentos e linhas telefônicas",
  "INSTALADORES E REPARADORES DE LINHAS E CABOS ELÉTRICOS, TELEFÔNICOS E DE COMUNICAÇÃO DE  Família",
  "INSTALADORES-REPARADORES DE  LINHAS E EQUIPAMENTOS DE TELECOMUNICAÇÕES",
  "Instrumentador cirúrgico",
  "Instrumentador cirúrgico (enfermeiro)",
  "Instrumentador em cirurgia",
  "Instrumentadora cirúrgica",
  "Instrumentista de aviões",
  "Instrumentista de laboratório (manutenção)",
  "Instrumentista de precisão",
  "Instrutor agrícola",
  null,
  "Instrutor de animais",
  "Instrutor de aprendizagem agroindustrial e florestal",
  "Instrutor de aprendizagem agropecuária",
  "Instrutor de aprendizagem comercial",
  "Instrutor de aprendizagem e treinamento agropecuário",
  "Instrutor de aprendizagem e treinamento comercial",
  "Instrutor de aprendizagem e treinamento industrial",
  "Instrutor de aprendizagem em comunicação",
  "Instrutor de aprendizagem em informática",
  "Instrutor de aprendizagem em transportes",
  "Instrutor de aprendizagem industrial",
  "Instrutor de auto-escola",
  "Instrutor de banda",
  "Instrutor de curimba",
  "Instrutor de cursos livres",
  "Instrutor de educação profissional industrial",
  "Instrutor de equitação para equoterapia",
  "Instrutor de fanfarra",
  "Instrutor de informática",
  "Instrutor de motoristas",
  "Instrutor de nível médio no ensino profissionalizante",
  "Instrutor de ofícios industriais",
  "Instrutor de pilates",
  "Instrutor de pilotagem (aviação)",
  "Instrutor de pilotagem de aviões",
  "Instrutor de pilotagem de helicópteros",
  "Instrutor de treinamento agropecuário",
  "Instrutor de treinamento comercial",
  "Instrutor de treinamento industrial (formação profissional)",
  "Instrutor de treinamentos industriais",
  "Instrutor de vôo",
  "Instrutor educacional",
  "Instrutor leigo de meditação budista",
  "Instrutor no ensino profissionalizante",
  "INSTRUTORES DE ENSINO PROFISSIONAL",
  "INSTRUTORES E PROFESSORES DE CURSOS LIVRES",
  "Intensivista",
  "Internacionalista",
  "Intérprete",
  "Intérprete comercial",
  "Intérprete de comunicação eletrônica",
  "Intérprete de conferência",
  "Intérprete de libras",
  "Intérprete de língua de sinais",
  "Intérprete educacional",
  "Intérprete simultâneo",
  "Intervencionista",
  "Inventariante florestal",
  "Invernador - na criação de gado bovino - empregador",
  "Invernador de gado",
  "Invernador de gado bovino, suíno, caprino, ovino - conta própria",
  "Inverneiro - na criação de gado bovino - empregador",
  "Invernista - na criação de gado bovino - empregador",
  "Investigador de polícia",
  "Investigador particular",
  "Irmã",
  "Irmão",
  "Isolador refratarista (caldeira e tubulações)",
  "Isolador térmico",
  "Iyakekerê",
  "Iyalorixá",
  "Iyamorô",
  "Iyawo",
  "Izadioncoé",
  "Jacarecultor",
  "Jacazeiro",
  "Jangadeiro (turismo náutico)",
  "Jangadeiro, na pesca de peixes e camarões",
  "Jardineiro",
  "Jardineiro (árvores para ornamentação urbana)",
  "Jateador - no garimpo",
  "Jateador de materiais abrasivos",
  "Jateador de vidro",
  "Jateador de vidro não plano",
  "Joalheiro",
  "Joalheiro (comércio varejista)",
  "Joalheiro (reparações)",
  "Joalheiro - na fabricação - empregador",
  "Joalheiro na confecção de bijuterias e jóias de fantasia",
  "Joalheiro na fabricação",
  "Joalheiro na reparação",
  "Joalheiro ourives na fabricação e reparação",
  "JOALHEIROS E LAPIDADORES DE GEMAS",
  "Jodoísta",
  "Jogador de futebol",
  "Jogador de golfe profissional",
  "Jogador de tênis profissional",
  "Jogador profissional de tênis",
  "Jóquei",
  "Jóquei profissional",
  "Jornaleiro (ambulante)",
  "Jornaleiro (em banca de jornal)",
  "Jornalista",
  "Jornalista exclusive empregador",
  "Jornalista-empregador",
  "Judoca",
  "Juiz (administrativo)",
  "Juiz auditor estadual - justiça militar",
  "Juiz auditor federal - justiça militar",
  "Juiz auxiliar (karatê)",
  "Juiz de alçada",
  "Juiz de direito",
  "Juiz de direito de primeira instância",
  "Juiz de gol (poloaquático)",
  "Juiz de impostos e tributos",
  "Juiz de linha (vôlei)",
  "Juiz do trabalho",
  "Juiz do tribunal eclesiástico",
  "Juiz do tribunal regional do trabalho",
  "Juiz do tribunal regional federal",
  "Juiz dos tribunais militares estaduais",
  "Juiz federal",
  "Juiz federal  de segunda  instância",
  "Juntador - na extração da carnaúba",
  "Jurisconsulto",
  "Jurista",
  "Jushoku",
  "Juteiro - conta própria",
  "Juticultor - conta própria",
  "Kaikyôshi",
  "Kambondo pokó",
  "Kantoku (diretor de missão)",
  "Karateca",
  "Kardexista",
  "Kunhã-karaí",
  "Kyôshi (mestre)",
  "Labirinteira (rendas e bordados)",
  "Labirinteira bordadeira, a  mão.",
  "Laboratorista (ensaios de areia)",
  "Laboratorista (solos e materiais de construção)",
  "Laboratorista - exclusive análises clínicas",
  "Laboratorista de água e controle ambiental",
  "Laboratorista de concreto",
  "Laboratorista de ensaios de areia de fundição",
  "Laboratorista de ensaios eletrônicos",
  "Laboratorista de ensaios mecânicos",
  "Laboratorista de ensaios químicos",
  "Laboratorista de filmes fotográficos, em preto e branco",
  "Laboratorista de materiais de construção",
  "Laboratorista de solos",
  "Laboratorista fotográfico",
  "Laboratorista industrial",
  "Laboratorista metalográfico (soldagem)",
  "Laboratorista químico",
  "Laboratorista têxtil",
  "LABORATORISTAS INDUSTRIAIS AUXILIARES",
  "Laceiro de cabos de aço",
  "Lacrador de embalagens, a  máquina",
  "Ladrilheiro",
  "Lagareiro",
  "Lagosteiro",
  "Lama budista tibetano",
  "Lama tibetano",
  "Laminador de madeira",
  "Laminador de massas alimentícias",
  "Laminador de metais não-ferrosos",
  "Laminador de metais preciosos",
  "Laminador de metais preciosos a  mão",
  "Laminador de metais preciosos, à máquina",
  "Laminador de ouro",
  "Laminador de plástico",
  "Laminador de prata",
  "Laminador gráfico",
  "Lanterneiro de automóveis (reparação)",
  "Lanterninha",
  "Lapidador (jóias)",
  "Lapidador de cristais de ótica",
  "Lapidador de diamantes",
  "Lapidador de pedras preciosas",
  "Lapidador de pedras semi-preciosas",
  "Lapidador de vidros (acabamento)",
  "Lapidador de vidros e cristais",
  "Lapidário",
  "Lapidário de diamantes",
  "Lapidário de pedras preciosas",
  "Lapidário de pedras semi-preciosas",
  "Laqueador de madeira",
  "Laqueador de móveis",
  "Lash designer",
  "Lasteiro - na extração da carnaúba",
  "Latoeiro de veículos (reparação)",
  "Lavadeiro, em geral",
  "Lavador de artefatos de tapeçaria",
  "Lavador de automóveis",
  "Lavador de carros",
  "Lavador de fachadas",
  "Lavador de fibras na indústria têxtil",
  "Lavador de garrafas, vidros e outros utensílios",
  "Lavador de lã",
  "Lavador de ônibus",
  "Lavador de peças",
  "Lavador de pratos",
  "Lavador de roupa a seco",
  "Lavador de roupa hospitalar",
  "Lavador de roupas",
  "Lavador de roupas  a maquina",
  "Lavador de roupas à maquina industrial",
  "Lavador de tapetes",
  "Lavador de veículos",
  "Lavador de vidros",
  "LAVADORES E PASSADORES DE ROUPA, A MÃO",
  "Lavoureiro na produção de arroz",
  "Lavoureiro na produção de cereais de inverno",
  "Lavoureiro na produção de milho e sorgo",
  "Lavrador - na cultura - exclusive conta própria e empregador",
  "Lavrador da cultura de especiarias",
  "Lavrador de cultura permanente - exclusive conta própria e empregador",
  "Lavrador de cultura temporária - exclusive conta própria e empregador",
  "Lavrador na horticultura e na floricultura - exclusive conta própria e empregador",
  "LEGISLADORES",
  "Legista",
  "Leiautista de circuitos impressos",
  "Leigo consagrado",
  "Leiloeiro",
  "LEILOEIROS E AVALIADORES",
  "Leiteiro (comércio ambulante)",
  "Leiteiro - na criação de gado bovino - empregador",
  "Leitor de hidrômetro",
  "Leitor de medidores de água, gás e energia elétrica",
  "Leitor de oráculos",
  "Leiturista",
  "Lenheiro ( comércio varejista)",
  "Letrista",
  "Letrista (música)",
  "Levantador de sal",
  "Lexicógrafo",
  "Lexicólogo",
  "Libretista",
  "Licorista",
  "Líder (construção de fornos)",
  "Líder (indústria de automotores e de material de transporte)",
  "Líder da área florestal",
  "Líder de área de corrida",
  "Líder de bombeiros",
  "Líder de comunidade caiçara",
  "Líder de conservação de vias permanentes",
  "Líder de convertedor",
  "Líder de ferramentaria",
  "Líder de linhas de tesoura de tiras a quente e a  frio",
  "Líder de linhas de tesoura, no acabamento de chapas e metais (tiras a frio)",
  "Líder de lubrificação de vias permanentes",
  "Líder de manutenção mecânica",
  "Líder de produção, no acabamento de chapas e metais",
  "Líder de recebimento de gusa",
  "Líder de recepção",
  "Líder partidário",
  "Líder produtivo de manutenção eletroeletrônica veicular",
  "Líder quilombola",
  "Ligador de linhas telefônicas",
  "Limpador a seco, à máquina",
  "Limpador de aves",
  "Limpador de aviões, bondes, coletivos, ônibus e trens",
  "Limpador de carne",
  "Limpador de fachadas",
  "Limpador de fachadas com jato",
  "Limpador de frutas e legumes para conserva",
  "Limpador de janelas",
  "Limpador de metais",
  "Limpador de pasto - na agropecuária",
  "Limpador de piaçava",
  "Limpador de piscinas",
  "Limpador de roupas a seco, à mão",
  "Limpador de tripas",
  "Limpador de vidros",
  "Lingotador",
  "Lingüiceiros na conservação de alimentos",
  "Lingüista",
  "Lingüista dicionarista",
  "Linotipista",
  "Livreiro (comércio varejista)",
  "Lixador de calçados",
  "Lixador de couros e peles",
  "Lixador de móveis metálicos",
  "Lixador de peças de metal",
  "Lixador de saltos de calçados",
  "Lixador de solas na confecção de calçados",
  "Lixeiro",
  "Locador (serviços de diversões)",
  "Locador de animais para lazer",
  "Locador de barco para lazer",
  "Locador de bicicletas para lazer",
  "Locador de bóia para lazer",
  "Locador de cadeira de praia",
  "Locador de cadeira para festa",
  "Locador de caiaque para lazer",
  "Locador de eletrodomésticos",
  "Locador de móveis",
  "Locador de televisão",
  "Localizador (cobrador)",
  "Localizador externo",
  "Locutor anunciador",
  "Locutor comercial",
  "Locutor de notícias",
  "Locutor de rádio e tv",
  "Locutor de rodeio",
  "Locutor de telejornal",
  "Locutor esportivo",
  "Locutor noticiarista",
  "Locutor publicitário",
  "Locutor-operador",
  "Lojista",
  "Lombador em matadouro",
  "Lombilheiro - empregador",
  "Lubrificador de automóveis",
  "Lubrificador de embarcações",
  "Lubrificador de máquinas",
  "Lubrificador de máquinas de terraplenagem",
  "Lubrificador de veículos automotores (exceto embarcações)",
  "Lubrificador industrial",
  "Lubrificador naval",
  "LUBRIFICADORES",
  "Ludomotricista",
  "Lustrador - engraxate",
  "Lustrador de madeira",
  "Lustrador de móveis",
  "Lustrador de peças de madeira",
  "Lustrador de piso",
  "Lustrador no acabamento de calçados",
  "Lutador de aikidô",
  "Lutador de capoeira",
  "Lutador de fullcontact",
  "Lutador de hapkidô",
  "Lutador de karatê",
  "Lutador de kendô",
  "Lutador de sumô",
  "Lutador de taekwondô",
  "Lutador profissional de boxe",
  "Luthier (confeccionador)",
  "Luthier (restauração de cordas arcadas)",
  "Macaqueiro - na extração de pedras",
  "Maçarandubeiro",
  "Maçariqueiro",
  "Maçariqueiro - na mineração",
  "Maçaroqueiro",
  "Macarroneiro",
  "Macheiro, a  máquina",
  "Macheiro, a mão",
  "Madeireiro (comércio atacadista)",
  "Madeireiro (comércio varejista)",
  "Madeireiro de subsolo - na mineração",
  "Madre superiora",
  "Madrinha de umbanda",
  "Madrinheiro",
  "Mãe crecheira",
  "Mãe social",
  "Mãe substituta",
  "Maestro",
  "Maestro correpetidor",
  "Maestro de banda",
  "Magarefe",
  "MAGAREFES E AFINS",
  "Mágico",
  "MAGISTRADOS",
  "Maître",
  "Maître - no serviço de alimentação",
  "Maître de ballet",
  "Maître de bar",
  "Maître de boate",
  "Maître de hotel",
  "Maître de restaurante",
  "Maître executivo",
  "Major bombeiro militar",
  "Major da aeronáutica",
  "Major da polícia militar",
  "Major do corpo de bombeiros militar",
  "Major do exército",
  "Major-brigadeiro",
  "Malabarista",
  "Maleiro (comércio varejista )",
  "Malteiro (germinação)",
  "Mameto ndenge",
  "Mameto nkisi",
  "Mamucabeira",
  "Mamulengueiro",
  "Mandrilador",
  "Mandrilador cnc",
  "Manequim",
  "Manicure/pedicure",
  "Manicuro",
  "Manilheiro - na fabricação",
  "Manipulador",
  "Manipulador de bonecos",
  "Manipulador de correspondências (correios e telégrafos)",
  "Manipulador de essência e melado (fumo)",
  "Manipulador de máquinas fixas",
  "Manipulador de misturas (vinhos)",
  "Manipulador de moldados (abrasivos)",
  "Manipulador de seção de comprimidos e drágeas",
  "Manipulador em laboratório de farmácia",
  "Manipulador postal",
  "Manobrador",
  "Manobrador de ferrovia",
  "Manobrador de trem",
  "Manobras e reparos",
  "Manobreiro de ferrovia",
  "Manobreiro de trem",
  "Manobrista",
  "Manobrista de ferrovia",
  "Manobrista de trem",
  "Manobrista de veículos pesados sobre rodas",
  "Manoseador",
  "Manteador na conservação de alimentos",
  "Manteigueiro na fabricação de laticínio",
  "Mantenedor de equipamentos de parques de diversões e similares",
  "Mantenedor de sistemas eletroeletrônicos de segurança",
  "Mantenedor operacional de processos químicos e petroquímicos",
  "MANTENEDORES DE EDIFICAÇÕES",
  "Maqueiro de hospital",
  "Maqueiro hospitalar",
  "Maquetista na marcenaria",
  "Maquiador",
  "Maquiador artístico",
  "Maquiador de caracterização",
  "Maquiador de cinema, teatro e tv",
  "Maquiador social",
  "Maquilador",
  "Maquinista de cenário",
  "Maquinista de cinema e vídeo",
  "Maquinista de conicaleira",
  "Maquinista de embarcações",
  "Maquinista de filatório",
  "Maquinista de locomotiva",
  "Maquinista de manobras",
  "Maquinista de máquina fixa",
  "Maquinista de ofsete",
  "Maquinista de perfuradora de subsolo",
  "Maquinista de teatro e espetáculos",
  "Maquinista de trem",
  "Maquinista de trem metropolitano",
  "Maquinista de usina termoelétrica",
  "Maquinista ferroviário",
  "Maquinista foguista",
  "Maraqueiro - no garimpo",
  "Maratonista",
  "Marcador de árvores",
  "Marcador de caixas",
  "Marcador de chapas",
  "Marcador de embalagem",
  "Marcador de fardos",
  "Marcador de peças confeccionadas para bordar",
  "Marcador de preços",
  "Marcador de produtos (siderúrgico e metalúrgico)",
  "Marcador de sola na confecção de calçados",
  "Marcador de toras",
  "Marceneiro",
  "Marceneiro adueleiro",
  "Marceneiro barriqueiro",
  "Marceneiro boteiro",
  "Marceneiro de móveis",
  "Marceneiro modelista",
  "Marceneiro naval",
  "Marceneiro pipeiro",
  "MARCENEIROS E AFINS",
  "Marchante",
  "Marchante (comércio de gado)",
  "Marcheteiro",
  "Marechal",
  "Marechal-do-ar",
  "Margarida",
  "Margeador gráfico de corte e vinco",
  "Marinheiro",
  "Marinheiro auxiliar de convés (marítimo e aquaviario)",
  "Marinheiro auxiliar de máquinas (marítimo e aquaviário)",
  "Marinheiro de convés",
  "Marinheiro de convés (marítimo e fluviário)",
  "Marinheiro de esporte e recreio",
  "Marinheiro de máquinas",
  "Marinheiro fluvial de convés",
  "Marinheiro fluvial de máquinas",
  "Marionetistas",
  "Mariscador",
  "Marisqueiro",
  "Marmorista (construção)",
  "Marmorista - exclusive empregador  em marmoraria",
  "Marmorista - exclusive na extração - empregador",
  "Marmorista - na extração - empregador",
  "Marnoteiro",
  "Marombeiro",
  "Marqueiro - na mineração",
  "Marreteiro (indústria alimentar)",
  "Marreteiro - na extração de pedras",
  "Marreteiro em matadouro",
  "Marroeiro",
  "Marroquineiro",
  "Marteleiro (forjaria)",
  "Marteleiro (metalúrgica)",
  "Marteleiro - na extração de pedras",
  "Marteleiro a carga de fogo",
  "Marteleiro de cutelaria",
  "Marteleteiro",
  "Marteleteiro - na construção civil",
  "Marteleteiro de rocha",
  "Marteleteiro detonador",
  "Massagista",
  "Masseiro (massas alimentícias)",
  "Masseiro (padeiro)",
  "Masseiro de biscoito, macarrão e pão",
  "Massoprevencionista",
  "Massoterapeuta",
  "Mastologista",
  "Matador (magarefe)",
  "Matador de formiga - na cultura",
  "Mateicultor - empregador",
  "Mateiro",
  "Mateiro (inventário de florestas)",
  "Mateiro - guia",
  "Mateiro-guarda florestal",
  "Matemático",
  "Matemático aplicado",
  "Matemático computacional",
  "Matemático industrial",
  "Matizador de couros e peles",
  "Matrizeiro",
  "Matrizeiro - na indústrias gráfica",
  "Matrizeiro de coelho",
  "Mecânico",
  "Mecânico afiador",
  "Mecânico ajustador",
  "Mecânico de aeronaves",
  "Mecânico de afinação de motores diesel (exceto de veículos automotores)",
  "Mecânico de amortecedores",
  "Mecânico de aparelhos de precisão",
  "Mecânico de aparelhos domésticos",
  "Mecânico de ar-condicionado e refrigeração",
  "Mecânico de auto em geral",
  "Mecânico de automóveis e caminhões",
  "Mecânico de automóvel",
  "Mecânico de bancada",
  "Mecânico de bicicletas",
  "Mecânico de bomba injetora (exceto de veículos automotores)",
  "Mecânico de bombas diesel (exceto de veículos automotores)",
  "Mecânico de bombas hidráulicas",
  "Mecânico de caldeiraria pesada",
  "Mecânico de caldeiras",
  "Mecânico de câmbio",
  "Mecânico de campo",
  "Mecânico de chassis",
  "Mecânico de diesel e eletricidade",
  "Mecânico de direção e freios de automóveis",
  "Mecânico de empilhadeira",
  "Mecânico de equipamento pesado",
  "Mecânico de equipamento pneumático",
  "Mecânico de equipamentos industriais",
  "Mecânico de escavadeira",
  "Mecânico de estamparia (máquinas têxteis)",
  "Mecânico de estiva",
  "Mecânico de fogão",
  "Mecânico de freios de automóveis",
  "Mecânico de fundição (manutenção)",
  "Mecânico de gerador",
  "Mecânico de injeção eletrônica",
  "Mecânico de instalação de freios",
  "Mecânico de instalações industriais (manutenção)",
  "Mecânico de instrumentos de precisão",
  "Mecânico de laminação (manutenção)",
  "Mecânico de lavadora e secadora",
  "Mecânico de locomotivas e vagões",
  "Mecânico de malharia (máquinas)",
  "Mecânico de manutenção (embarcações)",
  "Mecânico de manutenção (equipamento de edifícios)",
  "Mecânico de manutenção (máquinas hidráulicas)",
  "Mecânico de manutenção aeronáutica",
  "Mecânico de manutenção de aeronaves, em geral",
  "Mecânico de manutenção de aparelhos de levantamento",
  "Mecânico de manutenção de aparelhos de refrigeração",
  "Mecânico de manutenção de aparelhos esportivos e de ginástica",
  "Mecânico de manutenção de ar condicionado",
  "Mecânico de manutenção de automóveis",
  "Mecânico de manutenção de automóveis, motocicletas e veículos similares",
  "Mecânico de manutenção de aviões",
  "Mecânico de manutenção de bicicletas",
  "Mecânico de manutenção de bicicletas e veículos similares",
  "Mecânico de manutenção de bobinadeira (indústria têxtil)",
  "Mecânico de manutenção de bomba injetora (exceto de veículos automotores)",
  "Mecânico de manutenção de bombas",
  "Mecânico de manutenção de bombas centrífugas",
  "Mecânico de manutenção de bombas de refrigeração e hidráulicas",
  "Mecânico de manutenção de bombas hidráulicas",
  "Mecânico de manutenção de britagem",
  "Mecânico de manutenção de caminhão a diesel",
  "Mecânico de manutenção de cardas",
  "Mecânico de manutenção de compressores de ar",
  "Mecânico de manutenção de empilhadeiras",
  "Mecânico de manutenção de empilhadeiras e outros veículos de cargas leves",
  "Mecânico de manutenção de equipamento de mineração",
  "Mecânico de manutenção de equipamentos hidropneumáticos",
  "Mecânico de manutenção de espuladeira",
  "Mecânico de manutenção de guindaste",
  null,
  "Mecânico de manutenção de helicópteros",
  "Mecânico de manutenção de implementos agrícolas",
  "Mecânico de manutenção de instalações mecânicas de edifícios",
  "Mecânico de manutenção de locomotiva",
  "Mecânico de manutenção de máquina de calçado",
  "Mecânico de manutenção de máquina de curtume",
  "Mecânico de manutenção de máquina de embalagem",
  "Mecânico de manutenção de máquina de rotular",
  "Mecânico de manutenção de máquina industrial",
  "Mecânico de manutenção de máquinas agrícolas",
  "Mecânico de manutenção de máquinas agrícolas (tratores)",
  "Mecânico de manutenção de máquinas cortadoras de grama, roçadeiras, motosserras e similares",
  "Mecânico de manutenção de máquinas de acondicionar",
  "Mecânico de manutenção de máquinas de construção civil",
  "Mecânico de manutenção de máquinas de construção e terraplenagem",
  "Mecânico de manutenção de máquinas de costura",
  "Mecânico de manutenção de máquinas de embalagem",
  "Mecânico de manutenção de máquinas de fundição (usinagem de metais)",
  "Mecânico de manutenção de máquinas gráficas",
  "Mecânico de manutenção de máquinas industriais",
  "Mecânico de manutenção de máquinas maçaroqueiras",
  "Mecânico de manutenção de máquinas operatrizes",
  "Mecânico de manutenção de máquinas operatrizes (lavra de madeira)",
  "Mecânico de manutenção de máquinas têxteis",
  "Mecânico de manutenção de máquinas, em geral",
  "Mecânico de manutenção de máquinas-ferramentas (usinagem de metais)",
  "Mecânico de manutenção de motocicletas",
  "Mecânico de manutenção de motores a álcool",
  "Mecânico de manutenção de motores diesel (exceto de veículos automotores)",
  "Mecânico de manutenção de motores e equipamentos navais",
  "Mecânico de manutenção de ônibus",
  "Mecânico de manutenção de parques de diversões",
  "Mecânico de manutenção de pontes-rolantes",
  "Mecânico de manutenção de redutores",
  "Mecânico de manutenção de refrigerador",
  "Mecânico de manutenção de roçadeiras, motosserras e similares",
  "Mecânico de manutenção de sistema hidráulico de aeronaves (serviços de pista e hangar)",
  "Mecânico de manutenção de teares",
  "Mecânico de manutenção de transmissão",
  "Mecânico de manutenção de tratores",
  "Mecânico de manutenção de turbinas (exceto de aeronaves)",
  "Mecânico de manutenção de turbocompressores",
  "Mecânico de manutenção de veículos ferroviários",
  "Mecânico de manutenção e eletricista",
  "Mecânico de manutenção e instalação de aparelhos de climatização e  refrigeração",
  "Mecânico de manutenção e instalação elétrica",
  "Mecânico de manutenção hidráulica",
  "Mecânico de manutenção mariner",
  "Mecânico de máquina agrícola",
  "Mecânico de máquina de ventilação",
  "Mecânico de máquina para trabalho de minas",
  "Mecânico de máquinas de escrever",
  "Mecânico de máquinas de escrever e calcular",
  "Mecânico de máquinas de fiação",
  "Mecânico de máquinas de pasteurização",
  "Mecânico de máquinas fotográficas",
  "Mecânico de máquinas operatrizes (manutenção)",
  "Mecânico de máquinas pesadas (manutenção)",
  "Mecânico de mineração",
  "Mecânico de montagem de máquinas",
  "Mecânico de motocicletas",
  "Mecânico de motonetas",
  "Mecânico de motor a diesel",
  "Mecânico de motor a gasolina",
  "Mecânico de motor de explosão",
  "Mecânico de motores agrícolas",
  "Mecânico de motores marítimos",
  "Mecânico de motores turboalimentadores",
  "Mecânico de motosserra",
  "Mecânico de penteadeiras (máquinas têxteis)",
  "Mecânico de perfuratrizes",
  "Mecânico de ponte-rolante",
  "Mecânico de prensas",
  "Mecânico de radiadores",
  "Mecânico de recravadeira",
  "Mecânico de refrigeração",
  "Mecânico de refrigeração doméstica",
  "Mecânico de regulagem de motores diesel (exceto de veículos automotores)",
  "Mecânico de rotativa (indústria gráfica)",
  "Mecânico de socorro",
  "Mecânico de suspensão",
  "Mecânico de testes de automotores",
  "Mecânico de turbinas e caldeiras",
  "Mecânico de turbinas exclusive de avião",
  "Mecânico de urdideira e engomadeira",
  "Mecânico de usinagem (manutenção)",
  "Mecânico de veículos",
  "Mecânico de veículos automotores a diesel (exceto tratores)",
  "Mecânico de vôo",
  "Mecânico diesel (exceto de veículos automotores)",
  "Mecânico eletricista de automóveis",
  "Mecânico eletricista de aviões",
  "Mecânico eletricista de diesel (veículos automotores)",
  "Mecânico eletricista de veículos automotores",
  "Mecânico encarregado de manutenção de aeronave",
  "Mecânico ferroviário",
  "Mecânico lubrificador (embarcações)",
  "Mecânico lubrificador de manutenção industrial",
  "Mecânico lubrificador industrial",
  "Mecânico montador",
  "Mecânico montador de aeronaves",
  "Mecânico montador de motores de aeronaves",
  "Mecânico montador de motores de embarcações",
  "Mecânico montador de motores de explosão e diesel",
  "Mecânico montador de motores turboalimentadores",
  "Mecânico montador de turbinas",
  "Mecânico montador de turboalimentadores",
  "Mecânico montador especializado em motores de explosão e diesel",
  "Mecânico naval",
  "Mecânico reparador de bombas hidráulicas",
  "Mecânico reparador de máquinas",
  "Mecânico reparador de vagões",
  "Mecânico turboalimentador",
  "Mecânico-operador (cigarros)",
  "MECÂNICOS DE INSTRUMENTOS DE PRECISÃO",
  "MECÂNICOS DE MANUTENÇÃO AERONÁUTICA",
  "MECÂNICOS DE MANUTENÇÃO DE BICICLETAS E EQUIPAMENTOS ESPORTIVOS E DE GINÁSTICA",
  "MECÂNICOS DE MANUTENÇÃO DE BOMBAS, MOTORES, COMPRESSORES E EQUIPAMENTOS DE",
  "MECÂNICOS DE MANUTENÇÃO DE MÁQUINAS INDUSTRIAIS",
  "MECÂNICOS DE MANUTENÇÃO DE MÁQUINAS PESADAS E EQUIPAMENTOS AGRÍCOLAS",
  "MECÂNICOS DE MANUTENÇÃO DE MOTORES E EQUIPAMENTOS NAVAIS",
  "MECÂNICOS DE MANUTENÇÃO DE VEÍCULOS AUTOMOTORES",
  "MECÂNICOS DE MANUTENÇÃO E INSTALAÇÃO DE APARELHOS DE  CLIMATIZAÇÃO E REFRIGERAÇÃO",
  "MECÂNICOS DE MANUTENÇÃO METROFERROVIÁRIA",
  "Mecânicos de turbinas a vapor e gás",
  "Mecânicos de veículos automotores",
  "MECÂNICOS MONTADORES DE MOTORES E TURBOALIMENTADORES",
  "Mecanógrafo  (mecânico de máquina de escrever)",
  "Mediador de conflitos",
  "Mediador econômico",
  "Mediador extrajudicial",
  "Médico acupunturista",
  "Médico alergista",
  "Médico alergista e imunologista",
  "Médico alopata",
  "Médico anatomopatologista",
  "Médico anestesiologista",
  "Médico anestesista",
  "Médico angiologista",
  "Médico antroposófico",
  "Médico broncoesofagologista",
  "Médico broncoscopista",
  "Médico cancerologista (clínico)",
  "Médico cancerologista cirurgíco",
  "Médico cancerologista pediátrico",
  "Médico cardiologista",
  "Médico cirurgião",
  "Médico cirurgião cardiovascular",
  "Médico cirurgião da mão",
  "Médico cirurgião de cabeça e pescoço",
  "Médico cirurgião do aparelho digestivo",
  "Médico cirurgião geral",
  "Médico cirurgião pediátrico",
  "Médico cirurgião plástico",
  "Médico cirurgião torácico",
  "Médico citopatologista",
  "Médico clínico",
  "Médico clínico geral",
  "Médico coloproctologista",
  "Médico comunitário",
  "Médico da estratégia de saúde da família",
  "Médico de criança",
  "Médico de doenças infecciosas e parasitárias",
  "Médico de família",
  "Médico de família e comunidade",
  "Médico de mulheres",
  "Médico de saúde pública",
  "Médico dermatologista",
  "Médico do coração",
  "Médico do trabalho",
  "Médico do tráfego",
  "Médico em cirurgia vascular",
  "Médico em endoscopia",
  "Médico em hemoterapia",
  "Médico em medicina de tráfego",
  "Médico em medicina hiperbárica",
  "Médico em medicina intensiva",
  "Médico em medicina interna",
  "Médico em medicina laboratorial",
  "Médico em medicina nuclear",
  "Médico em radio-oncologia",
  "Médico em radiologia e diagnóstico por imagem",
  "Médico em radioterapia",
  "Médico endocrinologista",
  "Médico endocrinologista e metabologista",
  "Médico endoscopista",
  "Médico epidemiologista",
  "Médico especialista em clínica médica",
  "Médico especialista em medicina interna",
  "Médico fisiatra",
  "Médico foniatra",
  "Médico gastroenterologista",
  "Médico generalista",
  "Médico geneticista",
  "Médico geriatra",
  "Médico ginecologista",
  "Médico ginecologista e obstetra",
  "Médico hematologista",
  "Médico hemoterapeuta",
  "Médico higienista",
  "Médico hiperbaricista",
  "Médico hiperbárico",
  "Médico hiperbarista",
  "Médico homeopata",
  "Médico imunologista",
  "Médico infectologista",
  "Médico internista",
  "Medico intervencionista",
  "Médico laboratorista",
  "Médico legista",
  "Médico mastologista",
  "Médico metabolista",
  "Médico militar",
  "Médico nefrologista",
  "Médico neurocirurgião",
  "Médico neurocirurgião pediátrico",
  "Médico neurofisiologista",
  "Médico neurofisiologista clínico",
  "Médico neurologista",
  "Médico neuropediatra",
  "Médico nuclear",
  "Médico nutrologista",
  "Médico nutrólogo",
  "Médico obstetra",
  "Médico oftalmologista",
  "Médico oncologista cirúrgico",
  "Médico oncologista clínico",
  "Médico ortopedista",
  "Médico ortopedista e traumatologista",
  "Médico otorrinolaringologista",
  "Médico patologista",
  "Médico patologista clínico",
  "Médico patologista clínico / medicina laboratorial",
  "Médico pediatra",
  "Médico pneumologista",
  "Médico pneumotisiologista",
  "Médico proctologista",
  "Médico psicanalista",
  "Médico psicoterapeuta",
  "Médico psiquiatra",
  "Médico radiologista",
  "Médico radiologista intervencionista",
  "Médico radioterapeuta",
  "Médico reumatologista",
  "Médico sanitarista",
  "Médico traumatologista",
  "Médico urologista",
  "Médico veterinário",
  "Médico veterinário de saúde pública",
  "Médico veterinário sanitarista",
  "MÉDICOS CLÍNICOS",
  "MÉDICOS EM ESPECIALIDADES CIRÚRGICAS",
  "MÉDICOS EM MEDICINA DIAGNÓSTICA E TERAPÊUTICA",
  "Medidor de temperatura corporal",
  "Meeiro - na agropecuária - exclusive conta própria e empregador",
  "Meeiro na agropecuária - conta própria",
  "Meeiro polivalente",
  "Meia-colher",
  "Meia-praça (garimpo)",
  "Meio campista no futebol",
  "Meio oficial de mecânico de refrigeração",
  "Meio oficial mecânico de ar condicionado",
  "Mejitó",
  "Melaceador (cultura do fumo)",
  "Meleiro",
  "Membro de liderança quilombola",
  "Membro superior do poder executivo",
  "MEMBROS DO MINISTÉRIO PÚBLICO",
  "Memorialista de ficção",
  "Memorialista de não-ficção",
  "Mensageira",
  "Mensageiro",
  "Mensageiro (correios e telégrafos)",
  "Mensageiro externo",
  "Mensageiro interno",
  "Meôncia",
  "Merceeiro",
  "Mercerizador de fios e tecidos",
  "Merendeiro",
  "Meretriz",
  "Mergulhador",
  "Mergulhador - pescador de lagosta",
  "Mergulhador profissional (raso e profundo)",
  "Messalina",
  "Mestre (afiador de ferramentas)",
  "Mestre (construção civil)",
  "Mestre (construção naval)",
  "Mestre (fabricação de artigos de ourivesaria e joalheria)",
  "Mestre (indústria de automotores e material de transportes)",
  "Mestre (indústria de borracha e plástico)",
  "Mestre (indústria de calçados e artefatos de couro)",
  "Mestre (indústria de celulose, papel e papelão)",
  "Mestre (indústria de madeira e mobiliário)",
  "Mestre (indústria de máquinas e outros equipamentos mecânicos)",
  "Mestre (indústria petroquímica e carboquímica)",
  "Mestre (indústria têxtil e de confecções)",
  "Mestre (lapidação de pedras preciosas)",
  "Mestre acabador de produtos extrusados",
  "Mestre caldeireiro",
  "Mestre carpinteiro",
  "Mestre cervejeiro",
  "Mestre chocolateiro",
  "Mestre da indústria de bebidas",
  "Mestre da indústria de fumo",
  "Mestre da indústria de material elétrico e eletrônico",
  "Mestre da indústria de minerais não metálicos (exceto os derivados de petróleo e carvão)",
  "Mestre da indústria de produtos alimentícios",
  "Mestre das artes gráficas (indústria editorial e gráfica)",
  "Mestre de acabamento de fios",
  "Mestre de acabamento de peças (plástico e borracha)",
  "Mestre de acabamento em confecção",
  "Mestre de aciaria",
  "Mestre de afiação (ferramentas)",
  "Mestre de ajustagem e usinagem",
  "Mestre de alto-forno",
  "Mestre de alvejamento de tecidos",
  "Mestre de anodização",
  "Mestre de banda",
  "Mestre de bateria",
  "Mestre de bobinadeira têxtil",
  "Mestre de cabotagem",
  "Mestre de caldeiraria",
  "Mestre de cerimonias",
  "Mestre de cerzideira",
  "Mestre de conservas",
  "Mestre de construção civil",
  "Mestre de construção de ferramentas e dispositivos",
  "Mestre de construção de fornos",
  "Mestre de construção de matrizes",
  "Mestre de correção de ferramentas",
  "Mestre de cromação e lapidação de anéis",
  "Mestre de engarrafamento (bebidas)",
  "Mestre de espularia",
  "Mestre de estampagem",
  "Mestre de estanhamento",
  "Mestre de fabricação e montagem de caixas (papelão)",
  "Mestre de ferramentaria",
  "Mestre de ferramentas",
  "Mestre de fiação",
  "Mestre de fiação de algodão",
  "Mestre de fiação de juta",
  "Mestre de fiação de lã",
  "Mestre de fiação de linho",
  "Mestre de fiação de tecelagem",
  "Mestre de fiação e tecelagem de rami",
  "Mestre de forjaria",
  "Mestre de forno de resistência",
  "Mestre de forno elétrico",
  "Mestre de forno fundição",
  "Mestre de fotogravuras",
  "Mestre de fotolito",
  "Mestre de fundição",
  "Mestre de fundição de alumínio",
  "Mestre de fundição de materiais",
  "Mestre de galvanização",
  "Mestre de galvanoplastia",
  "Mestre de gravação (indústria gráfica)",
  "Mestre de indústria têxtil",
  "Mestre de inspeção de pinturas (tratamento de superfícies)",
  "Mestre de instalações mecânicas de edifícios",
  "Mestre de laminação",
  "Mestre de lingotamento",
  "Mestre de lingoteiras",
  "Mestre de linha de montagem (indústria automobilística)",
  "Mestre de linha férrea",
  "Mestre de linhas (ferrovias)",
  "Mestre de macharia",
  "Mestre de malharia",
  "Mestre de manutenção de equipamento de solda",
  "Mestre de manutenção de máquinas de cardas",
  "Mestre de manutenção de máquinas de estampar tecidos",
  "Mestre de manutenção de máquinas de fiação",
  "Mestre de manutenção de máquinas de sistemas operacionais",
  "Mestre de manutenção de máquinas operatrizes",
  "Mestre de manutenção de obras civis",
  "Mestre de manutenção de prédios",
  "Mestre de manutenção de tecelagem",
  "Mestre de manutenção elétrica",
  "Mestre de manutenção elétrica (alta tensão)",
  "Mestre de manutenção elétrica e equipamentos",
  "Mestre de manutenção eletromecânica",
  "Mestre de manutenção eletromecânica de equipamentos",
  "Mestre de manutenção eletrônica",
  "Mestre de manutenção industrial de máquinas",
  "Mestre de manutenção mecânica de sistemas operacionais",
  "Mestre de massas alimentícias",
  "Mestre de molde manual",
  "Mestre de montagem de motores elétricos",
  "Mestre de niquelagem",
  "Mestre de obras",
  "Mestre de obras civis",
  "Mestre de pequena cabotagem",
  "Mestre de pintura (tratamento de superfícies)",
  "Mestre de pintura galvanoplástica",
  "Mestre de polimento e banho (metais)",
  "Mestre de preparação de fumo",
  "Mestre de preparação de tear",
  "Mestre de preparação de tecelagem",
  "Mestre de preparação e revisão de bordados",
  "Mestre de produção (indústria de máquinas e outros equipamentos mecânicos)",
  "Mestre de produção (indústria gráfica)",
  "Mestre de produção de fundição de alumínio",
  "Mestre de produção farmacêutica",
  "Mestre de produção química",
  "Mestre de retorcedeira",
  "Mestre de seção de caldeiraria",
  "Mestre de seção de caldeiraria leve",
  "Mestre de seção de caldeiraria pesada",
  "Mestre de seção de forjaria (fornos)",
  "Mestre de seção de fundição",
  "Mestre de seção de usinagem",
  "Mestre de serviços gráficos",
  "Mestre de siderurgia",
  "Mestre de solda e corte",
  "Mestre de soldagem",
  "Mestre de supervisão de linhas (ferrovias)",
  "Mestre de tapeçaria",
  "Mestre de tecelagem",
  "Mestre de tecelagem de juta",
  "Mestre de tecelagem de malhas",
  "Mestre de tecelagem de máquina circular",
  "Mestre de tecelagem de rendas",
  "Mestre de tecelagem de telas",
  "Mestre de texturização de fios",
  "Mestre de tingimento (indústria têxtil e de confecções)",
  "Mestre de torção de fios",
  "Mestre de trefilação (metal)",
  "Mestre de trefilação de metais",
  "Mestre de urdimento",
  "Mestre de usinagem",
  "Mestre de usinagem e montagem",
  "Mestre de zincagem",
  "Mestre doceiro",
  "Mestre e contramestre de manutenção de serviço de esgoto",
  "Mestre e contramestre de produção e distribuição de água",
  "Mestre eletricista de bobinas estacionárias",
  "Mestre eletricista de manutenção",
  "Mestre eletricista enrolador de bobinas",
  "Mestre em artigos de ourivesaria e joalheria",
  "Mestre ferreiro",
  "Mestre fluvial",
  "Mestre fundidor a pressão",
  "Mestre gráfico",
  "Mestre gravador de clichê",
  "Mestre gravador têxtil",
  "Mestre impressor",
  "Mestre impressor calcográfico",
  "Mestre impressor de off-set",
  "Mestre litográfico",
  "Mestre mecânico de conicaleira",
  "Mestre mecânico de extrusão de alumínio",
  "Mestre niquelador",
  "Mestre padeiro",
  "Mestre sapateiro",
  "Mestre serralheiro",
  "Mestre serralheiro de alumínio",
  "Mestre serralheiro de estamparia",
  "Mestre tipógrafo",
  "Mestre vidreiro",
  "Metabolista",
  "Metabologista",
  "Metalizador (banho quente)",
  "Metalizador a pistola",
  "Metalógrafo (técnico)",
  "Meteorologista",
  "Metrologista",
  "Metrologista auxiliar",
  "Metrologista de calibrações",
  "Metrologista de ensaios",
  "Metropolita",
  "Michê",
  "Microbiologista",
  "Microfonista",
  "Micropigmentador",
  "Mímico",
  "Mineiro",
  "Minerador",
  "Minerador - no garimpo",
  "Minhocultor",
  "Minhoqueiro",
  "Minhoqueiro - exclusive conta própria e empregador",
  "Miniaturista (artesão de vidros)",
  "Ministro (diplomacia)",
  "Ministro da eucaristia",
  "Ministro das ezéquias",
  "Ministro de culto religioso",
  "Ministro de estado",
  "Ministro do  superior tribunal do trabalho",
  "Ministro do  superior tribunal militar",
  "Ministro do superior tribunal de justiça",
  "Ministro do supremo tribunal federal",
  "MINISTROS DE CULTO, MISSIONÁRIOS, TEÓLOGOS E PROFISSIONAIS ASSEMELHADOS",
  "Missionário",
  "Missionário leigo",
  "Missionário religioso",
  "Missionário sacerdote",
  "Misturador de areias - em fundição",
  "Misturador de café",
  "Misturador de chá ou mate",
  "Misturador de essência (fumo)",
  "Misturador de fios de fibras de vidro",
  "Misturador de massas alimentícias",
  "Misturador de tintas",
  "Misturador de vinagre",
  "Misturador de vinhos",
  "Mitilicutor",
  "Miudeiro em matadouro",
  "Mixador",
  "Moageiro",
  "Mobilizador de recursos",
  "Moço de convés",
  "Moço de convés (marítimo e fluviário)",
  "Moço de máquinas",
  "Moço de máquinas (marítimo e fluviário)",
  "Mocotozeiro em matadouro",
  "Modador-prensista (borracha)",
  "Modelador ceramista",
  "Modelador de chapéus",
  "Modelador de ferramentaria",
  "Modelador de fundição (madeira)",
  "Modelador de madeira",
  "Modelador de metais (fundição)",
  "Modelador de pneumáticos",
  "Modelador de porcelana",
  "Modelador em cerâmica",
  "Modelista",
  "Modelista de calçados",
  "Modelista de madeira",
  "Modelista de roupas",
  "Modelista em cerâmica",
  'Modelo "fashion"',
  "Modelo artístico",
  "Modelo comercial",
  "Modelo de detalhes",
  "Modelo de editorial de moda",
  "Modelo de modas",
  "Modelo de passarela",
  "Modelo fotográfico",
  "Modelo fotográfico de nu artístico",
  "Modelo fotográfico de workshop",
  "Modelo publicitário",
  "Modelo vivo",
  "MODELOS",
  "Moedor de café",
  "Moedor de condimento",
  "Moedor de especiarias",
  "Moedor de farinha",
  "Moedor de sal",
  "Moendeiro",
  "Moendeiro (farinheiro)",
  "Moezeiro - na exploração do babaçu",
  "Moldador (vidros)",
  "Moldador de abrasivos na fabricação de cerâmica, vidro e porcelana",
  "Moldador de bloco - na fundição",
  "Moldador de borracha",
  "Moldador de borracha por compressão",
  "Moldador de cerâmica",
  "Moldador de chapéus de palha",
  "Moldador de corpos de prova em usinas de concreto",
  "Moldador de fundição (metais)",
  "Moldador de lentes",
  "Moldador de machos (manual)",
  "Moldador de metal",
  "Moldador de parafinas",
  "Moldador de plástico",
  "Moldador de plástico por compressão",
  "Moldador de plástico por injeção",
  "Moldador de roupas",
  "Moldador em areia",
  "Moldador em barbotina",
  "Moldador em cerâmica",
  "Moldador macheiro (manual)",
  "Moldador prensista (borracha)",
  "Moldador, a  mão",
  "Moldador, a  máquina",
  "Moldeador de queijo",
  "Moleiro (tratamentos químicos e afins)",
  "Moleiro de cereais (exceto arroz)",
  "Moleiro de especiarias",
  "Moleiro de minérios",
  "Monge",
  "Monge budista",
  "Monge oficial responsável por templo budista (Jushoku)",
  "Monitor agrícola",
  "Monitor de acampamento",
  "Monitor de acantonamento",
  "Monitor de alunos",
  "Monitor de apoio ao teleatendimento",
  "Monitor de braile",
  "Monitor de cobrança",
  "Monitor de dependente químico",
  "Monitor de entretenimento",
  "Monitor de esportes e lazer",
  "Monitor de nível médio no ensino profissionalizante",
  "Monitor de prevenção de perdas",
  "Monitor de recreação",
  "Monitor de ressocialização prisional",
  "Monitor de sistemas eletrônicos de segurança externo",
  "Monitor de sistemas eletrônicos de segurança interno",
  "Monitor de teleatendimento",
  "Monitor de transporte escolar",
  "Monitor disciplinar prisional",
  "Monitor externo de alarmes",
  "Monitor infantil",
  "Monitor interno de alarmes",
  "Monotipista",
  "Monsenhor",
  "Montador",
  "Montador cinematográfico",
  "Montador de acessórios",
  "Montador de andaimes (cenários)",
  "Montador de andaimes (edificações)",
  "Montador de aparelhos elétricos",
  "Montador de aparelhos eletroeletrônicos",
  "Montador de aparelhos eletrônicos",
  "Montador de arte final",
  "Montador de artefatos de couro (exceto roupas e calçados)",
  "Montador de artefatos de madeira",
  "Montador de automóveis",
  "Montador de bicicletas",
  "Montador de bobinas",
  "Montador de brinquedos de pano",
  "Montador de caixa de papelão",
  "Montador de caixas de madeira",
  "Montador de caixotes de madeira",
  "Montador de calçados",
  "Montador de calçados (parte superior)",
  "Montador de caldeira",
  "Montador de capas trabalhadas",
  "Montador de carroceria",
  "Montador de centrais elétricas",
  "Montador de centrais telefônicas",
  "Montador de chassi",
  "Montador de clichês",
  "Montador de clichês sobre suportes",
  "Montador de comandos e sinalização",
  "Montador de computadores e equipamentos auxiliares",
  "Montador de dínamos",
  "Montador de eletrodomésticos",
  "Montador de elevadores e similares",
  "Montador de embalagens",
  "Montador de engradados de madeira",
  "Montador de equipamento de escritório",
  "Montador de equipamento de levantamento",
  "Montador de equipamentos de levantamento e movimentação",
  "Montador de equipamentos de radar",
  "Montador de equipamentos de telecomunicações",
  "Montador de equipamentos elétricos",
  "Montador de equipamentos elétricos (aparelhos eletrodomésticos)",
  "Montador de equipamentos elétricos (centrais elétricas)",
  "Montador de equipamentos elétricos (elevadores e equipamentos similares)",
  "Montador de equipamentos elétricos (instrumentos de medição)",
  "Montador de equipamentos elétricos (motores e dínamos)",
  "Montador de equipamentos elétricos (transformadores)",
  "Montador de equipamentos eletrônicos",
  "Montador de equipamentos eletrônicos (aparelhos médicos)",
  "Montador de equipamentos eletrônicos (computadores e equipamentos auxiliares)",
  "Montador de equipamentos eletrônicos (estação de rádio, tv e equipamentos de radar)",
  "Montador de equipamentos eletrônicos (instalações de sinalização)",
  "Montador de equipamentos eletrônicos (máquinas industriais)",
  "Montador de equipamentos eletrônicos (rádio e tv )",
  "Montador de esquadrias de madeira",
  "Montador de estruturas de aço",
  "Montador de estruturas de aeronaves",
  "Montador de estruturas metálicas",
  "Montador de estruturas metálicas de embarcações",
  "Montador de estruturas navais",
  "Montador de fechaduras",
  "Montador de filmes",
  "Montador de filmes de cinema",
  "Montador de fotocomposição",
  "Montador de fotolito (analógico e digital)",
  "Montador de fotolito em cores",
  "Montador de instalação de calefação, ventilação e refrigeração",
  "Montador de instrumentos  elétricos de medição",
  "Montador de instrumentos de corda",
  "Montador de instrumentos de óptica",
  "Montador de instrumentos de precisão",
  "Montador de lâminas",
  "Montador de linha de transmissão e rede de distribuição",
  "Montador de máquina de escrever",
  "Montador de máquinas",
  "Montador de máquinas (montagem em série)",
  "Montador de máquinas agrícolas",
  "Montador de máquinas de extração e beneficiamento de minérios",
  "Montador de máquinas de fiação",
  "Montador de máquinas de minas e pedreiras",
  "Montador de máquinas de terraplenagem",
  "Montador de máquinas gráficas",
  "Montador de máquinas operatrizes para madeira",
  "Montador de máquinas têxteis",
  "Montador de máquinas, motores e acessórios (montagem em série)",
  "Montador de máquinas-ferramentas (usinagem de metais)",
  "Montador de mármore",
  "Montador de motores (grupo motopropulsor-gmp)",
  "Montador de motores a diesel",
  "Montador de motores a explosão",
  "Montador de motores a gasolina",
  "Montador de móveis de madeira",
  "Montador de móveis e artefatos de madeira",
  "Montador de paletes",
  "Montador de pneus",
  "Montador de pontes rolantes",
  "Montador de punho de rede",
  "Montador de saltos de calçados",
  "Montador de seleção de cores",
  "Montador de sistemas de combustível de aeronaves",
  "Montador de sistemas eletroeletrônicos de segurança",
  "Montador de tapetes",
  "Montador de tonéis",
  "Montador de transformadores",
  "Montador de turbinas",
  "Montador de veículos (linha de montagem)",
  "Montador de veículos (reparação)",
  "Montador de veículos automotores (linha de montagem)",
  "Montador de vidros",
  "Montador gráfico de corte e vinco",
  "Montador instalador de acessórios",
  "Montador mecânico (máquinas industriais)",
  "Montador mecânico de máquinas agrícolas",
  "Montador mecânico de máquinas de terraplenagem",
  "Montador multifuncional em máquinas agrícolas",
  "Montador naval",
  "Montador naval (estruturas)",
  "Montador soldador",
  "Montador, a mão",
  "MONTADORES DE APARELHOS DE TELECOMUNICAÇÕES",
  "MONTADORES DE EQUIPAMENTOS ELETROELETRÔNICOS",
  "MONTADORES DE ESTRUTURAS DE CONCRETO ARMADO",
  "MONTADORES DE MÁQUINAS INDUSTRIAIS",
  "MONTADORES DE MÁQUINAS PESADAS E EQUIPAMENTOS AGRÍCOLAS",
  "MONTADORES DE MÁQUINAS, APARELHOS E ACESSÓRIOS EM LINHAS DE MONTAGEM",
  "MONTADORES DE MÓVEIS E ARTEFATOS DE MADEIRA",
  "MONTADORES DE SISTEMAS E ESTRUTURAS DE AERONAVES",
  "MONTADORES DE VEÍCULOS AUTOMOTORES (LINHA DE MONTAGEM)",
  "Mordomo de hotelaria",
  "Mordomo de residência",
  "MORDOMOS E GOVERNANTAS",
  "Morubixaba",
  "Mosaísta",
  "Mosoyoyó",
  "MOTOCICLISTAS E CICLISTAS DE ENTREGAS RÁPIDAS",
  "Motofretista",
  "Motorista  no serviço doméstico",
  "Motorista auxiliar",
  "Motorista auxiliar de tráfego",
  "Motorista carreteiro",
  "Motorista de ambulância",
  "Motorista de automóveis",
  "Motorista de basculante",
  "Motorista de caminhão",
  "Motorista de caminhão (entrega de bebidas)",
  "Motorista de caminhão (rotas regionais e internacionais)",
  "Motorista de caminhão leve",
  "Motorista de caminhão-basculante",
  "Motorista de caminhão-betoneira",
  "Motorista de caminhão-guincho leve",
  "Motorista de caminhão-guincho médio",
  "Motorista de caminhão-guincho pesado",
  "Motorista de caminhão-guincho pesado com munk",
  "Motorista de caminhão-guindaste",
  "Motorista de caminhão-pipa",
  "Motorista de caminhão-tanque",
  "Motorista de carga a frete",
  "Motorista de carro de passeio",
  "Motorista de carro forte",
  "Motorista de empilhadeira",
  "Motorista de furgão",
  "Motorista de furgão ou veículo similar",
  "Motorista de kombi",
  "Motorista de ônibus rodoviário",
  "Motorista de ônibus urbano",
  "Motorista de perua",
  "Motorista de praça",
  "Motorista de táxi",
  "Motorista de trólebus",
  "Motorista entregador",
  "Motorista manipulador",
  "Motorista operacional de guincho",
  "Motorista operador de betoneira",
  "Motorista operador de bomba de concreto",
  "Motorista operador de caminhão-betoneira",
  "Motorista particular",
  "Motorista segurança",
  "Motorista vigilante",
  "MOTORISTAS DE ÔNIBUS URBANOS, METROPOLITANOS E RODOVIÁRIOS",
  "MOTORISTAS DE VEÍCULOS DE CARGAS EM GERAL",
  "MOTORISTAS DE VEÍCULOS DE PEQUENO E MÉDIO PORTE",
  "Motorneiro",
  "Mototaxista",
  "Moveleiro - exclusive empregador",
  "Muézin",
  "Mufti",
  "Muladeiro",
  "Muladeiro (comércio varejista)",
  "Mulandeiro",
  "Mulher da vida",
  "Museólogo",
  "Músico arranjador",
  "Músico intérprete cantor",
  "Músico intérprete cantor erudito",
  "Músico intérprete cantor popular",
  "Músico intérprete instrumentista",
  "Músico intérprete instrumentista clássico",
  "Músico intérprete instrumentista erudito",
  "Músico intérprete instrumentista popular",
  "Músico regente",
  "Musicólogo",
  "MÚSICOS COMPOSITORES, ARRANJADORES, REGENTES E MUSICÓLOGOS",
  "MÚSICOS INTÉRPRETES",
  "Musicoterapeuta",
  "Muzenza",
  "Nail designer",
  "Narrador esportivo",
  "Naturólogo",
  "Naturopata",
  "Nefrologista",
  "Negociante (comércio atacadista )",
  "Negociante (comércio varejista - empregador)",
  "Negociante (comércio varejista)",
  "Neonatologista",
  "Neurocirurgião",
  "Neurocirurgião pediátrico",
  "Neurofisiologista clínico",
  "Neurologista",
  "Neuropediatra",
  "Neuropsicólogo",
  "Neuropsicopedagogo clinico",
  "Neuropsicopedagogo institucional",
  "Neuropsiquiatra",
  "Nhanderú arandú",
  "Niquelador",
  "Nisosan",
  "Nochê",
  "Normalizador de metais e de compósitos",
  "Notário",
  "Notificador",
  "Novelista (escritor)",
  "Noviço",
  "Numerólogo",
  "Numismata",
  "Nutricionista",
  "Nutricionista (saúde pública)",
  "NUTRICIONISTAS",
  "Nutrologista",
  "Obá",
  "Oboosan",
  "Obreiro bíblico",
  "Observador (futebol)",
  "Obstetra",
  "Obstetriz",
  "Oceanógrafo",
  "Oceanólogo",
  "Oculista",
  "Odontologia ocupacional",
  "Odontologista",
  "Odontologista social",
  "Odontólogo",
  "Odontólogo (cirurgia e traumatologia bucomaxilofacial)",
  "Odontólogo de saúde coletiva",
  "Odontólogo de saúde pública",
  "Odontólogo protesista",
  "Odontólogo-endodontista",
  "Odontopediatra",
  "Odontoradiologista",
  "Office-boy",
  "Office-girl",
  "OFICIAIS DAS FORÇAS ARMADAS",
  "OFICIAIS DE CONVÉS E AFINS",
  "OFICIAIS DE MÁQUINAS DA MARINHA MERCANTE",
  "OFICIAIS GENERAIS DAS FORÇAS ARMADAS",
  "OFICIAIS INTERMEDIÁRIOS DO CORPO DE BOMBEIROS MILITAR",
  "OFICIAIS SUPERIORES DA POLÍCIA MILITAR",
  "OFICIAIS SUPERIORES DO CORPO DE BOMBEIROS MILITAR",
  "Oficial carpinteiro",
  "Oficial da aeronáutica",
  "Oficial da marinha",
  "Oficial de arbitragem (futebol de salão)",
  "Oficial de arbitragem (poloaquático)",
  "Oficial de inteligência",
  "Oficial de justiça",
  "Oficial de manutenção",
  "Oficial de manutenção civil",
  "Oficial de manutenção predial",
  "Oficial de mesa (basquete)",
  "Oficial de mesa (judô)",
  "Oficial de obras - sepultador",
  "Oficial de proteção de dados pessoais (dpo)",
  "Oficial de quadra (basquete)",
  "Oficial de quarto de navegação da marinha mercante",
  "Oficial de registro",
  "Oficial de registro de contratos marítimos",
  "Oficial de serra",
  "Oficial de serviços diversos na manutenção de edificações",
  "Oficial de serviços gerais na manutenção de edificações",
  "Oficial do exército",
  "Oficial do registro civil de pessoas jurídicas",
  "Oficial do registro civil de pessoas naturais",
  "Oficial do registro de distribuições",
  "Oficial do registro de imóveis",
  "Oficial do registro de títulos e documentos",
  "Oficial encarregado de painel de advertência",
  "Oficial general da aeronáutica",
  "Oficial general da marinha",
  "Oficial general do exército",
  "Oficial judiciário",
  "Oficial legislativo",
  "Oficial maior",
  "Oficial mecânico de veículos",
  "Oficial subalterno do corpo de bombeiros militar",
  "Oficial superior de máquinas da marinha mercante",
  "Oficial técnico de inteligência",
  "Ofiologista",
  "Oftalmologista",
  "Oleiro (fabricação de telhas)",
  "Oleiro (fabricação de tijolos)",
  "Oleiro em torno de pedal",
  "Olericultor",
  "Olericultor de frutos e sementes",
  "Olericultor de legumes",
  "Olericultor de talos, folhas e flores",
  "Olorixá",
  "Ombudsman",
  "Oncologista (clínico)",
  "Oncologista cirúrgico",
  "Opacador",
  "Operador  de seccionadora",
  "Operador auxiliar de destilação",
  "Operador batedor de fibras",
  "Operador bilíngüe (telefonia)",
  "Operador braçal do refino de sal",
  "Operador britador",
  "Operador cinematográfico",
  "Operador de  mercadorias e derivativos",
  "Operador de abastecimento de combustível de aeronave",
  "Operador de abate em matadouro",
  "Operador de abertura (fiação)",
  "Operador de abridor de fibras",
  "Operador de acabamento (fundição)",
  "Operador de acabamento (indústria gráfica)",
  "Operador de acabamento de peças fundidas",
  "Operador de aciaria (basculamento de convertedor)",
  "Operador de aciaria (dessulfuração de gusa)",
  "Operador de aciaria (recebimento de gusa)",
  "Operador de adegas",
  "Operador de adubadeira",
  "Operador de aeronaves não tripuladas",
  "Operador de aeronaves pilotadas remotamente",
  "Operador de alambique de funcionamento contínuo (produtos químicos, exceto petróleo)",
  "Operador de alto-forno",
  "Operador de aparelho de flotação",
  "Operador de aparelho de precipitação (minas de ouro ou prata)",
  "Operador de aparelho de reação e conversão (produtos químicos, exceto petróleo)",
  "Operador de aparelho de sonda por rotação (poço de petróleo)",
  "Operador de aparelhos de preparação",
  "Operador de área de corrida",
  "Operador de atendimento aeroviário",
  "Operador de atendimento receptivo (telemarketing)",
  "Operador de atomizador",
  "Operador de áudio",
  "Operador de autoclave (conservação de alimentos)",
  "Operador de automotriz",
  "Operador de balança (concreto)",
  "Operador de balanças rodoviárias",
  "Operador de balancim",
  "Operador de balancim na confecção de calçados",
  "Operador de balancim no corte de couro",
  "Operador de bambury",
  "Operador de banho de solda",
  "Operador de banho metálico de vidro por flutuação",
  "Operador de banhos (níquel, zinco, cromoprata, ouro)",
  "Operador de basculamento de convertedor",
  "Operador de bate-estacas",
  "Operador de batedeira na fabricação de laticínio",
  "Operador de batedor de fibras",
  "Operador de bateria de gás de hulha",
  "Operador de beneficiamento de minérios",
  "Operador de betoneira",
  "Operador de binadeira",
  "Operador de bobinadeira",
  "Operador de bobinadeira de tiras a quente, no acabamento de chapas e metais",
  "Operador de bolsa - pregão",
  "Operador de bolsa de mercadorias e futuros",
  "Operador de bomba (refinação de petróleo)",
  "Operador de bomba de concreto",
  "Operador de bombas (estação de bombeamento)",
  "Operador de bombas no tratamento de água e efluentes",
  "Operador de bonde",
  "Operador de branqueador de pasta para fabricação de papel",
  "Operador de britadeira (tratamentos químicos e afins)",
  "Operador de britador de coque",
  "Operador de britador de mandíbulas",
  "Operador de cabine cinematográfica",
  "Operador de cabine de laminação (fio-máquina)",
  "Operador de cabos",
  "Operador de cad (copista)",
  "Operador de caixa",
  "Operador de caixa lotérico",
  "Operador de calandra (química, petroquímica e afins)",
  "Operador de calandra (siderurgia)",
  "Operador de calandras (tecidos)",
  "Operador de calcinação (tratamento químico e afins)",
  "Operador de caldeira",
  "Operador de caldeira a vapor",
  "Operador de caleadeira na refinação de açúcar",
  "Operador de câmaras frias",
  "Operador de câmaras frigoríficas",
  "Operador de câmbio/comércio exterior",
  "Operador de câmera",
  "Operador de câmera de televisão",
  "Operador de câmera de vídeo",
  "Operador de caminhão (minas e pedreiras)",
  "Operador de caminhão fora de estrada",
  "Operador de carboquímico",
  "Operador de cardas",
  "Operador de cardas de chapas grossas",
  "Operador de carregadeira",
  "Operador de carregamento de alto-forno",
  "Operador de carrinho (travelling)",
  "Operador de carro de apagamento e coque",
  "Operador de carro de aparas",
  "Operador de carro de lingotes",
  "Operador de carteiras internacionais",
  "Operador de casa de bomba",
  "Operador de casa de máquinas - exceto embarcações",
  "Operador de ceifadeira na conservação de vias permanentes",
  "Operador de célula (aparelho de flotação)",
  "Operador de central de asfalto",
  "Operador de central de britagem (tratamentos químicos e afins)",
  "Operador de central de concreto",
  "Operador de central de refrigeração",
  "Operador de central hidrelétrica",
  "Operador de central termoelétrica",
  "Operador de central termonuclear",
  "Operador de centrífuga (tratamentos químicos e afins)",
  "Operador de centrífuga de roupas",
  "Operador de centrifugadora (tratamentos químicos e afins)",
  "Operador de centro de controle ( sinterização)",
  "Operador de centro de controle (ferrovia e metrô)",
  "Operador de centro de controle de alto-forno",
  "Operador de centro de controle de sinterização e pátios",
  "Operador de centro de controle e equipamentos da sinterização",
  "Operador de centro de controle e matéria-prima para sinterizar",
  "Operador de centro de usinagem com comando numérico",
  "Operador de centro de usinagem de madeira (cnc)",
  "Operador de centro telefônico",
  "Operador de cerâmica (fundição de vidro)",
  "Operador de chamuscadeira de tecidos",
  "Operador de chamuscadeira/desengomagem de tecidos",
  "Operador de circular automática (madeira)",
  "Operador de co-processamento de resíduos",
  "Operador de cobrança",
  "Operador de cobrança bancária",
  "Operador de cobreamento",
  "Operador de colhedor florestal",
  "Operador de colheitadeira",
  "Operador de coluna de flotação",
  "Operador de compactadora de solos",
  "Operador de compressor - na extração de petróleo e gás",
  "Operador de compressor de ar",
  "Operador de compressor de refrigeração",
  "Operador de computador",
  "Operador de computador (inclusive microcomputador",
  "Operador de concentração",
  "Operador de cone de separação (minas)",
  "Operador de conicaleira",
  "Operador de conjunto de secador de fumo",
  "Operador de conservação de canais",
  "Operador de controle de produção de carboquímico",
  "Operador de controle mestre",
  "Operador de controle térmico",
  "Operador de corta-riscadeira  de papel",
  "Operador de cortadeira de papel",
  "Operador de cozedor a vácuo na refinação de açúcar",
  "Operador de cristalização na refinação de açucar",
  "Operador de debulhador de fumo",
  "Operador de decantador na refinação de açúcar",
  "Operador de desbobinadeira da linha de decapagem",
  "Operador de desbobinadeiras de tiras a quente e a frio",
  "Operador de descascador de madeira",
  "Operador de desempenadeira (conformação de metais)",
  "Operador de desempenadeira na usinagem convencional de madeira",
  "Operador de desgaseificação",
  "Operador de desintegrador de pedras",
  "Operador de desmineralizadora",
  "Operador de desnatadeira (fabricação de laticínios e afins)",
  "Operador de dessulfurador de gusa",
  "Operador de destilação (produtos químicos)",
  "Operador de destilação de amônia",
  "Operador de destilação de enxofre",
  "Operador de destilação e subprodutos de coque",
  "Operador de digestor de pasta para fabricação de papel",
  "Operador de dobradeira na indústria gráfica",
  "Operador de docagem",
  "Operador de draga",
  "Operador de drone",
  "Operador de eclusa",
  "Operador de eletrocardiógrafo",
  "Operador de eletroencefalógrafo",
  "Operador de eletroerosão a fio",
  "Operador de eletroerosão por penetração",
  "Operador de eletropolimento",
  "Operador de elevador",
  "Operador de embalagem, a máquina",
  "Operador de empilhadeira",
  "Operador de empilhadeira elétrica",
  "Operador de enfornamento e desenfornamento de coque",
  "Operador de engenho",
  "Operador de engomadeira de urdume",
  "Operador de ensaios na metrologia",
  "Operador de entalhadeira (usinagem de madeira)",
  "Operador de enxofreira na refinação de açúcar",
  "Operador de enxugador (couros)",
  "Operador de equipamento de compressor de ar",
  "Operador de equipamento de destilação de álcool",
  "Operador de equipamento de dragagem",
  "Operador de equipamento de escavadeira",
  "Operador de equipamento de flotação",
  "Operador de equipamento de motoniveladora",
  "Operador de equipamento de secagem de pintura",
  "Operador de equipamento para resfriamento",
  "Operador de equipamentos (cigarros)",
  "Operador de equipamentos (exploração de petróleo)",
  "Operador de equipamentos (filtro-prensa para tratamentos químicos)",
  "Operador de equipamentos de centrifugar",
  "Operador de equipamentos de entrada de dados",
  "Operador de equipamentos de parafinar",
  "Operador de equipamentos de precipitação",
  "Operador de equipamentos de preparação de areia",
  "Operador de equipamentos de refinação de açúcar (processo contínuo)",
  "Operador de equipamentos de secagem (mineração)",
  "Operador de equipamentos elétricos",
  "Operador de equipamentos pesados e móveis - na mineração",
  "Operador de equipe de montagem (veiculos automotores)",
  "Operador de escâner (pré-impressão gráfica)",
  "Operador de escavadeira",
  "Operador de escória e sucata",
  "Operador de esmaltadeira",
  "Operador de esmeril e serra de disco, no acabamento de metal",
  "Operador de esmeril fixo, no rebarbamento de metal",
  "Operador de esmeril, no rebarbamento de metal",
  "Operador de esmerilador de trilhos, no acabamento de metais",
  "Operador de espelhamento",
  "Operador de espessador",
  "Operador de espuladeira",
  "Operador de espulagem",
  "Operador de estação de bombeamento",
  "Operador de estação de captação, tratamento e distribuição de água",
  "Operador de estação de filtragem (filtro-prensa)",
  "Operador de estação de mistura (tratamentos químicos e afins)",
  "Operador de estação de tratamento de água",
  "Operador de estação de tratamento de água e efluentes",
  "Operador de estação de tratamento de esgoto",
  "Operador de estação de tratamento de esgotos e resíduos industriais",
  "Operador de estação de tratamento de resíduos industriais",
  "Operador de estação elevatória",
  "Operador de esticadeira (metal sem costura)",
  "Operador de estúdio (gravação de áudio)",
  "Operador de estufa de pintura",
  "Operador de estufa de secagem de malte",
  "Operador de estufas mecânicas",
  "Operador de evaporador na destilação",
  "Operador de evaporador para concentração do xarope na refinação de açúcar",
  "Operador de exaustor (coqueria)",
  "Operador de exploração de petróleo",
  "Operador de externas",
  "Operador de extração (petróleo)",
  "Operador de extração de café solúvel",
  "Operador de extração de oleos e gorduras vegetais",
  "Operador de extrusora (arte gráfica)",
  "Operador de extrusora (química, petroquímica e afins)",
  "Operador de extrusora (vidro)",
  "Operador de extrusora de borracha e plástico",
  "Operador de fábrica de  refino de sal",
  "Operador de fábrica de rações",
  "Operador de fabricação de tintas",
  "Operador de fabricação de tintas e vernizes",
  "Operador de filatório",
  "Operador de filtragem (exploração de petróleo)",
  "Operador de filtragem (filtro de tambor rotativo)",
  "Operador de filtragem (mineração)",
  "Operador de filtragem de parafina",
  "Operador de filtro (cerveja)",
  "Operador de filtro de secagem (mineração)",
  "Operador de filtro de tambor rotativo (tratamentos químicos e afins)",
  "Operador de filtro na refinação de açúcar",
  "Operador de filtro rotativo",
  "Operador de filtro-esteira (mineração)",
  "Operador de filtro-prensa",
  "Operador de filtro-prensa (tratamentos químicos e afins)",
  "Operador de filtros de parafina (tratamentos químicos e afins)",
  "Operador de fingirjoint",
  "Operador de flotação",
  "Operador de flotador na refinação de açúcar",
  "Operador de forja",
  "Operador de forno (conversor a oxigênio)",
  "Operador de forno (fabricação de pães, biscoitos e similares)",
  "Operador de forno (fundição de vidro)",
  "Operador de forno (refino de metais não-ferrosos)",
  "Operador de forno (serviços funerários)",
  "Operador de forno cubilô",
  "Operador de forno de calcinação",
  "Operador de forno de cerâmica (materiais de construção)",
  "Operador de forno de espera",
  "Operador de forno de incineração no tratamento de água, efluentes e resíduos industriais",
  "Operador de forno de indução elétrica",
  "Operador de forno de recozimento (vidro)",
  "Operador de forno de secagem (malte)",
  "Operador de forno de sinterizar",
  "Operador de forno de tratamento térmico",
  "Operador de forno de tratamento térmico de metais",
  "Operador de forno de tratamento térmico elétrico",
  "Operador de forno elétrico",
  "Operador de forno metalúrgico",
  "Operador de forno termoelétrico para elétrodos de grafite",
  "Operador de forno-poço",
  "Operador de fotocomposição",
  "Operador de fresadora (usinagem de madeira)",
  "Operador de fresadora com comando numérico",
  "Operador de fulão",
  "Operador de fundição",
  "Operador de furadeiras",
  "Operador de galvanização",
  "Operador de gás",
  "Operador de gaseificação de carvão",
  "Operador de germinação",
  "Operador de glp",
  "Operador de granitadeira",
  "Operador de gravação",
  "Operador de gravação (trilha sonora para filmes)",
  "Operador de grua",
  "Operador de grua (móvel)",
  "Operador de guilhotina (corte de papel)",
  "Operador de guincho",
  "Operador de guindaste (fixo)",
  "Operador de guindaste fixo sobre vagões de plataformas flutuantes",
  "Operador de guindaste móvel",
  "Operador de gunite",
  "Operador de hidrelétrica e subestações",
  "Operador de hidrogenação",
  "Operador de hidrogenação e cloroficação da água",
  "Operador de impermeabilizador de tecidos",
  "Operador de implementos agrícolas",
  "Operador de impressão de valores",
  "Operador de impressora de rotogravura",
  "Operador de impressora ofsete",
  "Operador de impressora platina",
  "Operador de incinerador",
  "Operador de incubadora",
  "Operador de industrialização de madeiras",
  "Operador de injetora de plástico",
  "Operador de inspeção de alto-forno",
  "Operador de inspeção de qualidade",
  "Operador de instalação de ar-condicionado",
  "Operador de instalação de beneficiamento",
  "Operador de instalação de extração, processamento, envasamento e distribuição de gases",
  "Operador de instalação de incineração de resíduos",
  "Operador de instalação de produção de energia elétrica",
  "Operador de instalação de refrigeração",
  "Operador de instalação de transferência de gases",
  "Operador de instalação de tratamento de água",
  "Operador de instalação para produção de resfriamento de banha",
  "Operador de jato abrasivo",
  "Operador de jato de água - em minas",
  "Operador de jato de areia",
  "Operador de jato de granalha",
  "Operador de jet flame",
  "Operador de jig (minas)",
  "Operador de laços de cabos de aço",
  "Operador de laminação",
  "Operador de laminadeira e reunideira",
  "Operador de laminador",
  "Operador de laminador de barras a frio",
  "Operador de laminador de barras a quente",
  "Operador de laminador de encruamento",
  "Operador de laminador de metais não-ferrosos",
  "Operador de laminador de placas",
  "Operador de laminador de tiras a quente",
  "Operador de laminador de tubos",
  "Operador de lavador de veículos",
  "Operador de lavadora de tapetes",
  "Operador de lavagem e depuração de pasta para fabricação de papel",
  "Operador de leito de chapas grossas",
  "Operador de lingotamento",
  "Operador de lingoteira contínua",
  "Operador de lingoteiras",
  "Operador de linha de decapagem",
  "Operador de linha de montagem (aparelhos elétricos)",
  "Operador de linha de montagem (aparelhos eletrônicos)",
  "Operador de linha de produção de preparação de matéria prima para produção de cigarros",
  "Operador de lixadeira (usinagem de madeira)",
  "Operador de lixadeira na usinagem de madeira",
  "Operador de locomotiva",
  "Operador de locomotiva (transporte metropolitano)",
  "Operador de maçarico",
  "Operador de maçarico de corte",
  "Operador de maçaroqueira",
  "Operador de malhas, a  máquina",
  "Operador de mandriladora com comando numérico",
  "Operador de manobras (auxiliar de ferrovias)",
  "Operador de manufaturado (química, petroquímica e afins)",
  "Operador de manutenção e recarga de extintor de incêndio",
  "Operador de manutenção eletromecânica",
  "Operador de máquina  empilhadeira",
  "Operador de máquina (fabricação de cigarros)",
  "Operador de máquina (pernas, fechamento, repassamento, alma)",
  "Operador de máquina - na industrialização do pescado",
  "Operador de máquina a fio diamantado",
  "Operador de máquina a vácuo",
  "Operador de máquina agrícola",
  "Operador de máquina bordatriz",
  "Operador de máquina cerâmica (pintor)",
  "Operador de máquina circular de malharia",
  "Operador de máquina copiadora (exceto operador de gráfica rápida)",
  "Operador de máquina coquilhadeira",
  "Operador de máquina cortadora (minas e pedreiras)",
  "Operador de máquina de abrir valas",
  "Operador de máquina de alvejar (couros)",
  "Operador de máquina de apontar bico",
  "Operador de máquina de arame",
  "Operador de máquina de areia para fundição",
  "Operador de máquina de bordar",
  "Operador de máquina de cablear",
  "Operador de máquina de cartonagem",
  "Operador de máquina de cigarros",
  "Operador de máquina de cilindrar chapas",
  "Operador de máquina de compressão de ar",
  "Operador de máquina de confeccionar palitos",
  "Operador de máquina de cordoalha",
  "Operador de máquina de cortar e dobrar papelão",
  "Operador de máquina de cortar tijolos e telhas",
  "Operador de máquina de cortar, colar e dobrar celofane e similar",
  "Operador de máquina de cortar, colar e dobrar papel",
  "Operador de máquina de corte (vidros)",
  "Operador de máquina de corte a gás",
  "Operador de máquina de corte de roupas",
  "Operador de máquina de cortina d´água (produção de móveis)",
  "Operador de máquina de costura de acabamento",
  "Operador de máquina de curvar metais",
  "Operador de máquina de desdobramento de mármores",
  "Operador de máquina de desdobrar madeira",
  "Operador de máquina de dobrar chapas",
  "Operador de máquina de eletroerosão",
  "Operador de máquina de embalar",
  "Operador de máquina de embrulhar",
  "Operador de máquina de empacotar",
  "Operador de máquina de encarteiramento (cigarros)",
  "Operador de máquina de encher colchões",
  "Operador de máquina de encher escovas, pincéis e vassouras",
  "Operador de máquina de enfestar",
  "Operador de máquina de engarrafamento",
  "Operador de máquina de enlatar",
  "Operador de máquina de envasar líquidos",
  "Operador de máquina de escarnar",
  "Operador de máquina de escrever",
  "Operador de máquina de esmaltação cerâmica",
  "Operador de máquina de esticar varetas ou tubos de vidro",
  "Operador de máquina de estirar vidro",
  "Operador de máquina de etiquetar",
  "Operador de máquina de extração contínua (minas de carvão)",
  "Operador de máquina de fabricação de cosméticos",
  "Operador de máquina de fabricação de produtos de higiene e limpeza (sabão, sabonete, detergente,",
  "Operador de máquina de fabricar charutos e cigarrilhas",
  "Operador de máquina de fabricar cigarros",
  "Operador de máquina de fabricar gelo",
  "Operador de máquina de fabricar machos (ar comprimido)",
  "Operador de máquina de fabricar papel",
  "Operador de máquina de fabricar papel  (fase úmida)",
  "Operador de máquina de fabricar papel (fase seca)",
  "Operador de máquina de fabricar papel e papelão",
  "Operador de máquina de filtro rotativo (tratamentos químicos)",
  "Operador de máquina de filtros (cigarros)",
  "Operador de máquina de fundição",
  "Operador de máquina de fundição por centrífuga",
  "Operador de máquina de fundir sob pressão",
  "Operador de máquina de laminação (a quente)",
  "Operador de máquina de laticínios",
  "Operador de máquina de lavanderia de lã",
  "Operador de máquina de lavar fios e tecidos",
  "Operador de máquina de lavar roupas, em geral",
  "Operador de máquina de machos",
  "Operador de máquina de manteiga",
  "Operador de máquina de moldar automatizada",
  "Operador de máquina de moldar lingoteira",
  "Operador de máquina de moldar vidro",
  "Operador de máquina de pasteurizar",
  "Operador de máquina de perfurar (minas e pedreiras)",
  "Operador de máquina de pintar contínua",
  "Operador de máquina de preparação de matéria prima para produção de cigarros",
  "Operador de máquina de produção na usinagem de madeira",
  "Operador de máquina de produtos farmacêuticos",
  "Operador de máquina de rotular",
  "Operador de máquina de secar celulose",
  "Operador de máquina de serrar",
  "Operador de máquina de serrar madeira",
  "Operador de máquina de serraria",
  "Operador de máquina de sinterizar",
  "Operador de máquina de solda a ultrassom",
  "Operador de máquina de solda elétrica",
  "Operador de máquina de solda eletrônica",
  "Operador de máquina de soldar",
  "Operador de máquina de soldar automática",
  "Operador de máquina de soprar vidro",
  "Operador de máquina de terraplenagem",
  "Operador de máquina de torrefação de cacau",
  "Operador de máquina de usinagem de madeira (produção em série)",
  "Operador de máquina de usinagem madeira, em geral",
  "Operador de máquina de vidraria",
  "Operador de máquina de virar chapas",
  "Operador de máquina eletroerosão, à fio, com comando numérico",
  "Operador de máquina extrusora de fibra de vidro",
  "Operador de máquina extrusora de varetas e tubos de vidro",
  "Operador de máquina extrusora de vidro",
  "Operador de máquina injetora de metais",
  "Operador de máquina intercaladora de placas (compensados)",
  "Operador de máquina misturadeira (tratamentos químicos e afins)",
  "Operador de máquina na fabricação de artefatos de cimento",
  "Operador de máquina na fabricação de macarrão.",
  "Operador de máquina ofsete",
  "Operador de máquina perfuradora (minas e pedreiras)",
  "Operador de máquina perfuradora - na mineração",
  "Operador de máquina perfuratriz",
  "Operador de máquina perfuratriz a fio diamantado",
  "Operador de máquina pneumática",
  "Operador de máquina recobridora de arame",
  "Operador de máquina reprográfica",
  "Operador de máquina retilínea de malharia",
  "Operador de máquina rodoferroviária",
  "Operador de máquina rodoviária",
  "Operador de máquina transportadora (madeira)",
  "Operador de máquina trituradora (tratamentos químicos e afins)",
  "Operador de máquina-elevador",
  "Operador de máquinas (exploração de petróleo)",
  "Operador de máquinas agrícolas",
  "Operador de máquinas de beneficiamento de produtos agrícolas",
  "Operador de máquinas de centrifugar (tratamentos químicos e afins)",
  "Operador de máquinas de confeccionar fósforo",
  "Operador de máquinas de construção civil e mineração",
  "Operador de máquinas de cozinhamento e alvejamento de fibras soltas, fios e tecidos",
  "Operador de máquinas de embalagem de sal",
  "Operador de máquinas de fabricação de chocolates e achocolatados",
  "Operador de máquinas de fabricação de doces, salgados e massas alimentícias",
  "Operador de máquinas de flanelar (peletizar, lixar) tecidos",
  "Operador de máquinas de impermeabilizar tecidos",
  "Operador de máquinas de lavar fibras soltas, fios, tecidos e peças confeccionadas",
  "Operador de máquinas de linha de montagem (aparelhos eletrônicos)",
  "Operador de máquinas de mercerizar fios e tecidos",
  "Operador de máquinas de mineração",
  "Operador de máquinas de usinar madeira (cnc)",
  "Operador de máquinas do acabamento de couros e peles",
  "Operador de máquinas e equipamentos rodoferroviários",
  "Operador de máquinas e veículos especiais na conservação de vias permanentes (trilhos)",
  "Operador de máquinas especiais em conservação de via permanente (trilhos)",
  "Operador de máquinas fixas, em geral",
  "Operador de máquinas florestais (colheitadeira)",
  "Operador de máquinas florestais (tratores)",
  "Operador de máquinas florestais estáticas",
  "Operador de máquinas operatrizes",
  "Operador de máquinas-ferramenta convencionais",
  "Operador de maracá - no garimpo",
  "Operador de maromba",
  "Operador de martelete",
  "Operador de mensagens de telecomunicações (correios)",
  "Operador de mesa (rádio)",
  "Operador de mesa bolsa de valores",
  "Operador de mesa de corte (serralharia)",
  "Operador de mesa de recebimento de lingotes",
  "Operador de mesa telefônica",
  "Operador de mexedeira na refinação de açúcar",
  "Operador de microcomputador",
  "Operador de microfone",
  "Operador de mídia audiovisual",
  "Operador de mini-lab",
  "Operador de mistura (tratamentos químicos e afins)",
  "Operador de misturador de areia para fundição",
  "Operador de moenda na fabricação de açúcar",
  "Operador de moinho (beneficiamento  de minérios)",
  "Operador de moinho (processo de moagem)",
  "Operador de moinho (tratamentos químicos e afins)",
  "Operador de moinho de bolas (minério)",
  "Operador de moinho de especiarias",
  "Operador de moinho de farinha crua",
  "Operador de moinho e mistura",
  "Operador de moinhos (argila)",
  "Operador de molduradora (usinagem de madeira)",
  "Operador de monta-cargas (construção civil)",
  "Operador de montagem de cilindros e mancais",
  "Operador de motobomba",
  "Operador de motoniveladora",
  "Operador de motoniveladora (extração de minerais sólidos)",
  "Operador de motosserra",
  "Operador de movimentação e armazenamento de carga",
  "Operador de negócios",
  "Operador de niveladora e de scraper",
  "Operador de oleoduto",
  "Operador de onduladora de papel",
  "Operador de open-end",
  "Operador de ordenhadeira",
  "Operador de oxicorte",
  "Operador de pá carregadeira",
  "Operador de pá carregadeira de esteira",
  "Operador de pá carregadeira de pneu",
  "Operador de pá carregadeira e tratores",
  "Operador de pá mecânica",
  "Operador de pá mecânica em subsolo",
  "Operador de pabx",
  "Operador de painel de controle",
  "Operador de painel de controle (refinação de petróleo)",
  "Operador de panela vazadora",
  "Operador de passadeira  na fabricação de compensados",
  "Operador de passador (fiação)",
  "Operador de pasteurizador",
  "Operador de patrol (niveladora)",
  "Operador de patrola",
  "Operador de pavimentadora (asfalto, concreto e materiais similares)",
  "Operador de pedreira",
  "Operador de pelotização",
  "Operador de peneiras hidráulicas",
  "Operador de peneiras vibratórias",
  "Operador de penteadeira",
  "Operador de perfiladeira",
  "Operador de pesagem de matéria prima",
  "Operador de pistola de pintura",
  "Operador de pistola rotativa",
  "Operador de plaina desengrossadeira",
  "Operador de plataforma (petróleo)",
  "Operador de polidora de cristais de ótica",
  "Operador de polidora de vidros e cristais",
  "Operador de ponte rolante",
  "Operador de pórtico rolante",
  "Operador de precipitador eletrostático",
  "Operador de prensa",
  "Operador de prensa (ferramentaria)",
  "Operador de prensa (folha de recobrimento de móveis)",
  "Operador de prensa - em olaria",
  "Operador de prensa a fabricação de papel",
  "Operador de prensa de alta freqüência na usinagem de madeira",
  "Operador de prensa de areia para fundição",
  "Operador de prensa de compensados",
  "Operador de prensa de embutir papelão",
  "Operador de prensa de enfardamento",
  "Operador de prensa de fardos",
  "Operador de prensa de material reciclável",
  "Operador de prensa de moldar vidro",
  "Operador de prensa na indústria de calçados",
  "Operador de prensadora de frutas",
  "Operador de preparação de grãos vegetais (óleos e gorduras)",
  "Operador de preparo de massas (alimentícias)",
  "Operador de preservação de área carboquímicos",
  "Operador de preservação e controle térmico",
  "Operador de processamento (transferência e estocagem de petróleo)",
  "Operador de processo (petróleo)",
  "Operador de processo (química, petroquímica e afins)",
  "Operador de processo de golfragem",
  "Operador de processo de moagem",
  "Operador de processo de produção",
  "Operador de processo de tratamento de imagem",
  "Operador de processo de zincagem",
  "Operador de processos (exploração de petróleo)",
  "Operador de processos automatizados de pintura",
  "Operador de processos de semibranqueamento de pasta/celulose",
  "Operador de processos químicos e petroquímicos",
  "Operador de produção (química, petroquímica e afins)",
  "Operador de produção de álcool",
  "Operador de produção metroferroviária",
  "Operador de programação (ferrovia)",
  "Operador de projetor cinematográfico",
  "Operador de projetor cinematográfico e áudio",
  "Operador de quadro de alimentação (subestação de distribuição de energia elétrica)",
  "Operador de quadro de distribuição de energia elétrica",
  "Operador de queijeira",
  "Operador de rádio",
  "Operador de rádio (telefonia)",
  "Operador de rádio-chamada",
  "Operador de radiotelefonia",
  "Operador de radiotelégrafo",
  "Operador de raio-x",
  "Operador de rameuse",
  "Operador de rampa ( transporte aéreo)",
  "Operador de reagentes",
  "Operador de reator (produtos químicos exceto petróleo)",
  "Operador de reator de coque de petróleo",
  "Operador de reator nuclear",
  "Operador de reatores (fabricação de vernizes e resinas)",
  "Operador de rebobinadeira na fabricação de papel e papelão",
  "Operador de recebimento de gusa",
  "Operador de rede de teleprocessamento",
  "Operador de rede de transmissão de dados",
  "Operador de refinaria de sal",
  "Operador de refrigeração (coqueria)",
  "Operador de refrigeração com amônia",
  "Operador de renda fixa",
  "Operador de renda variável",
  "Operador de repassadeira",
  "Operador de retificadora com comando numérico",
  "Operador de retorcedeira",
  "Operador de retro-escavadeira",
  "Operador de revérbero",
  "Operador de robô de pintura",
  "Operador de robô de soldagem",
  "Operador de roda de lingotamento",
  "Operador de rolo (impermeabilização)",
  "Operador de rolo compactador",
  "Operador de rolo compressor",
  "Operador de rompedor",
  "Operador de rov",
  "Operador de sala de brassagem",
  "Operador de sala de controle - na mineração",
  "Operador de sala de controle de instalações químicas, petroquímicas e afins",
  "Operador de sala de máquinas (embarcações)",
  "Operador de salina (sal marinho)",
  "Operador de schutthecar",
  "Operador de sdcd",
  "Operador de secadeiras no beneficiamento de produtos agrícolas",
  "Operador de secador (produtos agrícolas)",
  "Operador de secador da fabricação cerâmica",
  "Operador de secador de areia para fundição",
  "Operador de secador de mate",
  "Operador de secador de resíduos",
  "Operador de secador na fabricação de açúcar",
  "Operador de secador na fabricação e refino de açúcar",
  "Operador de secadora de roupas",
  "Operador de seccionadeira na usinagem de madeira",
  "Operador de serigrafia",
  "Operador de serra automática",
  "Operador de serra circular",
  "Operador de serra circular automática",
  "Operador de serra circular múltipla",
  "Operador de serra de disco",
  "Operador de serra de fita",
  "Operador de serra elétrica",
  "Operador de serra esquadrejadeira",
  "Operador de serra manual",
  "Operador de serra mecânica",
  "Operador de serra vertical automática",
  "Operador de serra-cinta",
  "Operador de serras (exploração florestal)",
  "Operador de serras (usinagem de madeira)",
  "Operador de serras no desdobramento de madeira",
  "Operador de setor de matanças de gado",
  "Operador de ship loader",
  "Operador de silkscreen",
  "Operador de sinterização",
  "Operador de sistema de aeronaves remotamente pilotadas (rpas)",
  "Operador de sistema de ar comprimido",
  "Operador de sistema de areia para fundição",
  "Operador de sistema de calcinação",
  "Operador de sistema de climatização",
  "Operador de sistema de computador",
  "Operador de sistema de gás",
  "Operador de sistema de precipitação",
  "Operador de sistema de refrigeração",
  "Operador de sistema de reversão (coqueria)",
  "Operador de sistemas computacionais em rede",
  "Operador de sistemas de água",
  "Operador de sistemas de informática (teleprocessamento)",
  "Operador de sistemas de prova (analógico e digital)",
  "Operador de sonda de percussão",
  "Operador de sonda manual",
  "Operador de sonda pesada",
  "Operador de sonda rotativa",
  "Operador de sopragem",
  "Operador de soque",
  "Operador de subestação",
  "Operador de supercalandra",
  "Operador de superfosfato",
  "Operador de suporte técnico (telemarketing)",
  "Operador de talha elétrica",
  "Operador de tambor rotativo",
  "Operador de tear de jacquard",
  "Operador de tear manual",
  "Operador de tear mecânico de maquineta",
  "Operador de tear mecânico de xadrez",
  "Operador de tear mecânico liso",
  "Operador de tear mecânico, exceto jacquad",
  "Operador de teleatendimento ativo (telemarketing)",
  "Operador de teleatendimento híbrido (telemarketing)",
  "Operador de telecobrança",
  "Operador de telecomunicações de correios",
  "Operador de teleférico (passageiros)",
  "Operador de telemarketing (operações híbridas)",
  "Operador de telemarketing ativo",
  "Operador de telemarketing ativo e receptivo",
  "Operador de telemarketing bilíngüe",
  "Operador de telemarketing especializado",
  "Operador de telemarketing receptivo",
  "Operador de telemarketing técnico",
  "Operador de teleprocessamento",
  "Operador de teleturfe",
  "Operador de telex",
  "Operador de tesoura desbastadora, no acabamento chapas e metais",
  "Operador de tesoura elétrica, no acabamento de chapas e metais",
  "Operador de tesoura mecânica e máquina de corte, no acabamento de chapas e metais",
  "Operador de tesoura pendular, no acabamento de chapas e metais",
  "Operador de tesoura rotativa, no acabamento de chapas e metais",
  "Operador de tesoura sucata",
  "Operador de tesoura transversal, no acabamento de chapas e metais",
  "Operador de tesoura volante e guilhotina, no acabamento de chapas e metais",
  "Operador de time de montagem",
  "Operador de torno automático (usinagem de madeira)",
  "Operador de torno com comando numérico",
  "Operador de torno semi-automático (cerâmica)",
  "Operador de torre de amostragem - na mineração",
  "Operador de torre de controle (aéreo)",
  "Operador de tráfego",
  "Operador de transferência e estocagem - na refinação do petróleo",
  "Operador de transporte multimodal",
  "Operador de tratamento de água e esgoto",
  "Operador de tratamento de calda na refinação de açúcar",
  "Operador de tratamento de esgoto",
  "Operador de tratamento de gás",
  "Operador de tratamento de gás (coque)",
  "Operador de tratamento químico de materiais radioativos",
  "Operador de tratamento residual",
  "Operador de tratamento térmico",
  "Operador de tratamento termoelétrico",
  "Operador de trator (minas e pedreiras)",
  "Operador de trator - exclusive na agropecuária",
  "Operador de trator de esteira",
  "Operador de trator de lâmina",
  "Operador de trator florestal",
  "Operador de tratores diversos",
  "Operador de trefila",
  "Operador de trefila (borracha)",
  "Operador de trem de metrô",
  "Operador de trem metropolitano",
  "Operador de triagem e transbordo",
  "Operador de triturador de toras",
  "Operador de tupia (usinagem de madeira)",
  "Operador de turbina a vapor na fabricação e refino de açúcar",
  "Operador de turbina a vapor na refinação de açúcar",
  "Operador de turbinas de central elétrica",
  "Operador de turismo",
  "Operador de ua (unmanned aircraft)",
  "Operador de umidificação",
  "Operador de unidade portátil externa",
  "Operador de urdideira",
  "Operador de urdideira de tapetes",
  "Operador de usina de asfalto",
  "Operador de usina elétrica",
  "Operador de usina hidrelétrica",
  "Operador de usina hidroelétrica",
  "Operador de usina nuclear",
  "Operador de usina térmica",
  "Operador de usina termoelétrica",
  "Operador de usina termogás",
  "Operador de usina termonuclear",
  "Operador de usinagem convencional por abrasão",
  "Operador de utilidade (produção e distribuição de vapor, gás, óleo, combustível, energia, oxigênio)",
  "Operador de utilidades (petróleo)",
  "Operador de utilidades no tratamento de água e efluentes",
  "Operador de vazamento",
  "Operador de veículos aéreos não tripulados (vant)",
  "Operador de veículos especiais na conservação de vias permanentes (trilhos)",
  "Operador de veículos subaquáticos controlados remotamente",
  "Operador de vendas (lojas)",
  "Operador de vibrador de concreto",
  "Operador de vídeo",
  "Operador de videoteipe",
  "Operador de viradeira (aço)",
  "Operador de viradeira de chapas",
  "Operador de viradeira de tubos",
  "Operador de viradeiras de fitas de aço",
  "Operador de water jet",
  "Operador de zincagem",
  "Operador de zincagem (processo eletrolítico)",
  "Operador dos vinte e quatro segundos (basquete)",
  "Operador eletromecânico",
  "Operador especializado de extrusora",
  "Operador financeiro",
  "Operador gráfico de corte e vinco na impressão",
  "Operador industrial (química, petroquímica e afins)",
  "Operador industrial especializado (petróleo)",
  "Operador industrial nas instalações químicas, petroquímicas e afins",
  "Operador internacional (telefonia)",
  "Operador junior (cigarros)",
  "Operador malto (cigarros)",
  "Operador mantanedor de compressores de gases",
  "Operador mecânico (cigarros)",
  "Operador mecânico de gás",
  "Operador misturador de fibras",
  "Operador multifuncional de tratamento térmico",
  "Operador passadista (fiação)",
  "Operador passadorista (fiação)",
  "Operador patroleiro",
  "Operador polivalente da indústria têxtil",
  "Operador-mantenedor de projetor cinematográfico",
  "OPERADORES DA FIAÇÃO",
  "OPERADORES DE CALCINAÇÃO E DE TRATAMENTOS QUÍMICOS DE MATERIAIS RADIOATIVOS",
  "OPERADORES DE EQUIPAMENTOS DE ACABAMENTO DE CHAPAS E METAIS",
  "OPERADORES DE EQUIPAMENTOS DE COQUEIFICAÇÃO",
  "OPERADORES DE EQUIPAMENTOS DE DESTILAÇÃO, EVAPORAÇÃO E REAÇÃO",
  "OPERADORES DE EQUIPAMENTOS DE ENTRADA E TRANSMISSÃO DE DADOS",
  null,
  "OPERADORES DE EQUIPAMENTOS DE FABRICAÇÃO  E BENEFICIAMENTO DE CRISTAIS, VIDROS,",
  "OPERADORES DE EQUIPAMENTOS DE FILTRAGEM E SEPARAÇÃO",
  "OPERADORES DE EQUIPAMENTOS DE LAMINAÇÃO",
  "OPERADORES DE EQUIPAMENTOS DE MOAGEM E MISTURA DE MATERIAIS (TRATAMENTOS QUÍMICOS E",
  "OPERADORES DE EQUIPAMENTOS DE MOVIMENTAÇÃO DE CARGAS",
  "OPERADORES DE EQUIPAMENTOS DE PRODUÇÃO E REFINO DE PETRÓLEO E GÁS",
  "OPERADORES DE EQUIPAMENTOS NA FABRICAÇÃO DE PÃES, MASSAS ALIMENTÍCIAS, DOCES,",
  "OPERADORES DE EQUIPAMENTOS PILOTADOS REMOTAMENTE",
  "OPERADORES DE FORNOS DE PRIMEIRA  FUSÃO E ACIARIA",
  "OPERADORES DE INSTALAÇÕES DE CAPTAÇÃO E ESGOTOS",
  "OPERADORES DE INSTALAÇÕES DE CAPTAÇÃO, TRATAMENTO E DISTRIBUIÇÃO DE ÁGUA",
  "OPERADORES DE INSTALAÇÕES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
  "OPERADORES DE INSTALAÇÕES DE EXTRAÇÃO, PROCESSAMENTO, ENVASAMENTO E DISTRIBUIÇÃO DE",
  "OPERADORES DE INSTALAÇÕES DE GERAÇÃO E DISTRIBUIÇÃO DE ENERGIA ELÉTRICA, HIDRÁULICA,",
  "OPERADORES DE INSTALAÇÕES DE REFRIGERAÇÃO E AR-CONDICIONADO",
  "OPERADORES DE INSTALAÇÕES DE SINTERIZAÇÃO",
  "OPERADORES DE INSTALAÇÕES E EQUIPAMENTOS DE FABRICAÇÃO DE MATERIAIS DE CONSTRUÇÃO",
  "OPERADORES DE INSTALAÇÕES E MÁQUINAS DE PRODUTOS PLÁSTICOS, DE BORRACHA E MOLDADORES",
  "OPERADORES DE MÁQUINA DE USINAR MADEIRA (PRODUÇÃO EM SÉRIE)",
  "OPERADORES DE MÁQUINAS A VAPOR E UTILIDADES",
  "OPERADORES DE MÁQUINAS DE AGLOMERAÇÃO E PRENSAGEM DE CHAPAS",
  "OPERADORES DE MÁQUINAS DE CONFORMAÇÃO DE METAIS",
  "OPERADORES DE MÁQUINAS DE COSTURAR E MONTAR CALÇADOS",
  "OPERADORES DE MÁQUINAS DE DESDOBRAMENTO DA MADEIRA",
  "OPERADORES DE MÁQUINAS DE FABRICAR PAPEL E PAPELÃO",
  "OPERADORES DE MÁQUINAS DE USINAGEM CNC",
  "OPERADORES DE MÁQUINAS DE USINAGEM DE MADEIRA CNC",
  "OPERADORES DE MÁQUINAS E EQUIPAMENTOS DE ELEVAÇÃO",
  "OPERADORES DE MÁQUINAS E INSTALAÇÕES DE PRODUTOS FARMACÊUTICOS, COSMÉTICOS E AFINS",
  "OPERADORES DE MÁQUINAS NA CONFECÇÃO DE ARTEFATOS DE  COURO",
  "OPERADORES DE MÁQUINAS NA FABRICAÇÃO DE PRODUTOS DE PAPEL E PAPELÃO",
  "OPERADORES DE MÁQUINAS PARA BORDADO E ACABAMENTO DE ROUPAS",
  "OPERADORES DE MÁQUINAS PARA COSTURA DE PEÇAS DO VESTUÁRIO",
  "OPERADORES DE PROCESSOS DAS INDÚSTRIAS DE TRANSFORMAÇÃO DE PRODUTOS QUÍMICOS,",
  "OPERADORES DE REDE DE TELEPROCESSAMENTO E AFINS",
  "OPERADORES DE TEAR E MÁQUINAS SIMILARES",
  "OPERADORES DE TELEFONIA",
  "OPERADORES DE TELEMARKETING E AFINS",
  "Operadores de tratamento e bombeamento de água",
  "OPERADORES DE USINAGEM CONVENCIONAL DE MADEIRA",
  "OPERADORES DE VEÍCULOS SOBRE TRILHOS E CABOS AÉREOS",
  "OPERADORES DO COMÉRCIO EM LOJAS E MERCADOS",
  "OPERADORES NA PREPARAÇÃO DE MASSAS PARA ABRASIVO, VIDRO, CERÂMICA, PORCELANA E",
  "OPERADORES POLIVALENTES DE EQUIPAMENTOS EM INDÚSTRIAS QUÍMICAS, PETROQUÍMICAS E AFINS",
  "Óptico contatólogo",
  "Óptico oftálmico",
  "Óptico optometrista",
  "Óptico protesista",
  "Ordenhador - na pecuária",
  "Organizador de degustações e harmonizações de bebidas",
  "Organizador de evento",
  "Organizador profissional",
  "Orientador de disciplina e área de estudo",
  "Orientador de ensino",
  "Orientador de plantio",
  "Orientador de tráfego para estacionamento",
  "Orientador educacional",
  "Orientador escolar",
  "Orientador fisiocorporal",
  "Orientador profissional",
  "Orientador sócio educativo",
  "Orientador vocacional e profissional",
  "Orizicultor",
  "Ornamentador",
  "Ornamentador de eventos",
  "Ornamentista de pedra",
  "Ornitólogo",
  "Orquestrador",
  "Ortesista",
  "Ortodontista",
  "Ortodontólogo",
  "Ortopedista",
  "Ortopedista maxilar",
  "Ortoptista",
  "Osho",
  "Osteopata",
  "OSTEOPATAS E QUIROPRAXISTAS",
  "Ostreicultor",
  "Otorrino",
  "Otorrinolaringologista",
  "Ourives",
  "Ourives de bijouteria",
  "Ourives montador",
  "OUTROS TRABALHADORES DOS SERVIÇOS",
  "Ouvidor",
  "Ovinocultor - empregador",
  "Ovinocultor - exclusive conta própria",
  "Ovinocultor cabanheiro",
  "Ovinocultor matrizeiro",
  "Oxicortador",
  "Oxicortador a mão e a  máquina",
  "Oxidador",
  "Padeiro",
  "Padeiro confeiteiro",
  "PADEIROS, CONFEITEIROS E AFINS",
  "Padioleiro-enfermeiro",
  "Padre",
  "Padrinho de umbanda",
  "Padronista de chapéus",
  "Padronizador de velas de embarcações, toldos e barracas",
  "Pagé",
  "Paginador",
  "Paisagista",
  "Pajém (baby-sitter em início de carreira)",
  "Palecionador de couros e peles",
  "Paleetnólogo",
  "Paleontólogo",
  "Palhaço",
  "Palmicultor",
  "Palmiteiro",
  "Panfleteiro",
  "Panificador",
  "Pantaneiro",
  "Pantaneiro - exclusive conta própria e empregador",
  "Pantografista",
  "Papeleiro (comércio varejista)",
  "Papiloscopista policial",
  "Paradista",
  "Parafinador",
  "Parafineiro",
  "Paramenteiro - alfaite",
  "Paranormal",
  "Parapsicólogo",
  "Parceiro - na criação de aves - empregador",
  "Parceiro - na criação de gado bovino - empregador",
  "Parceiro do bicho-da-seda",
  "Parceiro na agropecuária - conta própria",
  "Parceiro na agropecuária - exclusive conta própria e empregador",
  "Parceiro na criação de coelhos",
  "Parceiro na pecuária",
  "Parceiro polivalente",
  "Pároco",
  "Parozeiro",
  "Parteira",
  "Parteira leiga",
  "Parteira prática",
  "Parteira profissional",
  "Partidor judicial",
  "Partner (circo)",
  "Passadeira de peças confeccionadas",
  "Passador - no serviço do vestuário",
  "Passador a ferro a vapor",
  "Passador de amostras de roupas",
  "Passador de guarnição",
  "Passador de roupas",
  "Passador de roupas à prensa",
  "Passador de roupas em calandra",
  "Passador de roupas em geral",
  "Passador de roupas em manequim",
  "Passador de roupas, à mão",
  "Passador de tecidos",
  "Passamaneiro a  máquina",
  "Pasteleiro",
  "Pasteurizador",
  "Pastilheiro",
  "Pastor",
  "Pastor - na pecuária",
  "Pastor evangélico",
  "Pastor evangelista",
  "Patisseiro",
  "Patologista",
  "Patologista clínico",
  "Patrão de pesca de alto-mar",
  "Patrão de pesca na navegação interior",
  "Patroleiro",
  "Patroleiro de pavimentação",
  "Patroleiro de terraplanagem",
  "Pavimentador",
  "Peador (trabalhador portuário)",
  "Peão (asininos e muares)",
  "Peão (bubalinos)",
  "Peão (eqüinos)",
  "Peão - na agropecuária",
  "Peão de boiadeiro",
  "Peão de cavalo",
  "Peão de estábulo",
  "Peão de pecuária",
  "Pecuário - na criação de gado bovino - empregador",
  "Pecuarista",
  "Pecuarista - na criação de asininos",
  "Pecuarista - na criação de caprinos - empregador",
  "Pecuarista - na criação de eqüinos - empregador",
  "Pecuarista - na criação de gado bovino - empregador",
  "Pecuarista - na criação de muares",
  "Pecuarista - na criação de ovinos",
  "Pecuarista - na criação de suínos empregador",
  "Pecuarista de leite",
  "Pecunheiro",
  "Pedagogo",
  "Pedagogo em educação especial de cegos",
  "Pedagogo em educação especial de surdos",
  "Pedagogo especializado em deficiência física",
  "Pedagogo especializado em deficiência mental",
  "Pedagogo especializado em deficientes da áudio-comunicação",
  "Pediatra",
  "Pedreiro",
  "Pedreiro (chaminés industriais)",
  "Pedreiro (material refratário)",
  "Pedreiro (mineração)",
  "Pedreiro de acabamento",
  "Pedreiro de alvenaria",
  "Pedreiro de chaminés",
  "Pedreiro de concreto",
  "Pedreiro de conservação de vias permanentes (exceto trilhos)",
  "Pedreiro de edificações",
  "Pedreiro de fachada",
  "Pedreiro de forno",
  "Pedreiro de manutenção e conservação",
  "Pedreiro de mineração",
  "Pedreiro de reforma geral",
  "Pegador de animais - na agropecuária",
  "Pegador de bola",
  "Pegigan",
  "Peixeiro (comércio varejista)",
  "Peleteiro (comércio varejista)",
  "Penalista",
  "Peneirador - na agropecuária",
  "Peneirador de vinho de bacaba",
  "Peneireiro - na fundição",
  "Pequeno produtor rural",
  "Pequizeiro",
  "Perfumista",
  "Perfurador de cartões jacquard",
  "Perfurador de poços artesianos",
  "Perfusionista",
  "Periodontista",
  "Perito assistente (contador)",
  "Perito avaliador de bens móveis",
  "Perito contábil",
  "Perito contador",
  "Perito criminal",
  "Perito criminal federal",
  "Perito criminalístico",
  "Perito criminalístico engenheiro",
  "Perito criminalístico químico",
  "Perito de balanço",
  "Perito econômico",
  "Perito em identificação",
  "Perito forense de formação específica",
  "Perito judicial",
  "Perito judicial acidentólogo, de trânsito e transporte",
  "Perito judicial áudio e imagem",
  "Perito judicial avaliador de bens móveis",
  "Perito judicial contábil",
  "Perito judicial de formação específica",
  "Perito judicial de incêndio automotivo e explosões",
  "Perito judicial de incêndio de edificações",
  "Perito judicial em engenharia",
  "Perito judicial grafotécnico documentoscópico",
  "Perito judicial técnico industrial",
  "Perito liquidador (contador)",
  "Perito oficial",
  "Perito papiloscopista",
  "PERITOS CRIMINAIS E JUDICIAIS",
  "Personal stylist",
  "Personal treanning",
  "Pesador",
  "Pescador artesanal  de lagostas com covos",
  "Pescador artesanal de água doce",
  "Pescador artesanal de camarões",
  "Pescador artesanal de lagostas",
  "Pescador artesanal de lagostas com gaiolas",
  "Pescador artesanal de peixes",
  "Pescador artesanal de peixes e camaões com rede de calão",
  "Pescador artesanal de peixes e camarões",
  "Pescador artesanal de peixes e camarões com covos",
  "Pescador artesanal de peixes e camarões com espinhel",
  "Pescador artesanal de peixes e camarões com redes e linhas",
  "Pescador artesanal de peixes e camarões em currais",
  "Pescador de anzol",
  "Pescador de espinhel (embarcações de pesca)",
  "Pescador de lagostas",
  "Pescador de linhas (embarcações de pesca)",
  "Pescador de peixes e camarões com redes",
  "Pescador de peixes e camarões em embarcações de pequeno porte",
  "Pescador de tarrafa (peixes e camarões)",
  "Pescador industrial",
  "Pescador lagosteiro",
  "Pescador profissional",
  "PESCADORES DE ÁGUA COSTEIRA E ALTO MAR",
  "PESCADORES POLIVALENTES",
  "PESCADORES PROFISSIONAIS ARTESANAIS DE ÁGUA DOCE",
  "Pescoceiro em matadouro",
  "Pesquisador botânico",
  "Pesquisador das ciências agrárias",
  "Pesquisador de clínica médica",
  "Pesquisador de engenharia civil",
  "Pesquisador de engenharia e tecnologia (outras áreas da engenharia)",
  "Pesquisador de engenharia elétrica e eletrônica",
  "Pesquisador de engenharia mecânica",
  "Pesquisador de engenharia metalúrgica, de minas e de materiais",
  "Pesquisador de engenharia química",
  "Pesquisador de informações de rede",
  "Pesquisador de medicina básica",
  "Pesquisador de preços",
  "Pesquisador econômico",
  "Pesquisador em biologia ambiental",
  "Pesquisador em biologia animal",
  "Pesquisador em biologia de microorganismos e parasitas",
  "Pesquisador em biologia humana",
  "Pesquisador em biologia vegetal",
  "Pesquisador em ciências agronômicas",
  "Pesquisador em ciências da computação e informática",
  "Pesquisador em ciências da educação",
  "Pesquisador em ciências da pesca e aqüicultura",
  "Pesquisador em ciências da terra e meio ambiente",
  "Pesquisador em ciências da zootecnia",
  "Pesquisador em ciências florestais",
  "Pesquisador em ciências sociais e humanas",
  "Pesquisador em economia",
  "Pesquisador em física",
  "Pesquisador em história",
  "Pesquisador em matemática",
  "Pesquisador em medicina veterinária",
  "Pesquisador em metrologia",
  "Pesquisador em música",
  "Pesquisador em psicologia",
  "Pesquisador em química",
  "Pesquisador em saúde coletiva",
  "PESQUISADORES DAS CIÊNCIAS BIOLÓGICAS",
  "PESQUISADORES DAS CIÊNCIAS DA AGRICULTURA",
  "PESQUISADORES DAS CIÊNCIAS DA SAÚDE",
  "PESQUISADORES DAS CIÊNCIAS NATURAIS E EXATAS",
  "PESQUISADORES DAS CIÊNCIAS SOCIAIS E HUMANAS",
  "PESQUISADORES DE ENGENHARIA E TECNOLOGIA",
  "Pessicultor",
  "Petintal (calafetação)",
  "Petrógrafo",
  "Piaçaveiro",
  "Picador de carne em matadouro",
  "Picador em açougue",
  "Picheleiro",
  "Picotador de cartões jacquard",
  "Picotador de flandres",
  "Piercer",
  "Piloteiro de turismo de pesca",
  "Piloto agrícola",
  "Piloto comercial",
  "Piloto comercial (exceto linhas aéreas)",
  "Piloto comercial de helicóptero (exceto linhas aéreas)",
  "Piloto de aeronaves",
  "Piloto de aeronaves não tripuladas",
  "Piloto de aeronaves pilotadas remotamente",
  "Piloto de avião",
  "Piloto de competição automobilística",
  "Piloto de drone",
  "Piloto de ensaios em vôo",
  "Piloto de helicóptero",
  "Piloto de linha aérea regular",
  "Piloto de provas",
  "Piloto de rov",
  "Piloto de sistema de aeronaves remotamente pilotadas (rpas)",
  "Piloto de trem de metrô",
  "Piloto de ua (unmanned aircraft)",
  "Piloto de veículos aéreos não tripulados (vant)",
  "Piloto fluvial",
  "PILOTOS DE AVIAÇÃO COMERCIAL, MECÂNICOS DE VÔO E AFINS",
  "Pinçador (produção têxtil)",
  "Pintor (artes visuais)",
  "Pintor (retoques)",
  "Pintor a pincel e rolo (exceto obras e estruturas metálicas)",
  "Pintor a revólver",
  "Pintor a revólver (pintura eletrostática)",
  "Pintor de alvenaria",
  "Pintor de automóveis",
  "Pintor de aviões",
  "Pintor de brinquedos",
  "Pintor de carros",
  "Pintor de casas",
  "Pintor de cerâmica a  pistola",
  "Pintor de cerâmica manual",
  "Pintor de cerâmica, a  pincel",
  "Pintor de construções cênicas",
  "Pintor de couros e peles à mão",
  "Pintor de couros e peles à pistola",
  "Pintor de decoração de fundo (cinema e teatro)",
  "Pintor de edifícios",
  "Pintor de estruturas metálicas",
  "Pintor de geladeira",
  "Pintor de letreiros",
  "Pintor de máquinas industriais",
  "Pintor de metais a  pistola",
  "Pintor de motos",
  "Pintor de móveis - a pistola",
  "Pintor de obras",
  "Pintor de paredes",
  "Pintor de vagões tanques",
  "Pintor de veículos (fabricação)",
  "Pintor de veículos (reparação)",
  "Pintor especializado em pintura acrílica",
  "Pintor especializado em pintura acrílica (veículos)",
  "Pintor industrial",
  "Pintor por imersão",
  "Pintor por pulverização (exceto veículos)",
  "Pintor, a  pistola (exceto obras e estruturas metálicas)",
  "PINTORES DE OBRAS E REVESTIDORES DE INTERIORES (REVESTIMENTOS FLEXÍVEIS)",
  "Pipoqueiro ambulante",
  "Pirangueiro",
  "Pirangueiro de turismo de pesca",
  "Pirofagista",
  "Pirotécnico",
  "Piscicultor",
  "Piscineiro",
  "Piseiro (mármore e granito)",
  "Pistoleiro (cerâmica)",
  "Pizzaiolo",
  "Plainador de metais (plaina limadora)",
  "Planchador de calçados",
  "Planejador de controle de qualidade",
  "Planejador de interiores",
  "Planejador de processos (fabricação de ferramentas)",
  "Planejador de processos (manutenção de ferramentas)",
  "Planejador de produção (técnico)",
  "Planejador de turismo (turismólogo)",
  "Planejador urbano",
  "PLANEJADORES, PROGRAMADORES E CONTROLADORES DE PRODUÇÃO E MANUTENÇÃO",
  "Planejista",
  "Plantador - exclusive conta própria e empregador",
  "Plantador da matecultura",
  "Plantador de agave",
  "Plantador de algodão",
  "Plantador de algodão - exclusive conta própria e empregador",
  "Plantador de arroz - conta própria",
  "Plantador de arroz - empregador",
  "Plantador de beterraba",
  "Plantador de cana-de-açúcar - conta própria",
  "Plantador de cana-de-açúcar - empregador",
  "Plantador de cultura permanente",
  "Plantador de cultura temporária",
  "Plantador de curauá",
  "Plantador de juta",
  "Plantador de malva",
  "Plantador de milho e sorgo - conta própria",
  "Plantador de milho e sorgo - empregador",
  "Plantador de rami",
  "Plantador de sisal",
  "Plantador de sisal ou agave - exclusive conta própria e empregador",
  "Plantador de trigo - conta própria",
  "Plantador de trigo - empregador",
  "Plaqueiro",
  "Plataformista (petróleo)",
  "Platinador",
  "Pneumologista",
  "Pneumotisiologista",
  "Poaieiro",
  "Poceiro",
  "Poceiro (edificações)",
  "Podador agrícola",
  "Podador da cultura de cacau",
  "Podador de árvores na conservação de vias permanentes",
  "Podador de erva-mate",
  "Podador de guaraná",
  "Podólogo",
  "Poeta",
  "Polícia florestal e de mananciais",
  "POLICIAIS, GUARDAS-CIVIS MUNICIPAIS E AGENTES DE TRÂNSITO",
  "Policial legislativo",
  "Policial legislativo federal",
  "Policial penal",
  "Policial rodoviário federal",
  "Polidor de artefatos de couro",
  "Polidor de automóveis",
  "Polidor de calçados",
  "Polidor de calçados (na fabricação)",
  "Polidor de cristais",
  "Polidor de cristais de óptica, a máquina",
  "Polidor de ferramentas",
  "Polidor de fieiras",
  "Polidor de granito",
  "Polidor de lentes",
  "Polidor de mármore",
  "Polidor de mármore e granito (na construção)",
  "Polidor de marmorite",
  "Polidor de matrizes",
  "Polidor de metais",
  "Polidor de metais a mão",
  "Polidor de pedras",
  "Polidor de pedras, a mão",
  "Polidor de pedras, a máquina",
  "Polidor de rodas dentadas a máquina",
  "Polidor de veículos",
  "Polidor de vidros",
  "Polidor de vidros e cristais",
  "Pontífice",
  "Pope",
  "Porcelanista",
  "Portaló nas operações de máquinas e equipamentos de elevação",
  "Porteiro",
  "Porteiro (hotel)",
  "Porteiro de edifícios",
  "Porteiro de locais de diversão",
  "Porteiro industrial",
  "PORTEIROS, VIGIAS E AFINS",
  "Pós-produtor de tv e vídeo",
  "Posseiro na agropecuária - conta própria",
  "Praça da aeronáutica",
  "Praça da marinha",
  "Praça do exército",
  "PRAÇAS DAS FORÇAS ARMADAS",
  "Prateador",
  "Prateador (processo eletrolítico)",
  "Prateador de espelho",
  "Prático de portos da marinha mercante",
  "Prefeito",
  "Pregoeiro",
  "Prelado",
  "Prensador cerâmico",
  "Prensador de couros e peles",
  "Prensador de frutas (exceto oleaginosas)",
  "Prensador de sacos",
  "Prensador de sementes e frutas oleaginosas",
  "Prenseiro",
  "Prensista",
  "Prensista (operador de prensa)",
  "Prensista de aglomerado de madeira",
  "Prensista de aglomerados",
  "Prensista de borracha",
  "Prensista de compensados",
  "Prensista de lâmina de madeira",
  "Prensista de ourivesaria",
  "Prensista de plástico",
  "Prensista de rebolo de diamante",
  "Prensista de telhas e tijolos",
  "Preparador de aditivos",
  "Preparador de aditivos e massas refratárias",
  "Preparador de aglomerados de minérios e rochas",
  "Preparador de aglomerantes",
  "Preparador de amostras de minérios e rochas",
  "Preparador de anéis de parafina",
  "Preparador de área de corrida",
  "Preparador de areia - em fundição",
  "Preparador de argila",
  "Preparador de atleta",
  "Preparador de barbotina",
  "Preparador de calçados",
  "Preparador de cola para madeira",
  "Preparador de composição de matéria-prima (vidro)",
  "Preparador de couros curtidos",
  "Preparador de drinques e bebidas",
  "Preparador de esmalte vítreo",
  "Preparador de esmaltes (cerâmica)",
  "Preparador de essência de fumo",
  "Preparador de estruturas de aço",
  "Preparador de estruturas metálicas",
  "Preparador de explosivos bombeados - detonador (blaster)",
  "Preparador de ferramentas para máquinas-ferramenta",
  "Preparador de fotolitos",
  "Preparador de fresadora copiadora",
  "Preparador de fresadora de engrenagens",
  "Preparador de fumo",
  "Preparador de fumo na fabricação de charutos",
  "Preparador de furadeira",
  "Preparador de goma para colagem de madeira",
  "Preparador de guarnições",
  "Preparador de ingredientes (conservação de alimentos)",
  "Preparador de ligas na metalurgia",
  "Preparador de lotes e pacotes",
  "Preparador de máquina de tarraxar",
  "Preparador de máquina de transferência",
  "Preparador de máquinas de forjar",
  "Preparador de máquinas-ferramenta",
  "Preparador de massa (fabricação de abrasivos)",
  "Preparador de massa (fabricação de vidro)",
  "Preparador de massa abrasiva",
  "Preparador de massa cerâmica",
  "Preparador de massa de argila",
  "Preparador de massa de argila em cerâmica",
  "Preparador de massa de argila em olaria",
  "Preparador de massa para fabricação de papel",
  "Preparador de massa refratária (revestimento de forno)",
  "Preparador de massas alimentícias - na fabricação",
  "Preparador de matrizes de corte e vinco",
  "Preparador de melado e essência de fumo",
  "Preparador de mistura abrasiva",
  "Preparador de moldes (cerâmica)",
  "Preparador de moldes de fundição, à máquina",
  "Preparador de palmilhas",
  "Preparador de panelas",
  "Preparador de pastas (na fabricação de tintas)",
  "Preparador de peça para decapagem",
  "Preparador de peça para imersão",
  "Preparador de prensa mecânica de metais",
  "Preparador de produção de peças para bordar",
  "Preparador de ração natural para gado",
  "Preparador de rações",
  "Preparador de retificadora sem centro",
  "Preparador de saltos para calçados",
  "Preparador de solas e palmilhas",
  "Preparador de sucata e aparas",
  "Preparador de tela serigráfica de vidro",
  "Preparador de tela serigráfica para cerâmica",
  "Preparador de tintas",
  "Preparador de tintas (fábrica de tecidos)",
  "Preparador de torno automático",
  "Preparador de torno copiador",
  "Preparador de torno revólver",
  "Preparador e instalador de lodo para sondagem",
  "Preparador físico",
  "Preparador fisiocorporal",
  "PREPARADORES DE PASTA PARA FABRICAÇÃO DE PAPEL",
  "PREPARADORES E OPERADORES DE MÁQUINAS-FERRAMENTA CONVENCIONAIS",
  "Preposto de leiloeiro",
  "Presbítero",
  "Presidente da república",
  "Prestidigitador",
  "Primaz",
  "Primeiro oficial de  manutenção eletromecânica (utilidades)",
  "Primeiro oficial de manutenção na geração, transmissão e distribuição de energia elétrica",
  "Primeiro oficial de máquinas da marinha mercante",
  "Primeiro oficial de náutica",
  "Primeiro oficial de náutica (comandante)",
  "Primeiro oficial de náutica (imediato)",
  "Primeiro tenente de polícia militar",
  "Primeiro-sargento da aeronáutica",
  "Primeiro-sargento da marinha",
  "Primeiro-sargento do exército",
  "Primeiro-tenente bombeiro militar",
  "Primeiro-tenente da aeronáutica",
  "Primeiro-tenente da marinha",
  "Primeiro-tenente do corpo de bombeiros",
  "Primeiro-tenente do exército",
  "Prior",
  "Prioressa",
  "Processador de fumo",
  "Proctologista",
  "Procurador autárquico",
  "Procurador da assistência judiciária",
  "Procurador da fazenda nacional",
  "Procurador da república",
  "Procurador de fundação",
  "Procurador de justiça",
  "Procurador de justiça militar",
  "Procurador distrital",
  "Procurador do estado",
  "Procurador do município",
  "Procurador do trabalho",
  "Procurador federal",
  "Procurador fundacional",
  "Procurador municipal",
  "Procurador na Justiça",
  "Procurador regional da república",
  "Procurador regional do trabalho",
  "PROCURADORES E ADVOGADOS PÚBLICOS",
  "Produtor  em pecuária familiar",
  "Produtor agrícola polivalente",
  "Produtor agropecuário, em geral",
  "Produtor cinematográfico",
  "Produtor cultural",
  "Produtor da cultura de amendoim",
  "Produtor da cultura de canola",
  "Produtor da cultura de coco-da-baia",
  "Produtor da cultura de dendê",
  "Produtor da cultura de girassol",
  "Produtor da cultura de linho",
  "Produtor da cultura de mamona",
  "Produtor da cultura de palma",
  "Produtor da cultura de soja",
  "Produtor de abacate",
  "Produtor de abacaxi",
  "Produtor de agave",
  "Produtor de algodão",
  "Produtor de amendoim",
  "Produtor de arroz",
  "Produtor de árvores frutíferas",
  "Produtor de aveia",
  "Produtor de banana",
  "Produtor de cacau",
  "Produtor de café",
  "Produtor de caju",
  "Produtor de cana-de-açúcar",
  "Produtor de canola",
  "Produtor de caqui",
  "Produtor de centeio",
  "Produtor de cereais de inverno",
  "Produtor de cevada",
  "Produtor de coco",
  "Produtor de colza",
  "Produtor de conteúdo digital",
  "Produtor de curauá",
  "Produtor de dendê",
  "Produtor de erva-mate",
  "Produtor de especiarias",
  "Produtor de espécies frutíferas rasteiras",
  "Produtor de espécies frutíferas trepadeiras",
  "Produtor de flores de corte",
  "Produtor de flores em vaso",
  "Produtor de forrações",
  "Produtor de framboesa",
  "Produtor de fumo",
  "Produtor de girassol",
  "Produtor de gramíneas forrageiras",
  "Produtor de guaraná",
  "Produtor de húmus de minhoca",
  "Produtor de imagem (cinema)",
  "Produtor de imagem (televisão)",
  "Produtor de juta",
  "Produtor de kiwi",
  "Produtor de laranja",
  "Produtor de leite - na criação de gado bovino - empregador",
  "Produtor de linhaça",
  "Produtor de linho",
  "Produtor de maçã",
  "Produtor de mamão",
  "Produtor de mamona",
  "Produtor de manga",
  "Produtor de maracujá",
  "Produtor de melancia",
  "Produtor de melão",
  "Produtor de milho",
  "Produtor de milho e sorgo",
  "Produtor de moda",
  "Produtor de morango",
  "Produtor de mudas (florestas)",
  "Produtor de palma",
  "Produtor de pêra",
  "Produtor de pêssego",
  "Produtor de pinto de corte",
  "Produtor de plantas aromáticas e medicinais",
  "Produtor de plantas ornamentais",
  "Produtor de programa",
  "Produtor de rádio",
  "Produtor de rami",
  "Produtor de sementes forrageiras",
  "Produtor de sisal",
  "Produtor de soja",
  "Produtor de som (cinema)",
  "Produtor de som (rádio)",
  "Produtor de som (teatro)",
  "Produtor de som (televisão)",
  "Produtor de sorgo",
  "Produtor de teatro",
  "Produtor de televisão",
  "Produtor de texto",
  "Produtor de trigo",
  "Produtor de triticale",
  "Produtor de uva",
  "Produtor na olericultura de frutos e sementes",
  "Produtor na olericultura de legumes",
  "Produtor na olericultura de raízes, bulbos e tubérculos",
  "Produtor na olericultura de talos, folhas e flores",
  "Produtor rural de gado de corte",
  "Produtor rural em pecuária",
  "Produtor rural na agropecuária",
  "Produtor rural polivalente na agricultura - conta própria",
  "Produtor rural polivalente na agricultura - empregador",
  "PRODUTORES AGRÍCOLAS NA CULTURA DE GRAMÍNEAS",
  "PRODUTORES AGRÍCOLAS NA CULTURA DE PLANTAS ESTIMULANTES",
  "PRODUTORES AGRÍCOLAS NA CULTURA DE PLANTAS FIBROSAS",
  "PRODUTORES AGRÍCOLAS NA CULTURA DE PLANTAS OLEAGINOSAS",
  "PRODUTORES AGRÍCOLAS NA FRUTICULTURA",
  "PRODUTORES AGRÍCOLAS NA OLERICULTURA",
  "PRODUTORES AGRÍCOLAS NO CULTIVO DE FLORES E PLANTAS ORNAMENTAIS",
  "PRODUTORES AGRÍCOLAS POLIVALENTES",
  "PRODUTORES AGROPECUÁRIOS EM GERAL",
  null,
  "PRODUTORES ARTÍSTICOS E CULTURAIS",
  "PRODUTORES DA AVICULTURA E CUNICULTURA",
  "PRODUTORES DE ANIMAIS E INSETOS ÚTEIS",
  "PRODUTORES DE ESPECIARIAS E DE PLANTAS AROMÁTICAS E MEDICINAIS",
  "PRODUTORES EM PECUÁRIA DE ANIMAIS DE GRANDE PORTE",
  "PRODUTORES EM PECUÁRIA DE ANIMAIS DE MÉDIO PORTE",
  "PRODUTORES EM PECUÁRIA POLIVALENTE",
  "Proeiro",
  "Professor assistente de regência de classe",
  "Professor da  educação de jovens e adultos do ensino fundamental (primeira a quarta série)",
  "Professor da área de meio ambiente",
  "Professor das séries iniciais",
  "Professor de  língua portuguesa na modalidade escrita (ensino especial)",
  "Professor de administração",
  "Professor de administração financeira",
  "Professor de administração pública",
  "Professor de alemão",
  "Professor de álgebra linear (no ensino superior)",
  "Professor de aluno surdo-cego",
  "Professor de alunos com deficiência auditiva e surdos",
  "Professor de alunos com deficiência física",
  "Professor de alunos com deficiência mental",
  "Professor de alunos com deficiência múltipla",
  "Professor de alunos com deficiência visual",
  "Professor de alunos com deficiências mentais",
  "Professor de alunos com distúrbios da áudio-comunicação",
  "Professor de amostragem estatística (no ensino superior)",
  "Professor de análise de custos",
  "Professor de antropologia do ensino superior",
  "Professor de arquitetura",
  "Professor de arquivologia do ensino superior",
  "Professor de artes cênicas no ensino superior",
  "Professor de artes do ensino fundamental de de 5ª a 8ª série",
  "Professor de artes do espetáculo no ensino superior",
  "Professor de artes na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série",
  "Professor de artes no ensino médio",
  "Professor de artes visuais no ensino superior (artes plásticas e multimídia)",
  "Professor de astronomia (ensino superior)",
  "Professor de auditoria contábil",
  "Professor de biblioteconomia do ensino superior",
  "Professor de biologia no ensino médio",
  "Professor de braile",
  "Professor de cálculo numérico (no ensino superior)",
  "Professor de cegos",
  "Professor de ciência política do ensino superior",
  "Professor de ciências biológicas do ensino superior",
  "Professor de ciências exatas e naturais do ensino fundamental",
  "Professor de ciências na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série",
  "Professor de ciências naturais no ensino fundamental de 5ª a 8ª série",
  "Professor de circo no ensino superior",
  "Professor de clínica cirúrgica (ensino superior)",
  "Professor de clínica médica (ensino superior)",
  "Professor de computação (no ensino superior)",
  "Professor de comunicação social do ensino superior",
  "Professor de contabilidade",
  "Professor de contabilidade bancária",
  "Professor de contabilidade financeira",
  "Professor de contabilidade industrial",
  "Professor de contabilidade internacional",
  "Professor de contabilidade pública",
  "Professor de contabilidade societária",
  "Professor de creche",
  "Professor de crítica textual",
  "Professor de dança",
  "Professor de dança no ensino superior",
  "Professor de desenho técnico",
  "Professor de desenvolvimento econômico",
  "Professor de didática (ensino superior)",
  "Professor de direito do ensino superior",
  "Professor de disciplinas pedagógicas no ensino médio",
  "Professor de econometria",
  "Professor de economia",
  "Professor de economia brasileira",
  "Professor de economia internacional",
  "Professor de educação artística do ensino fundamental",
  "Professor de educação física do ensino fundamental",
  "Professor de educação física na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série",
  "Professor de educação física no ensino médio",
  null,
  "Professor de educação física no ensino superior",
  "Professor de enfermagem do ensino superior",
  "Professor de engenharia",
  "Professor de ensino especial na área de deficiência múltipla",
  "Professor de ensino fundamental - séries iniciais",
  "Professor de ensino fundamental de primeira a quarta séries",
  "Professor de ensino fundamental nas quatro primeiras séries",
  "Professor de ensino pré-escolar",
  "Professor de ensino pré-primário",
  "Professor de ensino profissionalizante no ensino de nível médio",
  "Professor de ensino superior na área de didática",
  "Professor de ensino superior na área de estágio supervisionado",
  "Professor de ensino superior na área de metodologia da pesquisa",
  "Professor de ensino superior na área de metodologia da pesquisa em educação",
  "Professor de ensino superior na área de orientação educacional",
  "Professor de ensino superior na área de pesquisa educacional",
  "Professor de ensino superior na área de prática de ensino",
  "Professor de ensino superior na área de prática de ensino e estágio supervisionado",
  "Professor de escolinha (maternal)",
  "Professor de espanhol",
  "Professor de estatística (no ensino superior)",
  "Professor de estimulação da língua portuguesa modalidade oral ( ensino especial)",
  "Professor de farmácia e bioquímica",
  "Professor de filologia",
  "Professor de filologia e crítica textual",
  "Professor de filologia germânica",
  null,
  "Professor de filologia portuguesa",
  "Professor de filologia românica",
  "Professor de filosofia do ensino superior",
  "Professor de filosofia no ensino médio",
  "Professor de finanças (administração)",
  "Professor de finanças públicas",
  "Professor de física (ensino superior)",
  "Professor de física no ensino médio",
  "Professor de fisioterapia",
  "Professor de fonoaudiologia",
  "Professor de francês",
  "Professor de futebol",
  "Professor de geofísica",
  "Professor de geografia do ensino fundamental",
  "Professor de geografia do ensino superior",
  "Professor de geografia no ensino médio",
  "Professor de geologia",
  "Professor de gestão (administração)",
  "Professor de história do ensino fundamental",
  "Professor de história do ensino superior",
  "Professor de história do pensamento econômico",
  "Professor de história econômica",
  "Professor de história no ensino médio",
  "Professor de informática (no ensino superior)",
  "Professor de inglês",
  "Professor de italiano",
  "Professor de jardim da infância",
  "Professor de jardim de infância (nível superior)",
  "Professor de jornalismo",
  "Professor de língua alemã",
  "Professor de língua brasileira de sinais (libras)",
  "Professor de língua e literatura brasileira no ensino médio",
  "Professor de língua espanhola",
  "Professor de língua estrangeira moderna  no ensino supletivo do ensino fundamental de  5ª a 8ª série",
  "Professor de língua estrangeira moderna do ensino fundamental",
  "Professor de língua estrangeira moderna no ensino médio",
  "Professor de língua francesa",
  "Professor de língua inglesa",
  "Professor de língua italiana",
  "Professor de língua portuguesa",
  "Professor de língua portuguesa do ensino fundamental",
  "Professor de língua portuguesa no ensino supletivo do ensino fundamental de 5ª a 8ª série",
  "Professor de línguas estrangeiras modernas",
  "Professor de lingüística",
  "Professor de lingüística aplicada",
  "Professor de lingüística e lingüística aplicada",
  "Professor de lingüística românica",
  "Professor de literatura alemã",
  "Professor de literatura brasileira",
  "Professor de literatura comparada",
  "Professor de literatura de línguas estrangeiras modernas",
  "Professor de literatura espanhola",
  "Professor de literatura francesa",
  "Professor de literatura inglesa",
  "Professor de literatura italiana",
  "Professor de literatura portuguesa",
  "Professor de macroeconomia",
  "Professor de marketing",
  "Professor de matemática (no ensino superior)",
  "Professor de matemática aplicada (no ensino superior)",
  "Professor de matemática do ensino fundamental",
  "Professor de matemática financeira (administração)",
  "Professor de matemática financeira (economia)",
  "Professor de matemática financeira (no ensino superior)",
  "Professor de matemática na educação de jovens e adultos do ensino fundamental de 5ª a 8ª série",
  "Professor de matemática no ensino médio",
  "Professor de matemática pura (no ensino superior)",
  "Professor de maternal",
  "Professor de maternal (nivel superior)",
  "Professor de medicina",
  "Professor de medicina veterinária",
  "Professor de microeconomia",
  "Professor de minimaternal",
  "Professor de museologia do ensino superior",
  "Professor de música no ensino superior",
  "Professor de nível médio na educação infantil",
  "Professor de nível médio no ensino fundamental",
  "Professor de nível médio no ensino profissionalizante",
  "Professor de nível superior do ensino fundamental (primeira a quarta série)",
  "Professor de nível superior na educação infantil (quatro a seis anos)",
  "Professor de nível superior na educação infantil (zero a três anos)",
  "Professor de nutrição",
  "Professor de odontologia",
  "Professor de organização e métodos (administração)",
  "Professor de orientação e mobilidade de cegos",
  "Professor de orientação educacional",
  "Professor de outras línguas e literaturas",
  "Professor de performance no ensino superior",
  "Professor de perícia contábil",
  "Professor de pesquisa econômica",
  "Professor de pesquisa educacional (ensino superior)",
  "Professor de pesquisa operacional (no ensino superior)",
  "Professor de pilates",
  "Professor de planejamento e desenvolvimento",
  "Professor de planejamento empresarial",
  "Professor de português",
  "Professor de prática de ensino (ensino superior)",
  "Professor de pré-escola",
  "Professor de psicologia do ensino superior",
  "Professor de psicologia no ensino médio",
  "Professor de química (ensino superior)",
  "Professor de química inorgânica (ensino superior)",
  null,
  "Professor de química no ensino médio",
  "Professor de química orgânica (ensino superior)",
  "Professor de reabilitação funcional de cego",
  "Professor de reabilitação visual",
  "Professor de semiótica",
  "Professor de serviço social do ensino superior",
  "Professor de sociologia do ensino superior",
  "Professor de sociologia no ensino médio",
  "Professor de sorobã",
  "Professor de suplência do ensino fundamental (primeira a quarta série)",
  "Professor de teatro no ensino superior",
  "Professor de teatro-educação no ensino superior",
  "Professor de técnicas agrícolas",
  "Professor de técnicas comerciais e secretariais",
  "Professor de técnicas de enfermagem",
  "Professor de técnicas e recursos audiovisuais",
  "Professor de técnicas industriais",
  "Professor de tecnologia e cálculo técnico",
  "Professor de teoria da administração",
  "Professor de teoria da literatura",
  "Professor de teoria econômica",
  "Professor de teoria matemática de sistemas (no ensino superior)",
  "Professor de terapia ocupacional",
  "Professor de zootecnia do ensino superior",
  "Professor dos ciclos iniciais (ensino fundamental)",
  "Professor em educação especial de DMu (deficiências múltiplas)",
  "Professor especializado em deficiência  auditiva e surdos",
  "Professor especializado em excepcionais",
  "Professor i - ensino fundamental",
  "Professor indigenista bilingüe",
  "Professor instrutor de ensino e aprendizagem agroflorestal",
  "Professor instrutor de ensino e aprendizagem em serviços",
  "Professor leigo em classe multisseriada",
  "Professor leigo em regência de classe",
  "Professor leigo no ensino fundamental",
  "Professor leigo responsável por escolas rurais",
  "Professor na área de deficiência física",
  "Professor não habilitado no ensino fundamental",
  "Professor prático no ensino profissionalizante",
  "Professor sem formação para o magistério",
  "Professor universitário de estágio supervisionado",
  "Professor universitário de metodologia do ensino",
  "Professor universitário em metodologia de pesquisa em educação",
  "Professor universitário na área de didática",
  "Professor universitário na área de orientação educacional",
  "Professor universitário na área de pesquisa educacional",
  "Professor universitário na área de prática de ensino",
  "Professor-instrutor de cursos de treinamento industrial",
  "Professor-instrutor de educação profissional industrial",
  "PROFESSORES DE ARQUITETURA E URBANISMO, ENGENHARIA, GEOFÍSICA E GEOLOGIA DO ENSINO",
  "PROFESSORES DE ARTES DO ENSINO SUPERIOR",
  "PROFESSORES DE CIÊNCIAS BIOLÓGICAS E DA SAÚDE DO ENSINO SUPERIOR",
  "PROFESSORES DE CIÊNCIAS ECONÔMICAS, ADMINISTRATIVAS E CONTÁBEIS DO ENSINO SUPERIOR",
  "PROFESSORES DE CIÊNCIAS FÍSICAS, QUÍMICAS E AFINS DO ENSINO SUPERIOR",
  "PROFESSORES DE CIÊNCIAS HUMANAS DO ENSINO SUPERIOR",
  "Professores de cursos livres",
  "PROFESSORES DE EDUCAÇÃO ESPECIAL",
  "PROFESSORES DE MATEMÁTICA, ESTATÍSTICA E INFORMÁTICA DO ENSINO SUPERIOR",
  "PROFESSORES DE NÍVEL MÉDIO NA EDUCAÇÃO INFANTIL",
  "PROFESSORES DE NÍVEL MÉDIO NO ENSINO FUNDAMENTAL",
  "PROFESSORES DE NÍVEL MÉDIO NO ENSINO PROFISSIONALIZANTE",
  "PROFESSORES DE NÍVEL SUPERIOR DO ENSINO FUNDAMENTAL (PRIMEIRA A QUARTA SÉRIES)",
  "PROFESSORES DE NÍVEL SUPERIOR NA EDUCAÇÃO INFANTIL",
  "PROFESSORES DE NÍVEL SUPERIOR NO ENSINO FUNDAMENTAL DE QUINTA A OITAVA SÉRIE",
  "PROFESSORES DO ENSINO MÉDIO",
  "PROFESSORES DO ENSINO PROFISSIONAL",
  "PROFESSORES LEIGOS NO ENSINO FUNDAMENTAL",
  "PROFESSORES NA ÁREA DE FORMAÇÃO PEDAGÓGICA DO ENSINO SUPERIOR",
  "PROFESSORES NAS ÁREAS DE LÍNGUA E LITERATURA DO ENSINO SUPERIOR",
  "PROFESSORES PRÁTICOS NO ENSINO PROFISSIONALIZANTE",
  "PROFISSIONAIS DA ADMINISTRAÇÃO DOS SERVIÇOS DE SEGURANÇA",
  "PROFISSIONAIS DA BIOTECNOLOGIA",
  "PROFISSIONAIS DA EDUCAÇÃO FÍSICA",
  "PROFISSIONAIS DA ESCRITA",
  "PROFISSIONAIS DA FISCALIZAÇÃO DE ATIVIDADES URBANAS",
  "PROFISSIONAIS DA INFORMAÇÃO",
  "PROFISSIONAIS DA INTELIGÊNCIA",
  "PROFISSIONAIS DA MATEMÁTICA",
  null,
  "PROFISSIONAIS DA METROLOGIA",
  "PROFISSIONAIS DA PILOTAGEM AERONÁUTICA",
  "PROFISSIONAIS DAS CIÊNCIAS ATMOSFÉRICAS E ESPACIAIS E DE ASTRONOMIA",
  "PROFISSIONAIS DAS TERAPIAS CRIATIVAS,EQUOTERÁPICAS E NATUROLÓGICAS",
  "PROFISSIONAIS DE ADMINISTRAÇÃO ECÔNOMICO-FINANCEIRA",
  "PROFISSIONAIS DE COMERCIALIZACÃO E CONSULTORIA DE SERVIÇOS BANCÁRIOS",
  "PROFISSIONAIS DE DIREITOS AUTORAIS E DE AVALIACÃO DE PRODUTOS DOS MEIOS DE COMUNICAÇÃO",
  "PROFISSIONAIS DE ESTATÍSTICA E AFINS",
  "PROFISSIONAIS DE MÍDIAS DIGITAIS E AFINS",
  "PROFISSIONAIS DE PLANEJAMENTO, PROGRAMAÇÃO E CONTROLES LOGISTICOS",
  "PROFISSIONAIS DE PUBLICIDADE",
  "PROFISSIONAIS DE RECURSOS HUMANOS",
  "Profissionais de relações institucionais",
  "PROFISSIONAIS DO JORNALISMO",
  "PROFISSIONAIS DO SEXO",
  "PROFISSIONAIS EM PESQUISA E ANÁLISE ANTROPOLÓGICA SOCIOLÓGICA",
  "PROFISSIONAIS EM PESQUISA E ANÁLISE GEOGRÁFICA",
  "PROFISSIONAIS POLIVALENTES DA CONFECÇÃO DE ROUPAS",
  "Profissional de atletismo",
  "Profissional de educação física na saúde",
  "Profissional de facility management",
  "Profissional de golfe",
  "Profissional de organização (personal organizer)",
  "Profissional de relações com investidores",
  "Profissional de relações com o mercado",
  "Profissional de relações governamentais",
  null,
  "Profissional de relações institucionais e governamentais",
  "Profissional de relações públicas",
  "Profissional de ri (relações com investidores)",
  "Profissional de tráfego aéreo",
  "Profissional do sexo",
  "Profissonal de relações internacionais",
  "Programador de aplicativos educacionais e de entretenimento",
  "Programador de comunicação de sistemas",
  "Programador de comunicação visual e sonora de sistemas",
  "Programador de controle de produção",
  "Programador de encaixe (cad)",
  "Programador de fotocomposição",
  "Programador de internet",
  "Programador de máquinas - ferramenta com comando numérico",
  "Programador de multimídia",
  "Programador de risco e corte",
  "Programador de sistemas de computador",
  "Programador de sistemas de informação",
  "Programador de transporte multimodal",
  "Programador de transporte rodoviário",
  "Programador de usinagem",
  "Programador e controlador de materiais",
  "Programador ferroviário",
  "Programador musical",
  "Programador visual gráfico",
  "PROGRAMADORES, AVALIADORES E ORIENTADORES DE ENSINO",
  "Projecionista",
  "Projetista (arquiteto)",
  "Projetista de áudio",
  "Projetista de eletricidade",
  "Projetista de ferramentas",
  "Projetista de fundição em siderurgia",
  "Projetista de instalações elétricas",
  "Projetista de instrumentos elétricos",
  "Projetista de matrizes",
  "Projetista de moldes",
  "Projetista de motores",
  "Projetista de móveis",
  "Projetista de sistemas de áudio",
  "Projetista de som",
  "Projetista elétrico",
  "Projetista eletroeletrônico",
  "Projetista eletrotécnico",
  "Projetista instrucional",
  "Projetista na arquitetura",
  "Projetista na construção civil",
  "Promotor de justiça",
  "Promotor de vendas",
  "Promotor de vendas especializado",
  "Propagandista",
  "Propagandista de produtos famacêuticos",
  "Proprietário - na criação de abelhas - empregador",
  "Proprietário - na criação de bicho-da-seda - empregador",
  "Proprietário - na criação de coelhos",
  "Proprietário - na criação de eqüinos",
  "Proprietário - na criação de gado bovino - empregador",
  "Proprietário - na criação de muares",
  "Proprietário - na criação de ovinos",
  "Proprietário - na criação de suínos empregador",
  "Proprietário - nos serviços de alimentação - empregador",
  "Proprietário - nos serviços de alojamento - empregador",
  "Proprietário - nos serviços de hospedagem - empregador",
  "Proprietário de areal - empregador",
  "Proprietário de bar - empregador",
  "Proprietário de bar, lanchonete, restaurante - conta própria",
  "Proprietário de casa funerária (comércio varejista)",
  "Proprietário de fliperama",
  "Proprietário de granja - na criação de aves",
  "Proprietário de granja de suínos",
  "Proprietário de hotel - conta própria",
  "Proprietário de hotel - empregador",
  "Proprietário de jogos eletrônicos",
  "Proprietário de lanchonete - empregador",
  "Proprietário de lavanderia",
  "Proprietário de lavanderia automática (comércio varejista)",
  "Proprietário de loja (comércio varejista)",
  "Proprietário de motel - conta própria",
  "Proprietário de oficina mecânica",
  "Proprietário de pebolim (comércio varejista)",
  "Proprietário de pedreira - empregador",
  "Proprietário de pensão - conta própria",
  "Proprietário de pensão - empregador",
  "Proprietário de porto de areia - empregador",
  "Proprietário de restaurante - empregador",
  "Proprietário de saibreira - empregador",
  "Proprietário de salina - empregador",
  "Proprietário de sinuquinha (comércio varejista)",
  "Proprietário de tinturaria (comércio varejista)",
  "Proprietário de totó (comércio varejista)",
  "Proprietário na agropecuária - conta própria",
  "Proprietário na criação de caprinos",
  "Proprietário no serviço de alimentação - conta própria",
  "Proprietário polivalente na agricultura - conta própria",
  "Proprietário polivalente na agricultura - empregador",
  "Prosador",
  "Prospector",
  "Prospector de jazidas",
  "Prospector de minerais",
  "Prospector de minérios",
  "Prostituta",
  "Protesista",
  "Protesista (técnico)",
  "Protesista bucomaxilofacial",
  "Protético dentário",
  "Prototipista (marceneiro)",
  "Provador de bebidas",
  "Provador de cacau",
  "Provador de café",
  "Provador de chá",
  "Provador de licores",
  "Provador de vinhos",
  "Provista (provas analógicas e digitais)",
  "Provista de clicheria (em cores)",
  "Provista tipógrafo",
  "Psicanalista",
  "Psicólogo acupunturista",
  "Psicólogo clínico",
  "Psicólogo criminal",
  "Psicólogo da educação",
  "Psicólogo da saúde",
  "Psicólogo desportivo",
  "Psicólogo do esporte",
  "Psicólogo do trabalho",
  "Psicólogo do trânsito",
  "Psicólogo educacional",
  "Psicólogo escolar",
  "Psicólogo forense",
  "Psicólogo hospitalar",
  "Psicólogo jurídico",
  "Psicólogo organizacional",
  "Psicólogo social",
  "PSICÓLOGOS E PSICANALISTAS",
  "Psicomotricista",
  "Psicopedagogo",
  "Psicoterapeuta",
  "Psiquiatra",
  "Publicitário",
  "Pugilista",
  "Pugilista de luta livre",
  "Pulverizador de metal, à pistola",
  "Pupunheiro",
  "Puxador de sisal - na cultura",
  "Quarteador em matadouro",
  "Quarteiro em matadouro",
  "Quebrador - na cultura de cacau",
  "Quebrador - na extração de pedras",
  "Quebrador de mamona",
  "Quebrador de ouricuri",
  "Queijeiro na fabricação de laticínio",
  "Queimador - em olaria",
  "Quelonicultor",
  "Químico",
  "Químico (fisico-química)",
  "Químico (produtos aromáticos)",
  "Químico (química inorgânica)",
  "Químico (química nuclear)",
  "Químico (química orgânica)",
  "Químico (tratamento de água)",
  "Químico agrícola",
  "Químico ambiental",
  "Químico analista",
  "Químico bromatologista",
  "Químico consultor",
  "Químico cosmetólogo",
  "Químico de alimentos",
  "Químico de cerâmica",
  "Químico de controle de qualidade",
  "Químico de curtume",
  "Químico de embalagens",
  "Químico de laboratório",
  "Químico de laboratório de controle",
  "Químico de laboratórios de solos",
  "Químico de petróleo",
  "Químico de polímeros",
  "Químico de processos",
  "Químico de produtos naturais",
  "Químico galvanoplasta",
  "Químico industrial",
  "Químico perfumista",
  "Químico têxtil",
  "QUÍMICOS",
  "Quirólogo",
  "Quiromante",
  "Quiropraxista",
  "Quitandeiro (comércio varejista)",
  "Rabino",
  "Rachador de couros e peles",
  "Rachador de lenha para carvão",
  "Rádio repórter",
  "Rádio-ator",
  "Radio-oncologista",
  "Radioestesista",
  "Radiofarmacêutico",
  "Radiojornalista",
  "Radiologista",
  "Radiologista intervencionista não vascular",
  "Radiologista intervencionista percutâneo",
  "Radiologista intervencionista vascular",
  "Radioperador",
  "Radiotécnico",
  "Radiotelegrafista",
  "Radioterapeuta",
  "Raizeiro",
  "Ralador de mandioca",
  "Ramicultor",
  "Rancheiro",
  "Rancheiro - na cultura",
  "Ranicultor",
  "Raspador",
  "Raspador de taco",
  "Raspador de tripas em matadouro",
  "Rasqueteador de metais",
  "Rasteleiro de asfalto",
  "Rastilheiro",
  "Rastreador de satélite",
  "Reabilitador oral",
  "Rebaixador de base (couros)",
  "Rebaixador de couros",
  "Rebarbador de metal",
  "Rebatedor de metais",
  "Rebitador a  martelo pneumático",
  "Rebitador, a  mão",
  "Rebobinador na fabricação de papel e papelão",
  "Rebordadeira",
  "Recebedor de apostas (loteria)",
  "Recebedor de apostas (turfe)",
  "Recenseador",
  "Recepcionista atendente",
  "Recepcionista auxiliar de secretária",
  "Recepcionista bilíngüe",
  "Recepcionista caixa",
  "Recepcionista de banco",
  "Recepcionista de casas de espetáculos",
  "Recepcionista de consultório médico ou dentário",
  "Recepcionista de crediário",
  "Recepcionista de empresa de navegação aérea",
  "Recepcionista de hospital",
  "Recepcionista de hotel",
  "Recepcionista de seguro saúde",
  "Recepcionista secretária",
  "Recepcionista telefonista",
  "Recepcionista vendedor de passagens aéreas",
  "Recepcionista, em geral",
  "RECEPCIONISTAS",
  "Recortador de palmilhas",
  "Recozedor e normalizador de metais",
  "Recreacionista",
  "Recreador",
  "Recreador de acantonamento",
  "RECREADORES",
  "Recuperador de ativos",
  "Recuperador de crédito",
  "Recuperador de guias e cilindros",
  "Recuperador externo",
  "Recuperador interno",
  "Redator de anais",
  "Redator de jornal",
  "Redator de manuais técnicos",
  "Redator de publicidade",
  "Redator de textos científicos",
  "Redator de textos comerciais",
  "Redator de textos técnicos",
  "Redeiro",
  "Redeiro (pesca)",
  "Refilador gráfico",
  "Refinador de óleo e gordura",
  "Refinador de pasta/celulose  para fabricação de papel",
  "Refinador de sal",
  "Reflorestador",
  "Reformador de móveis",
  "Reformador de pentes (máquinas têxteis)",
  "Reformadora de roupas",
  "Refratarista (caldeira e tubulações)",
  "Refratarista (pedreiro)",
  "Regador - na cultura",
  "Regente assistente",
  "Regente auxiliar",
  "Regente de banda",
  "Regente de coral",
  "Regente de orquestra",
  "Regente interno",
  "Registrador civil de pessoas jurídicas",
  "Registrador civil de pessoas naturais",
  "Registrador de câncer",
  "Registrador de contratos marítimos",
  "Registrador de títulos e documentos",
  "Registrador imobiliário",
  "Regulador de sinistros",
  "Reitor",
  "Rejuntador (construção civil)",
  "Rejuntador cerâmico",
  "Rejuntador de revestimentos",
  "Relações públicas",
  "Religiosa",
  "Religioso leigo",
  "Relojoeiro (comércio varejista)",
  "Relojoeiro (fabricação)",
  "Relojoeiro (reparação)",
  "Remador, na pesca de peixes e camarões",
  "Remendeira",
  "Remetedor de fios",
  "Rendeiro na agropecuária - exclusive conta própria e empregador",
  "Reparador de aparelhos de telecomunicações em laboratório",
  "Reparador de aparelhos eletrodomésticos (exceto imagem e som)",
  "Reparador de bicicletas",
  "Reparador de empilhadeiras",
  "Reparador de equipamentos de escritório",
  "Reparador de equipamentos elétricos e eletrônicos",
  "Reparador de equipamentos fotográficos",
  "Reparador de instalações telefônicas",
  "Reparador de instrumentos musicais",
  "Reparador de linhas  elétricas",
  "Reparador de linhas e aparelhos",
  "Reparador de máquinas",
  "Reparador de pabx",
  "Reparador de rádio, tv e som",
  "Reparador de telefone",
  "Reparador de tratores",
  "Reparador de veículos automotores",
  "Reparador técnico",
  "REPARADORES DE APARELHOS ELETRODOMÉSTICOS",
  "REPARADORES DE CARROCERIAS DE VEÍCULOS",
  "REPARADORES DE EQUIPAMENTOS DE ESCRITÓRIO",
  "REPARADORES DE EQUIPAMENTOS FOTOGRÁFICOS",
  "Repassador - na pecuária",
  "Repórter (exclusive rádio e televisão)",
  "Repórter cinematográfico",
  "Repórter correspondente",
  "Repórter de área",
  "Repórter de mídias audiovisuais",
  "Repórter de rádio",
  "Repórter de rádio e tv",
  "Repórter de televisão",
  "Repórter de web",
  "Repórter especial",
  "Repórter fotográfico",
  "Repositor - em supermercados",
  "Repositor de mercadorias",
  "Representante comercial autônomo",
  "Representante de cobrança",
  "Representante de controle de perdas",
  "Representante de produtos farmacêuticos",
  "Representante técnico de vendas",
  "REPRESENTANTES COMERCIAIS AUTÔNOMOS",
  "Reprodutor de clichê impressão (reprodução plástica)",
  "Reprodutor de desenhos sobre pedras litográficas",
  "Resgatista",
  "Resgatista socorrista",
  "Resideiro de sisal",
  "Responsável operacional de manutenção e recarga de extintor de incêndio",
  "Responsável técnico da calibração metrológica",
  "Responsável técnico de ensaios metrológicos",
  "Restaurador de edificações",
  "Restaurador de instrumentos musicais (exceto cordas arcadas)",
  "Restaurador de livros",
  "Restaurador de livros e documentos",
  "Restaurador de obras de arte",
  "RESTAURADORES DE INSTRUMENTOS MUSICAIS",
  "Retalhador de carne",
  "Retalhista (comércio varejista)",
  "Retificador  cnc",
  "Retificador (retificadora cilíndrica externa e interna)",
  "Retificador (retificadora de árvore de manivelas)",
  "Retificador (retificadora plana)",
  "Retificador de fieiras",
  "Retificador de motores a álcool",
  "Retificador de motores a diesel",
  "Retificador de motores a gasolina",
  "Retificador de motores de automóveis",
  "Retificador de motores de carros",
  "Retificador de motores de explosão",
  "Retificador de motores de veículos",
  "Retificador, em geral",
  "Retireiro",
  "Retireiro (caprinos)",
  "Retireiro - na pecuária",
  "Retireiro inseminador",
  "Retocador convencional",
  "Retocador de calçados",
  "Retocador de chapas de impressão (fotogravura)",
  "Retocador de pintura",
  "Retoquista (revelação fotográfica)",
  "Retratista",
  "Reumatologista",
  "Revelador de filmes fotográficos, em cores",
  "Revelador de filmes fotográficos, em preto e branco",
  "Reverendo",
  "Revestidor de interiores (papel e material plástico)",
  "Revestidor de interiores (papel, material plástico e emborrachados)",
  "Revestidor de móveis de madeira",
  "Revestidor de superfícies de concreto",
  "REVESTIDORES DE CONCRETO",
  "Revisor - na indústria  gráfica",
  "Revisor contábil",
  "Revisor de aparelhos eletrônicos",
  "Revisor de aparelhos telefônicos",
  "Revisor de equipamentos (controle de qualidade)",
  "Revisor de fios (produção têxtil)",
  "Revisor de linha de montagem (aparelhos eletrônicos)",
  "Revisor de produção de peças para bordar",
  "Revisor de qualidade de calçados",
  "Revisor de silk-screen",
  "Revisor de tecidos acabados",
  "Revisor de tecidos crus",
  "Revisor de texto",
  "Revisor gráfico",
  "Revisor têxtil",
  "Rimban (reitor de templo provincial)",
  "Riscador de copos",
  "Riscador de estruturas de aço",
  "Riscador de estruturas metálicas",
  "Riscador de palmilhas",
  "Riscador de roupas",
  "Riscador de tecidos",
  "Rizicultor",
  "Rizicultor - conta própria",
  "Rizicultor - empregador",
  "Rizipiscicultor",
  "Roadie (assistente de palco)",
  "Robotista (soldagem)",
  "Roçador - na cultura",
  "Roceiro - na agropecuária - exclusive conta própria e empregador",
  "Roceiro na agropecuária - conta própria",
  "Rodoboy",
  "Romancista",
  "Romaneador",
  "Ronda - organizações particulares de segurança",
  "Ronda de formiga (combate às formigas)",
  "Rondante - organizações particulares de segurança",
  "Rondante de guarda portuária",
  "Roshi",
  "Roteirista de jornal",
  "Roteirista na imprensa",
  "Rotogravador",
  "Rotulador",
  "Roupeiro de cinema",
  "Roupeiro de teatro",
  "Roupeiro de televisão",
  "Rumenal",
  "Sabugueiro",
  "Sacerdote",
  "Sacerdotisa",
  "Sacristão",
  "Safrista",
  "Saladeiro",
  "Salameiro na conservação de alimentos",
  "Salgadeiro",
  "Salgador - em charqueada",
  "Salgador de alimentos",
  "Salgador de pescados",
  "Salgador de tripas",
  "Salineiro (empregador)",
  "Salineiro (sal marinho)",
  "Salineiro - empregador",
  "Salineiro - na extração - empregador",
  "Salmorador na conservação de alimentos",
  "Salsicheiro (fabricação de lingüiça, salsicha e produtos similares)",
  "Salsicheiros na conservação de alimentos",
  "Saltador",
  "Saltimbanco",
  "Salva-surf",
  "Salva-vidas",
  "Sambista",
  "Sangrador (magarefe)",
  "Sanitarista",
  "Sapateiro (calçados sob medida)",
  "Sapateiro (comércio varejista)",
  "Sapateiro (confecção de calçados)",
  "Sapateiro (sapatos de balé)",
  "Sapateiro de confecção sob medida",
  "Sapateiro montador",
  "Sapateiro ortopédico",
  "Sargento bombeiro militar",
  "Sargento da policia militar",
  "Sargento do corpo de bombeiros militar",
  "Secador de areia",
  "Secador de arroz",
  "Secador de lã",
  "Secador de madeira",
  "Secador de malte",
  "Secretária (técnico em secretariado - português)",
  "Secretária trilíngüe",
  "Secretária(o) executiva(o)",
  "SECRETÁRIAS(OS) EXECUTIVAS(OS) E AFINS",
  "Secretário  bilíngüe",
  "Secretário  bilíngüe de diretoria",
  "Secretário  bilíngüe de gabinete",
  "Secretário  bilíngüe de presidência",
  "Secretário  de diretoria",
  "Secretário  de gabinete",
  "Secretário  de presidência",
  "Secretário  pleno",
  "Secretário  pleno  bilíngüe",
  "Secretário  pleno  trilíngüe",
  "Secretário  senior bilíngüe",
  "Secretário  sênior trilíngüe",
  "Secretário  trilíngüe de diretoria",
  "Secretário  trilíngüe de gabinete",
  "Secretário  trilíngüe de presidência",
  "Secretário (diplomacia)",
  "Secretário (técnico de nível médio)",
  "Secretário - executivo",
  "Secretário de escola(tecnólogo)",
  "Secretário de estado (serviço público federal)",
  "Secretário escolar(tecnólogo)",
  "Secretário estadual e distrital (serviço público estadual e distrital)",
  "Secretário municipal (serviço público municipal)",
  "Secretário sênior",
  "Secretário-assistente administrativo (técnico)",
  "Secretário-executivo de entidade religiosa",
  "Securitário",
  "Segundo oficial de máquinas da marinha mercante",
  "Segundo oficial de náutica",
  "Segundo oficial de náutica (comandante)",
  "Segundo oficial de náutica (imediato)",
  "Segundo tenente de polícia militar",
  "Segundo-sargento",
  "Segundo-sargento da aeronáutica",
  "Segundo-sargento da marinha",
  "Segundo-tenente bombeiro militar",
  "Segundo-tenente da aeronáutica",
  "Segundo-tenente da marinha",
  "Segundo-tenente do corpo de bombeiros",
  "Segundo-tenente do exército",
  "Segurança comunitário",
  "Segurança de evento",
  "Segurança pessoal",
  "Seladeira",
  "Selecionador de café",
  "Selecionador de calçados",
  "Selecionador de castanha de caju",
  "Selecionador de correspondências (correios e telégrafos)",
  "Selecionador de material reciclável",
  "Selecionador de pintos por sexo",
  "Selecionador de sucatas",
  "Selecionador e embalador de colheitas agrícolas",
  "Seleiro",
  "Seleiro (comércio varejista)",
  "Seleiro (equipamentos para equitação)",
  "Seleiro - na fabricação - empregador",
  "Seleiro na fabricação",
  "Semeador",
  "Sementeiro",
  "Sementeiro - na cultura",
  "Seminarista",
  "Senador",
  "Separador de cargas postais (correios e telégrafos)",
  "Separador de carnes em matadouro",
  "Separador de correspondências (correios e telégrafos)",
  "Separador de material reciclável",
  "Separador de sucata",
  "Separador de trefilados",
  "Sepultador",
  "Sericicultor - exclusive conta própria e empregador",
  "Sericultor",
  "Sericultor - conta própria",
  "Sericultor - exclusive conta própria e empregador",
  "Serigrafista",
  "Serigrafista (gráfico)",
  "Serigrafista em vidros",
  "Seringueiro",
  "Serpentarista",
  "Serpenteador",
  "Serpentineiro",
  "Serrador (usinagem da madeira)",
  "Serrador - em serrarias",
  "Serrador de árvores - na extração de madeira",
  "Serrador de bordas no desdobramento de madeira",
  "Serrador de chifres",
  "Serrador de lenha",
  "Serrador de madeira",
  "Serrador de madeira (serra circular múltipla)",
  "Serrador de madeira (serra de fita múltipla)",
  "Serrador de mármore",
  "Serrador de mocotó",
  "Serrador de parte dianteira",
  "Serrador de parte traseira",
  "Serrador de pedra (extração)",
  "Serrador de pedras (beneficiamento)",
  "Serrador de peito",
  "Serrador de ponta-de-agulha",
  "Serrador de porcos",
  "Serrador de toras",
  "Serralheiro",
  "Serralheiro de alumínio",
  "Serralheiro de ferro",
  "Serralheiro de manutenção",
  "Serralheiro de metal",
  "Serralheiro de produção",
  "Serralheiro de protótipo",
  "Serralheiro industrial",
  "Serralheiro modelista",
  "Serralheiro montador",
  "Serralheiro preparador",
  "Servente (construção civil)",
  "Servente de lanche",
  "Servente de limpeza",
  "Servente de limpeza e desossa em açougue",
  "Servente de obras",
  "Servente de pedreiro",
  "Servente de pedreiro na conservação de vias permanentes (exceto trilhos)",
  "Servente de reflorestamento",
  "Servente de serviços gerais na conservação de vias permanentes (exceto trilhos)",
  "SERVENTUÁRIOS DA JUSTIÇA E AFINS",
  "Sexador",
  "Sexador de pintos",
  "Sheikh",
  "Sileiro",
  "Sinaleiro (orientação de guindastes e equipamentos similares)",
  "Sinaleiro (ponte-rolante)",
  "Sinaleiro de campo nas operações de máquinas e equipamentos de elevações",
  "Sinaleiro naval",
  "Síndico (edifícios)",
  "Sirgueiro",
  "Sirieiro",
  "Sisaleiro",
  "Sitiante",
  "Sitiante - na criação de asininos e muares",
  "Sitiante - na criação de caprinos",
  "Sitiante - na criação de eqüinos",
  "Sitiante - na criação de suínos",
  "Sitiante na agropecuária - conta própria",
  "Sitiante na criação de ovinos - empregador",
  "Sitiante polivalente",
  "Sóchó",
  "Sóchó (superior de missão)",
  "Social media",
  "Sócio - proprietário - na criação de bicho-da-seda - empregador",
  "Sócio proprietário (comércio  varejista)",
  "Sócio proprietário - na criação de asininos",
  "Sócio proprietário - na criação de muares",
  "Sócio proprietário - no serviço de alimentação - conta própria",
  "Sócio proprietário - no serviço de alimentação - empregador",
  "Sócio proprietário de bar - empregador",
  "Sócio proprietário de bar, lanchonete - conta própria",
  "Sócio proprietário de lanchonete - empregador",
  "Sócio proprietário de pensão",
  "Sócio proprietário de pensão - conta própria",
  "Sócio proprietário de restaurante - conta própria",
  "Sócio proprietário de restaurante - empregador",
  "Sócio- proprietário - na criação de pequenos animais - empregador",
  "Sócio-proprietário - na criação de abelhas - empregador",
  "Sócio-proprietário - na criação de suínos empregador",
  "Sócio-proprietário - na extração mineral - empregador",
  "Sócio-proprietário - nos serviços de alojamento - empregador",
  "Sócio-proprietário - nos serviços de hospedagem - empregador",
  "Sócio-proprietário de areal - empregador",
  "Sócio-proprietário de hotel - conta-própria",
  "Sócio-proprietário de hotel - empregador",
  "Sócio-proprietário de motel - conta própria",
  "Sócio-proprietário de motel - empregador",
  "Sócio-proprietário de pedreira - empregador",
  "Sócio-proprietário de porto de areia - empregador",
  "Sócio-proprietário de saibreira - empregador",
  "Sócio-proprietário de salina - empregador",
  "Sócio-proprietário na agropecuária - conta própria",
  "Sócio-proprietário polivalente na agricultura - conta própria",
  "Sócio-proprietário polivalente na agricultura - empregador",
  "Sócioeducador",
  "Sociólogo",
  "Socorrista (exceto médicos e enfermeiros)",
  "Sojicultor",
  "Sokan",
  "Soldado bombeiro militar",
  "Soldado da aeronáutica",
  "Soldado da polícia militar",
  "Soldado do exército",
  "Soldado fuzileiro naval",
  "Soldador",
  "Soldador a  oxiacetileno",
  "Soldador a  oxigás",
  "Soldador a  oxigênio",
  "Soldador a arco elétrico",
  "Soldador aluminotérmico em conservação de trilhos",
  "Soldador autógeno",
  "Soldador com maçarico e arco elétrico",
  "Soldador de jóias",
  "Soldador de oficina mecânica",
  "Soldador de solda à forte",
  "Soldador de solda branca",
  "Soldador de solda elétrica e oxiacetileno",
  "Soldador de veículos",
  "Soldador elétrico",
  "Soldador manual",
  "Soldador mecânico",
  "Sommalier",
  "Sommelier",
  "Sommelier de cachaça",
  "Sommelier de cerveja",
  "Sommelier de saquê",
  "Sommelier de vinho",
  "Sondador (poços de petróleo e gás)",
  "Sondador - na mineração",
  "Sondador de geofísica",
  "Sondador de poços (exceto de petróleo e gás)",
  "Sondador de poços tubulares e sistemas rotativos",
  "Sonoplasta",
  "Soprador de convertedor",
  "Soprador de vidro",
  "Soprador de vidro (a máquina)",
  "Soprador de vidro (material de laboratório)",
  "SOPRADORES, MOLDADORES E MODELADORES DE VIDROS E AFINS",
  "Sorveiro",
  "Sorveteiro",
  "Sorveteiro (comércio ambulante)",
  "Sorveteiro (comércio varejista)",
  "Sota",
  "Sound designer",
  "Stylist",
  "Sub-chefe de máquinas da marinha mercante",
  "Subchefe de bar",
  "Subchefe de cozinha",
  "Subchefe de galvanização",
  "Subchefe de loja (operações comerciais)",
  "Subcontador",
  "Subdiretor de ensino privado",
  "Subencarregado central de concreto",
  "Subencarregado de escritório",
  "Subencarregado de terraplenagem",
  "Subgerente de hotel",
  "Subgerente de loja (operações comerciais)",
  "Subgerente de oficina (assistência técnica)",
  "Subgerente de restaurante",
  "Subgerente nos transportes",
  "Subidor de coqueiro",
  "Suboficial da aeronáutica",
  "Suboficial da marinha",
  "Subprocurador de justiça militar",
  "Subprocurador-geral da república",
  "Subprocurador-geral do trabalho",
  "Subscritor assistente de seguros",
  "Subsecretário de estado (serviço público federal)",
  "Subtenente bombeiro militar",
  "Subtenente da policia militar",
  "Subtenente do corpo de bombeiros militar",
  "Subtenente do exército",
  "SUBTENENTES E SARGENTOS DA POLICIA MILITAR",
  "SUBTENENTES E SARGENTOS DO CORPO DE BOMBEIROS MILITAR",
  "Sucubeiro",
  "Suinocultor - criador de leitão",
  "Suinocultor - empregador",
  "Suinocultor - exclusive conta  própria",
  "Suinocultor cabanheiro",
  "Suinocultor crecheiro",
  "Superintendente administrativo",
  "Superintendente administrativo e financeiro",
  "Superintendente comercial",
  "Superintendente de contratos de operações de obras pública e civil",
  "Superintendente de culto religioso",
  "Superintendente de desenvolvimento de produtos e processos",
  "Superintendente de finanças",
  "Superintendente de manutenção",
  "Superintendente de marketing",
  "Superintendente de operações industriais",
  "Superintendente de relações institucionais",
  "Superintendente de seção de estudos e programação",
  "Superintendente de seção de estudos especiais",
  "Superintendente de serviços culturais",
  "Superintendente de usina central de concreto",
  "Superintendente financeiro",
  "Superintendente técnico no transporte aquaviário",
  "Superior de culto religioso",
  "Superior geral",
  "Superiora de culto religioso",
  "Supervisor  (indústria de calçados e artefatos de couro)",
  "Supervisor (indústria de automotores e de material de transporte)",
  "Supervisor administrativo",
  "Supervisor administrativo de escritório",
  "Supervisor administrativo de pessoal",
  "Supervisor administrativo interno",
  "Supervisor comercial",
  "Supervisor da administração de aeroportos",
  "Supervisor da aqüicultura",
  "Supervisor da área florestal",
  "Supervisor da confecção de artefatos de tecidos, couros e afins",
  "Supervisor da indústria de bebidas",
  "Supervisor da indústria de fumo",
  "Supervisor da indústria de minerais não metálicos (exceto os derivados de petróleo e carvão)",
  "Supervisor da manutenção e reparação de veículos leves",
  "Supervisor da manutenção e reparação de veículos pesados",
  "Supervisor da mecânica de precisão",
  "Supervisor das artes gráficas  (indústria editorial e gráfica)",
  "Supervisor de acabamento de confecção de calçados",
  "Supervisor de açougue",
  "Supervisor de administração",
  "Supervisor de administração e arquivo técnico",
  "Supervisor de almoxarifado",
  "Supervisor de alta freqüência de confecção de calçados",
  "Supervisor de alto-forno",
  "Supervisor de andar",
  "Supervisor de apoio operacional na mineração",
  "Supervisor de área de operação elétrica",
  "Supervisor de arrecadação (apostas e jogos)",
  "Supervisor de atendimento (caixas e bilheteiros)",
  "Supervisor de atendimento ao cliente",
  "Supervisor de ativo fixo",
  "Supervisor de bar",
  "Supervisor de bilheteira de transportes",
  "Supervisor de bombeiros",
  "Supervisor de britagem (mineração)",
  "Supervisor de caixas e bilheteiros (exceto caixa de banco)",
  "Supervisor de calcinação",
  "Supervisor de câmbio",
  "Supervisor de carga e descarga",
  "Supervisor de carpintaria",
  "Supervisor de carpinteiro de manutenção",
  "Supervisor de carregamento de minérios",
  "Supervisor de central de atendimento",
  "Supervisor de centro de controle (ferrovia e metrô)",
  "Supervisor de cobrança",
  "Supervisor de cobrança de serviço financeiro, de câmbio e controle",
  "Supervisor de coleta",
  "Supervisor de coletadores de apostas e de jogos",
  "Supervisor de compras",
  "Supervisor de conservação de obras",
  "Supervisor de construção civil",
  "Supervisor de construção de fornos",
  "Supervisor de construção e conservação",
  "Supervisor de construção naval",
  "Supervisor de construções e manutenção",
  "Supervisor de contabilidade",
  "Supervisor de contas a pagar",
  "Supervisor de controle de linhas ferroviárias",
  "Supervisor de controle de processos documentais",
  "Supervisor de controle de tratamento térmico",
  "Supervisor de controle documental",
  "Supervisor de controle patrimonial",
  "Supervisor de convertedor",
  "Supervisor de corte de confecção de calçados",
  "Supervisor de corte na confecção do vestuário",
  "Supervisor de costura do vestuário",
  "Supervisor de cozinha",
  "Supervisor de crédito",
  "Supervisor de crédito e cobrança",
  "Supervisor de curtimento",
  "Supervisor de dessulfuração",
  "Supervisor de digitação",
  "Supervisor de digitação e operação",
  "Supervisor de embalagem (produção farmacêutica)",
  "Supervisor de embalagem e etiquetagem",
  "Supervisor de empresa aérea em aeroportos",
  "Supervisor de ensacamento",
  "Supervisor de ensino",
  "Supervisor de entrevistadores e recenseadores",
  "Supervisor de envasamento",
  "Supervisor de escavação e transporte (mineração)",
  "Supervisor de estação (metrô e ferrovia)",
  "Supervisor de estação ferroviária e rodoviária",
  "Supervisor de exploração agrícola",
  "Supervisor de exploração agropecuária",
  "Supervisor de exploração pecuária",
  "Supervisor de extração de sal",
  "Supervisor de fabricação (produção farmacêutica)",
  "Supervisor de fabricação de instrumentos de precisão",
  "Supervisor de fabricação de instrumentos musicais",
  "Supervisor de fabricação de produtos cerâmicos, porcelanatos e afins",
  "Supervisor de fabricação de produtos de vidro",
  "Supervisor de fibras e utilidades",
  "Supervisor de forno-panela",
  "Supervisor de forno-poço",
  "Supervisor de hospedagem",
  "Supervisor de injeção de confecção de calçados",
  "Supervisor de joalheria",
  "Supervisor de laboratório fotográfico",
  "Supervisor de laminador de bloco e tarugos",
  "Supervisor de laminador desbastador",
  "Supervisor de lavanderia",
  "Supervisor de lavra (mineração)",
  "Supervisor de lingotamento",
  "Supervisor de linhas de acabamento",
  "Supervisor de local de aplicação (concurso, avaliação, exame)",
  "Supervisor de logística",
  "Supervisor de manutenção de aparelhos térmicos, de climatização e de refrigeração",
  "Supervisor de manutenção de bombas, motores, compressores e equipamentos de transmissão",
  "Supervisor de manutenção de máquinas e equipamentos",
  "Supervisor de manutenção de máquinas gráficas",
  "Supervisor de manutenção de máquinas industriais têxteis",
  "Supervisor de manutenção de máquinas operatrizes e de usinagem",
  "Supervisor de manutenção de veículos de carga",
  "Supervisor de manutenção de veículos de passeio",
  "Supervisor de manutenção de vias férreas",
  "Supervisor de manutenção elétrica de alta tensão industrial",
  "Supervisor de manutenção eletromecânica",
  "Supervisor de manutenção eletromecânica (utilidades)",
  "Supervisor de manutenção eletromecânica industrial, comercial e predial",
  "Supervisor de manutenção em lubrificação de vias permanentes",
  "Supervisor de manutenção industrial",
  "Supervisor de manutenção mecânica de sistemas operacionais",
  "Supervisor de manutenção na geração ,transmissão e distribuição de energia elétrica",
  "Supervisor de máquina de terraplenagem",
  "Supervisor de máquinas para fabricar papel",
  "Supervisor de materiais em almoxarifado",
  "Supervisor de matrizaria de confecção de calçados",
  "Supervisor de mina",
  "Supervisor de modelagem de confecção de calçados",
  "Supervisor de montagem",
  "Supervisor de montagem de confecção de calçados",
  "Supervisor de montagem e instalação eletroeletrônica",
  "Supervisor de movimento de trens",
  "Supervisor de operação (indústria petroquímica e carboquímica)",
  "Supervisor de operação de fluidos (distribuição, captação, tratamento de água, gases, vapor)",
  "Supervisor de operação elétrica (geração, transmissão e distribuição de energia elétrica)",
  "Supervisor de operações (mídias audiovisuais)",
  "Supervisor de operações de sistemas de televisão e produtoras de vídeo",
  "Supervisor de operações logísticas",
  "Supervisor de operações na área de controle de produção",
  "Supervisor de operações portuárias",
  "Supervisor de operações turísticas",
  "Supervisor de orçamento",
  "Supervisor de papel e acabamento",
  null,
  "Supervisor de pátio de madeira",
  "Supervisor de pátio e sucata",
  "Supervisor de perfuração e desmonte",
  "Supervisor de pesponto de confecção de calçados",
  "Supervisor de pintura (tratamento de superfícies)",
  "Supervisor de pré-frezado de confecção de calçados",
  "Supervisor de processamento (produção farmacêutica)",
  "Supervisor de processo de fibras, químicos e madeira",
  "Supervisor de produção (artefatos de couro)",
  "Supervisor de produção (indústria de material elétrico e eletrônico)",
  "Supervisor de produção (produção farmacêutica)",
  "Supervisor de produção da indústria alimentícia",
  "Supervisor de produção de celulose",
  "Supervisor de produção de líquidos (produção farmacêutica)",
  "Supervisor de produção de papel",
  "Supervisor de produção de semi-sólidos (produção farmacêutica)",
  "Supervisor de produção de sólidos (produção farmacêutica)",
  "Supervisor de produção e lavra (mineração)",
  "Supervisor de produção na fabricação de produtos de cerâmica, porcelanatos e afins",
  "Supervisor de produção na fabricação de produtos de vidro",
  "Supervisor de produção na mineração",
  "Supervisor de produção química",
  "Supervisor de recepção de hotel",
  "Supervisor de recepcionistas",
  "Supervisor de recuperação de produtos químicos",
  "Supervisor de reparos linhas férreas",
  "Supervisor de seção de serviços gerais",
  "Supervisor de segurança (vigilância)",
  "Supervisor de segurança do trabalho",
  "Supervisor de segurança patrimonial",
  "Supervisor de serraria",
  "Supervisor de teleatendimento",
  "Supervisor de telefonistas",
  "Supervisor de telemarketing e atendimento",
  "Supervisor de televendas",
  "Supervisor de tesouraria",
  "Supervisor de tesouraria de apostas e jogos",
  "Supervisor de tráfego rodoviário",
  "Supervisor de transporte na mineração",
  "Supervisor de transportes",
  "Supervisor de trens",
  "Supervisor de turno (mineração)",
  "Supervisor de usina de concreto",
  "Supervisor de vendas comercial",
  "Supervisor de vendas de serviços",
  "Supervisor de vendas no atacado",
  "Supervisor de vendas no varejo",
  "Supervisor de vigilantes",
  "Supervisor do setor de embalagem",
  "Supervisor educacional",
  "Supervisor florestal",
  "Supervisor geral de operação (metrô)",
  "Supervisor operacional de lavanderia",
  "Supervisor operacional dos serviços de máquinas e veículos",
  "Supervisor pedagógico",
  "Supervisor técnico (mídias audiovisuais)",
  "Supervisor técnico de aplicação e distribuição de gás",
  "Supervisor técnico de manutenção elétrica (distribuição de energia)",
  "Supervisor técnico de operação de água e esgoto",
  "Supervisor técnico de operação de sistema de geração, transmissão e distribuição de energia elétrica",
  "Supervisor técnico operacional de sistemas de televisão e produtoras de vídeo",
  "SUPERVISORES ADMINISTRATIVOS",
  "SUPERVISORES DA CONFECÇÃO DE ARTEFATOS DE TECIDOS, COUROS E AFINS",
  "SUPERVISORES DA CONSTRUÇÃO CIVIL",
  "SUPERVISORES DA EXTRAÇÃO MINERAL",
  "SUPERVISORES DA FABRICAÇÃO DE ALIMENTOS, BEBIDAS E FUMO",
  "SUPERVISORES DA FABRICAÇÃO DE CELULOSE E PAPEL",
  "SUPERVISORES DA FABRICAÇÃO E MONTAGEM METALMECÂNICA",
  "SUPERVISORES DA INDÚSTRIA TÊXTIL",
  "SUPERVISORES DA MECÂNICA DE PRECISÃO E INSTRUMENTOS MUSICAIS",
  "SUPERVISORES DA PRODUÇÃO DE UTILIDADES",
  "SUPERVISORES DAS ARTES GRÁFICAS",
  "SUPERVISORES DE ATENDIMENTO AO PÚBLICO E DE PESQUISA",
  "SUPERVISORES DE JOALHERIA E AFINS",
  "SUPERVISORES DE LAVANDERIA",
  "Supervisores de manutenção de máquinas e equipamentos comerciais e residenciais",
  "SUPERVISORES DE MANUTENÇÃO ELETROELETRÔNICA INDUSTRIAL, COMERCIAL E PREDIAL",
  "SUPERVISORES DE MANUTENÇÃO ELETROELETRÔNICA VEICULAR",
  null,
  "SUPERVISORES DE MANUTENÇÃO ELETROMECÂNICA",
  "SUPERVISORES DE MONTAGENS E INSTALAÇÕES ELETROELETRÔNICAS",
  "SUPERVISORES DE OUTROS TRABALHADORES DE SERVIÇOS DE REPARAÇÃO, CONSERVAÇÃO E",
  "SUPERVISORES DE PRODUÇÃO EM INDÚSTRIAS DE PRODUTOS FARMACÊUTICOS, COSMÉTICOS E AFINS",
  "SUPERVISORES DE PRODUÇÃO EM INDÚSTRIAS DE TRANSFORMAÇÃO DE PLÁSTICOS E BORRACHAS",
  "SUPERVISORES DE PRODUÇÃO EM INDÚSTRIAS QUÍMICAS, PETROQUÍMICAS E AFINS",
  "SUPERVISORES DE PRODUÇÃO EM INDÚSTRIAS SIDERÚRGICAS",
  "SUPERVISORES DE SERVIÇOS FINANCEIROS, DE CÂMBIO E DE CONTROLE",
  "SUPERVISORES DE TRABALHADORES DE EMBALAGEM E ETIQUETAGEM",
  "SUPERVISORES DE USINAGEM, CONFORMAÇÃO E TRATAMENTO DE METAIS",
  "SUPERVISORES DE VENDAS E DE PRESTAÇÃO DE SERVIÇOS",
  "SUPERVISORES DE VIDRARIA, CERÂMICA E AFINS",
  "SUPERVISORES DOS SERVIÇOS DE PROTEÇÃO, SEGURANÇA E OUTROS",
  "SUPERVISORES DOS SERVIÇOS DE TRANSPORTE, TURISMO, HOTELARIA E ADMINISTRAÇÃO DE EDIFÍCIOS",
  "SUPERVISORES EM INDÚSTRIA DE MADEIRA, MOBILIÁRIO E DA CARPINTARIA VEICULAR",
  "SUPERVISORES EM SERVIÇOS DE REPARAÇÃO E MANUTENÇÃO DE MÁQUINAS E EQUIPAMENTOS",
  "SUPERVISORES EM SERVIÇOS DE REPARAÇÃO E MANUTENÇÃO VEICULAR",
  "SUPERVISORES NA ÁREA FLORESTAL E AQÜICULTURA",
  "SUPERVISORES NA CONFECÇÃO DE CALÇADOS",
  "SUPERVISORES NA CONFECÇÃO DO VESTUÁRIO",
  "SUPERVISORES NA EXPLORAÇÃO AGROPECUÁRIA",
  "SUPERVISORES NA FABRICAÇÃO DE MATERIAIS PARA CONSTRUÇÃO (VIDROS E CERÂMICAS)",
  "SUPERVISORES NA INDÚSTRIA DO CURTIMENTO",
  "SUPERVISORES OPERACIONAIS E TÉCNICOS EM MÍDIAS AUDIOVISUAIS",
  "Surfassagista",
  "Sushiman",
  null,
  "Swami",
  "Swami (missionário)",
  "TABELIÃES E REGISTRADORES",
  "Tabelião da justiça",
  "Tabelião de contratos marítimos",
  "Tabelião de notas",
  "Tabelião de protesto de letras e títulos",
  "Tabelião de protestos",
  "Tabelião substituto",
  "Taifeiro (exceto militares)",
  "Taifeiro da aeronáutica",
  "Taifeiro do exército",
  "Taifeiro-mor da aeronáutica",
  "Taifeiro-mor do exército",
  "Talabarteiro - empregador (selas)",
  "Talhador de carne",
  "Talhador de roupas",
  "Talhador em açougue",
  "Talhador em matadouro",
  "Tanoeiro",
  "Tapeceiro a mão (tapetes)",
  "Tapeceiro de autos",
  "Tapeceiro de aviões",
  "Tapeceiro de móveis",
  "Tapeceiro de veículos",
  "Taqueiro",
  "Taquígrafo",
  "Taquígrafo em línguas estrangeiras",
  "Taquígrafo judiciário",
  "Taquígrafo parlamentar",
  "Taquígrafo revisor",
  "Tarefeiro - na cultura",
  "Tarefeiro na cultura de erva-mate",
  "Tarólogo",
  "Tarrafeador na pesca de peixes e camarões",
  "Tata kisaba",
  "Tata nkisi",
  "Tateto ndenge",
  "Tatuador",
  "Taverneiro",
  "Taxeiro",
  "Taxidermista",
  "Taxista",
  "Tecedor - exclusive de tapetes",
  "Tecedor de redes",
  "Tecedor de redes - inclusive de punho, mamucaba, varanda",
  "Tecedor de tapetes",
  "Tecelão (redes)",
  "Tecelão (rendas e bordados)",
  "Tecelão (tear automático)",
  "Tecelão (tear jacquard)",
  "Tecelão (tear manual)",
  "Tecelão (tear mecânico de maquineta)",
  "Tecelão (tear mecânico de xadrez)",
  "Tecelão (tear mecânico liso)",
  "Tecelão (tear mecânico, exceto jacquard)",
  "Tecelão - exclusive de tapetes",
  "Tecelão de alfombras, a  mão",
  "Tecelão de bordados",
  "Tecelão de fitas",
  "Tecelão de malhas (máquina circular)",
  "Tecelão de malhas (máquina retilínea)",
  "Tecelão de malhas, a  máquina",
  "Tecelão de malhas, a mão",
  "Tecelão de meias (máquina circular)",
  "Tecelão de meias (máquina retilínea)",
  "Tecelão de meias, a  máquina",
  "Tecelão de redes - inclusive de punho, mamucaba, varanda",
  "Tecelão de rendas",
  "Tecelão de tapeçaria em tear manual",
  "Tecelão de tapetes, a  mão",
  "Tecelão de tapetes, a  máquina",
  "Tecelão de tear de fita",
  "Técnica de cinema",
  "Técnico aeronáutico",
  "Técnico agrícola",
  "Técnico agrícola executor de operações aéreas agrícolas",
  "Técnico agropecuário",
  "Técnico ambiental",
  "Técnico analista de controle de produtos manufaturados",
  "Técnico analista de controle estatístico de qualidade",
  "Técnico analista de estudos de tempo",
  "Técnico analista de materiais",
  "Técnico analista de padrões de qualidade",
  "Técnico analista de pcp",
  "Técnico analista de pesquisa de controle de qualidade",
  "Técnico analista de processos de produção",
  "Técnico analista de produção",
  "Técnico analista de programação",
  "Técnico analista de qualidade",
  "Técnico analista de recebimento de peças compradas",
  "Técnico automotivo",
  "Técnico ceramista",
  "Técnico corporal em medicina tradicional chinesa",
  "Técnico da receita federal",
  "Técnico de abastecimento de combustível de aeronave",
  "Técnico de abastecimento, captação e distribuição de água",
  "Técnico de acabamento em siderurgia",
  "Técnico de aciaria em siderurgia",
  "Técnico de alimentos",
  "Técnico de alto-forno em siderurgia",
  "Técnico de análise de óleos e graxas",
  "Técnico de análise química",
  "Técnico de analista de custos (construção civil)",
  "Técnico de aparelhos ortopédicos",
  "Técnico de apoio à bioengenharia",
  "Técnico de apoio ao usuário de informática (helpdesk)",
  "Técnico de apoio em pesquisa e desenvolvimento (exceto agropecuário e florestal)",
  "Técnico de apoio em pesquisa e desenvolvimento agropecuário florestal",
  "Técnico de apoio em pesquisa e desenvolvimento de processos",
  "Técnico de apoio em pesquisa e desenvolvimento de produtos",
  "Técnico de apoio em pesquisa e desenvolvimento de tecnologias energéticas",
  "Técnico de apoio em pesquisa e desenvolvimento em telecomunicações",
  "Técnico de arquivo (museologia)",
  "Técnico de artes gráficas",
  "Técnico de áudio (rádio)",
  "Técnico de avaliação de capacidade de estação de tratamento e distribuição de água e esgoto",
  "Técnico de balanças (eletrônicas)",
  "Técnico de bebidas",
  "Técnico de biblioteca",
  "Técnico de biotério",
  "Técnico de calculadora eletrônica",
  "Técnico de camarão",
  "Técnico de captação de som",
  "Técnico de carnes e derivados",
  "Técnico de celulose e papel",
  "Técnico de central térmica",
  "Técnico de ciências químicas",
  "Técnico de comandos e controle",
  "Técnico de comunicação de dados",
  "Técnico de comutação telefônica",
  "Técnico de construção civil",
  "Técnico de construção de estradas",
  "Técnico de contabilidade",
  "Técnico de controladoria",
  "Técnico de controle de lingoteira",
  "Técnico de controle de meio ambiente",
  "Técnico de controle de processo de manutenção eletrônica",
  "Técnico de controle de qualidade",
  "Técnico de controle de qualidade de alimentos",
  "Técnico de controle interno na produção",
  "Técnico de convertedor de aciaria em siderurgia",
  "Técnico de copiadora",
  "Técnico de desenvolvimento de análise química",
  "Técnico de desenvolvimento de sistemas (ti)",
  "Técnico de desenvolvimento de software",
  "Técnico de desporto individual e coletivo (exceto futebol)",
  "Técnico de documentação",
  "Técnico de documentação e informação",
  "Técnico de edificações",
  "Técnico de edificações, estradas e saneamento",
  "Técnico de eletricidade",
  "Técnico de enfermagem",
  "Técnico de enfermagem da estratégia de saúde da família",
  "Técnico de enfermagem de terapia intensiva",
  "Técnico de enfermagem do trabalho",
  "Técnico de enfermagem em saúde mental",
  "Técnico de enfermagem em saúde ocupacional",
  "Técnico de enfermagem ocupacional",
  "Técnico de enfermagem psiquiátrica",
  "Técnico de engenharia civil",
  "Técnico de ensaios elétricos",
  "Técnico de ensaios mecânicos (caldeiraria)",
  "Técnico de equipamentos elétricos",
  "Técnico de estradas",
  "Técnico de externas (operações audiovisuais)",
  "Técnico de externas (sistemas audiovisuais)",
  "Técnico de fiação",
  "Técnico de frutas e hortaliças",
  "Técnico de fundição em siderurgia",
  "Técnico de garantia da qualidade",
  "Técnico de geologia",
  "Técnico de gestão do meio ambiente",
  "Técnico de grãos e cereais",
  "Técnico de gravação de áudio",
  "Técnico de identificação (papiloscopista)",
  "Técnico de imobilização ortopédica",
  "Técnico de impressora (matricial)",
  "Técnico de indústria  petroquímica",
  "Técnico de indústria eletrônica",
  "Técnico de indústria química",
  "Técnico de inspeção da indústria metalúrgica",
  "Técnico de inspeção e especificação de materiais e equipamentos de construção de estradas",
  "Técnico de inspeção e especificação de materiais e equipamentos de obras de saneamento",
  "Técnico de laboratório - exclusive análises clínicas",
  "Técnico de laboratório de água e esgotos",
  "Técnico de laboratório de análises clínicas",
  "Técnico de laboratório de análises físico-químicas (materiais de construção)",
  "Técnico de laboratório de análises físico-químicas (petróleo)",
  "Técnico de laboratório de eletrônica de automação",
  "Técnico de laboratório e fiscalização desportiva",
  "Técnico de laboratório em patologia clínica",
  "Técnico de laboratório industrial",
  "Técnico de laboratório médico",
  "Técnico de laminação em siderurgia",
  "Técnico de laticínios",
  "Técnico de lingotamento",
  "Técnico de linhas de acabamento em siderurgia",
  "Técnico de manipulação de tráfego telefônico",
  "Técnico de manutenção aeronáutica",
  "Técnico de manutenção de aeronaves",
  "Técnico de manutenção de computador",
  "Técnico de manutenção de equipamento de comutação telefônica",
  "Técnico de manutenção de equipamento de transmissão",
  "Técnico de manutenção de obras",
  "Técnico de manutenção de sistemas de aeronaves",
  "Técnico de manutenção de sistemas e instrumentos",
  "Técnico de manutenção elétrica",
  "Técnico de manutenção elétrica de máquina",
  "Técnico de manutenção eletrônica",
  "Técnico de manutenção eletrônica (circuitos de máquinas com comando numérico)",
  "Técnico de manutenção industrial",
  "Técnico de máquina autenticadora",
  "Técnico de máquina de escrever elétrica e manual",
  "Técnico de máquina de escrever eletrônica",
  "Técnico de máquinas (a explosão)",
  "Técnico de máquinas eletrônicas",
  "Técnico de massas alimentícias",
  "Técnico de matéria-prima e material",
  "Técnico de metalografia e petrografia",
  "Técnico de meteorologia",
  "Técnico de mineração",
  "Técnico de mineração (águas subterrâneas)",
  "Técnico de mineração (minerais sólidos)",
  "Técnico de mineração (óleo e petróleo)",
  "Técnico de mineração (pesquisa geológica)",
  "Técnico de mineração (tóriom zircônio e outros minerais raros)",
  "Técnico de mixagem",
  "Técnico de montagem",
  "Técnico de montagem (caldeiraria)",
  "Técnico de obras",
  "Técnico de obras civis",
  "Técnico de oficina ortopédica",
  null,
  "Técnico de operação (química, petroquímica e afins)",
  "Técnico de operação de transporte",
  "Técnico de operação eletrotécnica",
  "Técnico de operação em processos químicos e petroquímicos",
  "Técnico de operação em refino de petróleo e gás",
  "Técnico de operações de serviços de máquinas e veículos",
  "Técnico de operações de telecomunicações",
  "Técnico de operações e serviços bancários - câmbio",
  "Técnico de operações e serviços bancários - crédito imobiliário",
  "Técnico de operações e serviços bancários - crédito rural",
  "Técnico de operações e serviços bancários - leasing",
  "Técnico de operações e serviços bancários - renda fixa e variável",
  "Técnico de orientação profissional",
  "Técnico de ortopedia",
  "Técnico de painel de controle",
  "Técnico de palco",
  "Técnico de panificação",
  "Técnico de perfuração (minas)",
  "Técnico de pescado e derivados",
  "Técnico de pesquisa mineral",
  "Técnico de planejamento da indústria metalúrgica (estruturas metálicas)",
  "Técnico de planejamento da indústria metalúrgica (soldagem)",
  "Técnico de planejamento de obras",
  "Técnico de planejamento de produção",
  "Técnico de planejamento e programação da manutenção",
  "Técnico de processamento em refino de petróleo e gás",
  "Técnico de processamento industrial (petroquímica)",
  "Técnico de processo de fabricação",
  "Técnico de processo petroquímico",
  "Técnico de processo petroquímico (operador de equipamentos)",
  "Técnico de processos (borracha)",
  "Técnico de processos (plástico)",
  "Técnico de processos minerais",
  "Técnico de produção",
  "Técnico de produção (gás combustível)",
  "Técnico de produção (petroquímica)",
  "Técnico de produção de alimentos",
  "Técnico de produção eletrônica",
  "Técnico de produção em refino de petróleo",
  "Técnico de produção florestal",
  "Técnico de produção na extração de petróleo",
  "Técnico de programação de produção",
  "Técnico de programação e controle da produção e expedição",
  "Técnico de programação e controle de serviço de manutenção",
  "Técnico de projeto (eletrotécnico)",
  "Técnico de projetos e obras de infra-estrutura de estradas",
  "Técnico de projetos elétricos",
  "Técnico de projetos mecânicos",
  "Técnico de prótese e órtese",
  "Técnico de prótese ortopédica",
  "Técnico de rádio (reparo)",
  "Técnico de rádio e televisão",
  "Técnico de radioterapia",
  "Técnico de recursos minerais",
  "Técnico de rede (telecomunicações)",
  "Técnico de redes de água e esgoto",
  "Técnico de redução na siderurgia (primeira fusão)",
  "Técnico de reflorestamento",
  "Técnico de refratário em siderurgia",
  "Técnico de refrigeração (fabricação)",
  "Técnico de refrigeração (instalação)",
  "Técnico de resseguros",
  "Técnico de saneamento",
  "Técnico de saneamento e controle ambiental",
  "Técnico de seguros",
  "Técnico de sinistros",
  "Técnico de sistema automação industrial",
  "Técnico de sistema de televisão",
  "Técnico de sistema de tratamento de água",
  "Técnico de sistemas audiovisuais",
  "Técnico de sistemas industriais (petroquímica)",
  "Técnico de suporte à inteligência (grupo apoio)",
  "Técnico de suporte ao usuário de tecnologia da informação",
  "Técnico de suporte de ti",
  "Técnico de teatro",
  "Técnico de telecomunicações (telefonia)",
  "Técnico de telefonia",
  "Técnico de teleprocessamento",
  "Técnico de televisão",
  "Técnico de tempos e métodos",
  "Técnico de transmissão (telecomunicações)",
  "Técnico de transmissor de rádio",
  "Técnico de transporte ferroviário",
  "Técnico de tratamento térmico (metalurgia)",
  "Técnico de tributos estadual",
  "Técnico de tributos municipal",
  "Técnico de tubulação (caldeiraria)",
  "Técnico de vendas",
  "Técnico do mobiliário",
  "Técnico do sono",
  "Técnico eletricista",
  "Técnico eletrônico",
  "Técnico eletrônico de manutenção industrial",
  "Técnico eletrônico de processo",
  "Técnico eletrônico em geral",
  "Técnico em açúcar e álcool",
  "Técnico em acupuntura",
  "Técnico em administração",
  "Técnico em administração de comércio exterior",
  "Técnico em agente comunitário de saúde",
  "Técnico em agricultura",
  "Técnico em agrimensura",
  "Técnico em agropecuária",
  "Técnico em análise de controle de produção",
  "Técnico em análises clínicas",
  "Técnico em aparelho gessado",
  "Técnico em aromas",
  "Técnico em atendimento e vendas",
  "Técnico em automação industrial",
  "Técnico em automobilística",
  "Técnico em avicultura",
  "Técnico em banco de sangue",
  "Técnico em biblioteconomia",
  "Técnico em biotecnologia",
  "Técnico em bioterismo",
  "Técnico em borracha",
  "Técnico em calçados e artefatos de couro",
  "Técnico em caldeiraria",
  "Técnico em calibração",
  "Técnico em canteiro de obras de construção civil",
  "Técnico em captações bioelétricas do cérebro",
  "Técnico em carcinicultura",
  "Técnico em cerâmica",
  "Técnico em citopatologia",
  "Técnico em confecções do vestuário",
  "Técnico em construção civil (obras de saneamento)",
  "Técnico em construção civil de obras de infra-estrutura de estradas",
  "Técnico em criação de animais de laboratório",
  "Técnico em curtimento",
  "Técnico em curtimento (supervisor de primeira linha)",
  "Técnico em desenho de construção civil",
  "Técnico em desenvolvimento de processos (fabricação de ferramentas)",
  "Técnico em desenvolvimento de processos (manutenção de ferramentas)",
  "Técnico em desenvolvimento de produtos (ferramentas mecânicas)",
  "Técnico em direitos autorais",
  "Técnico em documentação",
  "Técnico em eletrocardiografia",
  "Técnico em eletrocardiograma",
  "Técnico em eletroencefalografia",
  "Técnico em eletroencefalograma",
  "Técnico em eletromecânica",
  "Técnico em enfermagem socorrista",
  "Técnico em enologia",
  "Técnico em equipamentos ópticos",
  "Técnico em espirometria",
  "Técnico em estatística (escriturário)",
  "Técnico em estética",
  "Técnico em estruturas metálicas",
  "Técnico em exploração de petróleo",
  "Técnico em farmácia",
  "TÉCNICO EM FARMÁCIA E EM MANIPULAÇÃO FARMACÊUTICA",
  "Técnico em ferramentas elétricas",
  "Técnico em fibras ópticas",
  "Técnico em fotônica",
  "Técnico em geodésia e cartografia",
  "Técnico em geofísica",
  "Técnico em geologia",
  "Técnico em geoquímica",
  "Técnico em geotecnia",
  "Técnico em gesso hospitalar",
  "Técnico em gesso ortopédico",
  "Técnico em gravação de áudio",
  "Técnico em hemodiálise",
  "Técnico em hemodinâmica",
  "Técnico em hemoterapia",
  "Técnico em hemotransfusão",
  "Técnico em hidrografia",
  "Técnico em hidrometria (obras de saneamento)",
  "Técnico em higiene ocupacional",
  "Técnico em histologia",
  "Técnico em imobilizações do aparelho locomotor",
  "Técnico em imobilizações gessadas",
  "Técnico em imunobiológicos",
  "Técnico em injeção (borracha)",
  "Técnico em injeção (plástico)",
  "Técnico em instalação de equipamentos de áudio",
  "Técnico em instalação de sistemas ópticos",
  "Técnico em instrumentação",
  "Técnico em instrumentos de sopro",
  "Técnico em inteligência (grupo informações)",
  "Técnico em laboratório (borracha)",
  "Técnico em laboratório (plástico)",
  "Técnico em laboratório de farmácia",
  "Técnico em laboratório e campo de construção civil",
  "Técnico em laboratório óptico",
  "Técnico em laser",
  "Técnico em madeira",
  "Técnico em madeira e mobiliário",
  "Técnico em malococultura",
  "Técnico em mamografia",
  "Técnico em manutenção aeronáutica",
  "Técnico em manutenção de aeronaves",
  "Técnico em manutenção de balanças",
  "Técnico em manutenção de equipamentos de informática",
  "Técnico em manutenção de equipamentos e instrumentos médico-hospitalares",
  "Técnico em manutenção de hidrômetros",
  "Técnico em manutenção de instrumentos de medição e precisão",
  "Técnico em manutenção de máquinas",
  "Técnico em manutenção de sistema hidráulico de aeronaves",
  "Técnico em manutenção mecatrônica",
  "Técnico em masterização de áudio",
  "Técnico em materiais ópticos",
  "Técnico em materiais, produtos cerâmicos e vidros",
  "Técnico em mecânica de precisão",
  "Técnico em mecatrônica - automação da manufatura",
  "Técnico em mecatrônica - robótica",
  "Técnico em medicina nuclear",
  "Técnico em medidas ópticas",
  "Técnico em meio ambiente, segurança e saúde",
  "Técnico em metalurgia (soldagem)",
  "Técnico em métodos e processos (fabricação de ferramentas)",
  "Técnico em métodos eletrográficos em cardiologia",
  "Técnico em métodos eletrográficos em encefalografia",
  "Técnico em métodos gráficos em cardiologia",
  "Técnico em métodos não invasivos em cardiologia",
  "Técnico em mexilhões",
  "Técnico em mitilicultura",
  "Técnico em mixagem de áudio",
  "Técnico em moluscos",
  "Técnico em montagem aeronáutica",
  "Técnico em móveis (fabricação)",
  "Técnico em móveis e esquadrias",
  "Técnico em museologia",
  "Técnico em nutrição",
  "Técnico em nutrição e dietética",
  "Técnico em óptica e optometria",
  "Técnico em ostras",
  "Técnico em ovinocaprinocultura",
  "Técnico em patologia clínica",
  "Técnico em pecuária",
  "Técnico em pesquisa mineral",
  "Técnico em petroquímica",
  "Técnico em piscicultura",
  "Técnico em planejamento de lavra de minas",
  "Técnico em planejamento de obras de infra-estrutura de estradas",
  "Técnico em planejamento de obras de infra-estrutura de saneamento",
  "Técnico em plástico",
  "Técnico em podologia",
  "Técnico em polimento de superfície óptica",
  "Técnico em polissonografia",
  "Técnico em processamento mineral",
  "Técnico em processamento mineral (exceto petróleo)",
  "Técnico em programação de computador",
  "Técnico em programação visual",
  "Técnico em projeto de aúdio",
  "Técnico em prova de função pulmonar",
  "Técnico em química de alimentos",
  "Técnico em quiropraxia",
  "Técnico em radiologia",
  "Técnico em radiologia e imagenologia",
  "Técnico em radiologia médica",
  "Técnico em radiologia odontológica",
  "Técnico em ranicultura",
  "Técnico em refinação de petróleo",
  "Técnico em registro e informação em saúde",
  "Técnico em reparação de equipamentos fotográficos",
  "Técnico em reparação de máquinas fotográficas",
  "Técnico em ressonância magnética",
  "Técnico em saúde bucal",
  "Técnico em saúde bucal da estratégia de saúde da família",
  "Técnico em secretariado",
  "Técnico em secretariado (português)",
  "Técnico em segurança do trabalho",
  "Técnico em segurança industrial",
  "Técnico em sinais navais",
  "Técnico em sinalização náutica",
  "Técnico em soldagem",
  "Técnico em sondagem (obras de saneamento)",
  "Técnico em sonorização",
  "Técnico em suporte de documentação",
  "Técnico em teclado musical",
  "Técnico em tomografia",
  "Técnico em tratamento de efluentes",
  "Técnico em turismo",
  "Técnico em unhas",
  "Técnico em uti",
  "Técnico em vidros",
  "Técnico em vitivinicultura",
  "Técnico em zootecnia",
  "Técnico engessador",
  "Técnico especializado em produção mineral",
  "Técnico florestal",
  "Técnico gessista",
  "Técnico gráfico",
  "Técnico hidrometrista",
  "Técnico instrumentista",
  "Técnico instrumentista (manutenção de instrumentos de medição e precisão)",
  "Técnico madeireiro",
  "Técnico mecânico",
  "Técnico mecânico (aeronaves)",
  "Técnico mecânico (calefação, ventilação e refrigeração)",
  "Técnico mecânico (embarcações)",
  "Técnico mecânico (energia nuclear)",
  "Técnico mecânico (equipamentos médicos e odontológicos)",
  "Técnico mecânico (máquinas)",
  "Técnico mecânico (motores)",
  "Técnico mecânico aeronáutico",
  "Técnico mecânico de processos",
  "Técnico mecânico em ar condicionado",
  "Técnico mecânico em automação",
  "Técnico mecânico montador",
  "Técnico mecânico na fabricação de ferramentas",
  "Técnico mecânico na fabricação de máquinas, sistemas e instrumentos",
  "Técnico mecânico na manutenção de ferramentas",
  "Técnico mecânico na montagem de máquinas, sistemas e instrumentos",
  "Técnico mecânico naval",
  "Técnico montador de aeronaves",
  "Técnico moveleiro",
  "Técnico na extração de petróleo e gás natural",
  "Técnico naval",
  "Técnico operacional de serviços de correios",
  "Técnico optometrista",
  "Técnico orçamentista de obras na construção civil",
  "Técnico ortopédico",
  "Técnico petroquímico",
  "Técnico químico",
  "Técnico químico (plástico)",
  "Técnico químico - exclusive análises químicas",
  "Técnico químico de petróleo",
  "Técnico químico em borracha",
  "Técnico químico em couro",
  "Técnico químico em curtimento",
  "Técnico químico industrial",
  "Técnico silvicultor",
  "Técnico têxtil",
  "Técnico têxtil (beneficiamento)",
  "Técnico têxtil (tratamentos químicos)",
  "Técnico têxtil de fiação",
  "Técnico têxtil de malharia",
  "Técnico têxtil de tecelagem",
  "TÉCNICOS AGRÍCOLAS",
  "TÉCNICOS DA INTELIGÊNCIA",
  "TÉCNICOS DE APOIO À BIOENGENHARIA",
  "TÉCNICOS DE APOIO À BIOTECNOLOGIA",
  "TÉCNICOS DE APOIO EM PESQUISA E DESENVOLVIMENTO",
  "TÉCNICOS DE CONTROLE DA PRODUÇÃO",
  "TÉCNICOS DE DESENVOLVIMENTO DE SISTEMAS E APLICAÇÕES",
  "TÉCNICOS DE IMOBILIZAÇÕES ORTOPÉDICAS",
  "TÉCNICOS DE LABORATÓRIO INDUSTRIAL",
  "TÉCNICOS DE LABORATÓRIOS DE SAÚDE E BANCOS DE SANGUE",
  "TÉCNICOS DE ODONTOLOGIA",
  "TÉCNICOS DE OPERAÇÃO DE REGISTROS SONORO/AUDIOVISUAIS",
  "TÉCNICOS DE PRODUÇÃO DE INDÚSTRIAS QUÍMICAS, PETROQUÍMICAS, REFINO DE PETRÓLEO, GÁS E",
  "TÉCNICOS DE SEGUROS E AFINS",
  "TÉCNICOS DE SUPORTE E MONITORAÇÃO AO USUÁRIO DE TECNOLOGIA DA INFORMAÇÃO.",
  "TÉCNICOS DO MOBILIÁRIO E AFINS",
  "TÉCNICOS DO VESTUÁRIO",
  "TÉCNICOS E AUXILIARES DE ENFERMAGEM",
  "TÉCNICOS EM ADMINISTRAÇÃO",
  "TÉCNICOS EM AQÜICULTURA",
  "TÉCNICOS EM ARTES GRÁFICAS",
  "TÉCNICOS EM ÁUDIO",
  "TÉCNICOS EM BIBLIOTECONOMIA",
  "TÉCNICOS EM BIOLOGIA",
  "TÉCNICOS EM CALIBRAÇÃO E INSTRUMENTAÇÃO",
  "TÉCNICOS EM CENOGRAFIA",
  "TÉCNICOS EM CONSTRUÇÃO CIVIL (EDIFICAÇÕES)",
  "TÉCNICOS EM CONSTRUÇÃO CIVIL (OBRAS DE INFRAESTRUTURA)",
  "TÉCNICOS EM CONTABILIDADE",
  "TÉCNICOS EM CONTROLE AMBIENTAL, UTILIDADES E TRATAMENTO DE EFLUENTES",
  "TÉCNICOS EM ELETRICIDADE E ELETROTÉCNICA",
  "TÉCNICOS EM ELETROMECÂNICA",
  "TÉCNICOS EM ELETRÔNICA",
  "TÉCNICOS EM FABRICAÇÃO DE PRODUTOS PLÁSTICOS E DE BORRACHA",
  "TÉCNICOS EM FOTÔNICA",
  "TÉCNICOS EM GEOLOGIA",
  "TÉCNICOS EM GEOMÁTICA",
  "Técnicos em manobras em equipamentos de convés",
  "TÉCNICOS EM MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS BIOMÉDICOS",
  "TÉCNICOS EM MANUTENÇÃO E REPARAÇÃO DE INSTRUMENTOS DE MEDIÇÃO E PRECISÃO",
  "TÉCNICOS EM MATERIAIS, PRODUTOS CERÂMICOS E VIDROS",
  "TÉCNICOS EM MECÂNICA VEICULAR",
  "TÉCNICOS EM MECATRÔNICA",
  "TÉCNICOS EM METALURGIA (ESTRUTURAS METÁLICAS)",
  "TÉCNICOS EM MINERAÇÃO",
  "TÉCNICOS EM MONTAGEM, EDIÇÃO E FINALIZAÇÃO DE MÍDIA AUDIOVISUAL",
  "TÉCNICOS EM MUSEOLOGIA E AFINS",
  "TÉCNICOS EM NECRÓPSIA E TAXIDERMISTAS",
  "TÉCNICOS EM OPERAÇÃO DE APARELHOS DE PROJEÇÃO",
  "TÉCNICOS EM OPERAÇÕES E SERVIÇOS BANCÁRIOS",
  "TÉCNICOS EM ÓPTICA E OPTOMETRIA",
  "TÉCNICOS EM PECUÁRIA",
  "TÉCNICOS EM PRODUÇÃO, CONSERVAÇÃO  E DE QUALIDADE DE ALIMENTOS",
  "TÉCNICOS EM PRÓTESES ORTOPÉDICAS",
  "TÉCNICOS EM SECRETARIADO, TAQUÍGRAFOS E ESTENOTIPISTAS",
  "TÉCNICOS EM SEGURANÇA DO TRABALHO",
  "TÉCNICOS EM SERVIÇOS DE TURISMO E ORGANIZAÇÃO DE EVENTOS",
  "TÉCNICOS EM SIDERURGIA",
  "TÉCNICOS EM TELECOMUNICAÇÕES",
  null,
  "TÉCNICOS EM TRANSPORTES AÉREOS",
  "TÉCNICOS EM TRANSPORTES METROFERROVIÁRIOS",
  "TÉCNICOS EM TRANSPORTES POR VIAS NAVEGÁVEIS E OPERAÇÕES PORTUÁRIAS",
  "TÉCNICOS EM TRANSPORTES RODOVIÁRIOS",
  "TÉCNICOS FLORESTAIS",
  "TÉCNICOS MARÍTIMOS E FLUVIÁRIOS DE MÁQUINAS",
  "TÉCNICOS MARÍTIMOS, FLUVIÁRIOS E PESCADORES DE CONVÉS",
  "TÉCNICOS MECÂNICOS (FERRAMENTAS)",
  "TÉCNICOS MECÂNICOS NA FABRICAÇÃO E MONTAGEM DE MÁQUINAS, SISTEMAS E INSTRUMENTOS",
  "TÉCNICOS MECÂNICOS NA MANUTENÇÃO DE MÁQUINAS, SISTEMAS E INSTRUMENTOS",
  "TÉCNICOS QUÍMICOS",
  "TÉCNICOS TÊXTEIS",
  "Tecnólogo de engenharia rural",
  "Tecnólogo de evento",
  "Tecnólogo em agroindústria",
  "Tecnólogo em agronegócio",
  "Tecnólogo em agronegócio digital",
  "Tecnólogo em alimentos",
  "Tecnólogo em análise de desenvolvimento de sistema",
  "Tecnólogo em automação",
  "Tecnólogo em automação industrial",
  "Tecnólogo em banco de dados",
  "Tecnólogo em construção civil",
  "Tecnólogo em construção civil-modalidade edifícios",
  "Tecnólogo em construção civil-modalidade hidráulica",
  "Tecnólogo em construção civil-modalidade movimento de terra e pavimentação",
  null,
  "Tecnólogo em construção de edifícios",
  "Tecnólogo em controle de obras",
  "Tecnólogo em cosmetologia e estética",
  "Tecnólogo em cosmetologia e estética facial e corporal",
  "Tecnólogo em design de jóias",
  "Tecnólogo em design de moda",
  "Tecnólogo em design de móveis",
  "Tecnólogo em design de produtos",
  "Tecnólogo em design gráfico",
  "Tecnólogo em edificações",
  "Tecnólogo em eletricidade",
  "Tecnólogo em eletrônica",
  "Tecnólogo em enologia",
  "Tecnólogo em estética",
  "Tecnólogo em estética corporal, facial e capilar",
  "Tecnólogo em estética e cosmética",
  "Tecnólogo em estradas",
  "Tecnólogo em fabricação mecânica",
  "Tecnólogo em gastronomia",
  "Tecnólogo em gestão administrativo- financeira",
  "Tecnólogo em gestão ambiental",
  "Tecnólogo em gestão da tecnologia da informação",
  "Tecnólogo em gestão de cooperativas",
  "Tecnólogo em gestão de segurança empresarial",
  "Tecnólogo em gestão de segurança privada",
  "Tecnólogo em gestão de sistema de informação",
  "Tecnólogo em gestão de turismo",
  "Tecnólogo em gestão dos processos produtivos do vestuário",
  "Tecnólogo em gestão financeira",
  "Tecnólogo em gestão hospitalar",
  "Tecnólogo em gestão pública",
  "Tecnólogo em hotelaria",
  "Tecnólogo em laticínios",
  "Tecnólogo em logística de transporte",
  "Tecnólogo em mecânica",
  "Tecnólogo em mecatrônica",
  "Tecnólogo em mecatrônica industrial",
  "Tecnólogo em meio ambiente",
  "Tecnólogo em metalurgia",
  "Tecnólogo em oftalmologia",
  "Tecnólogo em petróleo e gás",
  "Tecnólogo em processamento de carnes",
  "Tecnólogo em processamento de dados",
  "Tecnólogo em processo de produção",
  "Tecnólogo em processo de produção e usinagem",
  "Tecnólogo em processos ambientais",
  "Tecnólogo em processos metalúrgicos",
  "Tecnólogo em processos químicos",
  "Tecnólogo em processos químicos industriais",
  "Tecnólogo em produção audiovisual",
  "Tecnólogo em produção audiovisual( cinema e vídeo)",
  "Tecnólogo em produção audiovisual( rádio e tv)",
  "Tecnólogo em produção de açúcar e álcool",
  "Tecnólogo em produção de música eletrônica",
  "Tecnólogo em produção de vestuário",
  "Tecnólogo em produção fonográfica",
  "Tecnólogo em produção gráfica",
  "Tecnólogo em produção industrial",
  "Tecnólogo em produção joalheira",
  "Tecnólogo em produção moveleira",
  "Tecnólogo em produção multimídia",
  "Tecnólogo em produção musical",
  "Tecnólogo em produção sulcroalcooleira",
  "Tecnólogo em radiologia",
  "Tecnólogo em redes de computadores",
  "Tecnólogo em redes de telecomunicações",
  "Tecnólogo em rochas ornamentais",
  "Tecnólogo em saneamento ambiental",
  "Tecnólogo em saúde",
  "Tecnólogo em secretariado",
  "Tecnólogo em secretariado escolar",
  "Tecnólogo em segurança da informação",
  "Tecnólogo em segurança do trabalho",
  "Tecnólogo em sistemas biomédicos",
  "Tecnólogo em sistemas de telecomunicações",
  "Tecnólogo em sistemas para internet",
  "Tecnólogo em soldagem",
  "Tecnólogo em telecomunicações",
  "Tecnólogo em telemática",
  "Tecnólogo em viticultura e enologia",
  "Tecnólogo gráfico",
  "Tecnólogo metalurgista",
  "Tecnólogo oftálmico",
  "TECNÓLOGOS E TÉCNICOS EM MÉTODOS DE DIAGNÓSTICOS E TERAPÊUTICA",
  "TECNÓLOGOS E TÉCNICOS EM TERAPIAS COMPLEMENTARES E ESTÉTICAS",
  "Teleatendente de emergência",
  "Teleator",
  "Teleatriz",
  "Telefonista",
  "Telefonista bilíngüe",
  "Telefonista-líder",
  "Telefonista-monitor",
  "Teleoperador",
  "Teleoperador ativo (telemarketing)",
  "Teleoperador de apoio (telemarketing)",
  "Teleoperador de marketing (híbrido)",
  "Teleoperador de suporte técnico (telemarketing)",
  "Teleoperador receptivo (telemarketing)",
  "Telhador (telhas de argila e materiais similares)",
  "Telhador (telhas de cimento-amianto)",
  "Telhador (telhas metálicas)",
  "Telhador (telhas plásticas)",
  "TELHADORES (REVESTIMENTOS RÍGIDOS)",
  "Telheiro",
  "Temperador de metais e de compósitos",
  "Temperador de vidro",
  "Tenente do corpo de bombeiros militar",
  "Tenente-brigadeiro",
  "Tenente-coronel bombeiro militar",
  "Tenente-coronel da aeronáutica",
  "Tenente-coronel da polícia militar",
  "Tenente-coronel do corpo de bombeiros militar",
  "Tenente-coronel do exército",
  "TENENTES DA POLÍCIA MILITAR",
  "TENENTES DO CORPO DE BOMBEIROS MILITAR",
  "Tenista profissional",
  "Teóloga",
  "Teólogo",
  "Terapeuta",
  "Terapeuta alternativo",
  "Terapeuta holístico",
  "Terapeuta naturalista",
  "Terapeuta ocupacional",
  "TERAPEUTAS OCUPACIONAIS, ORTOPTISTAS E PSICOMOTRICISTAS",
  "Terceiro-sargento da aeronáutica",
  "Terceiro-sargento da marinha",
  "Terceiro-sargento do exército",
  "Terminógrafo",
  "Terminólogo",
  "Terreirista de café",
  "Terrereiro de café",
  "Tesoureiro",
  "Tesoureiro de banco",
  "Testemunha qualificada do matrimônio",
  "Tijoleiro",
  "Tineiro",
  "Tingidor de couros e peles",
  "Tingidor de fios",
  "Tingidor de roupas",
  "Tingidor de tecidos",
  "Tintureiro lavagem de roupas (comércio varejista)",
  "TINTUREIROS, LAVADEIROS E AFINS, A MÁQUINA",
  "Tipógrafo",
  "Tipógrafo chapista",
  "Tirador - na cultura de cacau",
  "Tirador de látex",
  "Tirador de leite - produtor",
  "Tirador de machos - na fundição",
  "Tirador de óleo de copaíba",
  "Tirador de palha - na cultura",
  "Tirador de poaia",
  "Tirador de resinas",
  "Tisiologista",
  "Titeriteiro",
  "Tocador de gado - na pecuária",
  "Tocoginecologista",
  "Tomatecultor",
  "Tombador de cana-de-açúcar",
  "Toneleiro",
  "Tony de soirée",
  "Topógrafo",
  "Toqueiro - seringueiro",
  "Torneiro (lavra de pedra)",
  "Torneiro ajustador",
  "Torneiro ceramista",
  "Torneiro cnc",
  "Torneiro ferramenteiro",
  "Torneiro mecânico",
  "Torneiro na usinagem convencional de madeira",
  "Torneiro repuxador",
  "Torrador de cacau",
  "Torrador de café",
  "Torrador de chá",
  "Torrador de guaraná",
  "Torrista (petróleo)",
  "Torrista - na extração de petróleo e gás",
  "Tosador",
  "Tosador de animais domésticos",
  "Tosquiador",
  "Toucinheiro na conservação de alimentos",
  "Toy hunji",
  "Toy vodunnon",
  "Trabalhador  na fabricação de artefatos de cimento amianto",
  "Trabalhador  na fabricação de pedras abrasivas",
  "Trabalhador - na criação de peixe - exclusive empregador",
  "Trabalhador - na criação de peixes - exclusive conta própria",
  "Trabalhador - na pecuária - exclusive conta própria e empregador",
  "Trabalhador agrícola polivalente",
  "Trabalhador agropecuário em geral",
  "Trabalhador braçal - na agropecuária - conta própria",
  "Trabalhador braçal - na agropecuária - exclusive conta própria",
  "Trabalhador braçal - na agropecuária - exclusive empregador",
  "Trabalhador da avicultura de corte",
  "Trabalhador da avicultura de postura",
  "Trabalhador da caprinocultura",
  "Trabalhador da coleta de sementes",
  "Trabalhador da criação de ostras",
  "Trabalhador da cultura de abacate",
  "Trabalhador da cultura de abacaxi",
  "Trabalhador da cultura de acerola",
  "Trabalhador da cultura de algodão",
  "Trabalhador da cultura de ameixa",
  "Trabalhador da cultura de amora",
  "Trabalhador da cultura de arroz",
  "Trabalhador da cultura de atemóia",
  "Trabalhador da cultura de banana",
  "Trabalhador da cultura de cacau",
  "Trabalhador da cultura de café",
  "Trabalhador da cultura de cajá",
  "Trabalhador da cultura de caju",
  "Trabalhador da cultura de cana-de-açúcar",
  "Trabalhador da cultura de caqui",
  "Trabalhador da cultura de carambola",
  "Trabalhador da cultura de cítricos",
  "Trabalhador da cultura de cupuaçu",
  "Trabalhador da cultura de erva-mate",
  "Trabalhador da cultura de especiarias",
  "Trabalhador da cultura de feijão, lentilha e ervilha",
  "Trabalhador da cultura de framboesa",
  "Trabalhador da cultura de fruta-pão",
  "Trabalhador da cultura de fumo",
  "Trabalhador da cultura de goiaba",
  "Trabalhador da cultura de graviola",
  "Trabalhador da cultura de guaraná",
  "Trabalhador da cultura de jaca",
  "Trabalhador da cultura de jenipapo",
  "Trabalhador da cultura de laranja e outros cítricos",
  "Trabalhador da cultura de maçã",
  "Trabalhador da cultura de malva",
  "Trabalhador da cultura de manga",
  "Trabalhador da cultura de maracujá",
  "Trabalhador da cultura de melancia",
  "Trabalhador da cultura de melão",
  "Trabalhador da cultura de milho e sorgo",
  "Trabalhador da cultura de morango",
  "Trabalhador da cultura de nectarina",
  "Trabalhador da cultura de pêra",
  "Trabalhador da cultura de pêssego",
  "Trabalhador da cultura de pinha",
  "Trabalhador da cultura de pitanga",
  "Trabalhador da cultura de plantas aromáticas e medicinais",
  "Trabalhador da cultura de plantas ornamentais",
  "Trabalhador da cultura de sisal",
  "Trabalhador da cultura de tamarindo",
  "Trabalhador da cultura de trigo, aveia, cevada e triticale",
  "Trabalhador da cultura de umbu",
  "Trabalhador da cultura de uva",
  "Trabalhador da cultura do rami",
  "Trabalhador da cultura oiticica",
  "Trabalhador da cunicultura",
  "Trabalhador da elaboração de pré-fabricados (cimento amianto)",
  "Trabalhador da elaboração de pré-fabricados (concreto armado)",
  "Trabalhador da exploração de abútua",
  "Trabalhador da exploração de açacuzeiro",
  "Trabalhador da exploração de açaí",
  "Trabalhador da exploração de amapá",
  "Trabalhador da exploração de ameixeira",
  "Trabalhador da exploração de amesca",
  "Trabalhador da exploração de andiroba",
  "Trabalhador da exploração de angico",
  "Trabalhador da exploração de aroeira",
  "Trabalhador da exploração de babaçu",
  "Trabalhador da exploração de bacaba",
  "Trabalhador da exploração de bacupari",
  "Trabalhador da exploração de bacuri",
  "Trabalhador da exploração de barbatimão",
  "Trabalhador da exploração de breu",
  "Trabalhador da exploração de buriti",
  "Trabalhador da exploração de butiá",
  "Trabalhador da exploração de canela",
  "Trabalhador da exploração de capituí",
  "Trabalhador da exploração de carapanaúba",
  "Trabalhador da exploração de carnaúba",
  "Trabalhador da exploração de castanha",
  "Trabalhador da exploração de catuaba",
  "Trabalhador da exploração de caxinguba",
  "Trabalhador da exploração de cedro",
  "Trabalhador da exploração de cerejeira",
  "Trabalhador da exploração de cipó mil homens",
  "Trabalhador da exploração de cipó-cravo",
  "Trabalhador da exploração de cipó-de-alho",
  "Trabalhador da exploração de cipó-escada",
  "Trabalhador da exploração de cipó-macaco",
  "Trabalhador da exploração de cipó-seda",
  "Trabalhador da exploração de cipó-sume",
  "Trabalhador da exploração de cipó-tuína",
  "Trabalhador da exploração de coco-da-praia",
  "Trabalhador da exploração de copaíba",
  "Trabalhador da exploração de coquirana",
  "Trabalhador da exploração de craveira da terra",
  "Trabalhador da exploração de croapé",
  "Trabalhador da exploração de cumaru",
  "Trabalhador da exploração de espécies produtoras de gomas não elásticas",
  "Trabalhador da exploração de favela",
  "Trabalhador da exploração de graviola",
  "Trabalhador da exploração de inharé",
  "Trabalhador da exploração de ipê da flor amarela",
  "Trabalhador da exploração de ipê da flor roxa",
  "Trabalhador da exploração de ipecacuanha",
  "Trabalhador da exploração de jaborandi",
  "Trabalhador da exploração de jacareúba",
  "Trabalhador da exploração de janaguba",
  "Trabalhador da exploração de jatobá",
  "Trabalhador da exploração de jatobá  (resinas)",
  "Trabalhador da exploração de jenipapo",
  "Trabalhador da exploração de jotaicica",
  "Trabalhador da exploração de jucá",
  "Trabalhador da exploração de licuri",
  "Trabalhador da exploração de macaca poranga",
  "Trabalhador da exploração de madeiras tanantes",
  "Trabalhador da exploração de malva (pãina)",
  "Trabalhador da exploração de mangabeira",
  "Trabalhador da exploração de marapoama",
  "Trabalhador da exploração de moreira",
  "Trabalhador da exploração de murici-da-casca-vermelha",
  "Trabalhador da exploração de murumuru",
  "Trabalhador da exploração de mururé",
  "Trabalhador da exploração de oiticica",
  "Trabalhador da exploração de ouricuri",
  "Trabalhador da exploração de pãina",
  "Trabalhador da exploração de pau-para-tudo",
  "Trabalhador da exploração de pau-rosa",
  "Trabalhador da exploração de pequi",
  "Trabalhador da exploração de piaçava",
  "Trabalhador da exploração de pinhão",
  "Trabalhador da exploração de piquiá",
  "Trabalhador da exploração de preciosa",
  "Trabalhador da exploração de pupunha",
  "Trabalhador da exploração de quina",
  "Trabalhador da exploração de quinarana",
  "Trabalhador da exploração de raízes produtoras de substâncias aromáticas, medicinais e tóxicas",
  "Trabalhador da exploração de resinas",
  "Trabalhador da exploração de sacaca",
  "Trabalhador da exploração de sangue de grado",
  "Trabalhador da exploração de santa maria",
  "Trabalhador da exploração de sucuba",
  null,
  "Trabalhador da exploração de sucuruba",
  "Trabalhador da exploração de tatajuba",
  "Trabalhador da exploração de timbó",
  "Trabalhador da exploração de tucum",
  "Trabalhador da extração de pedras abrasivas",
  "Trabalhador da extração de substâncias aromáticas",
  "Trabalhador da extração de substâncias aromáticas, medicinais e tóxicas, em geral",
  "Trabalhador da extração de substâncias medicinais",
  "Trabalhador da extração de substâncias tóxicas",
  "Trabalhador da extração de tripas e demais vísceras",
  "Trabalhador da fabricação de laticínio",
  "Trabalhador da fabricação de lixa",
  "Trabalhador da fabricação de munição e explosivos",
  "Trabalhador da fabricação de pedras artificiais",
  "Trabalhador da fabricação de resinas e vernizes",
  "Trabalhador da manutenção de edificações",
  "Trabalhador da matecultura",
  "Trabalhador da ovinocultura",
  "Trabalhador da pecuária (asininos e muares)",
  "Trabalhador da pecuária (bovinos corte)",
  "Trabalhador da pecuária (bovinos leite)",
  "Trabalhador da pecuária (bubalinos)",
  "Trabalhador da pecuária (eqüinos)",
  "Trabalhador da produção de sementes agrícolas",
  "Trabalhador da suinocultura",
  "Trabalhador de confecções de bijouterias e jóias de fantasia",
  "Trabalhador de enxada - na agropecuária",
  "Trabalhador de extração florestal, em geral",
  "Trabalhador de fabricação de arreios",
  "Trabalhador de fabricação de bolas de couro",
  "Trabalhador de fabricação de comprimidos e drágeas",
  "Trabalhador de fabricação de margarina",
  "Trabalhador de fabricação de sorvete",
  "Trabalhador de fabricação de tintas",
  "Trabalhador de fabricação de vinhos",
  "Trabalhador de fruticultura em geral",
  "Trabalhador de limpeza de pescado - na industrialização",
  "Trabalhador de linha férrea",
  "Trabalhador de manutenção de edifícios",
  "Trabalhador de manutenção e preparação de tosqueadeiras",
  "Trabalhador de pavimentação (rodovias)",
  "Trabalhador de pecuária polivalente",
  "Trabalhador de preparação de pescados (limpeza)",
  "Trabalhador de serviços de limpeza e conservação de áreas públicas",
  "Trabalhador de tapetes de nós, a  mão",
  "Trabalhador de tratamento de leite",
  "Trabalhador de tratamento do leite e fabricação de laticínios e afins",
  "Trabalhador de vinhos achampanhados",
  "Trabalhador do acabamento de artefatos de tecidos e couros",
  "Trabalhador do beneficiamento de fumo",
  "Trabalhador do plantio e trato de árvores ornamentais",
  "Trabalhador do sexo",
  "Trabalhador em criatórios de animais produtores de veneno",
  "Trabalhador na apicultura",
  "Trabalhador na captura de crustáceos",
  "Trabalhador na colocação de inscrições em pedras",
  "Trabalhador na confecção de peças de couro",
  "Trabalhador na conservação de edifícios",
  "Trabalhador na criação de mexilhões",
  "Trabalhador na criação de rãs",
  "Trabalhador na cultura de agave",
  "Trabalhador na cultura de amendoim",
  "Trabalhador na cultura de batata-doce",
  "Trabalhador na cultura de batata-inglesa",
  "Trabalhador na cultura de beterraba",
  "Trabalhador na cultura de canola",
  "Trabalhador na cultura de cebola",
  "Trabalhador na cultura de coco-da-baía",
  "Trabalhador na cultura de dendê",
  "Trabalhador na cultura de hortaliças",
  "Trabalhador na cultura de mamona",
  "Trabalhador na cultura de mandioca",
  "Trabalhador na cultura de pimenta-do-reino",
  "Trabalhador na cultura de romã",
  "Trabalhador na cultura de soja",
  "Trabalhador na cultura de tomate",
  "Trabalhador na cultura do girassol",
  "Trabalhador na cultura do linho",
  "Trabalhador na fabricação de artefatos de cimento",
  "Trabalhador na fabricação de esmeris",
  "Trabalhador na fabricação de produtos abrasivos",
  "Trabalhador na fabricação de rebolos",
  "Trabalhador na floricultura (cultivo de mudas)",
  "Trabalhador na floricultura (flores e folhagens de corte)",
  "Trabalhador na floricultura (flores em vaso)",
  "Trabalhador na floricultura (forrações)",
  "Trabalhador na floricultura (plantas ornamentais)",
  "Trabalhador na formação de pastagem",
  "Trabalhador na jacarecultura",
  "Trabalhador na lavra de ouro",
  "Trabalhador na minhocultura",
  "Trabalhador na olericultura (frutos e sementes)",
  "Trabalhador na olericultura (legumes)",
  "Trabalhador na olericultura (raízes, bulbos e tubérculos)",
  "Trabalhador na olericultura (talos, folhas e flores)",
  "Trabalhador na operação de sistema de irrigação localizada (microaspersão e gotejamento)",
  "Trabalhador na operação de sistema de irrigação por aspersão (pivô central)",
  "Trabalhador na operação de sistemas convencionais de irrigação por aspersão",
  "Trabalhador na operação de sistemas de irrigação e aspersão (alto propelido)",
  "Trabalhador na operação de sistemas de irrigação e aspersão (canhão)",
  "Trabalhador na operação de sistemas de irrigação por superfície e drenagem",
  "Trabalhador na pesca de crustáceo",
  "Trabalhador na piscicultura",
  "Trabalhador na produção de mudas e sementes",
  "Trabalhador na sericicultura",
  "Trabalhador no cultivo de árvores frutíferas",
  "Trabalhador no cultivo de espécies frutíferas rasteiras",
  "Trabalhador no cultivo de flores e folhagens de corte",
  "Trabalhador no cultivo de flores em vaso",
  "Trabalhador no cultivo de forrações",
  "Trabalhador no cultivo de mudas",
  "Trabalhador no cultivo de plantas ornamentais",
  "Trabalhador no cultivo de quiui (kiwi)",
  "Trabalhador no cultivo de trepadeiras frutíferas",
  "Trabalhador no cultivo de uva de mesa",
  "Trabalhador no cultivo de uva de vinho e suco",
  "Trabalhador polivalente da confecção de calçados",
  "Trabalhador polivalente do curtimento de couros e peles",
  "Trabalhador portuário de bloco",
  "Trabalhador portuário de capatazia",
  "Trabalhador rural",
  "Trabalhador rural (bubalinos)",
  "Trabalhador rural - na agropecuária - exclusive conta própria",
  "Trabalhador rural - na agropecuária - exclusive empregador",
  "Trabalhador sericícola",
  "Trabalhador volante da agricultura",
  "TRABALHADORES AGRÍCOLAS DA CULTURA DE ESPECIARIAS E DE PLANTAS AROMÁTICAS E MEDICINAIS",
  "TRABALHADORES AGRÍCOLAS NA CULTURA DE GRAMÍNEAS",
  "TRABALHADORES AGRÍCOLAS NA CULTURA DE PLANTAS FIBROSAS",
  "TRABALHADORES AGRÍCOLAS NA CULTURA DE PLANTAS OLEAGINOSAS",
  null,
  "TRABALHADORES AGRÍCOLAS NA FRUTICULTURA",
  "TRABALHADORES AGRÍCOLAS NA OLERICULTURA",
  "TRABALHADORES AGRÍCOLAS NAS CULTURAS DE PLANTAS ESTIMULANTES",
  "TRABALHADORES AGRÍCOLAS NO CULTIVO DE FLORES E PLANTAS ORNAMENTAIS",
  "TRABALHADORES AGROPECUÁRIOS EM GERAL",
  "TRABALHADORES AQUAVIÁRIOS",
  "TRABALHADORES ARTESANAIS DA CONFECÇÃO DE CALÇADOS E ARTEFATOS DE COUROS E PELES",
  "TRABALHADORES ARTESANAIS DA CONFECÇÃO DE PEÇAS E TECIDOS",
  "TRABALHADORES ARTESANAIS DE PRODUTOS DE PAPEL E PAPELÃO",
  "TRABALHADORES ARTESANAIS NA CONSERVAÇÃO DE ALIMENTOS",
  "TRABALHADORES ARTESANAIS NA INDÚSTRIA DO FUMO",
  "TRABALHADORES ARTESANAIS NA PASTEURIZAÇÃO DO LEITE E NA FABRICAÇÃO DE LATICÍNIOS E AFINS",
  "TRABALHADORES AUXILIARES DOS SERVIÇOS FUNERÁRIOS",
  "TRABALHADORES AUXILIARES NOS SERVIÇOS DE ALIMENTAÇÃO",
  "TRABALHADORES DA CLASSIFICAÇÃO DE FIBRAS TÊXTEIS E LAVAGEM DE LÃ",
  "TRABALHADORES DA COLETA E SELEÇÃO DE MATERIAL RECICLÁVEL",
  "TRABALHADORES DA CONFECÇÃO DE ARTEFATOS DE TECIDOS, COUROS E SINTÉTICOS",
  "TRABALHADORES DA EXTRAÇÃO DE MINERAIS LÍQUIDOS E GASOSOS",
  "TRABALHADORES DA EXTRAÇÃO DE MINERAIS SÓLIDOS",
  "TRABALHADORES DA FABRICAÇÃO DE CERÂMICA ESTRUTURAL PARA CONSTRUÇÃO",
  "TRABALHADORES DA FABRICAÇÃO DE MUNIÇÃO E EXPLOSIVOS QUÍMICOS",
  "TRABALHADORES DA IMPRESSÃO GRÁFICA",
  "TRABALHADORES DA INDÚSTRIA DE BENEFICIAMENTO DE GRÃOS, CEREAIS E AFINS",
  "TRABALHADORES DA IRRIGAÇÃO E DRENAGEM",
  "TRABALHADORES DA MECANIZAÇÃO AGRÍCOLA",
  "TRABALHADORES DA MECANIZAÇÃO FLORESTAL",
  "TRABALHADORES DA PINTURA DE EQUIPAMENTOS, VEÍCULOS, ESTRUTURAS METÁLICAS E DE",
  "TRABALHADORES DA PRÉ-IMPRESSÃO GRÁFICA",
  "TRABALHADORES DA PREPARAÇÃO DA CONFECÇÃO DE CALÇADOS",
  "TRABALHADORES DA PREPARAÇÃO DA CONFECÇÃO DE ROUPAS",
  "TRABALHADORES DA PREPARAÇÃO DE ARTEFATOS DE TECIDOS, COUROS E TAPEÇARIA",
  "TRABALHADORES DA PREPARAÇÃO DO CURTIMENTO DE COUROS E PELES",
  "TRABALHADORES DA TRANSFORMAÇÃO DE VIDROS PLANOS",
  "TRABALHADORES DE ACABAMENTO DE CALÇADOS",
  "TRABALHADORES DE ACABAMENTO, TINGIMENTO E ESTAMPARIA DAS INDÚSTRIAS TÊXTEIS",
  "TRABALHADORES DE APOIO À AGRICULTURA",
  "TRABALHADORES DE APOIO À PESCA",
  "TRABALHADORES DE ARTE E  DO ACABAMENTO EM MADEIRA DO MOBILIÁRIO",
  "TRABALHADORES DE ATENÇÃO, DEFESA E PROTEÇÃO A PESSOAS EM SITUAÇÃO DE RISCO E",
  "TRABALHADORES DE BENEFICIAMENTO DE MINÉRIOS",
  "TRABALHADORES DE BENEFICIAMENTO DE PEDRAS ORNAMENTAIS",
  "TRABALHADORES DE CALDEIRARIA E SERRALHERIA",
  "TRABALHADORES DE CARGAS E DESCARGAS DE MERCADORIAS",
  "Trabalhadores de conservação de rodovias",
  "TRABALHADORES DE EMBALAGEM E DE ETIQUETAGEM",
  "TRABALHADORES DE ESTRUTURAS DE ALVENARIA",
  "TRABALHADORES DE EXTRAÇÃO DE MINERAIS SÓLIDOS (OPERADORES DE MÁQUINAS)",
  "TRABALHADORES DE FORJAMENTO DE METAIS",
  "TRABALHADORES DE FUNDIÇÃO DE METAIS PUROS E DE LIGAS METÁLICAS",
  "TRABALHADORES DE INSTALAÇÕES ELÉTRICAS",
  "TRABALHADORES DE LABORATÓRIO FOTOGRÁFICO E RADIOLÓGICO",
  "TRABALHADORES DE MANOBRAS DE TRANSPORTES SOBRE TRILHOS",
  null,
  "TRABALHADORES DE MANUTENÇÃO DE ROÇADEIRAS, MOTOSERRAS E SIMILARES",
  "TRABALHADORES DE MOLDAGEM DE METAIS E DE LIGAS METÁLICAS",
  "TRABALHADORES DE MONTAGEM DE ESTRUTURAS DE MADEIRA, METAL E COMPÓSITOS EM OBRAS CIVIS",
  "TRABALHADORES DE SEGURANÇA E ATENDIMENTO AOS USUÁRIOS NOS TRANSPORTES",
  "TRABALHADORES DE SERVIÇOS VETERINÁRIOS, DE HIGIENE E ESTÉTICA DE ANIMAIS DOMÉSTICOS",
  "TRABALHADORES DE SOLDAGEM E CORTE DE LIGAS METÁLICAS",
  "TRABALHADORES DE TECELAGEM MANUAL, TRICÔ, CROCHÊ, RENDAS E AFINS",
  "TRABALHADORES DE TRAÇAGEM E MONTAGEM DE ESTRUTURAS METÁLICAS E DE COMPÓSITOS",
  "TRABALHADORES DE TRATAMENTO DE SUPERFÍCIES DE METAIS E DE COMPÓSITOS (TERMOQUÍMICOS)",
  "TRABALHADORES DE TRATAMENTO E PREPARAÇÃO DA MADEIRA",
  "TRABALHADORES DE TRATAMENTO TÉRMICO DE METAIS",
  "TRABALHADORES DE TREFILAÇÃO E ESTIRAMENTO DE METAIS PUROS E LIGAS METÁLICAS",
  "TRABALHADORES DO ACABAMENTO DE ARTEFATOS DE TECIDOS E COUROS",
  "TRABALHADORES DO ACABAMENTO DE COUROS E PELES",
  "TRABALHADORES DO ACABAMENTO GRÁFICO",
  "TRABALHADORES DO CURTIMENTO DE COUROS E PELES",
  "TRABALHADORES DOS SERVIÇOS DOMÉSTICOS EM GERAL",
  "TRABALHADORES DOS SERVIÇOS FUNERÁRIOS",
  "TRABALHADORES ELEMENTARES DE SERVIÇOS DE MANUTENÇÃO VEICULAR",
  "TRABALHADORES EM REGISTROS E INFORMAÇÕES EM SAÚDE",
  "TRABALHADORES EM SERVIÇOS DE PROMOÇÃO E APOIO À SAÚDE",
  "TRABALHADORES FLORESTAIS POLIVALENTES",
  "TRABALHADORES NA AVICULTURA E CUNICULTURA",
  "TRABALHADORES NA CRIAÇÃO DE INSETOS E ANIMAIS ÚTEIS",
  "TRABALHADORES NA DEGUSTAÇÃO E CLASSIFICAÇÃO DE GRÃOS E AFINS",
  "TRABALHADORES NA FABRICAÇÃO DE CACHAÇA, CERVEJA, VINHOS E OUTRAS BEBIDAS",
  null,
  "TRABALHADORES NA FABRICAÇÃO E CONSERVAÇÃO DE ALIMENTOS",
  "TRABALHADORES NA FABRICAÇÃO E REFINO DE AÇÚCAR",
  "TRABALHADORES NA INDUSTRIALIZAÇÃO DE CAFÉ, CACAU, MATE E DE PRODUTOS AFINS",
  "TRABALHADORES NA OPERAÇÃO DE MÁQUINAS DE CONCRETO USINADO E AFINS",
  "TRABALHADORES NA OPERAÇÃO DE MÁQUINAS DE TERRAPLENAGEM E FUNDAÇÕES",
  "TRABALHADORES NA PASTEURIZAÇÃO DO LEITE E NA FABRICAÇÃO DE LATICÍNIOS  E AFINS",
  "TRABALHADORES NA PECUÁRIA DE ANIMAIS DE GRANDE PORTE",
  "TRABALHADORES NA PECUÁRIA DE ANIMAIS DE MÉDIO PORTE",
  "TRABALHADORES NO ATENDIMENTO EM ESTABELECIMENTOS DE SERVIÇOS DE ALIMENTAÇÃO, BEBIDAS E Família",
  "TRABALHADORES NO BENEFICIAMENTO DO SAL",
  "TRABALHADORES NOS  SERVIÇOS DE MANUTENÇÃO DE EDIFICAÇÕES",
  "TRABALHADORES NOS SERVIÇOS DE ADMINISTRAÇÃO DE EDIFÍCIOS",
  "TRABALHADORES NOS SERVIÇOS DE ARTE CORPORAL",
  "TRABALHADORES NOS SERVIÇOS DE CLASSIFICAÇÃO E ENTREGAS DE CORRESPONDÊNCIAS,",
  "TRABALHADORES NOS SERVIÇOS DE COLETA DE RESÍDUOS, DE LIMPEZA E CONSERVAÇÃO DE ÁREAS",
  "TRABALHADORES NOS SERVIÇOS DE EMBELEZAMENTO E HIGIENE",
  "TRABALHADORES OPERACIONAIS DE CONSERVAÇÃO DE VIAS PERMANENTES (EXCETO TRILHOS)",
  "TRABALHADORES POLIVALENTES DA CONFECÇÃO DE ARTEFATOS DE TECIDOS E COUROS",
  "TRABALHADORES POLIVALENTES DA CONFECÇÃO DE CALÇADOS",
  "TRABALHADORES POLIVALENTES DAS INDÚSTRIAS TÊXTEIS",
  "TRABALHADORES POLIVALENTES DO CURTIMENTO DE COUROS E PELES",
  "TRABALHADORES SUBAQUÁTICOS",
  "TRABALHADORES TIPOGRÁFICOS LINOTIPISTAS E AFINS",
  "Traçador de caldeiraria",
  "Traçador de estruturas metálicas",
  "Traçador de pedras",
  null,
  "Traçador de vidros",
  "Tradutor",
  "Tradutor de libras",
  "Tradutor de textos eletrônicos",
  "Tradutor de textos escritos",
  "Tradutor público juramentado",
  "Tradutor simultâneo",
  "Tradutor-intérprete de libras",
  "Trançador - na fabricação de chapéus de palha",
  "Trançador de cabos de aço",
  "Trançador de couro",
  "TRANÇADORES E LACEIROS DE CABOS DE AÇO",
  "Transformador de tubos de vidro",
  "Transitário de cargas",
  "Transportador de árvores",
  "Transportador de madeiras",
  "Trapezista",
  "Tratador (asininos e muares)",
  "Tratador (eqüinos)",
  "Tratador - na pecuária",
  "Tratador de animais",
  "Tratador de animais (jardim zoológico)",
  "Tratador de animais - caprinos",
  "Tratador de animais - na pecuária",
  "Tratador de documentos (biblioteconomia)",
  "TRATADORES POLIVALENTES DE ANIMAIS",
  "Tratorista  operador de roçadeira",
  "Tratorista - exclusive na agropecuária",
  "Tratorista agrícola",
  "Tratorista florestal",
  "Tratorista operador de semeadeira",
  "Traumatologista",
  "Trefilador (joalheria e ourivesaria)",
  "Trefilador à máquina",
  "Trefilador de barras de metal, à máquina",
  "Trefilador de borracha",
  "Trefilador de metais preciosos, a  máquina",
  "Trefilador de metais, à máquina",
  "Treinador (asininos e muares)",
  "Treinador (eqüinos)",
  "Treinador assistente de modalidade esportiva",
  "Treinador auxiliar de modalidade esportiva",
  "Treinador de animais (circense)",
  "Treinador de animais domésticos",
  "Treinador esportivo",
  "Treinador profissional de futebol",
  "Triador de lã",
  "Triador de material reciclável",
  "Triador de sucata",
  "Tricotador, à mão",
  "Tricoteiro, à mão",
  "Trinchador",
  "Tripeiro em matadouro",
  "Triticultor",
  "Triticultor - conta própria",
  "Triticultor - empregador",
  "Trocador",
  "Tropeiro",
  "Tropeiro - na cultura de cacau",
  "Trovador",
  "Truqueiro (ferrovias)",
  "Turbineiro na refinação de açúcar",
  "Turismólogo",
  "Tuxaua",
  "Upasaka",
  "Upasika",
  "Urbanista",
  "Urologista",
  "Vacinador",
  "Vagalume",
  "Valeiro - na cultura",
  "Valeteiro - exclusive na agropecuária",
  "Vaqueador de couros e peles",
  "Vaqueiro",
  "Vaqueiro (bovinhos leite)",
  "Vaqueiro (bovinos corte)",
  "Vaqueiro (bubalinos)",
  "Vaqueiro - na agropecuária - exclusive conta própria e empregador",
  "Vaqueiro inseminador",
  "Vaqueiro inseminador (bovinos corte)",
  "Varandeira",
  "Varredor de rua",
  "Varredor na conservação de vias permanentes (exceto trilhos)",
  "Vassoureiro",
  "Vazador (fundição)",
  "Vazador de metais",
  "Vazador de vidro fundido",
  "Vazador de vidro fundido em moldes",
  "Vedete",
  "Vendedor  permissionário",
  "Vendedor - no comércio de mercadorias",
  "Vendedor ambulante",
  "Vendedor de apostas de turfe",
  "Vendedor de cachorro quente",
  "Vendedor de comércio varejista",
  "Vendedor de consórcio",
  "Vendedor de informações comerciais",
  "Vendedor de jornais e revistas",
  "Vendedor de livros",
  "Vendedor de pipocas ambulante",
  "Vendedor de plano de saúde",
  "Vendedor de poule",
  "Vendedor de serviços",
  "Vendedor em comércio atacadista",
  "Vendedor em domicílio",
  "Vendedor interno",
  "Vendedor orçamentista",
  "Vendedor por catálogos",
  "Vendedor porta a porta",
  "Vendedor pracista",
  "VENDEDORES AMBULANTES",
  "VENDEDORES EM BANCAS, QUIOSQUES E BARRACAS",
  "VENDEDORES EM DOMICÍLIO",
  "Vendeiro (comércio varejista)",
  "Ventríloquo",
  "Vereador",
  "Veterinário",
  "VETERINÁRIOS E ZOOTECNISTAS",
  "Vibradorista",
  "Vice-almirante",
  "Vice-diretor de centro de educação infantil público",
  "Vice-diretor de centro de ensino especial público",
  "Vice-diretor de centro de ensino fundamental público",
  "Vice-diretor de centro de ensino médio público",
  "Vice-diretor de centro de ensino médio, profissionalizante público",
  "Vice-diretor de centro de ensino supletivo público",
  "Vice-diretor de centro interescolar de línguas público",
  "Vice-diretor de colégio público",
  "Vice-diretor de escola de classe pública",
  "Vice-diretor de escola pública",
  "Vice-diretor de grupo escolar público",
  "Vice-governador de estado",
  "Vice-governador do distrito federal",
  "Vice-prefeito",
  "Vice-presidente comercial",
  "Vice-presidente da república",
  "Vice-presidente de marketing",
  "Vice-presidente de vendas",
  "Vidente",
  "Vidraceiro",
  "Vidraceiro (comércio varejista)",
  "Vidraceiro (edificações)",
  "Vidraceiro (painéis de vidro)",
  "Vidraceiro (vitrais)",
  "Vidraceiro colocador de vidros",
  "Vidraceiro de clarabóias",
  "Vidraceiro de quadros e molduras (comércio varejista)",
  "VIDRACEIROS (REVESTIMENTOS RÍGIDOS)",
  "Vidrador-esmaltador em cerâmica",
  "Vidreiro",
  "VIDREIROS E CERAMISTAS (ARTE E DECORAÇÃO)",
  "Vigário",
  "Vigia",
  "Vigia da proa",
  "Vigia de rua",
  "Vigia florestal",
  "Vigia noturno",
  "Vigia portuário",
  "Vigilante",
  "Vigilante bancário",
  "Vigilante de aeroporto",
  "Vigilante de proteção de aeroporto",
  "Vigilante de saúde",
  "VIGILANTES E GUARDAS DE SEGURANÇA",
  "Vimeiro",
  "Vinagreiro",
  "Vinhateiro",
  "Vinicultor",
  "Virador de calçados",
  "Virador de chapas",
  "Virador em matadouro",
  "Visitador de saúde",
  "Visitador de saúde em domicílio",
  "Visitador sanitário",
  "Visitador sanitário domiciliar",
  "Vistoriador de alarmes",
  "Vistoriador de identificação veicular",
  "Vistoriador de risco de auto",
  "Vistoriador de sinistros",
  "Vistoriador naval",
  "Vistoriador veicular",
  "Visual merchandiser",
  "Viticultor",
  "Vitralista (vitrais)",
  "Vitrificador",
  "Vitrinista",
  "Viveirista (mudas)",
  "Viveirista agrícola",
  "Viveirista de flores e plantas ornamentais",
  "Viveirista florestal",
  "Vocabularista",
  "Voduno ( ministro de culto religioso)",
  "Vodunsi (ministro de culto religioso)",
  "Vodunsi poncilê (ministro de culto religioso)",
  "Volante na agricultura",
  "Vulcanizador de correias transportadoras",
  "Xaropeiro",
  "Xaropeiro - na indústria de bebidas",
  "Xeramõe (ministro de culto religioso)",
  "Xondaria (ministro de culto religioso)",
  "Xondáro (ministro de culto religioso)",
  "Ywyrájá (ministro de culto religioso)",
  "Zagueiro - jogador de futebol",
  "Zelador",
  "Zelador de edifício",
  "Zenji (missionário)",
  "Zincador",
  "Zoologista",
  "Zoólogo",
  "Zootecnista",
]

export const JOBS_OPTIONS: TOptions[] = JOBS.map((job) => ({
  label: job,
  value: job,
}))
