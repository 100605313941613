import BVComponentsOld from '@bv/components-old';
import React, { useEffect, useState } from 'react';
import FormWrapper from 'react-bootstrap/Form';
import BVUtilsOld from '@bv/utils-old';
import BVUtils from '@bv/utils';
import StepTitle from '../StepTitle';
import ValidatePassword from '../ValidatePassword';
import { AreaPasswordRules, ContainerStyled, LinkPoliciesStyled, PoliciesWrapperStyled } from './styles';

import { Form as IForm } from '../../interfaces/form.interface';
import { ValidatePassword as ValidatePasswordClass } from '../../utils/ValidatePassword';

type PasswordStepProps = {
  visible: boolean;
  form: IForm;
};

const PasswordStep: React.FC<PasswordStepProps> = ({ visible, form }: PasswordStepProps) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const validPassword = new ValidatePasswordClass(password);

  const allRulesIsValid = validPassword.isAllValid() && password == rePassword;

  useEffect(() => {
    if (allRulesIsValid) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }, [allRulesIsValid]);

  const [validated, setValidated] = useState(false);

  async function onRegister(event) {
    event.preventDefault();

    const formEvent = event.currentTarget;
    if (formEvent.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!allRulesIsValid) {
      return;
    }

    setValidated(true);

    const __form: IForm = {
      ...form,
      acceptedTerms: acceptedTerms,
      password: password,
      repeatPassword: rePassword,
    };

    setLoading(true);

    try {
      const response = await BVUtilsOld.api.userApi.create(__form);
      BVUtils.toastHandler({ type: 'success', message: 'Cadastro realizado com sucesso' });

      console.log(JSON.stringify(response, null, 2));

      if (response.data.data?.accessToken) {
        BVUtilsOld.cookiesHelper.setAccessToken(response.data.data.accessToken);
        BVUtilsOld.cookiesHelper.setCurrentUser(response.data.data.newUser);
        BVUtilsOld.cookiesHelper.setCompany(
          response.data.data.newUser.companiesHistory[response.data.data.newUser.companiesHistory.length - 1].newCompanyId,
        );
        window.localStorage.setItem('token', JSON.stringify(response.data.data.accessToken));

        setTimeout(() => {
          location.href = '/dashboard';
        }, 3000);
      } else {
        setTimeout(() => {
          location.href = '/login';
        }, 3000);
      }
    } catch (err) {
      if (err?.messages) {
        err.messages.map((message) => BVUtils.toastHandler({ type: 'error', message }));
      } else {
        BVUtils.toastHandler({ type: 'error', message: err.message });
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <FormWrapper noValidate validated={validated} onSubmit={onRegister}>
      <ContainerStyled visible={visible} className="row">
        <div className="col-xs-12">
          <StepTitle>Criar senha</StepTitle>
        </div>

        <div className="col-xs-12">
          <div className="row">
            <div className="col-md-6">
              <BVComponentsOld.Form
                isPasswordInput
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                noValidate
                required
                id="password"
                autoComplete="off"
                type="password"
                label="Senha"
                placeholder="🤫 Shhhhhhhh..."
                // text="A senha deve conter letras, números, caracteres especiais e o mínimo de 8 digitos."
                value={password}
                onChange={(el) => {
                  setPassword(el.target.value);
                }}
              />
            </div>
            <div className="col-md-6">
              <BVComponentsOld.Form
                isPasswordInput
                noValidate
                showPassword={showConfirmPassword}
                setShowPassword={setShowConfirmPassword}
                required
                type="password"
                autoComplete="off"
                label="Repetir Senha"
                placeholder="🤫 Shhhhhhhh..."
                // text="Agora ficou fácil, aqui é só repetir a senha mais uma vez."
                value={rePassword}
                onChange={(el) => {
                  // handleForm("repeatPassword", el.target.value);
                  setRePassword(el.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-xs-12">
          <div className="row">
            <div className="col-md-12">
              <AreaPasswordRules>
                <ValidatePassword
                  title={
                    <span>
                      A senha deve conter no mínimo <b>8 digitos</b>
                    </span>
                  }
                  isValid={validPassword.minLength()}
                />

                <ValidatePassword
                  title={
                    <span>
                      A senha deve conter pelo menos <b>um número</b>
                    </span>
                  }
                  isValid={validPassword.containNumber()}
                />

                <ValidatePassword
                  title={
                    <span>
                      A senha deve conter pelo menos um <b>caractere especial (@!$-_%&*#)</b>{' '}
                    </span>
                  }
                  isValid={validPassword.containsSpecialChars()}
                />
                <ValidatePassword title={<span>As senhas devem ser iguais</span>} isValid={allRulesIsValid} />
              </AreaPasswordRules>
            </div>
          </div>
        </div>

        <div className="col-xs-12">
          <PoliciesWrapperStyled>
            <BVComponentsOld.Form
              required
              type="switch"
              label="Li e estou de acordo com os "
              value={acceptedTerms.toString()}
              onChange={() => setAcceptedTerms((old) => !old)}
              id="accepted-terms"
            />
            <LinkPoliciesStyled href="https://www.eyecarehealth.com.br/termos-de-uso" target="_blank">
              Termos de uso
            </LinkPoliciesStyled>
            <span> e </span>
            <LinkPoliciesStyled href="https://eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade" target="_blank">
              Aviso de privacidade
            </LinkPoliciesStyled>
          </PoliciesWrapperStyled>
        </div>

        <div className="col-xs-12">
          <BVComponentsOld.ButtonLoading
            className="i-btn-finish btn btn-primary w-100 flex justify-center"
            type="submit"
            loading={loading}
            disabled={!allRulesIsValid || !acceptedTerms || loading}>
            Concluir cadastro
          </BVComponentsOld.ButtonLoading>
        </div>
      </ContainerStyled>
    </FormWrapper>
  );
};

export default PasswordStep;
