import styled, { keyframes, css } from 'styled-components'

const FloatingVertical = keyframes`
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
`


const FloatingHorizontal = keyframes`
	0% {
		transform: translatex(0px);
	}
	50% {
		transform: translatex(-20px);
	}
	100% {
		transform: translatex(0px);
	}
`


export const ContainerStyled = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Inter", sans-serif;
    z-index: 1;
    background: linear-gradient(360deg, rgba(51, 255, 182, 0.44) 1.02%, rgba(51, 255, 182, 0) 103.53%), radial-gradient(131.31% 131.31% at 72.81% 43.51%, #407BFF 0%, rgba(40, 69, 220, 0) 100%);
`

export const TitleStyled = styled.div`
    color: white;
    font-weight: 700;
    font-size: max(30px, 2vw);
`

export const ImageWrapperStyled = styled.div`
    width: 100%;
    height: 70vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const CircleStyled = styled.div`
    position: absolute;
    width: 200px;
    height: 200px;
    border: 3px dashed white;
    border-radius: 50%;
    z-index: 1;
`

export const CloudStyled = styled.img`
    position: absolute;
    width: 30%;
    max-width: 540px;
    opacity: 0.2;
    z-index: 2;
    animation: ${FloatingHorizontal} 6s ease-in-out infinite;

    @media (max-width: 1200px) {
        width: 70%;
    }

    @media (max-width: 768px) {
        width: 80%;
    }
`

export const EnvelopStyled = styled.img`
    position: absolute;
    width: 20%;
    max-width: 360px;
    z-index: 3;
    animation: ${FloatingVertical} 6s ease-in-out infinite;

    @media (max-width: 1200px) {
        width: 50%;
    }

    @media (max-width: 768px) {
        width: 60%;
    }
`

export const SubTitleStyled = styled.div`
    color: white;
    font-weight: 600;
    font-size: max(20px, 1.3vw);
`