import ReactSelect from "react-select";

import styled from "styled-components";

export const Select = styled(ReactSelect)<{ showError: boolean }>`
  min-width: 240px;
  max-height: 37px;

  .my-select__control {
    border: ${({ showError }) => (showError ? "1.5px solid  #dc3545" : "")};
    height: 37px;

    :hover {
    }
  }

  .my-select__value-container {
    padding: 0px 8px;
  }

  .my-select__indicator-separator {
    display: none;
  }

  input:focus {
    box-shadow: none;
    outline: none;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
