import React from 'react';
import { ContainerStyled, StepStyled } from './styles';

type StepsProps = {
  step: number;
  setStep: (step: number) => void;
  isSSO: boolean;
};

const Steps: React.FC<StepsProps> = ({ step, setStep, isSSO }: StepsProps) => {
  function goToStep(toStep: number) {
    if (toStep < step) {
      setStep(toStep);
    }
  }

  return (
    <ContainerStyled>
      <StepStyled selected={step >= 1} onClick={() => goToStep(1)} />
      <StepStyled selected={step >= 2} onClick={() => goToStep(2)} />
      {!isSSO && <StepStyled selected={step >= 3} onClick={() => goToStep(3)} />}
    </ContainerStyled>
  );
};

export default Steps;
