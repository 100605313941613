import styled from "styled-components";

export const ContainerStyled = styled.div<{visible: boolean}>`
  display: ${(props) => (props.visible ? "flex" : "none")};

  .form--label {
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(12, 29, 89);

    display: flex;
    align-items: center;
    gap: 8px;
  }

  .cpf__field {
    label {
      display: none;
    }
  }

  .rnm__field {
    padding: 0;

    > div {
      width: 100%;
      padding: 0;
    }
    label {
      display: none;
    }
    .rnm__field__input {
    }
  }
`;
