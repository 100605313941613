import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Invited from './layouts/Invited';
import { BrowserRouter } from 'react-router-dom';

const App = () => (
  <BrowserRouter>
    <div className="container-fluid z-1 absolute bottom-0 left-0 right-0 top-0 p-0">
      <Invited />
      <ToastContainer position="top-right" autoClose={5000} theme="light" />
    </div>
  </BrowserRouter>
);

export default App;
