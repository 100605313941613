import React from 'react';

import * as C from './styles';

export type TOptions = {
  value: string;
  label: string;
};

type ReactSelectProps = {
  onChange: (option: TOptions) => void;
  onInputChange?: (newValue: string) => void;
  options: TOptions[];
  placeholder?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  value: unknown;
  showError?: boolean;
  className?: string;
  id?: string;
  name?: string;
  isLoading?: boolean;
  noOptionsMessage?: () => string | React.ReactNode;
  formatOptionLabel?: (data: TOptions) => React.ReactNode;
};

function ReactSelect({
  // onChange,
  // options,
  // placeholder = "Selecione...",
  isSearchable = true,
  showError,
  className,
  id,
  name,
  isLoading = false,

  // value
  isClearable = true,
  onInputChange,
  noOptionsMessage = () => 'Nenhum resultado encontrado',
  formatOptionLabel = undefined,
  ...rest
}: ReactSelectProps) {
  return (
    <C.Select
      // defaultValue={options[0]}
      // isDisabled={false}
      isClearable={isClearable}
      isSearchable={isSearchable}
      // // onChange={onChange}

      // options={options}
      // instanceId={"sdsdsds"}
      // id="sdsdsds"
      // inputId="sdsdsds"
      // placeholder={placeholder}
      // value={value}
      id={id}
      name={name}
      classNamePrefix="my-select"
      showError={showError}
      {...rest}
      className={className}
      menuPlacement="auto"
      isLoading={isLoading}
      onInputChange={onInputChange}
      noOptionsMessage={noOptionsMessage}
      formatOptionLabel={formatOptionLabel}
    />
  );
}

export default React.memo(ReactSelect);
